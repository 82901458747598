import { Canvas, ICanvasError, IValidationError, RenderEvent } from 'core';
import { DynamicRender } from 'core/DynamicRender';
import { HtmlRender } from 'core/HtmlRender';
import React from 'react';
import { DialogResult } from '../../core';
import { ScreenSource } from '../ScreenSource';
import { DialogContainer } from './DialogContainer';

export class ErrorDialog extends React.Component<{
    error: ICanvasError,
    canvas:Canvas,
    onClose?:(result:DialogResult) => void;
}> {


    render() {
        let title = this.getTitle();
        
        let size:'small' | 'large' = 'small';
        let error = this.props.error;
        if (error.sourceCode){
            size = 'large';
        }
        if (error.render){
            return <ServerRenderedErrorPresenter error={error} message={error.messages[0]} size="small" onClose={this.props.onClose} layerZIndex={15000}/>
        }
        return (<ErrorDialogPresenter message={title} size={size} onClose={this.props.onClose} layerZIndex={15000}>
            {this.renderMessages()}
            {this.renderValidationErrors()}
            {this.renderSourceCode()}
        </ErrorDialogPresenter>);

        /*
        let layer = this.props.canvas.layer;
        if (layer == "dialog" || layer == "lookup") {
            
        }
        return (
            <div className="RT-Error__panel">
                <div className="RT-Error__panel-message">
                    {this.renderErrorList()}
                </div>
            </div>
        );
        */
    }

    getTitle():string{
        let error = this.props.error;
        if (!error) return "Error";
        let title:string;
        let validationErrors = error.validationErrors;
        if (validationErrors){
            let requiredCount = 0;
            for(let i = 0; i < validationErrors.length;i++){
                let v = validationErrors[i];
                if (v.message == "required"){
                    requiredCount++;
                }
            }
            if (requiredCount > 1){
                title = "You missed a few things...";
            }
            else if (requiredCount == 1){
                title = "We need one more thing...";
            }
        }
        if (!title && error.messages){
            title = error.messages[0];
        }

        return title;
    }

    renderMessages(){
        let elems = [];
        let error = this.props.error;
        if (error.messages){
            for(let i = 1; i < error.messages.length;i++){
                elems.push(<div>{error.messages[i]}</div>)
            }
        }
        if (!elems.length) return null;
        return elems;
    }
    renderValidationErrors() {
        let error = this.props.error;
        if (error.validationErrors){
            return ValidationErrorPresenter.renderValidationErrors(error.validationErrors);
        }
        else {
            return null;
        }
    }

    renderSourceCode(){
        let error = this.props.error;
        if (error.sourceCode){
            /*
            return <div style={{marginTop:30}}>
                <ScreenSource source={error.sourceCode} codeErrors={error.codeErrors}/>
            </div>
            */
           let renderEvent = new RenderEvent(this.props.canvas);
           return <div>
                {DynamicRender.render(renderEvent,error.sourceCode)}
            </div>
            /*
            let html = {__html:error.sourceCode};
           return <div dangerouslySetInnerHTML={html}/>
           */
        }
        return null;
    }

}


class ErrorDialogPresenter extends React.Component<{
    message:string;
    size:'small' | 'large';
    onClose?:(result:DialogResult) => void;
    layerZIndex?:number;
}> {

    render() {
        let icon: any;
        
        let headerClass = "Message-Box__header";
        let buttonClass = "Message-Box__buttons";
    
        icon = this.renderErrorIcon();
        headerClass += " Message-Box__header--error";
        buttonClass += " Message-Box__buttons--error";

        let title = this.props.message;
        var header = (
            <div className={headerClass}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {icon}
                </div>
            </div>
        );
        var content = this.props.children;
        var body = (
            <div className="Message-Box__body">
                <div className="Message-Box__title">{title}</div>
                {content}
            </div>
        );

        let buttons;
        buttons = ( <button
            className="RT-Button RT-Button--cancel "
            onClick={this.handleClose}
        >
            Close
        </button>)

    
        var footer = (
            <div className={buttonClass}>
               {buttons}
            </div>
        );

        return (
            <DialogContainer size={this.props.size} zIndex={this.props.layerZIndex} animate>
                {header}
                {body}
                {footer}
            </DialogContainer>
        )
      
    }

    handleClose = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClose({continue:false});
    };

   

 
    renderErrorIcon() {
        return (
            <svg
                className="Message-Box__icon Message-Box__icon--error"
                viewBox="0 0 24 24"
            >
                <path d="M12,7a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V8A1,1,0,0,0,12,7Zm.92,8.62a.76.76,0,0,0-.09-.18l-.12-.15a1,1,0,0,0-1.09-.21,1.15,1.15,0,0,0-.33.21A1,1,0,0,0,11,16a1,1,0,0,0,.08.38.9.9,0,0,0,.54.54.94.94,0,0,0,.76,0,.9.9,0,0,0,.54-.54A1,1,0,0,0,13,16a1.36,1.36,0,0,0,0-.2A.64.64,0,0,0,12.92,15.62ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" />
            </svg>
        );
    }

   
}


class ServerRenderedErrorPresenter extends React.Component<{
    error:ICanvasError,
    message:string;
    size:'small' | 'large';
    onClose?:(result:DialogResult) => void;
    layerZIndex?:number;
}> {

    render() {

        let error = this.props.error;

        let buttonClass = "Message-Box__buttons";
        
        let buttons;
        buttons = ( <button
            className="RT-Button RT-Button--cancel "
            onClick={this.handleClose}
        >
            Close
        </button>);

        let canvas = new Canvas(null);
        let event = new RenderEvent(canvas);
        let content;
        if (error.render.$tag){
            content = HtmlRender.renderTag(event,error.render,0,null);
        }
        else {
            content = DynamicRender.render(event,error.render);
        }
        return (
            <DialogContainer size="error" zIndex={this.props.layerZIndex} animate>
                {content}
                <div className={buttonClass}>
                    {buttons}
                </div>
            </DialogContainer>
        )
    }

    handleClose = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClose({continue:false});
    };

   

 
  

   
}



class ValidationErrorPresenter extends React.Component<{  errors: IValidationError[], title?: string }, any> {

    render() {
        var errors = this.props.errors;
        if (!errors) return null;

        return (
            <div data-retool-error="true">
                {ValidationErrorPresenter.renderValidationErrors(errors)}
            </div>
        );
    }
    
    static renderValidationErrors(errors: IValidationError[]) {
        let required: string[] = [];
        let generalMessages:string[] = [];
        let fieldMessages:string[] = [];
    


        for (let i = 0; i < errors.length; i++) {
            let e = errors[i];
            if (e.field) {
                let label = e.label || e.field;
                if (e.message == "required") {
                    required.push(label);
                } else {
                    fieldMessages.push(label + " " + e.message);
                }
            }
            else {
                generalMessages.push(e.message);
            }
        }

        if (!generalMessages.length && !fieldMessages.length && !required.length) return;
        let requiredFields = required.length ? required.join(", ") : null;

        let genMessageElems;
        if (generalMessages.length){
            genMessageElems =  generalMessages.map((message, index) => <li key={"g" + index} style={{ marginLeft: 20 }}>{message}</li>)
        }

        let fieldMessageElems;
        if (fieldMessages.length){
            fieldMessageElems =  fieldMessages.map((message, index) => <li key={"f" + index} style={{ marginLeft: 20 }}>{message}</li>)
        }
        let requiredContent;
        if (requiredFields){
            let labelColor = "#707784";
            if (required.length > 1){
                requiredContent = <><span style={{color:labelColor,fontWeight:400}}>These fields must be completed: </span>{requiredFields}</>;
            }
            else {
                requiredContent = <><span style={{color:labelColor,fontWeight:400}}>This field must be completed: </span>{requiredFields}</>;
            }
        }
        return (
            <ul className="RT-Form__error">
                {genMessageElems}
                {requiredFields && (
                    <li style={{fontWeight:500, marginLeft: 20 }}>{requiredContent}</li>
                )}
                {fieldMessageElems}
            </ul>
        );
    }
  
}