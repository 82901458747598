import { type } from "os";
import { ExpressionOps } from "./ExpressionOps";
import { Parser } from "./Parser";
import { Serializer } from "./Serializer";
import { TypeChecking } from "./TypeChecking";
import { IEvalContext } from "./types";

export class Evaluator {
    public static evaluate(context:IEvalContext,expr:string):any {
        if (!expr) return;
      
        let f = context.canvas.expressionCache[expr];
        if (f){
            return f(ExpressionOps,context);
        }
    
        let exprFunc = Serializer.createExpressionFunction(expr);
        context.canvas.expressionCache[expr] = exprFunc;
        return exprFunc(ExpressionOps,context);

        /*
        else {
            let typeChecking = new TypeChecking();
            typeChecking.rootSchema = context.canvas.schema;
            typeChecking.defaultRecord = context.canvas.defaultRecord;
            let node = Parser.parse(expr,typeChecking);
            if (!node) return null;
            js = Serializer.serialize(node);
        }
        if (js){
            let exprFunc = Serializer.createExpressionFunction(js);
            context.canvas.expressionCache[expr] = exprFunc;
            return exprFunc(ExpressionOps,context);
        }
        */
    }
}