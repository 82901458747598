import { ActionRef, makeComponent, RenderEvent } from '../core';
import React from 'react';
import { FileDropZone } from './FileUpload';

export const Image = makeComponent( class extends React.Component<{event:RenderEvent,name?:string,url:string;$id:string,styles?:string,width:any}>{
    render(){
        let event = this.props.event;
        let src = this.props.url;
        if (event.designMode && !src){
            return this.renderDesignMode();
        }
        let styles = this.props.event.computeStyles(null,this.props.styles);
        let style:React.CSSProperties = {...styles.style,width:this.props.width};
        if (event.designMode){
            return (<div>
                <img src={src} style={style} className={styles.className + ' auto-space-lg'}/>
            </div>)
        }
        return <img src={src} style={style} className={styles.className + ' auto-space-lg'}/>
    }

    renderDesignMode(){
        return <FileDropZone hasContent={false} onDrop={this.handleDrop} />
    }

    handleDrop = (file:File) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            let filename = file.name;
            let content = e.target.result;
            let canvas = this.props.event.canvas;
            canvas.studioCommandBuffer.setAsset(this.props.$id,content,filename);
            canvas.studioCommandBuffer.send();
            
        }
        reader.readAsDataURL(file);

    }
});