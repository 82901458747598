import { Stack } from 'core/Dialog';
import React from 'react';
import {makeComponent,ActionRef, RenderEvent, Url} from '../core'; 
import { ClickThrottle } from './helpers/ClickThrottle';
import { Icon } from './Icon';


export const EditLink = makeComponent(class extends React.Component<{label:string,action:ActionRef,icon:string}>{
    
    clickThrottle:ClickThrottle;

    render(){
        let button = this.props.action;
        if (!button || button.hidden) return null;
        let icon;
        if (this.props.icon){
            icon = <><Icon icon={this.props.icon} size={16} fill="currentColor"/>
                <div style={{width:4}}/>
            </>
        }
        return <a href='#' className="RT-Link" onClick={this.handleClick}>{icon}{this.props.label}</a>
    }

    handleClick = (e:React.MouseEvent) => {
        this.clickThrottle = this.clickThrottle || new ClickThrottle();
        this.clickThrottle.handle(e,null,() => {
            e.preventDefault();
            e.stopPropagation();
            this.props.action.trigger();
        });
    }
});

