import { RenderEvent } from "core";
import React from "react";

export class DesignerProp extends React.Component<{event:RenderEvent, label:string,value:any}>{
    render(): React.ReactNode {
        let valueStyle:React.CSSProperties = {fontWeight:600};
        let value = this.props.value;
        if (value && value.startsWith && value[0] == "'"){
            valueStyle.color = "#008761";
        }
        return <div style={{display:"flex",alignItems:"center",gap:10,fontSize:"15px",letterSpacing:"0.25px"}}>
            <div style={{paddingRight:0}}>{this.props.label}</div>
            <div style={valueStyle}>{value}</div>
        </div>
    }
}