import { ActionRef, FieldRef, RenderEvent } from '../core';
import React from 'react';
import { Icon } from './Icon';
import { StudioIconPropEditor } from './studio/StudioIconChooser';
import { Expressions } from 'core/studio/Expressions';



export class InlineFieldEditor extends React.Component<{ event: RenderEvent; $id?: string; field: FieldRef; styles?: string; allowEnterForNewLine?: boolean;placeholder?:string }> {

    dirty: boolean;
    editValue: string;
    ref = React.createRef<HTMLTextAreaElement>();

    componentDidMount() {
        let canvas = this.props.event.canvas;
        if (this.props.$id == canvas.designFocus) {
            canvas.designFocus = null;
            this.ref.current.focus();
        }
    }

    render() {
        let styles = this.props.event.computeStyles(null, this.props.styles);
        let divStyle: React.CSSProperties = { ...styles.style, opacity: 0 ,paddingRight:20};
        let textAreaStyle: React.CSSProperties = {
            ...styles.style, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "transparent",
            outline: "none", resize: "none"
        };

        let field = this.props.field;
        let value = this.dirty ? this.editValue : Expressions.toDisplayValue(field.value);
        let placeholder = field.placeholder;
        let displayValue = value;
        if (!value){
            divStyle.opacity = 0.3;
            displayValue = placeholder;
        }

        return <div style={{ position: "relative" }}>
            <div style={divStyle} className={styles.className}>{displayValue || '\u00A0'}</div>
            <textarea data-elem-id={this.props.$id} ref={this.ref} onChange={this.handleChange} onBlur={this.handleBlur} onKeyPress={this.handleKeyPress}
                style={textAreaStyle} className={styles.className} value={value || ''} />
        </div>;

    }

    handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key == "Enter") {
            e.preventDefault();

            let canvas = this.props.event.canvas;
            if (this.dirty) {
                canvas.setValue(this.props.field, Expressions.fromDisplayValue(this.editValue));
            }
            let commands: any[] = [];
            if (this.props.allowEnterForNewLine) {
                commands.push({ command: "AppendLine", target: this.props.$id });
            }
            if (!commands.length)
                return;
            let action = new ActionRef(canvas, "studio-content-updated", {});
            action.trigger({ value: commands });
            this.dirty = false;
            this.forceUpdate();
        }
    };

    handleBlur = (e: React.FocusEvent) => {
        if (!this.dirty)
            return;

        let canvas = this.props.event.canvas;
        let value = Expressions.fromDisplayValue(this.editValue);
        canvas.setValue(this.props.field, value);
        this.dirty = false;
        this.forceUpdate();
    };

    handleChange = (e: React.ChangeEvent) => {
        var target = e.target as HTMLTextAreaElement;
        this.dirty = true;
        this.editValue = target.value;
        this.forceUpdate();
    };



}

export class InlineIconEditor extends React.Component<{event:RenderEvent, field: FieldRef}>{
    render(): React.ReactNode {
        let field = this.props.field;
        let icon = Expressions.toDisplayValue(field.value);
        let iconElem;
        let size = 24;
        if (icon){
            iconElem = <Icon icon={icon} fill="currentColor" size={size}/>
        }
        else {
            iconElem = <div style={{width:size,height:size,border:"dashed 2px rgb(220,220,220)"}}/>
        }

        return <div style={{padding:4,display:"flex",justifyContent:"center",alignItems:'center',cursor:"pointer"}} onClick={this.handleClick}>
            {iconElem}
        </div>
    

    }

    handleClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        StudioIconPropEditor.open(e.currentTarget as HTMLElement,this.props.event,{field:this.props.field});
    }
}
