
import React from 'react';
import {Application, makeComponent, RenderEvent, ActionRef, ComponentStyles} from '../core';
import { ClickThrottle } from './helpers/ClickThrottle';
import { InlineTextPropEditor } from './studio/InlineTextPropEditor';
import { StudioEditorChildren } from './studio/StudioEditorChildren';

 export class Card extends React.Component<{event?:RenderEvent,label?:string,actions?:any,
    $id?:string,onclick?:ActionRef,styles?:string,labelStyles?:string,$elem?:any}>{

    clickThrottle:ClickThrottle;
    render() {
       
        let label = this.props.label;
        let styles = this.props.event.computeStyles(this.props.styles);
        let labelStyles = this.props.event.computeStyles(this.props.labelStyles);
        let event = this.props.event;
        let children;
        if (event.designMode){
            let elem = this.props.$elem;
            children = <div style={{position:"relative"}}>
                <StudioEditorChildren event={event} placeholder="Drop card elements here" children={this.props.children} $elem={elem} $id={this.props.$id}/>
            </div>
        }
        else {
            children = this.props.children;
        }
        return Card.renderSection(event,this.props.$id,children, label,null,this.props.actions,this.handleClick
            ,styles,labelStyles,this.props.$elem);

    }

    handleClick = (e:React.MouseEvent) => {
        if (e.shiftKey){
            e.preventDefault();
            e.stopPropagation();
            let studioWindow = Application.instance.studioWindow;
            if (studioWindow){
                studioWindow.postMessage({action:"showElement",id:this.props.$id},"*");
            }
        }
        else if (this.props.onclick){
            
            this.clickThrottle = this.clickThrottle || new ClickThrottle();
            this.clickThrottle.handle(e,null,() => {
            
                e.preventDefault();
                e.stopPropagation();
                this.props.onclick.trigger();
            });
        
        }
    }



    static renderSection(event:RenderEvent,id:string,content:any, label:string,helpTopic:string,actions:any,
        onClick:(e:React.MouseEvent)=> void,styles:ComponentStyles,labelStyles:ComponentStyles,$elem:any){
        let header;
        let actionElems = actions;

        let classNames = ['RT-Section'];
        if (label || helpTopic || actionElems || event.designMode){
            let labelClass = ['RT-Section__title'];
            labelClass.push(labelStyles.className);
            let title;
            if (event.designMode){
                title = <div style={{flexGrow:1,marginBottom:10}}>
                    <InlineTextPropEditor event={event} $id={id} propName="label" style={{fontWeight:500}} placeHolder="Click to edit card label"
                     $elem={$elem}
                    />
                </div>
            }
            else {
                title = <div className={labelClass.join(' ')}>{label}</div>
            }
            
            let buttons;
            let helpButton;
            if (helpTopic){
              //  helpButton =<Help.DialogHelpButton topic={helpTopic}/>;
            }
            if (actionElems || helpButton){
                buttons = <div className="RT-Section__actions">{actionElems}{helpButton}</div>;
            }

            header = <div className="RT-Section__header" >
                {title}
                {buttons}
            </div>
        }
        else {
            classNames.push('RT-Section--no-label');
        }
       

        classNames.push(styles.className);
        /*
        if(event.designMode){
            classNames.push("hover-container");
        }
        */
        return <div style={styles.style} className={classNames.join(' ')} data-no-drop data-elem-id={id} onClick={onClick}>
            {header}
            {content}
        </div>
    
    }

    static Actions = makeComponent(class extends React.Component<{}>{
        render(){
           return this.props.children || null;
        }
    });
}