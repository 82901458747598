import { FieldRef, RenderEvent } from "core";
import React from "react";

export class DropList extends React.Component<{event:RenderEvent,field:FieldRef},{isOpen:boolean}>{

    ref = React.createRef<HTMLDivElement>();
    render(): React.ReactNode {
        let field = this.props.field;
        let value = field.formattedValue || "Choose section";
        let label;
        if (field.label){
            label = <div style={{fontSize:"12px",fontWeight:500,marginRight:6,color:"var(--rt-label-color)"}}>{field.label}</div>
        }
        return <div  onClick={this.handleClick} style={{padding:4,cursor:"pointer",display:"flex",alignItems:'center',gap:4}}>
            {label}
            <div ref={this.ref} style={{fontWeight:500}}>{value}</div>
            <div style={{borderLeft:"solid 5px transparent",borderRight:"solid 5px transparent",borderTop:"solid 5px currentColor"}}/>
        </div>
    }

    handleClick = (e:React.MouseEvent) =>{
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        canvas.openPopup({target:this.ref.current,render:this.renderPopup});
    }
    
    renderPopup = ({}) => {
        let field = this.props.field;
        let options = field.options;
        let elems = [];
        if (options){
            for(let i = 0 ; i < options.length;i++){
                let option = options[i];
                elems.push(<DropValue key={i} value={option.value} label={option.label} field={field} />)
            }
        }   
        return elems;
    }
}


class DropValue extends React.Component<{label:string,value:string,field:FieldRef}>{
    render(): React.ReactNode {
        return <div style={{padding:"5px 20px",cursor:"pointer"}} onClick={this.handleClick}>
            {this.props.label}
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        let field = this.props.field;
        field.setValue(this.props.value);
        field.canvas.closePopup();
    }
}