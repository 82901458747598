
import React from 'react';
import { Broadcast, ActionRef,  RenderEvent ,CollectionRef, FieldFormatter, Canvas,  ComponentStyles, ContextMenuHandler, ContextMenuHandlerArgs} from 'core';
import { Collection, CollectionArray,  ICollectionGroup, ICollectionSortField } from 'core/Collection';
import { CollectionGrouper, IQueryGroupFooter, IQueryGroupHeader, IQueryGroupMap, IReportTotal, ITotalAccumulator, TotalRowCount, TotalSum } from 'core/CollectionGrouper';
import { IRecordSchema, Schema } from 'core/Schema';
import { ClickThrottle } from '../helpers/ClickThrottle';
import {DataGridGroupHeader} from './DataGridGroupHeader';
import { DataGridGroupFooter } from './DataGridGroupFooter';
import { DynamicRender } from 'core/DynamicRender';
import { Line } from 'components/Line';
import { Popup } from 'components/popups/Popup';
import { AnchorPoint } from 'components/helpers/AnchorPoint';
import { Icon } from 'components/Icon';
import { BoardView } from './BoardView';
import { GridViewType, IDataGrid, IDataGridColumn, IDataGridLayout, IDataGridTotal, IGridState, IRowContextMenuItem } from './types';
import { ElementContextMenu } from 'components/studio/ElementContextMenu';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';





const selectorWidth = 50;


export class DataTable extends React.Component<{event?:RenderEvent,$id?:string,collection:CollectionRef
    ,whenEmpty?:any,actions?:any,search?:any,onRefresh?:ActionRef,layout?:IDataGridLayout,totalBar?:any,label:string,
    onRowClick?:ActionRef}>{
    render(): React.ReactNode {
    
       
        if (this.props.whenEmpty){
            let collection = this.props.collection;
            if (!collection || !collection.rows || collection.rows.length == 0){
                return this.props.whenEmpty;
            }
        }
        let actions = this.props.actions;
       
        return <DataTablePresenter event={this.props.event} $id={this.props.$id} collection={this.props.collection} actions={actions} 
        search={this.props.search} onRefresh={this.props.onRefresh} layout={this.props.layout} totalBar={this.props.totalBar} 
        label={this.props.label} onRowClick={this.props.onRowClick}/>
    }
}
export class DataTablePresenter extends React.Component<{event?:RenderEvent;$id:string;defaultView?:string,collection:CollectionRef
    ,layout?:IDataGridLayout,emptyState?:any,withShell?:boolean,actions?:any,search?:any,onRefresh?:ActionRef,totalBar?:any,label?:string,
    onRowClick?:ActionRef}>{

  
    broadcast = new Broadcast();

    containerRef: React.RefObject<HTMLDivElement> = React.createRef();
    headerContainer :React.RefObject<HeaderContainer> = React.createRef();
  
    grid:IDataGrid;

    groupMap:IQueryGroupMap;
    currentPage = 0;
    currentView:string;
    
    renderedPageVersion:string;
    renderedLayout:any;
    renderedCollectionVersion:number;
    contextMenuHandler:ContextMenuHandler;

    clickThrottle = new ClickThrottle();


    constructor(props){
        super(props);
        let canvas = this.props.event.canvas;
        this.contextMenuHandler = canvas.app.contextMenuManager.createHandler(canvas,this.handleContextMenuClick);

    }
    componentDidMount(){
        this.broadcast.connect(this);
        let canvas = this.props.event.canvas;
        canvas.app.contextMenuManager.connect(this.contextMenuHandler);
        let collection = this.props.collection;
        if (collection){
            canvas.connectDataListener(this,collection.name,(action,rows:CollectionArray)=> {
                canvas.sidebarState.isOpen = false;
                canvas.sidebarContent = null;
                this.createGrid(this.props.layout,canvas,collection,rows); 
                this.formatData(canvas,rows);
            })
           
        }
    
        window.addEventListener("resize",this.measure);
        if (this.grid && this.grid.columns && !this.grid.measured){
            this.measure();
        }
        
    }

    componentWillUnmount(){
        this.broadcast.disconnect(this);
        let canvas = this.props.event.canvas;
        canvas.disconnectDataListener(this);
        if (this.contextMenuHandler){
            canvas.app.contextMenuManager.disconnect(this.contextMenuHandler);
        }
        window.removeEventListener("resize",this.measure);
    }

    componentDidUpdate(){
        if (this.grid && this.grid.columns && !this.grid.measured){
            this.measure();
        }
    }

    formatData(canvas:Canvas,rows:any[]){
    
        let grid = this.grid;
        if (!rows || !this.grid) return;
       
        let sort:ICollectionSortField[] = [];
        if (grid.groups){
            for(let i = 0; i < grid.groups.length;i++){
                let group = grid.groups[i];
                if (group.sort){
                    for(let j = 0; j < group.sort.length;j++){
                        sort.push(group.sort[j]);
                    }
                }
            }
        }
        if (grid.sort){
            for(let i = 0; i < grid.sort.length; i++){
                sort.push(grid.sort[i]);
            }
        }
        if (sort && sort.length){
            Collection.sort(rows,grid.schema,sort);
        }
        let grouper = new CollectionGrouper();
        grouper.pageSize = grid.pageSize || 25;
        grouper.groups = grid.groups;
        grouper.reportGroup = grid.reportGroup;
        grouper.definedTotals = grid.totals;
        this.groupMap = grouper.groupRows(rows);
        this.currentPage = 0;
        let collection = this.props.collection;
        let currentRow = Collection.getCurrentRow(canvas,collection.name);
        if (!currentRow && rows.length){
            Collection.setCurrentRow(canvas,collection.name,rows[0]);
        }
    }

   

    render(){
        let collection = this.props.collection;
        let rows:any[];
        let canvas = this.props.event.canvas;
        if (collection){ 
            rows = collection.rows;
            if (!this.grid || this.renderedPageVersion != canvas.pageInfo.version || this.renderedLayout != this.props.layout){
                this.createGrid(this.props.layout,canvas,collection,rows); //todo: detect layout change ?
            }
            let version = Collection.getVersion(rows);
            if (!this.grid || version != this.renderedCollectionVersion){
                this.currentView = this.props.defaultView;
                this.formatData(canvas,rows);
            }
            this.renderedCollectionVersion = version;
        }
        else {
            return null;
        }
      
        let grid = this.grid;
       
        let totalWidth = grid.totalWidth;
        if (grid.rowSelector){
            totalWidth += selectorWidth;
        }
        let bodyStyle:React.CSSProperties = {
            position:"relative",
            minWidth:"100%",
            width:totalWidth
        }

        let isGrouped:boolean = (grid.groups && grid.groups.length != 0)

        let gridState:IGridState = {
            collection,
            rows,
            groupMap:this.groupMap,
            currentPage:this.currentPage,
            grid,
            dataVersion:this.renderedCollectionVersion,
            event:this.props.event,
            broadcast:this.broadcast,
            isGrouped
        }

        let style:React.CSSProperties = {};
        style.display = "flex";
        style.flexDirection = "column";
        style.height = "100%";
        style.width = "100%";
        style.overflow = "hidden";
        
        let reportHeader;
        let gridContent;

        
        if (grid.viewType == 'board'){
            gridContent = <BoardView gridState={gridState} />
        }
        else {

            let columnHeaders;
            if (!gridState.isGrouped && !grid.hideHeader){
                columnHeaders = (<HeaderContainer width={grid.totalWidth} >
                    <ColumnarHeader gridState={gridState} rows={rows} grouped={isGrouped} />
                </HeaderContainer>)
            }
            if (collection.rows){
                let className = "RT-DataTable RT-DataTable--pull-padding";
                if (isGrouped){
                    className += " RT-DataTable--grouped";
                }
                gridContent = ( <div className={className}>
                    
                    <div className="RT-DataTable__body rt-scrollbars" >
                        {columnHeaders}
                        <div style={bodyStyle}>
                            <GridRows gridState={gridState}/>
                        </div>
                    </div>
                </div>)
            }
        
            if (gridState.groupMap && gridState.groupMap.summaryGroup){
                reportHeader = this.renderReportHeader(gridState);
            }
        }
        
    

        let searchPanel;
        if (this.props.search){
            let searchProps = this.props.search.props;
            if (searchProps){
                searchPanel =  (<div className="RT-Search-Panel" data-elem-id={searchProps.$id}>
                    <Line event={this.props.event} spacing="none">
                        {searchProps.children}
                    </Line>
                </div>)
            }
        }
        let contextMenuId;
        if (this.contextMenuHandler){
            contextMenuId = this.contextMenuHandler.id;
        }
    
        let popup;
        if (this.contextMenuHandler && this.contextMenuHandler.isOpen){
            popup = this.renderPopup();
        }
       
        return <div style={style} ref={this.containerRef} onClick={this.handleClick} data-context-menu={contextMenuId}>
            {popup}
            {searchPanel}
            {this.renderButtonBar(gridState)}
            {reportHeader}
            {gridContent}
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
          
        if (e.ctrlKey){
            e.preventDefault();
            e.stopPropagation();
            let canvas = this.props.event.canvas;
            canvas.viewSource();

        }
    }

    handleContextMenuClick = (args:ContextMenuHandlerArgs) => {

       

        let target = args.target;
        let rowElem = target.closest('[data-row-index]');
        let row;
        if (rowElem){

            let rowIndex = parseInt(rowElem.getAttribute("data-row-index"),10);
            let collection = this.props.collection;
            let row = collection.rows[rowIndex];
            collection.currentRow = row;
        }
        /*
        if (!this.grid.rowMenu || !this.grid.rowMenu.length){
            this.props.event.canvas.update();
            return;
        }
        */
        let elem = target.closest('[data-elem-id]');
        if (elem){
            this.contextMenuHandler.elemId = elem.getAttribute("data-elem-id");
        }
        else {
            this.contextMenuHandler.elemId = null;
        }
        this.contextMenuHandler.rect = args.rect;
        this.contextMenuHandler.row = row;
        this.contextMenuHandler.open();
      
    }

    renderPopup(){
        let handler = this.contextMenuHandler;
        return (<Popup
            attachedRef={handler.rect}
            anchorPoint={AnchorPoint.BottomAlignLeft}
            onForceClose={this.handlePopupForceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderMenu}
            withCover
        />);
    }

    renderMenu = ()=> {
        /*
        let elementContextMenu = <ElementContextMenu elemId={this.contextMenuHandler.elemId} targetCanvas={this.contextMenuHandler.canvas} 
        event={this.props.event} onClose={this.handlePopupForceClose}/>
        */

        let content = <div style={{ width: 480, backgroundColor: "#fff", boxShadow: "rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px", borderRadius: 4,
            padding:"8px 0",border:"solid 2px rgb(0 0 0 / 8%)",marginTop:-6 ,marginLeft:2}}>
                <RowContextMenu handler={this.contextMenuHandler} grid={this.grid} collection={this.props.collection}/>
        </div>
        return content;
    }

    handlePopupForceClose = () => {
        this.contextMenuHandler.isOpen = false;
        this.forceUpdate();
    }

    measure = () => {
        let grid = this.grid;
        let columns = grid.columns;
        if (!columns.length) return;
        let definedWidth = 0;
        let stretchColumn:IDataGridColumn;

        for(let i = 0; i < columns.length;i++){
            let col = columns[i];
            if (col.stretch){
                stretchColumn = col;
            }
            else {
                definedWidth += col.width;
            }
        }
        grid.measured = true;
        if (!stretchColumn) return;

        let availableWidth = this.containerRef.current.offsetWidth;
        let stretchWidth = availableWidth - definedWidth - 30;
        if (stretchWidth < 100){
            stretchWidth = 100;
        }
        Grid.setWidth(grid,stretchColumn,stretchWidth);
        grid.version++;
       
        this.broadcast.refresh();
    }

    createGrid(layout:IDataGridLayout,canvas:Canvas,collection:CollectionRef,rows:CollectionArray){
       
        if (!layout && rows && rows.$$meta){
            layout = rows.$$meta.tableLayout;
        }
        this.grid = Grid.create(canvas,layout,collection.schema);
        this.renderedPageVersion = canvas.pageInfo.version;
        this.renderedLayout = layout;
        if (this.props.onRowClick){
            this.grid.onRowClick = this.props.onRowClick;
        }
        else if (collection.schema.onRowClick){
            this.grid.onRowClick = new ActionRef(canvas,collection.schema.onRowClick,this.props.event.scope);
        }
        if (collection.schema.onSelectorClick){
            this.grid.onSelectorClick = new ActionRef(canvas,collection.schema.onSelectorClick,this.props.event.scope);
        }
        if (this.props.onRefresh){
            this.grid.onRefresh = this.props.onRefresh;
        }
    }
    
    renderButtonBar(gridState:IGridState) {
        if (gridState.grid.hideHeader) return null;
        let actions;
        if (this.props.actions){
            actions = this.props.actions;
        }
      
        let verbose;
      
        let queryPopulated:boolean; // populated ?
        let rowCount:number;
        let collection = this.props.collection;
        if (collection && collection.rows){
            queryPopulated = true;
            rowCount = collection.rows.length;
        }
        let viewSelector;
        let countElem;

        if (!queryPopulated && !actions && !this.props.totalBar) return null;

        if (queryPopulated){
            let pagination;
            let groupMap = this.groupMap;
            if (groupMap){    
                let numPages = groupMap.pages.length;
                if (numPages > 1){
                    pagination = <Pagination currentPage={this.currentPage + 1} numPages={numPages} onClick={this.handlePageNumberClick}/>
                }
            }
            let refresh;
            if (gridState.grid.onRefresh){
                refresh = (<div style={{display:"flex",alignItems:"center",gap:5,marginRight:10,cursor:"pointer"}} onClick={this.handleRefreshClick}>
                    <Icon icon="refresh" fill="rgb(130,132,136)" size={16}/>
                    <a href='#'  style={{fontSize:"13px"}}>Refresh</a>
                </div>)
            }

            let tableView;
            let boardView;
            
            if (gridState.grid.columns && gridState.grid.columns.length){
                tableView = <ViewTypeIcon gridState={gridState} iconName="table" viewType="columnar"/>
            }
            if (gridState.grid.cardContent){
                boardView = <ViewTypeIcon gridState={gridState} iconName="columns" viewType="board"/>
            }
            if (rowCount == 1){
                countElem = <div style={{fontSize:"13px"}}>
                    <span style={{fontWeight:700}}>{rowCount}</span> row
                </div>
            }
            else if (rowCount){
                countElem = <div style={{fontSize:"13px"}}>
                    <span style={{fontWeight:700}}>{rowCount}</span> rows
                </div>
            }
            else{
                countElem = <div style={{fontSize:"13px"}}>
                    <span style={{fontWeight:700}}>No rows</span>
                </div>
            }

            verbose = <div style={{display:"flex",alignItems:"center",marginLeft:'auto'}}>
               
                {tableView}
                {boardView}
                {refresh}
                <div style={{marginLeft:15,marginRight:15}}>{countElem}</div>
                {pagination}
                
            </div>
           
            /*
             viewSelector = (<div >
                <ViewDrop gridState={gridState}/>
            </div>);
            */
        }

      //  if (!actions && !verbose) return null;
        let totalBarElem;
        let totalBar = this.props.totalBar;
        let collectionName = gridState.collection.name;

        if (totalBar && totalBar.props){
            let renderContent = totalBar.props.render_children;
            if (renderContent && gridState.groupMap && gridState.groupMap.summaryGroup){
                let summaryGroup = gridState.groupMap.summaryGroup;
                let childEvent =  this.props.event.create({[collectionName]:summaryGroup.row,[collectionName + "$totals"]:summaryGroup.header.totals} as any);
                totalBarElem = renderContent(childEvent);
            }
        }
       
        let id = this.props.$id;
        let className = "RT-DataTable__list-actions";
        if (!queryPopulated){
            className += " RT-DataTable__list-actions--no-rows";
        }

        let labelElem;
        if (this.props.label){
            labelElem = <div style={{fontWeight:500,marginLeft:5}}>{this.props.label}</div>
        }

        return (
            <div
                className = {className} data-elem-id={id}
            >
                {labelElem}
                {actions}
               {totalBarElem}
               {verbose}
               {viewSelector}
            </div>
        );
    }
    handleRefreshClick = (e:React.MouseEvent) => {

        this.clickThrottle.handle(e,null,()=> {
            e.preventDefault();
            e.stopPropagation();
            let event = this.props.event;
            if (this.grid.onRefresh){
                this.grid.onRefresh.trigger({});
                return;
            }
        })
    }

    renderReportHeader( gridState:IGridState){
        let grid = gridState.grid;
        let renderEvent = gridState.event;
        let summaryGroup = this.groupMap.summaryGroup;
        let collectionName= gridState.collection.name;
        let childEvent = renderEvent.create({[collectionName]:summaryGroup.row,[collectionName + "$totals"]:summaryGroup.header.totals} as any);
        return DynamicRender.render(childEvent,summaryGroup.header.group.headerContent);
    }

    handlePageNumberClick = (pageNumber:number) => {
        this.currentPage = pageNumber - 1;
        this.grid.version++;
        this.broadcast.refresh();
    }
}


class ViewTypeIcon extends React.Component<{gridState:IGridState,viewType:GridViewType,iconName:string}>{
    render(){
        let gridState = this.props.gridState;
        let fill = gridState.grid.viewType == this.props.viewType ? "var(--rt-primary-color)" : "rgb(130,132,136)";
        return <div style={{marginRight:10,cursor:"pointer"}} onClick={this.handleClick}>
            <Icon icon={this.props.iconName} size="16" fill={fill} />
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let gridState = this.props.gridState;
        gridState.grid.viewType = this.props.viewType;
        let settings = gridState.event.canvas.getSettings();
        settings.viewType = this.props.viewType;
        Collection.incrementVersion(gridState.rows);
        gridState.broadcast.refresh();
    }
}

class GridRows extends React.Component<{gridState:IGridState}>{
    clickThrottle = new ClickThrottle();

    renderedDataVersion:number = -1;
    renderedGridVersion:number = -1;
    shouldComponentUpdate(nextProps){
        if (nextProps.dataVersion == this.renderedDataVersion && nextProps.layout && nextProps.layout.version == this.renderedGridVersion) return false;
        return true;
    }

    render(){
        let gridState = this.props.gridState;
        let grid = gridState.grid;
        this.renderedDataVersion = gridState.dataVersion;
        this.renderedGridVersion = grid.version;
        let rowElems = [];
        let rows = gridState.rows;
        if (!rows) return null;
       
        let groupMap = gridState.groupMap;
        if (groupMap){
            let currentPage = gridState.currentPage;
            let items = groupMap.items;
            
            let pageIndex = groupMap.pages[currentPage];
            if (!pageIndex) return null;

            let start = pageIndex.start;
            let n = pageIndex.end;

            for(let i = start; i < n;i++){
                let item = items[i];
                if (item.type == "row"){
                    let isActive = false; //  (query.activeRowIndex == item.rowIndex);
                  
                    rowElems.push(<ColumnarRow key={i} gridState={gridState} row={item.row} rowIndex={item.rowIndex} clickThrottle={this.clickThrottle}/>);
                }
                
                else if (item.type == "header"){
                    let printElems = this.renderGroupHeaderContent(item.header);
                    let columnHeaders;
                    if (item.header.innermost){
                        columnHeaders = (<HeaderContainer width={grid.totalWidth}>
                            <ColumnarHeader gridState={gridState} rows={rows} grouped={true} />
                        </HeaderContainer>)
                    }
                    rowElems.push(<DataGridGroupHeader key={i} header={item.header} continued={item.continued} printElems={printElems} columnHeaders={columnHeaders}/>)
                }
                else if (item.type == "footer"){    
                    let content = this.renderGroupFooterContent(item.footer);
                    if (item.footer.totals && item.footer.totals.length){
                        content = <div>
                            <TotalRow key={"t" + i}gridState={this.props.gridState} row={item.footer.row} totals={item.footer.totals}/>
                            {content}
                        </div>
                    }
                    if (content){
                        rowElems.push(<DataGridGroupFooter key={i} footer={item.footer} isLastRow={false}>{content}</DataGridGroupFooter>);
                    }
                }
                else if (item.type == "last-row"){
                    let content = this.renderGroupFooterContent(item.footer);
                    let marginLeft;
                    let marginRight;
                    if (gridState.isGrouped){
                        marginLeft = 0;
                        marginRight = 0;
                    }
                    else {
                        marginLeft = -10;
                        marginRight = -10;
                    }
                    if (item.footer.totals && item.footer.totals.length){
                        content = <div>
                            <div style={{marginLeft,marginRight}}>
                                <TotalRow key={"t" + i}gridState={this.props.gridState} row={item.footer.row} totals={item.footer.totals}/>
                            </div>
                            {content}
                        </div>
                    }
                    if (grid.tableStyle != "list"){
                        rowElems.push(<DataGridGroupFooter key={i} footer={item.footer} isLastRow={true}>{content}</DataGridGroupFooter>);
                    }
                }
            }
        }
        else {
            for(let i = 0; i < rows.length;i++){
                let row = rows[i];
                rowElems.push(<ColumnarRow key={i} gridState={gridState} row={row} rowIndex={i} clickThrottle={this.clickThrottle}/>);
            }
        }
        return rowElems;
    }

    renderGroupHeaderContent(header:IQueryGroupHeader){
    
        
        let row = header.row;
        let gridState = this.props.gridState;
        let grid = gridState.grid;
        let renderEvent = gridState.event;

        let extra;
        let collectionName = gridState.collection.name;
        
        if (header.group.headerContent){
            let childEvent = renderEvent.create({[collectionName]:row,[collectionName + "$totals"]:header.totals} as any);
            extra = DynamicRender.render(childEvent,header.group.headerContent);
        }
        
        return extra;

        /*
        let labelElem;
        
        
        if (header.group.display){
            labelElem = <GroupTitle renderEvent={gridState.event} gridState={gridState} row={row} group={header.group} />
        }
        
        
        return <Line spacing='none'>
            {labelElem}
            {extra}
        </Line>
        */
    
    }

    renderGroupFooterContent(footer:IQueryGroupFooter){
       
        let content = footer.group.footerContent;
        if (!content) return null;

        let left = [];
        let right = [];
        for(let i = 0 ; i < content.length;i++){
            let item = content[i];
            let formatted;

            if (item.total){
                let total = footer.totals[item.total].value;
                formatted = FieldFormatter.format(total,item.fieldType);
                let elem = (<div key={i} style={{textAlign:"right"}}>
                    <div className="RT-DataTable__group-field-name">
                        {item.label}
                    </div>
                    <div style={{minWidth:200}}>
                        {formatted}
                    </div>
                </div>);
                right.push(elem);
            }
            else if (item.name){
                if (footer.row){
                    formatted = footer.row[item.name];
                    let elem = (<div key={i}>
                        <div className="RT-DataTable__group-field-name">
                            {item.label}
                        </div>
                        <div style={{minWidth:200}}>
                            {formatted}
                        </div>
                    </div>);
                    left.push(elem);
                }
            }
            else {
                left.push(<div style={{minWidth:200}}>{item.label}</div>)
            }
            
        }
        return <div style={{display:"flex"}}>
            <div style={{display:"flex"}}>{left}</div>
            <div style={{marginLeft:"auto",display:"flex"}}>{right}</div>    
        </div>
    

    }

}

class GroupTitle extends React.Component<{renderEvent:RenderEvent,gridState:IGridState,row:any,group:ICollectionGroup}>{
    render(){
        let grid = this.props.gridState.grid;
        let group = this.props.group;
        let collectionName = this.props.gridState.collection.name;

        let childEvent = this.props.renderEvent.create({[collectionName]:this.props.row} as any);
        let display = childEvent.getValue(group.display);
        if (group.onClick){
            display = <a href='#' style={{display:"block"}} onClick={this.handleClick}>{display}</a>
        }
        return <div className="RT-Static-Field">
            <label className="RT-Static-Field__label">{group.label}</label>
            <div className="RT-Static-Field__content text-bold text-medium">{display}</div>
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let canvas = this.props.renderEvent.canvas;
        let grid = this.props.gridState.grid;
        let collectionName = this.props.gridState.collection.name;
        let actionRef = new ActionRef(canvas,this.props.group.onClick,{[collectionName]:this.props.row} as any);
        actionRef.trigger();
    }
}


class ColumnarRow extends React.Component<{gridState:IGridState,row:any,rowIndex:number,clickThrottle:ClickThrottle,grouped?:boolean}>{

    private renderedRowDataVersion: number;

    shouldComponentUpdate(nextProps){
        /*
        if (query.rowDataVersion != this.renderedRowDataVersion) {
            return true;
        }
        return false;
        */
       return true;
    }
    render(){

        let cells = [];
        let gridState = this.props.gridState;
        let grid = gridState.grid;
        let columns = grid.columns;
        let row = this.props.row;
        let rowRenderEvent:RenderEvent;

        let offset = 0;
        if (grid.rowSelector){
            offset = selectorWidth;
            cells.push(this.renderSelectorCell(row));
        }
       
        for(let i = 0; i < columns.length;i++){
            let col = columns[i];
            let style:React.CSSProperties;
            let left = col.left + offset;
            if (col.width) {
                style = {
                    position: "absolute",
                    top: 0,
                    left,
                    width: col.width,
                    bottom: 0
                };
            } else {
                style = {
                    position: "absolute",
                    top: 0,
                    left,
                    right: 0,
                    bottom: 0
                };
            }
            let value = (col.displayLabelInCell) ? col.label : row[col.name];
         
            let valueStyle:ComponentStyles
         
            let suffix;
            if (col.action){
                /*
                if (!value) {
                    hidden = true;
                }
                */
                if (col.getStyle){
                    valueStyle = col.getStyle(gridState.event,row);
                }
            } 
            else {
                if (col.getStyle){
                    valueStyle = col.getStyle(gridState.event,row);
                }
                if (col.getSuffix){
                    suffix = col.getSuffix(gridState.event,row);
                }
                else {
                    suffix = row[col.name + "$suffix"];
                }
            }
           
            let cellClasses:string[] = ['RT-DataTable__cell'];
            let contentCss:string[] = [];

            if (col.styles){
                cellClasses.push(col.styles);
            }
            if (col.align == "right"){
                cellClasses.push("align-right");
            }
            if (valueStyle){
                contentCss.push(valueStyle.className);           
            }
            let content;
        
            if (col.render){
                content = col.render(row);
            }
            else if (col.type == "logical"){
                if (value){
                    content = this.renderCheckmark();
                }
            }
            else if (col.action || col.onClick || col.onClickAction){
                content = <a href='#'>{value}</a>
            }
            else if (col.format == "progress"){
                content = <div style={{backgroundColor:"#8884d8",height:15,marginTop:4,width:value + "%"}}/>
            }
            else if (col.drawAs){
                if (!rowRenderEvent){
                    rowRenderEvent = gridState.event.create({[gridState.collection.name]:row});
                }
                content = rowRenderEvent.render(col.drawAs);
            }
            else if (suffix){
                content = <>
                    { FieldFormatter.format(value,col.type,col.format)}
                    <span style={{marginLeft:5}}>{suffix}</span>
                </>
            }
            else {

                content= FieldFormatter.format(value,col.type,col.format);
            }
            cells.push(<div key={i} className={cellClasses.join(' ')} data-col-index={i} style={style}>
                <div className={contentCss.join(' ')} >{content}</div>
            </div>)
        }
        let rowStyle:React.CSSProperties = {height:34,position:"relative"};
        if (grid.onRowClick){
            rowStyle.cursor = "pointer";
        }
        
        let className = ["RT-DataTable__row"];
        if (grid.tableStyle == "list"){
            className.push("RT-DataTable--as-list");
        }
        else if (row == gridState.collection.currentRow){
            rowStyle.backgroundColor = "#f2f6fc"; //"rgb(238 245 255)";
        }
        if (grid.getRowStyle){
            let rowStyles = grid.getRowStyle(gridState.event,row);
            if (rowStyles){
                className.push(rowStyles.className);
            }
        }
    
        var rowCalcStyle = row["$rowstyle"];
        if (rowCalcStyle){
            className.push(rowCalcStyle);
        }
        
        return <div data-row-index={this.props.rowIndex} style={rowStyle} onClick={this.handleClick} className={className.join(' ')}>
            {cells}
        </div>
    }

    handleClick = (e:React.MouseEvent<HTMLDivElement>) => {
        this.props.clickThrottle.handle(e,null,()=> {
            e.preventDefault();
            e.stopPropagation();
            let target:HTMLDivElement = e.target as any;
            let cell = target.closest("[data-col-index]");
            let gridState = this.props.gridState;
            let grid = gridState.grid;
            let row = this.props.row;
            if (cell){
                let colIndex = parseInt(cell.getAttribute("data-col-index"),10);
                let column = gridState.grid.columns[colIndex];
                
                let buttonName = column.action || column.onClickAction;
                if (!buttonName && column.onClick){
                    buttonName = column.name;
                }
                if (buttonName){
                    var anchor = target.closest("a");
                    if (!anchor) return; 

                    let targetPos = {x:e.clientX,y:e.clientY};
                    
                    let canvas = gridState.event.canvas;
                    let schema = gridState.grid.schema;

                    gridState.collection.currentRow = row;
                    gridState.event.canvas.update();
                    let collectionName = gridState.collection.name;
                    let buttonRef = new ActionRef(canvas,buttonName,{[collectionName]:this.props.row});
                    if (column.onClick){
                        buttonRef.statements = column.onClick;
                    }
                    let rowKey = Collection.getRowKey(row,grid.schema);
                    buttonRef.trigger({targetPos,current:row,value:rowKey,table:gridState.rows});
                    return;
                }
                if (column.onClick){

                }
            }
            gridState.collection.currentRow = row;
            gridState.event.canvas.update();
            if (grid.onRowClick){
                
                let scope = {[gridState.collection.name]:row};
                let rowKey = Collection.getRowKey(row,grid.schema);
                grid.onRowClick.trigger({current:row,value:rowKey,table:gridState.rows,scope});
            }
            else {
                gridState.event.canvas.update();
            }
            
            
          
        
        });
    }

  
    renderSelectorCell(row:any) {
        let checked = Collection.isRowSelected(row);
        return (
            <div
                key="sel"
                className="RT-DataTable__selector-cell"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    width:selectorWidth
                }}
                onClick={this.handleSelectorClick}
            >
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={this.handleSelectorChange}
                />
            </div>
        );
    }

    renderCheckmark(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{display:"inline-block",width:20,height:20,fill:"var(--rt-primary-color)"}}><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>);
    }

    handleSelectorChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let target = e.target;
        let checked = target.checked;
        let row = this.props.row;
        Collection.setRowSelected(row,checked);
        fireSelectorChanged(this.props.gridState,row);
        this.forceUpdate();
    };

    handleSelectorClick = (e: React.MouseEvent) => {
        // cancel row click if clicking on row selector
        e.stopPropagation();
        let target = e.target as any;
        if (target.type == "checkbox") return;
        let row = this.props.row;
        Collection.setRowSelected(row,!Collection.isRowSelected(row));
        fireSelectorChanged(this.props.gridState,row);
        this.forceUpdate();
    };




}


function fireSelectorChanged(gridState:IGridState,row:any){
    let grid = gridState.grid;
      
    if (grid.onSelectorClick){
        let collectionName = gridState.collection.name;   
        let scope = {[collectionName]:row};
        let rowKey;
        if (row){
            rowKey = Collection.getRowKey(row,grid.schema);
        }
        grid.onSelectorClick.trigger({current:row,value:rowKey,table:gridState.rows,scope});
    }
}


class HeaderContainer extends React.Component<{width:number}>{
    render(){
        return <div className="RT-DataTable__header" style={{width:this.props.width}}>{this.props.children}</div>
    }   
}

class ColumnarHeader extends React.Component<{gridState:IGridState,rows:any[],grouped:boolean}>{
    render(){
        let gridState = this.props.gridState;
        let grid = gridState.grid;
        let columns = grid.columns;

        if (!columns) return null;
        var elems = [];
        let rows = this.props.rows;
        let offset = 0;
        if (grid.rowSelector){
            offset = selectorWidth;
            elems.push(this.renderHeaderSelectorCell());
        }
        let broadcast = gridState.broadcast;
        for (var i = 0; i < columns.length; i++) {
            let col = columns[i];
            elems.push(<HeaderCell key={i} rows={rows} broadcast={broadcast} layout={grid} column={col} offset={offset}  onResize={this.handleColumnResize}/>)
        }
        return elems;
    }

    handleColumnResize = () => {
        let gridState = this.props.gridState;
        gridState.grid.version++;
        gridState.broadcast.refresh();
    }

    renderHeaderSelectorCell() {
        let checked = this.props.gridState.selectAllValue;
        return <div key="sel" style={{ position: "absolute", left: 0, top: 0, bottom: 0, width:selectorWidth, cursor: "pointer" }} className="RT-DataTable__header-selector-cell">
            <input type="checkbox" checked={checked} onChange={this.handleSelectorChange} />
        </div>
    }

    handleSelectorChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let target = e.target;
        let checked = target.checked;
        let gridState = this.props.gridState;
        gridState.selectAllValue = checked;
        Grid.setSelected(gridState.rows,checked);
        fireSelectorChanged(gridState,null);
        gridState.grid.version++;
        gridState.broadcast.refresh();
      //  let query = this.props.query;
      //  query.toggleSelectAll(checked);
    };
}

class HeaderCell extends React.Component<{broadcast:Broadcast,rows:any[],layout:IDataGrid,column:IDataGridColumn,offset:number,onResize:() => void}>{
    render() {
        let col = this.props.column;
        let style:React.CSSProperties;
        let offset = this.props.offset;

        if (col.width) {
            style = { position: "absolute", left: col.left + offset, width: col.width, top: 0, bottom: 0, cursor: "pointer" };
        }
        else {
            style = { position: "absolute", left: col.left + offset, top: 0, bottom: 0, right: 0, cursor: "pointer" }
        }
        if (col.align == 'right'){
            style.justifyContent = "flex-end";
        }
        else if (col.align == "center"){
            style.justifyContent = "center";
        }
        let sort = Grid.getColumnSort(this.props.layout,col);
        let sortElem;
        if (sort){
            if (sort.descending) {
                sortElem = <span className="RT-DataTable__header-sort-icon"><SortDirectionDesc /></span>;
            }
            else {
                sortElem = <span className="RT-DataTable__header-sort-icon"><SortDirectionAsc /></span>;
            }
        }

        let resizeHandle = <ColumnResizeHandle layout={this.props.layout} column={col} onResize={this.props.onResize}/>
        return <div style={style} className="RT-DataTable__header-cell" data-elem-id={col.elemId} onClick={this.handleClick}>{col.label}{sortElem}{resizeHandle}</div>;
    }



    handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        Grid.setSort(this.props.layout,this.props.column);
        Collection.incrementVersion(this.props.rows);
        this.props.broadcast.refresh();
    }
}


class TotalRow extends React.Component<{gridState:IGridState,row:any,totals:any}>{
    render(){
        let elems = [];
        let columns = this.props.gridState.grid.columns;
        let totals = this.props.totals;
        let row = this.props.row;
        for(let i =0 ; i < columns.length; i++){
            let col = columns[i];
            if (i  == 0){
                elems.push(<div key={i} className="RT-DataTable__cell" style={{fontWeight:500}}>Total</div>)
            } 
            else if (!col.total){
                elems.push(<div key={i} className="RT-DataTable__cell">{'\u00A0'}</div>)
            }
            else {
                elems.push(<TotalCell key={i} row={row} totals={totals} column={col}/>);
            }
            
        }
        let rowStyle:React.CSSProperties = {height:30,position:"relative",marginLeft:-15,marginRight:-15};
        return <div style={rowStyle}>{elems}</div>
    }
}

class TotalCell extends React.Component<{row:any,totals:any,column:IDataGridColumn}> {
    render(){
        let col = this.props.column;
        let style:React.CSSProperties;
        let offset = 0;

        if (col.width) {
            style = { position: "absolute", left: col.left + offset, width: col.width, top: 0, bottom: 0};
        }
        else {
            style = { position: "absolute", left: col.left + offset, top: 0, bottom: 0, right: 0}
        }
        let cellClasses:string[] = ['RT-DataTable__cell'];
        if (col.align == "right"){
            cellClasses.push("align-right");
        }
        let value = this.props.totals[col.total].value;
        let content = FieldFormatter.format(value,col.type,col.format);
        return <div className={cellClasses.join(' ')} style={style}>{content}</div>

    }
}

class SortDirectionDesc extends React.Component {
    render(){
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path d="M 12 3 C 11.448 3 11 3.448 11 4 L 11 17.070312 L 7.1367188 13.207031 C 6.7457187 12.816031 6.1126563 12.816031 5.7226562 13.207031 L 5.6367188 13.292969 C 5.2457187 13.683969 5.2457187 14.317031 5.6367188 14.707031 L 11.292969 20.363281 C 11.683969 20.754281 12.317031 20.754281 12.707031 20.363281 L 18.363281 14.707031 C 18.754281 14.316031 18.754281 13.682969 18.363281 13.292969 L 18.277344 13.207031 C 17.886344 12.816031 17.253281 12.816031 16.863281 13.207031 L 13 17.070312 L 13 4 C 13 3.448 12.552 3 12 3 z"></path>
    </svg>
    }
}

class SortDirectionAsc extends React.Component {
    render(){
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path d="M 12 3.3417969 C 11.744125 3.3417969 11.488469 3.4412187 11.292969 3.6367188 L 5.6367188 9.2929688 C 5.2457188 9.6829687 5.2457187 10.316031 5.6367188 10.707031 L 5.7226562 10.792969 C 6.1126563 11.183969 6.7457187 11.183969 7.1367188 10.792969 L 11 6.9296875 L 11 20 C 11 20.552 11.448 21 12 21 C 12.552 21 13 20.552 13 20 L 13 6.9296875 L 16.863281 10.792969 C 17.253281 11.183969 17.886344 11.183969 18.277344 10.792969 L 18.363281 10.707031 C 18.754281 10.317031 18.754281 9.6839688 18.363281 9.2929688 L 12.707031 3.6367188 C 12.512031 3.4412187 12.255875 3.3417969 12 3.3417969 z"></path>
    </svg>
    }
}


class ColumnResizeHandle extends React.Component<{layout:IDataGrid,column:IDataGridColumn,onResize:()=> void},{startX:number,currentWidth:number,newWidth:number,moving:boolean}> {

    minWidth = 45;
    constructor(props){
        super(props);
        this.state = {
            currentWidth:0,
            startX:0,
            newWidth:0,
            moving:false
        }
    }
    render() {
        let right = -8;
        let css = "RT-DataTable__resize-handle";
        if (this.state.moving){
          //  right -= (this.state.newWidth - this.state.currentWidth);
            css += " RT-DataTable__resize-handle--moving";
        }
        return <div style={{ position: "absolute", width: 16, top: -2, height: 36, right, zIndex: 2, cursor: "ew-resize" }} className={css}
            onMouseDown={this.handleMouseDown} onClick={this.handleClick}>
            <div/>
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            startX:e.clientX,
            currentWidth:this.props.column.width,
            newWidth:this.props.column.width,
            moving:true
        })
        document.addEventListener("mousemove",this.handleMouseMove,true);
        document.addEventListener("mouseup",this.handleMouseUp,true)

    }

    handleMouseUp = e => {
        e.preventDefault();
        e.stopPropagation();
        document.removeEventListener("mousemove",this.handleMouseMove,true);
        document.removeEventListener("mouseup",this.handleMouseUp,true);
        let change = e.clientX - this.state.startX;
        let col = this.props.column;
        let newWidth = this.state.currentWidth + change;
        if (newWidth < this.minWidth){
            newWidth = this.minWidth;
        }
        Grid.setWidth(this.props.layout,col,newWidth); 
        this.setState({
            moving:false
        })
    }

    handleMouseMove = e => {
        e.preventDefault();
        e.stopPropagation();
        let change = e.clientX - this.state.startX;
        let newWidth = this.state.currentWidth + change;
        if (newWidth < this.minWidth){
            newWidth = this.minWidth;
        }
        this.setState ({
            newWidth
        })
        let col = this.props.column;
        Grid.setWidth(this.props.layout,col,newWidth);
        this.props.onResize();
    }
}


class Grid {

    static create(canvas:Canvas,layout:IDataGridLayout,schema:IRecordSchema):IDataGrid {

       
        let grid:IDataGrid;
        if (layout){
            let totals = Grid.buildTotals(layout.totals);
            let settings = canvas.getSettings();
            let viewType= settings.viewType || 'columnar';
            grid = {
                rowSelector:layout.rowSelector,
                columns:layout.columns,
                schema,
                sort:layout.sort,
                groups:layout.groups,
                reportGroup: layout.reportGroup,
                version:1,
                totals,
                cardContent:layout.cardContent,
                rowMenu:layout.rowMenu,
                viewType,
                pageSize:layout.pageSize,
                hideHeader:layout.hideHeader,
                hideRowCount:layout.hideRowCount,
                tableStyle:layout.tableStyle
            }
            Grid.computeColumns(grid);
            for(let i = 0; i < grid.columns.length;i++){
                let col = grid.columns[i];
                if (col.action){
                    let action = canvas.actions[col.action];
                    if (action && action.styles){
                        col.getStyle = (event:RenderEvent,row:any) => {
                            let styles = event.canvas.getValue(action.styles,{scope:{[schema.name]:row}});
                            if (styles){
                                return event.computeStyles(styles);
                            }
                        }
                    }
                }
                else {
                    if (col.styles){
                        col.getStyle = (event:RenderEvent,row:any) => {
                            let styles = event.canvas.getValue(col.styles,{scope:{[schema.name]:row}});
                            if (styles){
                                return event.computeStyles(styles);
                            }
                        }
                    }
                    let field = Schema.getFieldDef(schema,col.name);
                    if (field){
                        if (field.styles && !col.getStyle){
                            col.getStyle = (event:RenderEvent,row:any) => {
                               
                                let styles = event.canvas.getValue(field.styles,{scope:{[schema.name]:row}});
                                if (styles){
                                    return event.computeStyles(styles);
                                }
                            }
                        }
                        if (field.suffixExpression){
                            col.getSuffix = (event:RenderEvent,row:any) => {
                                return event.canvas.getValue(field.suffixExpression,{scope:{[schema.name]:row}})
                            }
                        }
                    }
                }
            }
            if (layout.rowStyleExpression){
                grid.getRowStyle = (event:RenderEvent,row:any) => {
                    let styles = event.canvas.getValue(layout.rowStyleExpression,{scope:{[schema.name]:row}});
                    if (styles){
                        return event.computeStyles(styles);
                    }
                }
            }
        }
        else {
            grid = {schema,version:1,columns:[],groups:[],reportGroup:null};
        }
        return grid;
    }

    static setWidth(grid:IDataGrid,column:IDataGridColumn,newWidth:number){
        column.width = newWidth;
        Grid.computeColumns(grid);
    }

    static buildTotals(totals:IDataGridTotal[]):IReportTotal[] {
        let out:IReportTotal[] = [];
        if (totals){
            for(let i = 0; i < totals.length;i++){
                let item = totals[i];
                let createTotal:() => ITotalAccumulator;
                if (item.totalType == "sum"){
                    createTotal = () => new TotalSum(item.field);
                }
                else {
                    createTotal = () => new TotalSum(item.field);
                }
                let reportTotal:IReportTotal = {
                    name:item.name,
                    label:item.label,
                    fieldType:item.fieldType,
                    createTotal
                }
                out.push(reportTotal);
            }
        }
        let rowCountTotal:IReportTotal = {
            name:"rowCount",
            label:"Count",
            fieldType:"integer",
            createTotal: () => new TotalRowCount()
        }
        out.push(rowCountTotal);

        return out;
    }
    static computeColumns(layout:IDataGrid){
        let left = 0;
        let totalWidth = 0;
        let columns = layout.columns;
        for(let i =0; i < columns.length;i++){
            let col = columns[i];
            col.left = left;
            if (!col.width){
                col.width = 140;
            }
            left += col.width;
            totalWidth += col.width;
        }
        layout.totalWidth = totalWidth;
    }

    static setSort(layout:IDataGrid,column:IDataGridColumn){
        let current =layout.sort;
        if (current && current.length == 1){
            let currentSortField = current[0];
            if (currentSortField.name == column.name){
                currentSortField.descending = !currentSortField.descending;
                return;
            }
        }
        layout.sort = [{name:column.name,descending:false}];
    }

    static getColumnSort(layout:IDataGrid,column:IDataGridColumn):ICollectionSortField {
        if (!layout.sort) return null;
        for(let i = 0; i < layout.sort.length;i++){
            let item = layout.sort[i];
            if (item.name == column.name) return item;
        }
    }

    static setSelected(rows:any[],value:boolean){
        for(let i =0 ; i < rows.length;i++){
            let row = rows[i];
            Collection.setRowSelected(row,value);
        }
    }
}



class Pagination extends React.Component<{currentPage:number,numPages:number,onClick:(pageNumber:number) => void}>{
    render(){
        let numPages = this.props.numPages;
        let currentPage = this.props.currentPage;
        let style:React.CSSProperties = {userSelect:"none"};
        if (numPages > 7){
            
            let prev;
            let next;
           
            let start = currentPage - 2;
            if (start < 2){
                start = 2;
            }
            let end = start + 4;
            if (end > numPages - 1){
                end = numPages - 1;
                start = end - 4;
            }
            
            if (start > 2){
                prev = <PageIconButton name="prev" onClick={this.handleIconClick} numPages={numPages} currentPage={currentPage}/>
                start++;
            }
            if (end < numPages -1){
                next = <PageIconButton name="next" numPages={numPages} currentPage={currentPage} onClick={this.handleIconClick} />
                end--;
            }
            let rangeElems = [];
            let i = start;
            while (i <= end){
                rangeElems.push(<PageButton key={i} pageNumber={i} currentPage={currentPage} onClick={this.handlePageClick} />)
                i++;
            }
            return <div style={style}>
                <PageButton pageNumber={1} leftEdge currentPage={currentPage} onClick={this.handlePageClick} />
                {prev}
                {rangeElems}
                {next}
                <PageButton pageNumber={numPages} rightEdge currentPage={currentPage} onClick={this.handlePageClick} />
            </div>
        }
        else {
            let elems = [];
            for(let i = 1; i <= numPages;i++){
                elems.push(<PageButton key={i} pageNumber={i} currentPage={currentPage} onClick={this.handlePageClick} />)
            }
            return <div style={style}>
                <PageIconButton name="prev" numPages={numPages} currentPage={currentPage} onClick={this.handleIconClick} />
                {elems}
                <PageIconButton name="next" numPages={numPages} currentPage={currentPage} onClick={this.handleIconClick} />
            </div>
        }
    }

    handleIconClick = (name:string) => {
        let pageNumber:number;
        let currentPage = this.props.currentPage;
        if (name == "prev"){
            if (currentPage > 1){
                pageNumber = currentPage -1;
                this.props.onClick(pageNumber);
            }
        }
        else if (name == "next"){
            if (currentPage < this.props.numPages){
                pageNumber = currentPage + 1;
                this.props.onClick(pageNumber);
            }
        }
    }


    handlePageClick = (pageNumber:number) => {
        this.props.onClick(pageNumber);
    }
}

class PageIconButton extends React.Component<{name:string,numPages:number,currentPage:number;onClick:(name:string) => void}>{
    render(){
        let name = this.props.name;
        let style:React.CSSProperties = {backgroundColor:"#fff",outline:"none",width:40,height:40,
            display:"inline-block",padding:8,fontSize:"14px",color:"rgb(120,130,140"};
        let icon;

        if (name == "prev"){
            if (this.props.currentPage < 2){
                style.opacity = 0.4;
            }
            icon = "<";
        }
        else {
          
            icon = ">";
            if (this.props.currentPage >= this.props.numPages){
                style.opacity = 0.4;
            }
        }
        return <button style={style}
            onClick={this.handleClick}>{icon}</button>
    }
    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.name);
    }
}

class PageButton extends React.Component<{pageNumber:number,currentPage:number,onClick:(pageNumber:number) => void,leftEdge?:boolean,rightEdge?:boolean}>{
    render(){
        let style:React.CSSProperties = {backgroundColor:"#fff",outline:"none",textAlign:'center',
        width:40,height:40,display:"inline-block",padding:8,fontSize:"14px",color:"rgb(70,80,90)"};
        if (this.props.pageNumber == this.props.currentPage){
            style.backgroundColor = "#ebf2fe";
            style.color = "#000";
            style.fontWeight = 500
        }
        else {
            style.backgroundColor = "#fff";
        }
    
        return <button style={style}
            onClick={this.handleClick}>{this.props.pageNumber}</button>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.pageNumber);

    }
}


export class VerboseWhere extends React.Component<{rowCount:number,label?:string,labelPlural?:string}>{

    render(){
     
        let rowCount = this.props.rowCount;
        let objectLabel = (rowCount == 1) ? this.props.label : this.props.labelPlural;
        let tokens = null; // query.verboseWhere;
        if (tokens && tokens.length) {
            let tokenContent = this.renderTokens(tokens);
            return <div className="RT-DataTable__verbose-search"><span>Found <strong>{rowCount}</strong> {objectLabel} where </span>{tokenContent}</div>
        }
        else {
            return <div className="RT-DataTable__verbose-search"><span>Found <strong>{rowCount}</strong> {objectLabel}</span></div>
        }
    }
    renderTokens(tokens:any[]){
        let elems = [];
        if (tokens){
            for(var i = 0; i < tokens.length;i++){
                var item = tokens[i];
                let style:any = {};
                if (item.token == "field"){
                    style.fontWeight = 500;
                    style.color = "#888b8d";
                }
                else if (item.token == "value"){
                    style.color = "#000";
                    style.fontWeight = 500;
                }
                elems.push(<span key={i} style={style}>{item.value}</span>);
            }
        }
        return elems;
    }


}

interface IDropMenuOption {
    name:string;
    label:string;
}

class SidebarDropMenu extends React.Component<{active:IDropMenuOption,options:IDropMenuOption[],onChoose:(option:IDropMenuOption) => void},{open:boolean}>{

    dropRef:React.RefObject<HTMLDivElement> = React.createRef();
   
    state= {open:false}
    render(){
        let label;
        if (this.props.active){
            label = this.props.active.label;
        }
        else {
            label = "Select"
        }
        let popup;
        if (this.state.open){
            popup = this.renderPopup();
        }
        return <>
            <div style={{fontSize:"13px",fontWeight:500,display:'flex',alignItems:'center',cursor:"pointer"}} onClick={this.handleClick} ref={this.dropRef}>
                <div>{label}</div>
                <div style={{marginLeft:8}}>
                    {this.renderAngleDown()}
                </div>
            </div>
            {popup}
        </>
    }

    renderPopup(){
        return (<Popup
            attachedRef={this.dropRef.current as HTMLElement}
            anchorPoint={AnchorPoint.BottomAlignRight}
            onForceClose={this.handlePopupForceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderMenu}
        />);
    }

    renderMenu = ()=>{
        let elems = [];
        let options = this.props.options;
        let active = this.props.active;
        let isActive;
        for(let i = 0; i < options.length;i++){
            let option = options[i];
            if (active){
                isActive = (active.name == option.name);
            }
            elems.push(<DropMenuItem option={option} isActive={isActive} onClick={this.handleOptionClick}/>)
        }
        let content = <div style={{ backgroundColor: "#fff", boxShadow: "0 0 0 2px hsla(0,0%,0%,0.1)", borderRadius: 4,
            padding:8 }}>
            {elems}
        </div>
        return content;


    }
    handleOptionClick = (option:IDropMenuOption) => {
        this.setState({open:false});
        this.props.onChoose(option);
    }
    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({open:true});
    }

    handlePopupForceClose = () => {
        this.setState({open:false});
    }

    renderAngleDown(){
        return (<svg xmlns="http://www.w3.org/2000/svg" style={{display:"block",width:"20px",height:"20px",fill:"rgb(21,27,38)"}} viewBox="0 0 24 24">
           <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
        </svg>);
    }

}

class DropMenuItem extends React.Component<{isActive:boolean;option:IDropMenuOption, onClick:(option:IDropMenuOption) => void}>{
    render(){
        let option = this.props.option;
        let icon;
        if (this.props.isActive){
            icon = <div style={{width:24}}>
                <Icon icon="check" size={20} fill="var(--rt-primary-color)"/>
            </div>
        }
        else {
            icon = <div style={{width:24}}/>
        }
        return <a href='#' style={{display:"flex",alignItems:"center",padding:"8px 10px",fontSize:"14px",fontWeight:500}} className="RT-DropMenu__item" onClick={this.handleClick}>
            {icon}
            {option.label}
        </a>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.option);
    }
}


class RowContextMenu extends React.Component<{grid:IDataGrid,handler:ContextMenuHandler,collection:CollectionRef}>{
    render(){
        let elems = [];
        let rowMenu = this.props.grid.rowMenu;
        if (!rowMenu) return null;
        let handler = this.props.handler;
        let collectionName = this.props.collection.name;
        let scope = {[collectionName]:handler.row};
        for(let i = 0; i < rowMenu.length;i++){
            let item = rowMenu[i];
            let disabled = false;
            if (item.disableIf){
                let test = handler.canvas.getValue(item.disableIf,{scope});
                if (test){
                    disabled = true;
                }
            }
            elems.push(<RowContextMenuItem key={i} option={item} disabled={disabled} onClick={this.handleClick}/>);

        }
        return elems;
    }
    handleClick = (item:IRowContextMenuItem) => {
        let handler = this.props.handler;
        let collectionName = this.props.collection.name;
        let scope = {[collectionName]:handler.row};
        handler.trigger(item.action,scope);
    }
}

class RowContextMenuItem extends React.Component<{option:IRowContextMenuItem, disabled:boolean,onClick:(option:IRowContextMenuItem) => void}>{
    render(){
        let option = this.props.option;
        let icon;
        if (option.icon){
            icon = <div style={{width:28}}>
                <Icon icon={option.icon} size={18} fill="var(--rt-text-color)"/>
            </div>
        }
        let opacity = (this.props.disabled) ? 0.3 : 1;
        return <a href='#' style={{display:"flex",alignItems:"center",padding:"8px 15px",fontSize:"14px",fontWeight:400,opacity}} className="RT-DropMenu__item" onClick={this.handleClick}>
            {icon}
            {option.label}
        </a>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.disabled) return;

        this.props.onClick(this.props.option);
    }
}