import { RenderEvent } from 'core';
import React from 'react';

export class SpotHolder extends React.Component<{event:RenderEvent,$id:string}>{
    render(): React.ReactNode {
        if (this.props.event.designMode) return this.renderDesign();

        return <div></div>
    }

    renderDesign(){
        let appendElem;
        let appendStyle:React.CSSProperties = {display:"flex",
        alignItems:"center",justifyContent:"center",color:"rgb(180,190,200)",fontSize:"13px",fontWeight:400,
        border:"dashed 2px rgb(232,232,232)"};
        let appendClass = "studio-hide-unless-dragging";
        appendStyle.height = 30;
        /*
        if (index){
            appendStyle.height = 30;
            appendClass += " hover-opacity-0";
        }
        else {
            appendStyle.height = 60;
        }
        */
        appendElem = (<div data-drop-target={this.props.$id} data-drop-position="replace" style={appendStyle} className={appendClass}>
           Add side-by-side elements here
        </div>)

        return appendElem;
    }
}