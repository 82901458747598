import { Application } from "./Application";
import { IPageLaunchParams } from "./types";

export interface IParsedUrl {
    app:string;
    domain:string;
    folder:string;
    filename:string;
    search:{[name:string]:string};
    branch:string;
    isScreen:boolean;
}


export class Url {

    static parse(pathname:string,search:string): IParsedUrl {
        let domain:string;
        let folder:string;
        let filename:string;
       
        let app:string;
        let isScreen:boolean;

        if (pathname){
            pathname = pathname.substring(1);
            let segments = pathname.split('/');
        
            for(let i =0 ; i < segments.length;i++){
                let segment = decodeURIComponent(segments[i]);
                if (!app){
                    app = segment;
                }
                else if (!folder && segment[0] == '@'){
                    domain = segment.substring(1);
                }
                else if (!folder){
                    if (segment == "screens"){
                        isScreen = true;
                    }
                    else {
                        folder = segment;
                    }
                }
                else if (!filename){
                    filename = segment;
                }
            }
        }
        if (!app){
            app = "pages";
        }


        let searchValues = Url.parseSearch(search);
        let params = {};
        if (search) {
            for (var key in searchValues) {
                if (key[0] != "_"){
                    let v = search[key];
                    params[key] = v;
                }
            }
        }
        let branch = searchValues["_branch"];
        return {
            app,
            domain,
            folder,
            filename,
            search:searchValues,
            branch,
            isScreen
        }
    }
    
    static updateSearch(location:Location,patch:any):string{
        let pathname = location.pathname;
        let search = Url.parseSearch(location.search);
        search = {...search,...patch};
        return pathname + Url.stringifySearch(search);
    }
    
    static parseSearch(search: string): any {

        if (!search) {
            return {};
        }
        if (search[0] == "?") {
            search = search.substr(1);
        }
        var parameters = search.split('&');
        var obj = {};
        for (var i = 0; i < parameters.length; i++) {
            var parameter = parameters[i].split('=');
            var pName = decodeURIComponent(parameter[0]);
            obj[pName] = decodeURIComponent(parameter[1]);
        }
        return obj;

    }

    static stringify(app:Application,folder:string,filename:string,params?:any):string {
       

        let pathname = '/' + app.appName + '/';
        if (app.domain){
            pathname = pathname + "@" + app.domain + "/";
        }
        pathname = pathname + folder + "/";
        if (filename){
            pathname += filename;
        }
        if (params){
            pathname = pathname + Url.stringifySearch(params);
        }
        return pathname;
    }

    static stringifyScreenLink(app:Application,launchParams:IPageLaunchParams):string {
        return document.location.origin + Url.stringify(app,"screens",launchParams.name,launchParams.props);
    }

    static stringifySearch(searchObj: any): string {
        var segments: string[] = [];

        if (searchObj) {
            for (var key in searchObj) {
                if (key[0] != "_"){
                    let value = searchObj[key];
                    if (Url.isPrimitive(value)) {
                        segments.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
                    }
                }
            }

        }

        if (segments.length) {
            return "?" + segments.join('&');
        }
        return "";
    }

    static stringifyRedirect(domain:string,form?:string,search?:string):string {
        let url =  "/@" + encodeURIComponent(domain);
        if (form){
            url += "/" + encodeURIComponent(form);
        }

        if (search){
            url += "/" + search;
        }
        return url;
    }

    static isPrimitive(value: any): boolean {
        if (value === undefined || value === null) return false;
        if (typeof (value) == "string" || typeof (value) == "number" || typeof (value) == "boolean") {
            return true;
        }
        return (value != Object(value));
    }
}