import React from 'react';
import { FieldRef, makeComponent } from "core";

export const CodeBlock = makeComponent(class extends React.Component<{field:FieldRef}>{

    render(){
        let field = this.props.field;
        if (!field) return null;
        return (<pre>{field.value}</pre>);
      
    }

    

    
});