import React from 'react';
import {makeComponent,ActionRef, RenderEvent} from 'core';
import {ClickThrottle} from './helpers/ClickThrottle';
import { Icon } from './Icon';
import { HelpButton } from './HelpButton';
import { IStyleIcon } from 'core/types';

export type ButtonStyle = 'primary' | 'link' | 'secondary' | 'add' | 'cancel' | 'destructive' | 'warning' | 'outline';

export const Button = makeComponent(class extends React.Component<{event:RenderEvent,$id:string,label:string,size?:string;
    style?:ButtonStyle;action?:ActionRef,icon?:string,styles?:string,onClick?:ActionRef}>{

    render(){
        let action = this.props.action || this.props.onClick;
        if (!action || action.hidden) return null;

        let styles = this.props.event.computeStyles(action.styles,this.props.styles);
        
        let icon:IStyleIcon;
        if (styles.icon){
            icon = styles.icon;
        }
        else {
            let iconName = this.props.icon || action.icon;
            if (iconName) {
                icon = {name:iconName};
            }
        }
        
        let className:string[] = ['RT-Button'];

       
        if (!icon && styles.className && styles.className.indexOf("btn-add") != -1){
            icon = {name:"plus"};
        }
      
        let label = this.props.label || action.label;
        
        if (styles){
            className.push(styles.className)
        }
      
        let tabIndex; //  = getTabIndex(this.props);
        let button = <ButtonPresenter  className={className} size={this.props.size} 
            tabIndex={tabIndex} label={label} action={action} icon={icon} $id={this.props.$id}/>;
        let helpTopic = this.props.event.canvas.getHelpTopic(action.name);
        if (helpTopic){
            return <div style={{display:"flex",alignItems:"center",gap:10}}>
                {button}
                <HelpButton event={this.props.event} topic={helpTopic}/>
            </div>
        }
        return button;
    }

});



class ButtonPresenter extends React.Component<{className:string[],size:string,
    tabIndex:number,icon?:IStyleIcon,label:string,action:ActionRef,$id}>{

    clickThrottle:ClickThrottle;

    buttonRef = React.createRef<HTMLButtonElement>();
    componentDidMount(){
        let action = this.props.action;
        if (action && action.shouldFocus){
            if (this.buttonRef.current){
                this.buttonRef.current.focus();
            }
        }
    }

    componentDidUpdate(){
        let action = this.props.action;
        if (action && action.shouldFocus){
            if (this.buttonRef.current){
                this.buttonRef.current.focus();
            }
        }
    }
    render(){

        let action = this.props.action;
        let label = this.props.label;
        let disabled:boolean;
        let help:string;
        if (action){
            disabled = action.disabled;
            if (disabled){
                help = action.disabledHelp;
            }
            else {
                help = action.help;
            }
        }
       
       
        let size = this.props.size;
        let className = this.props.className;

        switch (size) {
            case "small":
                className.push("RT-Button--small");
                break;
            case "large":
                className.push("RT-Button--large");
                break;
        }

        let content;
        let icon = this.props.icon;
        if (icon){
            content = <div style={{display:"flex",alignItems:'center',gap:5}}>{<Icon icon={icon.name} className={'RT-Button__icon ' + icon.className}/>}{label}</div>
        }
        else {
            content = label;
        }

       
        return (
            <>
                <button data-elem-id={this.props.$id}
                    ref={this.buttonRef}
                    className={className.join(" ")}
                    disabled={disabled}
                    onClick={this.handleClick}
                    tabIndex={this.props.tabIndex}
                    title={help}
                >
                    {content}
                </button>
                {"\n"}
            </>
        );
    }


    handleClick = (e:React.MouseEvent) => {
        this.clickThrottle = this.clickThrottle || new ClickThrottle();
        this.clickThrottle.handle(e,null,() => {
            e.preventDefault();
            e.stopPropagation();
            let target = e.target as HTMLElement;
            let targetPos;
            if (target){
                targetPos = {x:e.clientX,y:e.clientY};
            }
            this.props.action.trigger({targetPos});
        });
    }


}
