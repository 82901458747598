import { RenderEvent } from 'core';
import React from 'react';
import { StudioEditorChildren } from './studio/StudioEditorChildren';

export const Stacked = class extends React.Component<{
    event:RenderEvent,
    spacing?:string,
    verticalCenter?:boolean,
    $elem:any,
    $id:string
}> {
    render() {
        if (this.props.event.designMode){
            let elem = this.props.$elem;
            return <div style={{position:"relative"}}>
                <StudioEditorChildren event={this.props.event} placeholder="Drop stacked items here" children={this.props.children} $elem={elem} $id={this.props.$id}
                    hideAppend/>
            </div>
        }
        let children = this.props.children;
        if (!children) return null;
        let className = 'RT-Stacked';
        if (this.props.spacing){
            className += ' RT-Stacked--' + this.props.spacing;
        }
        let style:React.CSSProperties = {display:"flex",flexDirection:"column"};
        if (this.props.verticalCenter){
            style.alignSelf = "center";
        }
        return <div className={className} style={style}>
            {this.props.children}
        </div>
    }
}