import { GridViewType } from "components/data-table/types";
import { Application } from "./Application";

export class CanvasSettingsCache {
    private _pages:{[name:string]:CanvasSettings} = {};
    public app:Application;

    constructor(app:Application){
        this.app = app;
    }
    getSettings(pageName:string){
        if (!pageName) return;
        let settings = this._pages[pageName.toLowerCase()];
        if (!settings){
            settings = new CanvasSettings(this.app,pageName);
            this._pages[pageName.toLowerCase()] = settings;
        }
        return settings;
    }

    setOverlayWidth(pageName:string,value:number){
        let settings = this.getSettings(pageName);
        if (!settings) return;
        settings.overlayWidth = value;
    }
}

export class CanvasSettings {
    public _pageName:string;
    private _overlayWidth:number;
    private _viewType:GridViewType;
    public app:Application;

    constructor(app:Application,pageName:string){
        this.app = app;
        this._pageName = pageName;
    }

    get overlayWidth():number {
        return this._overlayWidth;
    }

    set overlayWidth(value:number){
        this._overlayWidth = value;
    }

    get viewType():GridViewType {
        return this._viewType;
    }

    set viewType(value:GridViewType){
        this._viewType = value;
    }
}