import React from 'react';
import ReactDOM from 'react-dom';
import { AnchorPoint, getAnchoredPositionResult, getWindowRect, IRectangle, ZeroRect } from '../helpers/AnchorPoint';

interface IPopupProps {
    attachedRef: HTMLElement|React.ReactInstance|{x:number,y:number}| {top:number,bottom:number,left:number,right:number}
    showOnSide?: boolean   
    anchorPoint?: AnchorPoint
    debugTag?:any
    render: (props:{onResize:()=>void}) => React.ReactElement<any>
    onForceClose:(target:HTMLElement,isTop:boolean)=>void
    onWheel?:(e:React.MouseEvent<WheelEvent>) => boolean
    onAnchored?:({anchorPoint:AnchorPoint}) => void
    zIndex?:number;
    withCover?:boolean;
}

interface IPopupState {
    node: HTMLElement | null
    contentsRef: React.RefObject<any> | null
}

let popupId = 0;

let getAnchorPoint = (anchorRect: IRectangle, showOnSide?: boolean): AnchorPoint => {
    let windowRect = getWindowRect();
    let windowWidth = windowRect.right - windowRect.left;
    let isOnRight = false;
    if (anchorRect.left > windowRect.right - windowWidth / 2) {
        isOnRight = true;
    }
    if (showOnSide) {
        if (isOnRight) {
            return AnchorPoint.LeftVerticalCenter
        }
        return AnchorPoint.RightVerticalCenter
    }
    if (isOnRight) {
        return AnchorPoint.BottomAlignRight
    }
    return AnchorPoint.BottomAlignLeft
}

let getUpdateMenuPosition = (anchorRect:IRectangle,menuRect:IRectangle, 
                                options:{showOnSide?:boolean, anchorPoint?:AnchorPoint}):{rect:IRectangle,anchoredTo:AnchorPoint} => {
    
    if (anchorRect == null) anchorRect = ZeroRect
    if (menuRect == null) menuRect = ZeroRect

    let positionResult;
    if(options.anchorPoint) {
        positionResult = getAnchoredPositionResult(menuRect, anchorRect, options.anchorPoint)
    }
    else {
        positionResult = getAnchoredPositionResult(menuRect, anchorRect, getAnchorPoint(anchorRect, options.showOnSide))
    }

    let newContentRect = positionResult.bounds;
    if(newContentRect == null) {
        newContentRect = menuRect
    }
    return {
        rect: newContentRect,
        anchoredTo: positionResult.anchorPoint
    }
}    

export class Popup extends React.Component<IPopupProps, IPopupState> {

    static popupStack:Popup[] = [];

    state = {
        node: null,
        contentsRef: null,
        isPositionedOnce: false
    }

    handleWheel = e => {
        let contentEle = this.getContentEle()

        if(contentEle != null) {
            if(document.body.contains(contentEle)) {
                if(this.props.onWheel) {
                    this.props.onWheel(e)
                }                
            }
        }
    }

    handleScroll = e => {
        let contentEle = this.getContentEle()

        if(contentEle != null) {
            if(!document.body.contains(contentEle)) {
                return 
            }

            if(contentEle === e.target || contentEle.contains(e.target)) {
                return
            }
        }

        let node = this.state.node
        if(node != null && (node as HTMLElement).contains(e.target)) {
            console.log('force closing of popup by mistake')
        }
        return;
        //  this.props.onForceClose(e,true)
    }    

    getIsTop():boolean {
        if (Popup.popupStack.length){
            return (this == Popup.popupStack[Popup.popupStack.length -1]);
        }
        return true;
    }

    getStackPosition():number {
        for(let i = 0; i < Popup.popupStack.length;i++){
            if (this == Popup.popupStack[i]) return i;
        }
        return -1;
    }

    handlePossibleOutsideClick = e => {
        let isTop = this.getIsTop();
        
        let contentEle = this.getContentEle()
        if(contentEle != null) {
            if(contentEle === e.target || contentEle.contains(e.target)) {
                return
            }
            let popupContainer = e.target.closest('[data-popup-stack]');
            if (popupContainer){
                let popupStackPosition = parseInt(popupContainer.getAttribute('data-popup-stack',10));
                if (popupStackPosition > this.getStackPosition()){
                    return;
                }
            }
        }
        let attachedEle = this.getAttachedEle()
        if(attachedEle != null) {
            if(attachedEle === e.target || attachedEle.contains(e.target)) {
                return
            }
        }        
        this.props.onForceClose(e.target,isTop)
    }

    componentDidMount() {
        console.log('popup mounting')
        window.addEventListener('scroll', this.handleScroll, true)
        window.addEventListener('wheel', this.handleWheel, true)
        window.addEventListener('mouseup', this.handlePossibleOutsideClick)
        window.addEventListener('touchstart', this.handlePossibleOutsideClick)        
        let node = document.createElement('div')
        document.body.appendChild(node);
        Popup.popupStack.push(this);
        this.setState(state => {
            return {
                node
            }
        })
    }

    isAttachedToPosition() {
        let {attachedRef} = this.props
        if(attachedRef != null) {
            let {x,y} = attachedRef as any
            return typeof x === "number" && typeof y === "number"
        }
        return false
    }

    isAttachedToRect() {
        let {attachedRef} = this.props
        if(attachedRef != null) {
            let {top,bottom,left,right,width,height} = attachedRef as any
            return typeof top === "number" && typeof bottom === "number" 
                        && typeof left === "number" && typeof right === "number" 
        }
        return false
    }

    getAttachedEle():HTMLElement | null {
        if(this.isAttachedToPosition() || this.isAttachedToRect()) return null
        let { attachedRef } = this.props
        if(attachedRef != null)  {
            return ReactDOM.findDOMNode(attachedRef as any) as HTMLElement
        }   
        return null
    }

    getContentEle():HTMLElement | null {
        let { contentsRef } = this.state
        if(contentsRef != null)  {
            return ReactDOM.findDOMNode(contentsRef) as HTMLElement
        }   
        return null
    }

    updatePosition = () => {
        let { attachedRef,showOnSide,anchorPoint} = this.props        
        let contentEle = this.getContentEle()
        let attachedEle = this.getAttachedEle()
        let attachedRect:IRectangle | null = null
        if(this.isAttachedToPosition()) {            
            let {x,y} = attachedRef as {x:number, y:number}
            attachedRect = {left: x, right: x, top: y, bottom: y,}
        }
        else if(this.isAttachedToRect()) {
            attachedRect = attachedRef as {top:number,bottom:number,left:number,right:number}
        }
        else if(attachedEle != null) {
            attachedRect = attachedEle.getBoundingClientRect()
        }
        if (contentEle != null && attachedRect != null) {    
            console.log("Updating popup position");        
            let contentRect = contentEle.getBoundingClientRect()
            let result = getUpdateMenuPosition(attachedRect,contentRect,{showOnSide,anchorPoint})
            let positionUpdated = false
            for(let key in result.rect) {
                if(key == 'top' || key == 'left') {
                    let before = contentEle.style[key]
                    let after = result.rect[key] + 'px'
                    if(before != after) {
                        contentEle.style[key] = result.rect[key] + 'px'
                        positionUpdated = true
                    }
                }                
            }
            if(positionUpdated) {
                //console.log('position updated')
                //this.setState({})
            }
            if(this.props.onAnchored) {
                this.props.onAnchored({anchorPoint:result.anchoredTo})
            }
        }
    }

    componentWillUnmount() {
        console.log('popup unmounting')
        window.removeEventListener('wheel', this.handleWheel)        
        window.removeEventListener('mouseup', this.handlePossibleOutsideClick)
        window.removeEventListener('touchstart', this.handlePossibleOutsideClick);
        this.updateStack();
        let { node } = this.state
        if (node != null) {
            document.body.removeChild(node)
        }
    }

    updateStack(){
        let stack = Popup.popupStack;
        let index = stack.indexOf(this);
        if (index != -1){
            Popup.popupStack = Popup.popupStack.slice(0,index);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.updatePosition()
    }

    handleContentsRef = (contentsRef) => {
        this.setState(state => {
            return {                
                contentsRef,
            }
        })
    }

    renderContents() {
        let containerStyle: React.CSSProperties = {
            position: 'fixed',
            zIndex:this.props.zIndex
        }   
        let cover;      
        if (this.props.withCover){
            cover = <div style={{position:'fixed',top:0,bottom:0,right:0,left:0,zIndex:this.props.zIndex - 1}}/>
        }       
        let popupStackPosition = this.getStackPosition();
        return (<>
            <div style={containerStyle} ref={this.handleContentsRef} data-popup-stack={popupStackPosition}>
                {this.props.render({onResize: this.updatePosition})}
            </div>
            {cover}
        </>)
    }

    render() {
        let { node } = this.state
        if (node != null) {
            let contents = this.renderContents()
            return ReactDOM.createPortal(contents, node)
        }
        return null
    }
}