import React from 'react';
import { RenderEvent } from 'core';
import { NumberFormatter } from 'core/FieldFormatter';
import { Icon } from 'components/Icon';
import { EmptyState } from 'components';
import { ScrollRegion } from 'components/helpers/ScrollRegion';

export var theme = {
    cartValueColor:"#468ffd", // "rgb(130,0,114)",
    cartValueWarningColor:"#ff7713",
    cartCountBackground:"#468ffd", // "rgb(130,0,114)",
    circleCheckColor:"rgb(7,136,0)",
    filterCheckedBackground:"rgb(241,253,227)",
    filterCheckedFill:"rgb(132, 195, 61)",
    linkColor:"#468ffd",
    headerBackgroundColor :"#84c33d",
    headerFontSize:"11px",
    headerColor:"#fff",
    bodyFontSize:"16px"
}

interface IData {
    level:number;
    categ1:string;
    categ2:string;
    categ3:string;
    categ4:string;
    categ5:string;
    categ1_label:string;
    categ2_label:string;
    categ3_label:string;
    categ4_label:string;
    categ5_label:string;
    child_categories:{
        categ_id:string;
        descrip:string
    }[];
    sibling_categories:{
        categ_id:string;
        descrip:string
    }[];
    products:{
        product_id:string,
        available_qty:number,
        cart_qty:number,
        "Product.descrip":string
    }[];
}
export class ProductBreadcrumbs extends React.Component<{event:RenderEvent,actions?:any,sidebar?:any}>{
    render(): React.ReactNode {
        let data = this.getData(this.props.event);
       // if (!data.child_categories) return null;
        return <div style={{height:"100%",display:"flex",flexDirection:"column"}}>
            {this.renderHeader(data,this.props.actions)}
            <div style={{marginTop:15,flexGrow:1,overflow:"hidden"}}>
                {this.renderBody(data)}
            </div>
        </div>
    }

    renderHeader(data:IData,actions?:any){
        
        let categoriesElem;
        if (data.child_categories){
            let level = data.level;
            let elems = [];
            for(let i = 1; i <= level;i++){
                if (i > 1){
                    elems.push(<div key={"s" + i} style={{marginTop:2}}>{'>'}</div>)
                }
                let value = this.getCategoryValue(data,i);
                if (i == level && i > 1 && !data.child_categories.length){
                    elems.push(this.renderChooseWrapper("siblings",this.renderSiblingCategories(data,i)))
                }
                else {
                    elems.push(<CategoryLink key={i} event={this.props.event} categ_id={value} level={i} 
                        label={this.getCategoryDescrip(data,i)} isActive={level == i}/>)
                }
            }
            if (data.child_categories.length){
                let childCategories = [];
                for(let i = 0; i < data.child_categories.length;i++){
                    let categ = data.child_categories[i];
                    childCategories.push(<CategoryLink key={categ.categ_id} event={this.props.event} withBullet level={level + 1} label={categ.descrip} categ_id={categ.categ_id} />);
                }   
                elems.push(<div key="child-sep" style={{marginTop:2}}>{'>'}</div>)
                elems.push(this.renderChooseWrapper("child-cat",childCategories))
            }
            categoriesElem = (<div style={{display:"flex",gap:8,paddingLeft:5}}>
                {elems}
            </div>);
        }
        let actionBar;
        if (this.props.actions){
            actionBar = <div style={{marginLeft:"auto",paddingLeft:15,paddingRight:15}}>
                {actions}
            </div>
        }
        return <div>
          
            <div style={{marginTop:10,paddingBottom:8,paddingTop:26,display:"flex"}}>
                {categoriesElem}
                {actionBar}
            </div>
        </div>
        //,borderTop:"solid 2px rgb(230,231,232)"   // marginLeft:-7,
    }

    renderChooseWrapper(key:string,content:any){
        return <div key={key} style={{marginTop:-19}}>
            <div style={{fontSize:"10px",fontWeight:500,marginBottom:4,marginLeft:9}}>CHOOSE SUB-CATEGORY</div>
            <div >{content}</div>
        </div>
    }

    renderSiblingCategories(data:IData,level:number){
        let elems = [];
        let value = this.getCategoryValue(data,level);
        for(let i = 0; i < data.sibling_categories.length;i++){
            let categ = data.sibling_categories[i];
            let isActive = this.matches(value,categ.categ_id);
            elems.push(<CategoryLink key={categ.categ_id} event={this.props.event} withBullet level={level} label={categ.descrip} categ_id={categ.categ_id} 
                isActive={isActive}/>);
        }   
        return elems;
    }

    matches(value1:string,value2:string):boolean{
        if (!value1 || !value2) return false;
        return (value1.toLowerCase() == value2.toLowerCase());
    }

    getCategoryDescrip(data:IData,index:number){
        switch(index){
            case 1:return data.categ1_label;
            case 2:return data.categ2_label;
            case 3:return data.categ3_label;
            case 4:return data.categ4_label;
            case 5:return data.categ5_label;
        }
    }

    getCategoryValue(data:IData,index:number){
        switch(index){
            case 1:return data.categ1;
            case 2:return data.categ2;
            case 3:return data.categ3;
            case 4:return data.categ4;
            case 5:return data.categ5;
        }
    }

    renderBody(data:IData){
        if (!data.products) return null; 
        if (!data.products.length) return <EmptyState event={this.props.event} label="Choose a sub-category to view products." icon="list"/>
        var elems = [];
        let toggle = false;
        let event = this.props.event;
        let selected_product_id = event.canvas.getFieldValue("@selected_product_id");
        for(let i = 0 ;i < data.products.length;i++){
            let product = data.products[i];
            let isActive = (selected_product_id == product.product_id);
            elems.push(<ProductRow key={product.product_id} event={event} product_id={product.product_id} 
                descrip={product["Product.descrip"]} shaded={toggle} isActive={isActive} availQty={product.available_qty} cartQty={product.cart_qty}/>);
            toggle = !toggle;
        }
        let thStyle:React.CSSProperties = {
            backgroundColor:"rgb(132,195,61",
            color:"rgb(255,255,255)",
            fontSize:"11px",
            letterSpacing:"1px",
            textTransform:"uppercase",
            textAlign:"center",
            padding:8,
            borderRight:"solid 3px rgb(255,255,255)"
        }

        let sidebarElem;
        if (this.props.sidebar){
            let sidebarBackground = (selected_product_id) ? "#fff" : "rgb(248,249,250)";
            sidebarElem = (<div style={{backgroundColor:sidebarBackground,flexShrink:0,width:360}}>
                {this.props.sidebar}
            </div>);
        }
        
        return (<div style={{display:"flex",gap:15,height:"100%"}}>
            <div style={{flexGrow:1,backgroundColor:"#fff"}}>
                <ScrollRegion>
                    <table cellPadding="0" cellSpacing="0" style={{width:"100%",borderCollapse:"collapse",borderSpacing:0}}>
                        <thead style={{position:"sticky",top:0}}>
                            <tr>
                                <th><div style={thStyle}>PRODUCT</div></th>
                                <th><div style={{...thStyle,borderRight:null}}>AVAILABLE QTY</div></th>
                            </tr>
                        </thead>
                        <tbody>{elems}</tbody>
                    </table>
                </ScrollRegion>
            </div>
            {sidebarElem}
        </div>)
    }

    getData(event:RenderEvent):IData {
        let canvas = event.canvas;
        let data:IData = {
            level:canvas.getFieldValue("@level"),
            categ1:canvas.getFieldValue("@categ1"),
            categ2:canvas.getFieldValue("@categ2"),
            categ3:canvas.getFieldValue("@categ3"),
            categ4:canvas.getFieldValue("@categ4"),
            categ5:canvas.getFieldValue("@categ5"),
            categ1_label:canvas.getFieldValue("@categ1_label"),
            categ2_label:canvas.getFieldValue("@categ2_label"),
            categ3_label:canvas.getFieldValue("@categ3_label"),
            categ4_label:canvas.getFieldValue("@categ4_label"),
            categ5_label:canvas.getFieldValue("@categ5_label"),
            child_categories:canvas.getRecord("@child_categories",null),
            sibling_categories:canvas.getRecord("@sibling_categories",null),
            products:canvas.getRecord("@products",null)
        }
        return data;
    }

}

class CategoryLink extends React.Component<{event:RenderEvent,label:string,level:number,categ_id:string,isActive?:boolean,withBullet?:boolean}>{
    render(){
        let style:React.CSSProperties;
        let labelStyle:React.CSSProperties;
        if (this.props.isActive){
            style = {
                height:26,
                display:"block",
                fontWeight:600,
                textDecoration:"none",           
                fontSize:"14px",
                backgroundColor:"rgb(0, 106, 83)", // "rgb(101,88,245)",
                color:"#fff",
                borderRadius:99,
                padding:"1px 10px"
            }
            labelStyle = {
                
            }
        }
        else {
            style = {
                height:26,
                display:"block",
                fontWeight:600,
                textDecoration:"none",
                color:"rgb(0, 106, 83)", // "rgb(101,88,245)",
                padding:"2px 10px",
                fontSize:"14px"
            }
            labelStyle = {textDecoration:"underline"}
        }
        let bullet;
        if (this.props.withBullet){
            //bullet = <span style={{marginRight:5,textDecoration:"none"}}>{'\u2022'}</span>
        }
        return <a href='#' style={style} onClick={this.handleClick}>
            {bullet}
            <span style={labelStyle}>{this.props.label}</span>
            
        </a>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        let field = canvas.getFieldRef("@level",null);
        canvas.setRecordValueNoUpdate(field,this.props.level);
        let level = this.props.level;
        if (level == 1){
            field = canvas.getFieldRef("@categ1",null);
        }
        else if (level == 2){
            field = canvas.getFieldRef("@categ2",null);
        }
        else if (level == 3){
            field = canvas.getFieldRef("@categ3",null);
        }
        else if (level == 4){
            field = canvas.getFieldRef("@categ4",null);
        }
        else if (level == 5){
            field = canvas.getFieldRef("@categ5",null);
        }
        canvas.setRecordValueNoUpdate(field,this.props.categ_id);
        canvas.restart();
    }
}

class ProductRow extends React.Component<{event:RenderEvent,product_id:string,descrip:string,shaded:boolean,isActive:boolean,availQty:number,cartQty:number}>{
    render(): React.ReactNode {
        let backgroundColor:string;
        let borderColor = "transparent";
        let fontWeight = 400;
        if (this.props.isActive){
            backgroundColor = "#468ffd17";
            borderColor = "#468ffd";
            fontWeight = 600;
        }
        else if (this.props.shaded){
            backgroundColor = "rgb(248, 249, 250)";
        }
        else {
            backgroundColor = "#fff";
        }
       
        return (<tr key={this.props.product_id} style={{letterSpacing:"0.3px",fontSize:"16px",
            backgroundColor,cursor:"pointer"}}
            onClick={this.handleClick}>
            <td><div style={{padding:"6px 16px",fontWeight}}>{this.props.descrip}</div></td>
            <td style={{textAlign:"right"}}><div style={{padding:"6px 16px",borderStyle:"solid",borderWidth:2,borderColor,}}>
                <ProductQtyCell cart_qty={this.props.cartQty} value={this.props.availQty} theme={theme}/>
                </div>
            </td>
        </tr>)
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        let field = canvas.getFieldRef("@selected_product_id",null);
        canvas.setValue(field,this.props.product_id);
    }
}

class ProductQtyCell extends React.Component<{cart_qty?:number,theme:any,value:number}>{
    render(): React.ReactNode {
        let cart_qty = this.props.cart_qty;
        let value = this.props.value;
        let theme = this.props.theme;
        
        if (cart_qty > 0) {
            let fill = (value >= cart_qty) ? theme.cartValueColor: theme.cartValueWarningColor;
            let icon = <Icon icon="cart" size={18} fill={fill} />;
            return (
                <div style={{ display: "flex", padding: "1px 3px 1px 12px", gap: 6, alignItems: "center", justifyContent: "flex-end" }}>

                    <div style={{ fontWeight: 500, minWidth: 36 }}>{NumberFormatter.formatNumber(cart_qty)}</div>
                    <div style={{ width: 20 }}>{icon}</div>
                </div>);
        }
        else if (value > 0) {
            let icon = <Icon icon="circleCheck" size={18} fill={theme.circleCheckColor} />;
            return (
                <div style={{ display: "flex", padding: "1px 3px 1px 12px",  gap: 6, alignItems: "center", justifyContent: "flex-end" }}>

                    <div style={{ fontWeight: 500, minWidth: 36 }}>{NumberFormatter.formatNumber(value)}</div>
                    <div style={{ width: 20 }}>{icon}</div>
                </div>);
        }
        else {
            let formatted = (!value) ? '\u00A0' : NumberFormatter.formatNumber(value);
            return <div style={{ padding: '1px 29px 1px 12px' }}>{formatted}</div>
        }
    }

}