import { Application, PageContainer, RenderEvent } from 'core';
import { MenuHandler } from 'core/MenuHandler';
import React from 'react';
import { AnchorPoint } from './helpers/AnchorPoint';
import { Icon } from './Icon';
import { Popup } from './popups/Popup';

export class MainMenuLaunch extends React.Component<{event:RenderEvent,style:React.CSSProperties},{isOpen:boolean}>{

    state = {isOpen:false};
    dropRef = React.createRef<HTMLDivElement>();
    menuHandler:MenuHandler;

    constructor(props){
        super(props);
        let app = this.props.event.canvas.app;
        this.menuHandler = app.menuManager.createHandler(this.props.event.canvas,this.handleForceClose);
    }
    componentDidMount(): void {
        let app = this.props.event.canvas.app;
        app.menuManager.connect(this.menuHandler);
        document.addEventListener("mousemove",this.handleMouseMove);

    }
    componentWillUnmount(): void {
        let app = this.props.event.canvas.app;
        app.menuManager.disconnect(this.menuHandler);
        document.removeEventListener("mousemove",this.handleMouseMove);
    }

    render(){
        let popup;
        let event = this.props.event;
        if (this.state.isOpen){
            popup = <MainMenuPopup menuId={this.menuHandler.id} app={event.canvas.app} width={900} dropRef={this.dropRef.current} forceClose={this.handleForceClose} />
        }
    
        let style:React.CSSProperties = {...this.props.style,cursor:"pointer"};
        return (<>
            <div ref={this.dropRef} data-main-nav-menu={true} style={style} onClick={this.handleClick} onMouseEnter={this.handleMouseEnter} >
                <Icon icon="navMenu" size={24} fill="currentColor"/>
            </div>
            {popup}
        </>)
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.isOpen){
            this.setState({isOpen:false});
        }
        else {
            this.setState({isOpen:true});
        }
    
    }

    handleMouseEnter = (e:React.MouseEvent) => {
        if (!this.state.isOpen){
            this.setState({isOpen:true})
        }    
    }

    handleMouseMove = (ev:MouseEvent) => {
        if (this.state.isOpen){
            let target = ev.target as HTMLElement;
            if (target && !target.closest('[data-main-nav-menu]')){
                this.setState({isOpen:false});
            }
        }
    }

    handleForceClose = () => {
        this.setState({isOpen:false});
    }

}

export class MainMenuPopup extends React.Component<{app:Application,menuId:string,dropRef:HTMLElement,forceClose:()=> void,width:number}>{
    render(){
        return (<Popup
            attachedRef={this.props.dropRef}
            anchorPoint={AnchorPoint.BottomAlignLeft}
            onForceClose={this.props.forceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderMenu}
        />);
    }

    renderMenu = ()=>{
        return <div data-main-nav-menu={true} data-menu-id={this.props.menuId} className="shadow-md" style={{border:"solid 2px rgb(232,232,232)",width: this.props.width, backgroundColor: "#fff", borderRadius: 4,
        padding:25 }}>
            <MainMenu app={this.props.app} handleClose={this.props.forceClose} />
        </div>
    }
}


export class MainMenu extends React.Component<{app:Application,handleClose?:() => void}>{


    render(){
        let initialsBadgeBackground = "rgb(117,196,88)" ;
        let initials = this.getInitials(this.props.app);
        let signout:IDropMenuOption = {name:"signout",label:"Sign out"};

        let links = <PageContainer page={{name:"pages/user/mainmenu"}} />
        return (<div>
                {links}
                <div style={{height:25}}/>
                <div style={{borderTop:"solid 1px rgb(232,232,232)",paddingTop:25}}>
                    <div style={{display:"flex",alignItems:"center",marginLeft:15}}>
                        <div style={{width:28,height:28,borderRadius:99,backgroundColor:initialsBadgeBackground,display:"flex",
                            alignItems:"center",justifyContent:'center',fontSize:"15px",color:"#fff"}}>{initials}</div>
                        <div style={{fontSize:"14px",fontWeight:500,marginLeft:10}}>My profile and settings</div>
                        <div style={{marginLeft:"auto"}}>
                            <DropMenuItem option={signout} onClick={this.handleClick}/>
                        </div>
                    </div>
                </div>
        </div>);
    }

    getInitials(app:Application){
        let session = app.session;
        let initials:string;
        if (session.firstName && session.lastName){
            initials = session.firstName[0] + session.lastName[0];
        }
        else if (session.firstName){
            initials = session.firstName.substring(0,2);
        }
        if (initials){
            initials = initials.toUpperCase();
        }
        return initials;
    }

    handleClick = (option:IDropMenuOption) => {
        if (option.name == "signout"){
            this.props.app.session.signout();
        }
        else {
            this.props.app.openWorkspace(option.name);
        }
        if (this.props.handleClose){
            this.props.handleClose();
        }

    }

}


interface IDropMenuOption {
    name:string;
    label:string;
    icon?:string;
    description?:string;
}

class DropMenuItem extends React.Component<{option:IDropMenuOption, onClick:(option:IDropMenuOption) => void,description?:string,icon?:string}>{
    render(){
        let option = this.props.option;
        let desc;
        if (this.props.description){
            desc = (<div style={{fontSize:'12px',fontWeight:400,color:"var(--rt-text-color)"}}>{this.props.description}</div>);
        }
        let icon;
        if (this.props.icon){
            icon = <div className="RT-NavLink__icon"><Icon icon={this.props.icon} size={20} /></div>
        }

        return <a href='#' className="RT-DropMenu__item" style={{display:"flex",fontSize:"15px",alignItems:"center",gap:0,color:"var(--rt-primary-color)",letterSpacing:"0.3px",textDecoration:"none",fontWeight:500,padding:"8px 10px"}} onClick={this.handleClick}>
            {icon}
            <div>
                {option.label}
                {desc}
            </div>
        </a>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.option);
    }
}

export class MainMenuLink extends React.Component<{event:RenderEvent;page:string,label:string,description?:string,icon?:string}>{
    render(){

        let desc;
        if (this.props.description){
            desc = (<div style={{fontSize:'12px',fontWeight:400,color:"var(--rt-text-color)"}}>{this.props.description}</div>);
        }
        let icon;
        if (this.props.icon){
            icon = <div className="RT-NavLink__icon"><Icon icon={this.props.icon} size={20} /></div>
        }

        return <a href='#' className="RT-DropMenu__item" style={{display:"flex",fontSize:"15px",alignItems:"center",gap:0,color:"var(--rt-primary-color)",letterSpacing:"0.3px",textDecoration:"none",fontWeight:500,padding:"8px 10px"}} onClick={this.handleClick}>
            {icon}
            <div>
                {this.props.label}
                {desc}
            </div>
        </a>
    }

    handleClick = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let event = this.props.event;
        let page = this.props.page;
        if (!page) return;
        let app = event.canvas.app;
        event.canvas.closeMenu(e.target as HTMLElement);
        app.openWorkspace(page);
       
    }
}

