
import { Canvas, PageContainer, RenderEvent } from 'core';
import { IPageLaunchParams } from 'core/types';
import React from 'react';

export class ElementContextMenu extends React.Component<{event:RenderEvent,targetCanvas:Canvas,
    elemId?:string,nodeId?:any,onClose?:() => void,}>{
    render(){
        let node_id;
        if (this.props.nodeId){
            node_id = this.props.nodeId;
        }
        else {
            let elemId = this.props.elemId;
            let section:string;
            let element:string;
            if (elemId){
                let i = elemId.indexOf(":");
                if (i == -1){
                    section = elemId;
                }
                else {
                    section = elemId.substring(0,i);
                    element = elemId.substring(i + 1);
                }
            }
            let targetCanvas = this.props.targetCanvas;
            node_id = {page:targetCanvas.pageId,draft:targetCanvas.pageInfo.draft_id, section, element};
        }
       // let page:IPageLaunchParams = {name:"pages/Studio/ElementContextMenu",props:{"@node_id":node_id}};
       // let page:IPageLaunchParams = {name:"pages/HelpAuthor/context_menu",props:{"@node_id":node_id}};
       let page:IPageLaunchParams = {name:"pages/Studio/context_menu",props:{"@node":node_id}};
       let content = <PageContainer page={page} onClose={this.props.onClose} />
       // let content = null;
        return content;
    }
}

