import React from 'react';

export class DataView extends React.Component {
    render(){
        return <div>Data view</div>
    }
}

export class DataViewColumns extends React.Component {
    render(){
        return <div>Data view</div>
    }
}



export class DataViewSorting extends React.Component {
    render(){
        return <div>Data view</div>
    }
}

export class DataViewSort extends React.Component {
    render(){
        return <div>Data view</div>
    }
}

export class DataViewGrouping extends React.Component {
    render(){
        return <div>Data view</div>
    }
}