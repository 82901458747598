import { RenderEvent } from 'core';
import { DynamicRender } from 'core/DynamicRender';
import React from 'react';

export class HelpButton extends React.Component<{ event: RenderEvent; topic: any; }> {
    render(): React.ReactNode {
        return <div style={{ backgroundColor: "rgb(170 191 227)", cursor:"pointer",color: "#fff", fontSize: "14px",fontWeight:500, width: 20, height: 20, borderRadius: 99, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            onClick={this.handleClick}>
            <div>?</div>
        </div>;

    }
    handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        let event = this.props.event;
        let canvas = event.canvas;
        let content = this.props.topic.content;
        let target = e.target as HTMLElement;
        /*
        let video = (
            <div style={{position:"relative",paddingBottom: "71.71314741035857%", height: 0}}>
                <iframe src="https://www.loom.com/embed/c40039714b124bcfb07b7a79076a5520" frameBorder="0" allowFullScreen={true}
               
                 style={{position: "absolute", top: 0, left: 0, width: "100%", height:"100%"}}></iframe></div>

        )
        */

        canvas.openPopup({
            target,
            render:() => {
                return <div style={{ flexGrow: 1, display: "flex", flexDirection: 'column', padding: 15 }}>
                    {DynamicRender.render(event, content)}
                </div>;
            }

        });
        
    };
}
