import { Icon } from 'components/Icon';
import React from 'react';

export class DataViewTabs extends React.Component<{dataViews:any,grid:any}>{
    render(){
        let tabs = [];
        let dataViews = this.props.dataViews;
        let activeView = this.props.grid.getActiveView();

        if (dataViews){
            for (let i = 0; i < dataViews.length;i++){
                let dataView = dataViews[i];
                let isActive = (dataView.name == activeView);
                let label = <div style={{display:"flex",alignItems:"center",gap:10}}>
                    <Icon icon="table" size={16} fill="currentColor"/>
                    <div>{dataView.label}</div>
                </div>
                tabs.push(<Tab key={dataView.name} name={dataView.name} label={label} isActive={isActive} onClick={this.handleClick} />)
            }
        }
        return <HorizontalTabSet tabs={tabs} panels={null} />
    }

    handleClick = (name:string) => {
        this.props.grid.changeView(name);
    }
}

class Tab extends React.Component<{name?:string,label:any,isActive:boolean,onClick:(name:string) => void}>{

    render(){
        let className = ['RT-TabSet__link'];
        if (this.props.isActive) {
            className.push('active');
        }
        return <li className="RT-TabSet__label" >
            <a onClick={this.handleClick} className={className.join(' ')} href='#' data-studio-click data-studio-select>{this.props.label}</a>
        </li>;
    }

    handleClick = async (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.name);
    }
}

class HorizontalTabSet extends React.Component<{tabs:any,panels:any,hideLabels?:boolean,scrollable?:boolean,insideDialog?:boolean}>{
    render(){

       

        let className = "RT-TabSet RT-TabSet--horizontal";
        if (this.props.scrollable){
            className += " RT-TabSet--scrollable";
        }
        if (this.props.insideDialog){
            className += " RT-TabSet--inside-dialog";
        }
    
        let tabs;
        if (this.props.tabs.length > 1){
            tabs = <ul className="RT-TabSet__labels--horizontal" style={{paddingLeft:10}}>
                {this.props.tabs}
            </ul>
        }
        if (this.props.hideLabels){
            className += " RT-TabSet--hide-labels";
        }
        return <div className={className}>
            {tabs}
            {this.props.panels}
        </div>
    
    }
}