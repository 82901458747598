import { ClickThrottle } from 'components/helpers/ClickThrottle';
import {ActionRef, Broadcast, Canvas, CollectionRef, FieldRef, RenderEvent} from 'core';
import { Collection, CollectionSorter, ICollectionSortField } from 'core/Collection';
import { textChangeRangeIsUnchanged } from 'typescript';

interface IFilterOption {
    value:string;
    label:string;
}
export type IFilterOptions = IFilterOption[];


export interface IProductRow {
    id:string;
    sku:string;
    size:string;
    size_sort:number;
    length:string;
    color:string;
    color_text:string;
    family:string;
    family_text:string;
    family_sort:number;
    profile:string;
    profile_text:string;
    profile_sort:number;
    qty_avail:number;
    qty_future:number;
    price:number;
    price1:number;
    price2:number;
    price3:number;
    cart_qty:number;
    pkg_size:number;
    details_visible:boolean;
    inbound:any;
    notes:any;
}

interface ITheme {
    cartValueColor:string;
    cartValueWarningColor:string;
    cartCountBackground:string;
    circleCheckColor:string;
    filterCheckedBackground:string;
    filterCheckedFill:string;
    linkColor:string;
    headerBackgroundColor:string;
    headerFontSize:string;
    headerColor:string;
    bodyFontSize:string;
}

interface ISettings {
    emptyStateMessage:string;
    productTitleSub:string;
    productTitleMain:string;
    pivotColumnLabel:string;
    assortedLabel:string;
}


export var DefaultTheme:ITheme = {
    cartValueColor:"#468ffd", // "rgb(130,0,114)",
    cartValueWarningColor:"#ff7713",
    cartCountBackground:"var(--rt-primary-bg-color)", // "rgb(130,0,114)",
    circleCheckColor:"rgb(7,136,0)",
    filterCheckedBackground:"rgb(241,253,227)",
    filterCheckedFill:"rgb(132, 195, 61)",
    linkColor:"#468ffd",
    headerBackgroundColor :"#84c33d",
    headerFontSize:"11px",
    headerColor:"#fff",
    bodyFontSize:"16px"
}
export class ProductTableStore {
    broadcast = new Broadcast();
    colors:string[] = [];

    profileImages:{[name:string]:string};
    colorOptions:any[];

    familyColors : {[name:string]:string[]};

    family:FieldRef;
    profile:FieldRef;
    color:FieldRef;
    available_date:FieldRef;
    selected_product_id:FieldRef;

    colorNames: {[name:string]:string};
    activeRowId:string;
    loaded:boolean;
    tableKey:number = 1;
    rows:IProductRow[];
  
    theme:ITheme;
    settings:ISettings;
    loadSidebarAction:ActionRef;
    rowMap:{[name:string]:IProductRow};
    clickThrottle = new ClickThrottle();
    canvas:Canvas;

    load(event:RenderEvent,collection:CollectionRef){
        this.rows = collection.rows;
    
        this.family = event.field("@family");
        this.profile = event.field("@profile");
        this.color = event.field("@color");
        this.available_date = event.field("@available_date");
        this.selected_product_id = event.field("@selected_product_id");
        this.loadSidebarAction = event.action("load-sidebar");

        let profileOptions = this.profile.options;
        this.profileImages = {};
        for(let i =0 ; i < profileOptions.length;i++){
            let profile = profileOptions[i] as any;
            if (profile.value){
                this.profileImages[profile.value] = profile.image;
            }
        }
       
        this.colorOptions = this.color.options;
        this.colorNames = {};
        for(let i = 0; i < this.colorOptions.length;i++){
            let c = this.colorOptions[i];
            if (c.value){
                this.colorNames[c.value] = c.label;
            }
        }
        let fc = event.canvas.getFieldRef("@family_colors",{});
        this.familyColors = fc.value;
       
        this.formatData(collection.rows);
        this.loadTheme();
        let settingsField = event.canvas.getFieldRef("@settings",{});

        this.settings = settingsField.value;
    }

    loadTheme(){
        this.theme = DefaultTheme;
    }

    loadSettings(){
        this.settings = {
            emptyStateMessage:"Get started by selecting colors or choosing a product family.",
            productTitleSub:"Trex",
            productTitleMain:"Decking",
            pivotColumnLabel:"Available Qty By Color",
            assortedLabel:"Assorted Families"
        }
    }

    handleCartChange = () => {
        /*
        let rows = this.rows;
        
        let count = 0;
        for(let i =0 ; i < rows.length;i++){
            let row = rows[i];
            if (row.cart_qty > 0){
                count++;
            }
        }
        */
        this.broadcast.refresh();
    }

    buildFamilyColors(family:string):string[]{
        let out:string[] = [];
        let colorSet:string[] = this.familyColors[family];
        for(let i = 0; i < colorSet.length;i++){
            let color = colorSet[i];
            if (this.colors.indexOf(colorSet[i]) != -1){
                out.push(color);
            }
        }
        return out;
    }

    get activeRow():IProductRow {
        if (this.activeRowId && this.rowMap){
            return this.rowMap[this.activeRowId];
        }
        return null;
    }

    get groupByFamily():boolean {
        if (this.colors && this.colors.length > 4) return true;
        if (this.family.value) return true;
        return false;
    }

    formatData(rows:any[]){
        let orderBy:ICollectionSortField[];
        if (this.groupByFamily){
            orderBy = [
                {name:"family_sort"},
                {name:"profile_sort"},
                {name:"size_sort"},
                {name:"length"},
                {name:"color_sort"}
            ];
        }
        else {
            orderBy = [
                {name:"profile_sort"},
                {name:"family_sort"},
                {name:"size_sort"},
                {name:"length"},
                {name:"color_sort"}
            ];
        }
        let schema = Collection.getSchema(rows);
        let sorter = new CollectionSorter(rows,schema,orderBy);
        sorter.executeSort();
        this.rows = rows;
        this.mapRows();
        this.activeRowId = null;
    }

    mapRows(){
        let rowMap = {};
        let rows = this.rows;
        for(let i = 0; i < rows.length;i++){
            let row = rows[i];
            rowMap[row.id] = row;
        }
        this.rowMap = rowMap;
    }

    handleColorChange = (value:string) => {
        let i = this.colors.indexOf(value);
        if (i == -1){
            this.colors.push(value);
        }
        else {
            this.colors.splice(i,1);
        }
        this.formatData(this.rows);
        this.tableKey++;
        this.broadcast.refresh();
    }

    handleSelectAll = ()=> {
        this.colors = [];
        if (this.family.value){
            let colorSet = this.familyColors[this.family.value];
            for(let i =0 ; i < colorSet.length;i++){
                this.colors.push(colorSet[i]);
            }
        }
        else {
            for(let key in this.colorNames){
                this.colors.push(key);
            }
        }
        this.formatData(this.rows);
        this.tableKey++;
        this.broadcast.refresh();
    }

    handleSelectNone = () => {
        this.colors = [];
        this.activeRowId = null;
        this.formatData(this.rows);
        this.tableKey++;
        this.broadcast.refresh();
    }

    handleFamilyFilterChange = (value:string) => {
        this.family.setValue(value);
        if (value){
            this.colors = [];
            if (value){
                let colorSet = this.familyColors[value];
                for(let i = 0; i < colorSet.length;i++){
                    this.colors.push(colorSet[i]);
                }
            }
        }
        this.broadcast.refresh();
    }

    handleProfileFilterChange = (value:string) => {
        this.profile.setValue(value);
        this.broadcast.refresh();
    }

    setPrices(priceLevel:string){
        let rows = this.rows;
        if (priceLevel == "price1"){
            for(let i = 0; i < rows.length;i++){
                rows[i].price = rows[i].price1;
            }
        }
        else if (priceLevel == "price2"){
            for(let i = 0; i < rows.length;i++){
                rows[i].price = rows[i].price2;
            }
        }
        else if (priceLevel == "price3"){
            for(let i = 0; i < rows.length;i++){
                rows[i].price = rows[i].price3;
            }
        }
        else {
            for(let i = 0; i < rows.length;i++){
                rows[i].price = 0;
            }
        }
    }

    handleSelectCell = async (row:IProductRow) => {

        this.activeRowId = row.id;
        let selectedField = this.canvas.getFieldRef("@selected_product_id",null);
        if (selectedField){
            selectedField.setValue(row.sku);
        }
        this.broadcast.refresh();
        
       // this.fetchSidebar(row);
    }

    addToCart(row:IProductRow,qty:number){
        //row.cart_qty = qty;
        //this.handleCartChange();
    }

    parseQty(value:string):number {
        if (!value) return 0;
        return parseInt(value,10);
    }


    async fetchSidebar(row:IProductRow){
        this.loadSidebarAction.trigger({scope:{"@items":row}});
    }
}