import { Icon } from 'components/Icon';
import { RenderEvent } from 'core';
import React from 'react';


export class StudioToolboxLaunch extends React.Component<{event:RenderEvent}>{

    componentDidMount(): void {
        let canvas = this.props.event.canvas;
        canvas.app.studioBroadcast.connect(this);
        canvas.app.designerToolboxBroadcast.connect(this);
    }

    componentWillUnmount(): void {
        let canvas = this.props.event.canvas;
        canvas.app.studioBroadcast.disconnect(this);
        canvas.app.designerToolboxBroadcast.disconnect(this);
    }

    render(): React.ReactNode {
        let event = this.props.event;
        let canvas = event.canvas;
        let toolboxElem;
        let toolbox = canvas.designerToolbox;
        if (toolbox.isOpen){
            toolboxElem = <div style={{marginBottom:10}}>
                {event.render(toolbox.content)}
            </div>
        }

        let style:React.CSSProperties;
        
        if (canvas.dragState && canvas.dragState.dragging){
            style = {display:"none"};
        }
        else {
            style = {display:"block"};
        }
        
        let buttonStyle:React.CSSProperties = {padding:"6px 16px",borderRadius:999,color:"#fff",fontSize:"14px",
            fontWeight:500,
            cursor:"pointer",display:"inline-flex",alignItems:"center",gap:4,border:"none",transition:"background-color 0.15s"};
        let icon;
        if (!toolbox.isOpen){
            buttonStyle.backgroundColor = "var(--rt-primary-bg-color)";
            icon = <div key="icon" style={{transform:"rotate(0)",transition:"transform 0.15s"}}>
                <Icon icon="plus" size={16} fill="#fff"/>
            </div>
        }
        else {
            buttonStyle.backgroundColor = "#2e3749";
            icon = <div key="icon" style={{transform:"rotate(45deg)",transition:"transform 0.15s"}}>
                <Icon icon="plus" size={16} fill="#fff"/>
            </div>
        }
        return (<div key="container" style={style}>
            {toolboxElem}
            <div style={buttonStyle} onClick={this.handleClick}>
                {icon}
                <div>Add element</div>
            </div>
        </div>)

    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        canvas.designerToolbox.isOpen = !canvas.designerToolbox.isOpen;
        canvas.designerToolbox.refresh();
    }
}