import React from 'react';
import {  FieldRef, FieldType, InputControl, makeInput } from 'core';
import { RecordMeta ,FilterOp} from 'core';
import { Focus } from 'core/Focus';
import { AnchorPoint } from './helpers/AnchorPoint';
import { Popup } from './popups/Popup';
import { DisplayProps } from './PutField';
import { DatePickerPopup } from './popups/DatePickerPopup';
import { toDisplay } from './helpers';
import { Icons } from './display/TextBox';
import { IFieldCompareOption, IFieldCompareOptions } from 'core/RecordMeta';
import { Icon } from './Icon';


function findCompare(options:IFieldCompareOptions,value:string):IFieldCompareOption{
    if (!options) return null;
    for(let i =0; i < options.length;i++){
        let option = options[i];
        if (option.value == value) return option;
    }
    return null;
}



export class FilterField extends React.Component<{field:FieldRef,$id:string,icon?:string,label?:string,compareOptions?:any},{popup:string}>{

    private operatorRef:React.RefObject<HTMLDivElement> = React.createRef();

    constructor(props){
        super(props);
        this.state = {
            popup:null
        };
    }

    render(){
        let field = this.props.field;
    
      
        let label = this.props.label || field.label;
       

        if (field.type == "logical"){
            return <LogicalFilter field={field} label={label}/>
        }
       
        
        let opClass = "operator";
        let compareOptions = this.props.compareOptions || field.def.compareOptions;
        let compare = findCompare(compareOptions as any,field.compare);
        let opDesc;
        if (!compare){
            compare = {value:"=",label:"is",hasValue:true,hasToValue:false};
        }

        if (compare){
            let compareLabel = compare.label;
            if (!compare.hasValue && compareLabel.indexOf(' ') != -1){
                let i = compareLabel.indexOf(' ');
                opDesc = <>{compareLabel.substr(0,i)}<span style={{fontWeight:500,fontSize:"14px",color:"#000",marginLeft:8}}>{compareLabel.substr(i)}</span></>
            }
            else {
                opDesc = compareLabel;
            }
        }
      

        let fromInput;
        if (compare.hasValue){
            if (field.options){
                fromInput = <SelectFilter  field={field} value={field.value || ""} onChange={this.setValue}/>
            }
            else {
                fromInput = <TextFilter  field={field} icon={this.props.icon}/>
            }
        }
        
        
        let value = field.value;
        let hasValue:boolean;
        if (value || value === 0){
           hasValue = true;
        }
        let upperBoundInput;
        if (compare && compare.hasToValue){
         
            if (field.options){
                upperBoundInput = <>
                    <div className={opClass + ' operator--and'}>and</div>
                    <SelectFilter field={field} value={field.toValue} onChange={this.setToValue}/>
                </>
            }
            else {
                upperBoundInput = <>
                    <div className={opClass + ' operator--and'}>and</div>
                    <TextFilter  field={field} isToValue icon={this.props.icon} />
                </>
            }
            let upperBoundValue = field.toValue;
            if (upperBoundValue || upperBoundValue === 0){
                hasValue = true;
            }
    
            
        }
        let labelElem = <label>{label}</label>;
        let operator;
        if (field){  //todo: has variable compare
            if (compareOptions && compareOptions.length > 1){
                let drop = <div style={{borderColor:"currentColor transparent transparent transparent",borderWidth:4,borderStyle:"solid",opacity:0.4,
                    marginTop:4,marginLeft:4}}/>
                operator=<div className={opClass}  ref={this.operatorRef} onClick={this.handleOperatorClick}>{opDesc}{drop}</div>
            }
            else if (compare.value != "="){
                operator=<div className={opClass} style={{cursor:"default"}} ref={this.operatorRef}>{opDesc}</div>
            }
        }
        let popup = this.renderPopup(compareOptions);
        let containerClass = "RT-FilterBox";
        /*
        if (hasValue){
            containerClass += " has-value";
        }
        */
        return <>
            <div data-elem-id={this.props.$id} className={containerClass}>
                {labelElem}
                <div className="RT-FilterBox__content">
                    {operator}
                    {fromInput}
                    {upperBoundInput}
                </div>
            </div>
            {popup}
        </>
    }


    setValue = (value:any) => {
        this.props.field.setValue(value);
    }

    setToValue = (value:any) => {
        this.props.field.setToValue(value);
    }

    renderPopup(compareOptions:any[]){
        if (this.state.popup == "operator"){
           
            if (compareOptions && compareOptions.length){
                let type = this.props.field.type || "character";
                let content = <OperatorPopup compareOptions={compareOptions} close={this.handleChooseOperator}/>
                return this.renderPopupContent(content);
            }
        }
        return null;
    }

    handleChooseOperator = (options:{operator:FilterOp}) => {
        let field = this.props.field;
        Focus.focus(field.canvas,field);
        field.setCompare(options.operator,true);
        this.setState({popup:null});
    }

    renderPopupContent(dropContent:any){
        let anchorRef = this.operatorRef;
        return (
            <Popup
                attachedRef={anchorRef.current}
                onForceClose={this.handleForcePopupClose}
                zIndex={20000}
                render={() => dropContent}
                anchorPoint={AnchorPoint.BottomAlignLeft}

            />
        )
    }

    handleForcePopupClose = () => {
        this.setState({popup:null});
    }

    handleOperatorClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            popup:"operator"
        })
    }
}


class OperatorPopup extends React.Component<{compareOptions:any[],close:({operator})=>void}> {
    render(){
        let options = [];
        let compareOptions = this.props.compareOptions;
        if (compareOptions){
            for(let i = 0; i < compareOptions.length;i++){
                let fo = compareOptions[i];
                if (fo.divider){
                    options.push(<div className="Operator-List__divider"/>);
                }
                else {
                    let label:any = fo.label;
                    if (label.substr(0,3) == "is "){
                        label = <span>is <span style={{fontWeight:500}}>{label.substr(3)}</span></span>
                    }
                    options.push(<OperatorChoice label={label} key={fo.value} value={fo.value} onChoose={this.handleChoose}/>)
                }
            }
        }
        return <div className="Operator-List">
            {options}
        </div>
    }	

    handleChoose = (value:string) => {
        this.props.close({operator:value});
    }
}

class OperatorChoice extends React.Component<{label:string,value:string,onChoose:(value:string)=> void}>{
    render(){
        return <div className="Operator-List__item"><a ref='#' onClick={this.handleClick}>{this.props.label}</a></div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onChoose(this.props.value);
    }
}


class LogicalFilter extends React.Component<{label:string,field:FieldRef}>{
    render(){
        let compare = this.props.field.compare;
        let active:string;
        if (compare == "is true"){
            active = "yes";
        }
        else if (compare == "is false"){
            active = "no";
        }
        else {
            active = "any";
        }
      
        return <div className="Logical-Filter">
            <label>{this.props.label}</label>
            <div className="Logical-Filter__content">
                <LogicalFilterChoice name="any" label="any" active={active == "any"} onClick={this.handleClick} />
                <LogicalFilterChoice name="yes" label="yes" active={active == "yes"} onClick={this.handleClick} />
                <LogicalFilterChoice name="no" label="no" active={active == "no"} onClick={this.handleClick} />
            </div>
        </div>
    }

    handleClick = (name:string) => {
        let compare:string;
        if (name=="yes"){
            compare = "is true";
        }
        else if(name == "no"){
            compare = "is false";
        }
        else {
            compare = "none";
        }
        this.props.field.setCompare(compare,true);
    }
}

class LogicalFilterChoice extends React.Component<{name:string,label:string,active:boolean,onClick:(name:string) => void}> {
    render(){
        let className = "Logical-Filter__choice";
        if (this.props.active){
            className += " is-active";
        }
        if (this.props.name == "any"){
            className += " Logical-Filter__choice--any";
        }
        return <div className={className} onClick={this.handleClick}>{this.props.label}</div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.name);
    }
}

class SelectFilter extends React.Component<{field:FieldRef,value:string,onChange:(value:any) => void}>{

    focusElemRef = React.createRef<HTMLSelectElement>();
    componentDidMount(){
        let field = this.props.field;
        if (this.focusElemRef.current && field.shouldFocus) {
            this.focusElemRef.current.focus();
        }
    }

    componentDidUpdate(){
        let field = this.props.field;
        if (this.focusElemRef.current && field.shouldFocus) {
            this.focusElemRef.current.focus();
        }
    }
    render(){
        let optionElems = [];
        let field = this.props.field;
        if (field.options) {
            for (let i = 0; i < field.options.length; i++) {
                let opt = field.options[i];
                optionElems.push(<option key={i} value={opt.value}>{opt.label}</option>);
            }
        }
        let value= this.props.value || "";
        if (value && value.toUpperCase){
            value = value.toUpperCase();  
        }
        return <div className="RT-TextBox" style={{paddingRight:8}}>
            <select className="RT-TextBox__select"  value={value} onChange={this.handleChange} ref={this.focusElemRef}>
                {optionElems}
            </select>
        </div>
    }

    handleChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        let value = e.target.value;
        this.props.onChange(value);
    }
}


export var TextFilter = makeInput(
    class TextFilter extends React.Component<{field:FieldRef,input?:InputControl,isToValue?:boolean,icon?:string}>{

        datePickerRef:React.RefObject<any>; 

        constructor(props){
            super(props);
            let input = this.props.input;
            if (input && input.field && (input.type == "date" || input.type == "datetime")){
                input.handleLaunchFinder = this.launchDatePicker;
            }
        }

        componentDidMount(){
            let input = this.props.input
            if (input.focusElemRef.current && input.field.shouldFocus) {
                input.focusElemRef.current.focus();
                input.focusElemRef.current.select();
            }
        }
    
        componentDidUpdate(){
            let input = this.props.input;
            if (input.focusElemRef.current && input.field.shouldFocus){
                input.focusElemRef.current.focus();
                input.focusElemRef.current.select();
            }
        }

        render(){
            let input = this.props.input;
            let value = input.editValue;
            let field = input.field;

            let isDatePicker = input.type == "date" || input.type == "datetime";
            let hideValue = false;
            let displayElem;
            let clearButton;

            let hasFinder: boolean;
            let finderButton;
            if (input.isLookup || isDatePicker) {
                hasFinder = true;
            }

            let isComboBox = false;
            if (field.type == "uid"){
                isComboBox = true;
                hideValue = true;
            }

            let containerClass = ["RT-TextBox"];
            if (hideValue){
                containerClass.push("RT-TextBox--hide-value");
            }
            if (hasFinder) {
                let icon = (isDatePicker) ? Icons.datePicker() : Icons.search();
                finderButton = (
                    <button
                        className="RT-TextBox__finder-button"
                        tabIndex={-1}
                        onClick={this.handleFinderClick}
                        ref={input.findButtonRef}
                    >
                        {icon}
                    </button>
                );
                containerClass.push("RT-TextBox--has-finder");
                if (input.finderOpen && !isDatePicker){
                    value = "";
                    displayElem = null;
                }
            }
            else if (this.props.icon){
                finderButton = (<span className="RT-TextBox__finder-button">
                    <Icon icon={this.props.icon} size={20}/>
                </span>)
            }
            
            let datePicker;
            if (input.finderOpen && isDatePicker){
                datePicker = this.renderDatePicker();
            }

         
            
            if (isComboBox && !input.dirty) {
          
                let lookupLabel = field.lookupLabel;
               
                if (hideValue){
                    value = "";
                }
           
                displayElem = (
                    <div className="RT-TextBox__description">
                        <div style={{ opacity: 0 }}>{value}</div>
                        <div className="RT-TextBox__description-text">
                            {toDisplay(lookupLabel)}
                        </div>
                    </div>
                );
                if (field.value){
                    clearButton = <div className="RT-TextBox__clear-button">
                        <ClearIcon onClick={this.handleClear}/>
                    </div>
                }
            }
            let inputClassName = "RT-TextBox__input";
            if (!value && value !== '0'){
                inputClassName += " RT-TextBox--empty-value";
            }
            let inputElem = <input
                ref={input.focusElemRef}
                className={inputClassName}
                type="text"
                value={value || ""}
                onBlur={input.onBlur}
                onChange={input.onChange}
                onKeyDown={input.onKeyDown}
                onKeyPress={input.onKeyPress}
                autoComplete="custom-data"
            />
            return <>
                    <div className={containerClass.join(' ')}>
                    <div className="RT-TextBox__container">
                        {displayElem}
                        {inputElem}
                        {clearButton}
                        {finderButton}
                    </div>
                </div>
                {datePicker}
            </>
        }

        launchDatePicker = async () => {
            let input = this.props.input;
            input.finderOpen = true;
            input.allowInputWithFinderOpen = true;
            this.datePickerRef = React.createRef();
            input.focusElemRef.current.focus();
            input.refresh();
        }
    
        renderDatePicker(){
            
            let input = this.props.input;
            let content =  <DatePickerPopup ref={this.datePickerRef} input={input} value={input.editValue} onChange={this.handleDatePickerChange} close={this.handleCloseDatePicker} />
            return <Popup
                attachedRef={input.focusElemRef.current}
                onForceClose={this.handleClose}
                zIndex={20000}
                render={() => content}
                anchorPoint={AnchorPoint.BottomAlignLeft}
    
            />
        
        }
    
        handleFinderClick = (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            this.props.input.launchFinder(false);
        };

        
        handleDatePickerChange = (date:string) => {
            let input = this.props.input;
            input.finderOpen = false;
            if (input.focusElemRef.current){
                input.focusElemRef.current.focus();
            }
            input.setValue(date);
        }
    
        handleCloseDatePicker = () => {
            let input = this.props.input;
            input.finderOpen = false;
           
            input.refresh();
        }

        handleClear = () => {
            let input = this.props.input;
            input.setValue(null);
            input.focusElemRef.current.focus();
        }
        
        handleClose = () => {
            let input = this.props.input;
            input.finderOpen = false;
            input.refresh();
        }
    

    }
);


class ClearIcon extends React.Component<{ onClick: () => void }>{
    render() {
        let style = { alignItems: "center", display: "inline-flex",cursor:"pointer" };
        return <a href='#' onClick={this.handleClick} tabIndex={-1} style={style} data-studio-click>
            <svg style={{ width: 16, height: 16 }} focusable="false" viewBox="0 0 32 32"><path d="M18.1,16l8.9-8.9c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0L16,13.9L7.1,4.9c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l8.9,8.9l-8.9,8.9c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"></path></svg>
        </a>
    }
    handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick();
    }
}