import React from 'react';
import {Broadcast} from 'core/Broadcast';

interface IMenu {
    sections:IMenuSection[];
}

interface IMenuSection {
    name:string;
    label:string;
    items:IMenuItem[]
}

interface IMenuItem {
    name:string;
    label:string;
}

export class MenuStore {
    menu:IMenu;
    broadcast:Broadcast = new Broadcast();
    activeSection:IMenuSection;

    handleSectionClick(section:IMenuSection){
        if (this.activeSection == section){
            this.activeSection = null;
        }
        else {
            this.activeSection = section;
        }
        this.refresh();
    }

    closeMenu(){
        this.activeSection = null;
        this.refresh();
    }

    handleMenuClick(item:IMenuItem){
        this.activeSection = null;
        this.refresh();
    }

    refresh(){
        this.broadcast.refresh();
    }
}

export class ProductMenu extends React.Component<{store:MenuStore}>{

    componentDidMount(){
        this.props.store.broadcast.connect(this);
    }

    componentWillUnmount(){
        this.props.store.broadcast.disconnect(this);
    }

    render(){
        let elems = [];
        let store = this.props.store;
        let menu = store.menu;
        let sections = menu.sections;

        for(let i = 0 ; i < sections.length;i++){
            let section = sections[i];
            elems.push(<Section key={section.name} store={store} section={section} />)
        }
        let activeMenu;
        if (store.activeSection){
            activeMenu = <MenuPanel key={store.activeSection.name} store={store} />
        }
        return <div style={{position:"relative",backgroundColor:"#fff",height:50,boxShadow:"rgb(0 0 0 / 8%) 0px 0px 5px 2px"}}>
            <div style={{position:"absolute",left:"50%",transform:"translateX(-50%)",display:"flex",alignItems:"center",gap:10,color:"rgb(40,60,120)",paddingTop:10,paddingBottom:10,
                justifyContent:"center"}}>
                {elems}
            </div>
            
            <div style={{position:"relative",top:50}}>
                {activeMenu}
            </div>

            
        </div>
    }

   
}

class MenuPanel extends React.Component<{store:MenuStore}>{

    containerRef = React.createRef<HTMLDivElement>();

    componentDidMount(){
        document.addEventListener("click",this.clickListener,{capture:true});
    }

    componentWillUnmount(){
        document.removeEventListener("click",this.clickListener,{capture:true});
    }

    clickListener = (e) => {
        let sectionLink = e.target.closest('[data-menu-section]');
        if (sectionLink) return;
        if (!this.containerRef.current.contains(e.target)){
            this.props.store.closeMenu();
        }
    }

    render(){
        return (<div ref={this.containerRef} style={{position:"absolute",top:4,width:"50%",left:"50%",zIndex:20,height:300,
       }}>
        <div style={{padding:"20px 20px",transform:"translateX(-50%)",backgroundColor:"#fff", borderTop:"solid 3px #000",boxShadow:"rgb(0 0 0 / 8%) 0px 0px 10px 2px"}}>
            {this.renderMenuItems(this.props.store.activeSection)}
        </div>
    </div>)
    }

    renderMenuItems(section:IMenuSection){

        let elems = [];
        let items = section.items;
        let store = this.props.store;

        for(let i = 0; i < items.length;i++){
            let item = items[i];
            elems.push(<MenuItem key={item.name} store={store} item={item} />)
        }
        return elems;
       
    }
}

class Section extends React.Component<{store:MenuStore,section:IMenuSection}>{
    render(){
        let section = this.props.section;
        let caret;
        if (this.props.store.activeSection == section){
            caret = <div style={{position:"absolute",marginTop:5,left:"50%",marginLeft:-10}}>{this.renderCaret()}</div>
        }
        else {

        }
        return <div data-menu-section className="hover-underline" style={{padding:"4px 10px",fontSize:"15px",fontWeight:500,
            color:"rgb(40,60,100)",cursor:"pointer",position:"relative"}} onClick={this.handleClick}>
            {section.label}
            {caret}
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.store.handleSectionClick(this.props.section);
    }

    renderCaret(){
        return (<div style={{height:10,borderLeft:"10px solid transparent",borderRight:"10px solid transparent",borderBottom:"10px solid black"}}/>);
    }
}

class MenuItem extends React.Component<{store:MenuStore,item:IMenuItem}>{
    render(){
        let item = this.props.item;
       
        return <div className="hover-underline" style={{padding:"10px 10px",fontSize:"16px",cursor:"pointer",fontWeight:500}} onClick={this.handleClick}>
            {item.label}
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.store.handleMenuClick(this.props.item);
    }

  
}