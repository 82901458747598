import React from 'react';
import { Icon } from '../Icon';
import { ProductTableStore } from './ProductTableStore';

export class FilterItem extends React.Component<{ store: ProductTableStore; label: string; value: string; disabled?: boolean; onChange: (value: string) => void; }> {
    render() {
        let icon;
        let style: React.CSSProperties = { fontSize: "14px" };
        let iconOpacity;
        let cursor = "pointer";
        let backgroundColor;
        if (this.props.disabled) {
            style.opacity = 0.5;
            iconOpacity = 0.25;
            cursor = null;
        }
        else {
            backgroundColor = "#fff";
        }
        let store = this.props.store;
        let checked = store.colors.indexOf(this.props.value) != -1;
        let boxBackground;
        let boxBorder;
    
        if (checked) {
            icon = <div style={{ position: "absolute", top: -3, left: -3 }}>
                <Icon icon="check" size={20} fill="#fff" />
            </div>;
            style.fontWeight = 500;
            backgroundColor = store.theme.filterCheckedBackground;
            boxBackground = store.theme.filterCheckedFill;
            boxBorder = "solid 1px " + store.theme.filterCheckedFill;
        }
        else {
            boxBorder = "solid 1px #000";
        }
        return <div style={{
            display: "flex", alignItems: "center", gap: 6, padding: "5px 8px", marginBottom: 2, backgroundColor,
            cursor
        }} onClick={this.handleClick}>
            <div style={{ width: 16, height: 16, border: boxBorder, background: boxBackground, position: "relative", opacity: iconOpacity }}>
                {icon}
            </div>
            <div style={style}>{this.props.label}</div>
        </div>;
    }

    handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.disabled)
            return;
        this.props.onChange(this.props.value);


    };
}
