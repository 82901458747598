import { CollectionRef, FieldFormatter, RenderEvent } from 'core';
import { DateFormatter } from 'core/FieldFormatter';
import React from 'react';
import { Icon } from './Icon';
import { RowSelector } from './RowSelector';

export class ActivityTimeline extends React.Component<{event:RenderEvent,collection:CollectionRef}>{
    render(): React.ReactNode {
        let items:any[] = [];

        let collection = this.props.collection;
        if (!collection) return null;
        let rows = collection.rows;
        let event = this.props.event;
        for(let i = 0; i < rows.length;i++){
            let row = rows[i];
            items.push(<Item key={i} event={event} row={row}/>)
        }
        return <div>
            {items}
            <div style={{height:20,backgroundColor:"#fff"}}/>
        </div>
    } 
}


class Item extends React.Component<{event:RenderEvent,row:any}>{
    render(): React.ReactNode {
        let row = this.props.row;
        let channels:any[] = [];
        if (row.channels != null){
            for(let i = 0; i < row.channels.length;i++){
                let channel = row.channels[i];
                channels.push(<div key={i} style={{display:"flex",alignItems:"center",gap:5}}>
                    <div style={{color:"rgb(80,90,100)",fontSize:"12px"}}>{channel.name}</div>
                    <div>{'\u2022'}</div>
                    <div style={{fontWeight:500,color:"var(--rt-primary-color)"}}>{channel.label}</div>
                </div>)
            }
        }
        var dt = DateFormatter.parse(row.logged_at);
        var time = new Date(dt.year,dt.month - 1,dt.day,dt.hour,dt.minute);
        let logged_at = DateFormatter.getDurationText(time.getTime());
    
        let reason;
        if (row.reason_label){
            reason = <div style={{marginTop:3}}><span style={{fontSize:"12px"}}>Reason</span> <span>{'\u2022'}</span> <span style={{fontWeight:500}}>{row.reason_label}</span></div>
        }
        return (<div style={{padding:15,position:"relative",display:"flex",backgroundColor:"#fff",overflow:"visible",letterSpacing:"0.2px"}}>
            <div style={{width:28,height:28,backgroundColor:row.color,borderRadius:99,display:"flex",alignItems:"center",
            justifyContent:'center',opacity:0.9}}>
                <div style={{fontSize:"14px",fontWeight:500,color:"#fff",letterSpacing:"0.5px",lineHeight:1}}>{row.initials}</div>
            </div>
            <div style={{position:"absolute",width:2,top:49,bottom:-10,left:28,backgroundColor:"rgb(232,232,232)",zIndex:1}}/>
            <div style={{padding:"0 15px"}}>
                <div style={{display:"flex",alignItems:"center",gap:20,fontSize:"13px",fontWeight:400}}>
                    <div style={{fontWeight:500,color:"rgb(120,130,140)"}}>{logged_at}</div>
                    <div>{row.user_name}</div>
                </div>
                <div style={{fontSize:"15px",fontWeight:500,marginTop:1}}>{row.message}</div>     
                {reason}
                <div style={{display:"flex",alignItems:"center",gap:25,marginTop:1}}>
                    {channels}
                </div>
            </div>

        </div>)
    }
}