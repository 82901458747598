import React from 'react';
import { Icon } from '../Icon';
import { DateFormatter, NumberFormatter } from 'core/FieldFormatter';
import { ProductTableStore, IProductRow } from './ProductTableStore';
import { ToggleLink } from './ToggleLink';
import { RenderEvent } from 'core';


export class Sidebar extends React.Component<{ event:RenderEvent; row: IProductRow; store: ProductTableStore; }, { editValue: string; }> {

    constructor(props) {
        super(props);
        let row = this.props.row;
        if (row.cart_qty > 0) {
            this.state = { editValue: row.cart_qty.toString() };
        }
        else {
            this.state = { editValue: "" };
        }
    }

    render() {
        let row = this.props.row;
        if (!row)
            return null;
        let store = this.props.store;
        
        let qtyIcon;

        let buttonLabel;
        if (row.cart_qty > 0) {
            buttonLabel = "Update cart";
        }
        else {
            buttonLabel = "Add to cart";
        }

        if (row.qty_avail > 0) {
            qtyIcon = <Icon icon="circleCheck" size={22} fill={store.theme.circleCheckColor} />;
        }
       
        let future;
        if (row.inbound){
            future = this.renderInbound(store,row.inbound,row.qty_avail); // todo: *** this number is not right, we need base
        }
        else {
            future = <div style={{marginTop:20}}>Checking...</div>
        }
       
        let cartButtonOpacity = 1;
        let cartButtonDisabled = false;
      
        if (store.parseQty(this.state.editValue) == (row.cart_qty || 0)) {
            cartButtonOpacity = 0.5;
            cartButtonDisabled = true;
        }

        let price2;
        if (row.price2){
            price2 = (<div>
                <div>Unit+</div>
                <div style={{ fontSize: "18px", fontWeight: 500 }}>{NumberFormatter.formatMoney(row.price2)}<span style={{ fontWeight: 400 }}>/pc</span></div>
            </div>);
        }
        return (<div>
            <div style={{ fontSize: "20px", fontWeight: 500 }}>{row.color_text}</div>

            <div style={{ fontSize: "18px", fontWeight: 500, marginTop: 10 }}>
                <span>{row.size}</span>
                <span> x {row.length}'</span>
            </div>
            <div style={{ fontSize: "16px", fontWeight: 500, marginTop: 10 }}>{row.profile_text}</div>
            <div style={{ fontSize: "14px", fontWeight: 500, marginTop: 5, opacity: 0.7 }}>{row.family_text}</div>
            <div style={{display:"flex",gap:20,marginTop: 15}}>
                <div>
                    <div>Less than unit</div>
                    <div style={{ fontSize: "18px", fontWeight: 500, }}>{NumberFormatter.formatMoney(row.price1)}<span style={{ fontWeight: 400 }}>/pc</span></div>
                </div>
                {price2}
            </div>
            <div style={{ fontSize: "11px", letterSpacing: "0.5px", fontWeight: 500, opacity: 0.6, marginTop: 20, textTransform: "uppercase" }}>Immediate Available Qty</div>
            <div style={{ fontSize: "24px", fontWeight: 500, display: "flex", alignItems: "center", gap: 5 }}><div>{NumberFormatter.formatNumber(row.qty_avail,"#,##0;0")}</div>{qtyIcon}</div>
           
        
            <div>
                <div style={{ fontSize: "11px", letterSpacing: "0.5px", fontWeight: 500, opacity: 0.6, marginTop: 20, textTransform: "uppercase" }}>Unit Size</div>
                <div style={{ fontSize: "16px" }}><span style={{ fontWeight: 500 }}>{row.pkg_size}</span> <span style={{ fontSize: "13px" }}>pcs/unit</span></div>
            </div>
                
            <div style={{ fontSize: "12px", marginTop: 15, letterSpacing: "0.5px", fontWeight: 500, opacity: 0.6, textTransform: "uppercase", marginBottom: 5 }}>Order Qty</div>
            <div style={{ display: "flex", alignItems: "center" }}>

                <input type="text" value={this.state.editValue} onChange={this.handleChange} onKeyPress={this.handleKeyPress}
                    style={{ fontSize: "17px", borderRadius: 2, fontWeight: 500, padding: "3px 8px", width: 100, border: "solid 1px #000" }} />

                <button disabled={cartButtonDisabled} style={{
                    fontSize: "13px", marginLeft: 10, fontWeight: 500, textTransform: "uppercase",whiteSpace:"nowrap",
                    opacity: cartButtonOpacity, border: "solid 1px " + store.theme.cartValueColor, color:store.theme.cartValueColor, borderRadius: 5, display: "flex", alignItems: 'center', padding: "8px 15px",
                    justifyContent: "center", flexGrow: 1
                }} onClick={this.handleAddToCart}>
                    <Icon icon="cart" size={18} fill={store.theme.cartValueColor} /><span style={{ marginLeft: 5 }}>{buttonLabel}</span></button>
            </div>
            {future}
            <div style={{ fontSize: "11px", letterSpacing: "0.5px", fontWeight: 500, opacity: 0.6, marginTop: 20, textTransform: "uppercase" }}>SKU</div>
            <div style={{ fontSize: "14px" ,letterSpacing:"0.5px",opacity:0.5,fontWeight:500}}>{row.sku}</div>
            <div style={{ marginTop: 30 }}>
                <ToggleLink store={store} label="product details" open={row.details_visible} onClick={this.handleToggleShowDetails}>
                    <div style={{ fontSize: "11px", letterSpacing: "0.5px", fontWeight: 500, opacity: 0.6, marginTop: 20, textTransform: "uppercase" }}>MFG ITEM NUMBER</div>
                    <div style={{ fontSize: "14px" ,letterSpacing:"0.5px",opacity:0.5,fontWeight:500}}>{row.id}</div>
                    {this.renderNotes(row.notes)}
                </ToggleLink>
            </div>
        </div>);

    }

    renderInbound(store:ProductTableStore,inbound:any,availQty:number){
        let elems:any[] = [];
        let showFirstAvail = true;
        if (availQty > 0){
           // showFirstAvail = false;
        }
        if (inbound){
            for(let i =0 ; i < inbound.length;i++){
                let line = inbound[i];
                availQty = availQty + line.qty;
                let icon;
                if (availQty > 0 && showFirstAvail){
                    icon = <Icon icon="circleCheck" size={18} fill={store.theme.circleCheckColor} />;
                    //showFirstAvail = false;
                }
                elems.push( <div key={i} style={{ display: "flex", alignItems: "center", fontSize: "15px", gap: 5 }}>
                    <div style={{ width: 50 }}>{DateFormatter.format(line.expected_date,"mmm d")}</div>
                    <div style={{ width: 70, textAlign: 'right' }}>+{NumberFormatter.formatNumber(line.qty)}</div>
                    <div style={{ marginLeft: "auto", width: 70, textAlign: 'right', fontWeight: 500, fontSize: "18px", opacity: 0.7 }}>{NumberFormatter.formatNumber(availQty)}</div>
                    <div style={{ width: 20 }}>{icon}</div>
                </div>);
            }
        }
        if (!elems.length){
            return <div style={{marginTop:20}}>No future stock</div>;
        }

        return (<>
            <div style={{
                fontSize: "11px", letterSpacing: "0.5px", marginTop: 20, fontWeight: 500, opacity: 0.6, textTransform: "uppercase",
                paddingBottom: 2, borderBottom: "solid 1px rgb(200,200,200)", display: "flex", alignItems: "flex-end"
            }}>
                <div>Future Stock</div>
                <div style={{ marginLeft: "auto", paddingRight: 20 }}>Available Qty</div>
            </div>
            {elems}

        </>);
    }

    renderNotes(notes:any){
        if (!notes) return null;
        let elems:any[] = [];
        for(let i = 0; i < notes.length;i++){
            elems.push(<div key={i}>{notes[i].text}</div>)
        }
        return <div style={{marginTop:15,fontSize:"15px"}}>{elems}</div>
    }

    handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key == "Enter") {
            e.preventDefault();
            e.stopPropagation();
            let qty = this.props.store.parseQty(this.state.editValue);
            this.addToCart(qty);
        }
    };

    handleAddToCart = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let qty = this.props.store.parseQty(this.state.editValue);
        this.addToCart(qty);
       
    };

    async addToCart(qty:number){
        let row = this.props.row;
        let store = this.props.store;
        let event = this.props.event;
        let current_cart_id = event.canvas.app.cart.cart_id;
        if (!current_cart_id){
            let getOrCreate = event.action("get-or-create-cart");
            let getResult = await getOrCreate.trigger();
            if (!getResult.continue) return;
        }
        row = store.activeRow;
        let action = event.action("add-to-cart");
        await action.trigger({scope:{"@cart_item":{"product_id":row.sku,qty}}});
        row = store.activeRow;
        store.addToCart(row, qty);
    }

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ editValue: e.target.value });
    };

    handleToggleShowDetails = () => {
        let row = this.props.row;
        row.details_visible = !row.details_visible;
        this.forceUpdate();
    };
}
