import { ActionRef, RenderEvent } from 'core';
import { Expressions } from 'core/studio/Expressions';
import React from 'react';

export class StudioPropEditor extends React.Component<{event:RenderEvent,name:string,target:string,value:string,type:string,label:string,width:any,onChange:ActionRef}>{

    value:string;
    editValue:string;
    dirty:boolean;

    constructor(props){
        super(props)
        if (this.props.type == "expr"){
            this.value = Expressions.toDisplayValue(this.props.value);
        }
        else {
            this.value = this.props.value;
        }
    }

    render(): React.ReactNode {
        let value = this.dirty ? this.editValue : this.value;
        let label = this.props.label;
        let inputStyle:React.CSSProperties = {flexGrow:1,border:'solid 1px var(--rt-text-color)',borderRadius:4,padding:5};
        return <div style={{display:"flex",alignItems:"center",gap:8,width:this.props.width}}>
            <div className="font-semi-bold text-gray-400 uppercase" style={{fontSize:"13px"}}>{label}</div>
            <input type="text" style={inputStyle} onChange={this.handleChange} onBlur={this.handleBlur} value={value || ''} />
        </div>
    }

    handleChange = (e: React.ChangeEvent) => {
        var target = e.target as HTMLTextAreaElement;
        this.dirty = true;
        this.editValue = target.value;
        this.forceUpdate();
    };

    handleBlur = (e: React.FocusEvent) => {
        if (!this.dirty)
            return;

        this.value = this.editValue;
        let newValue;
        if (this.props.type == "expr"){
            newValue = Expressions.fromDisplayValue(this.editValue);
        }
        else {
            newValue = this.editValue;
        }
        let canvas = this.props.event.canvas;

        canvas.studioCommandBuffer.setProp(this.props.target,this.props.name,newValue);
        
        if (this.props.onChange){     
            this.props.onChange.trigger();
        }
        else {
            canvas.studioCommandBuffer.send();
        }
        
        this.dirty = false;
        this.forceUpdate();
    };

}