
import React from 'react';
import {makeComponent, RenderEvent} from '../core';
import { DataViewTabs } from './data-table/DataViewTabs';

 export const SearchPanel  = makeComponent(class extends React.Component<{event:RenderEvent}>{
    render() {
        let canvas = this.props.event.canvas;

        let dataViewTabs = <DataViewTabs dataViews={canvas.schema.dataViews} grid={canvas["grid"]}/>
        return <>
            <div className="RT-Search-Panel">
                {this.props.children}
            </div>
            {dataViewTabs}
        </>

    }
});