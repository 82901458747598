import { Broadcast } from "core/Broadcast";
import { Canvas } from "core/Canvas";

export class DesignerToolbox {
    public canvas:Canvas;
    public content:any;
    public isOpen:boolean;
    public activeFolder:string;
    public activeFolderContent:any[];
    private _folderContent:{[name:string]:any} = {}
   

    public refresh(){
        this.canvas.app.designerToolboxBroadcast.refresh();
    }

    public activateFolder(name:string) {

        let content = this._folderContent[name];
        if (!content){
            this.fetchFolderContent(name);
        }
        this.activeFolder = name;
        this.activeFolderContent = content;
        this.refresh();
    }


    public async fetchFolderContent(name:string):Promise<any>{
        this.canvas.studioCommandBuffer.loadToolbox("folder",name);
        await this.canvas.studioCommandBuffer.send();
    }

    public setFolderContent(name:string,value:any){
        this._folderContent[name] = value;
        if (name == this.activeFolder){
            this.activeFolderContent = value;
        }
        this.refresh();
    }

    public getFolderContent(name:string){
        return this._folderContent[name];
    }

    public run(elem:any){
        let action = elem.action;
        if (action == "close"){
            this.canvas.designerOpen = false;
            this.canvas.app.studioBroadcast.refresh();
        }
        else if (action == "focus"){
            if (elem.name){
                this.canvas.designFocus = elem.name;
            }
        }
        else if (action == "setToolboxFolder"){
            this.setFolderContent(elem.name,elem.value);
        }
        else if (action == "activateFolder"){
            this.activateFolder(elem.name);
        }
    }
}