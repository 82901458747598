import { ComponentStyles } from 'core';
import React from 'react';

export class FieldLabel extends React.Component<{label:string,required?:boolean,helpTopic?:string,styles:ComponentStyles}>{
    render(){

        let required;
        if (this.props.required) {
            required = <span className="text-danger">* </span>;
        }
        let helpIcon;
        if (this.props.helpTopic){
            helpIcon = <FieldInfoIcon topicId={this.props.helpTopic} />
        }
        return (<label style={this.props.styles.style} className={this.props.styles.className}>
            {required}
            {this.props.label}
            {helpIcon}
        </label>);
    }
    
}
class FieldInfoIcon extends React.Component<{topicId:string}>{
    render(){
        return <div className="RT-TextBox__info-icon">{this.renderInfoIcon()}</div>
    }
    renderInfoIcon(){
        return <svg viewBox="0 0 24 24">
        <path d="M12,11a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V12A1,1,0,0,0,12,11Zm.38-3.92a1,1,0,0,0-.76,0,1,1,0,0,0-.33.21,1.15,1.15,0,0,0-.21.33A.84.84,0,0,0,11,8a1,1,0,0,0,.29.71,1.15,1.15,0,0,0,.33.21A1,1,0,0,0,13,8a1.05,1.05,0,0,0-.29-.71A1,1,0,0,0,12.38,7.08ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/>
        </svg>
    }
}