import { Icon } from 'components/Icon';
import { Application, Broadcast, RenderEvent } from 'core';
import {IComponentDefinition, IComponentProp, IComponentPropType} from 'core/ComponentDefinition';
import React from 'react';
import { StatementNode, StatementTree } from './StatementNode';


const keywordColor = "rgb(205, 29, 208)";

function keywordStyle(style?:React.CSSProperties):React.CSSProperties{
    let k:React.CSSProperties = {color:"rgb(205, 29, 208)",fontWeight:400,textTransform:"uppercase"};
    return Object.assign(k,style);
}

class SourceCodeState {
    statements:{[name:string]:any};
    public broadcast:Broadcast = new Broadcast();

    private _expanded:{[name:string]:boolean} = {};
    private _isEdit:{[name:string]:boolean} = {};

    public isExpanded(elem):boolean {
        return this._expanded[elem.$id];
    }

    public isEdit(elem):boolean {
        return this._isEdit[elem.$id];
    }

    public setExpanded(elem,value:boolean){
        this._expanded[elem.$id] = value;
        this.broadcast.refresh();
    }

    public setEdit(elem,value:boolean){
        this._isEdit[elem.$id] = value;
        this.broadcast.refresh();
    }

    public clear(){
        this._isEdit = {};
        this._expanded =  {};
    }

    public toggleExpanded(elem){
        let newValue = !this._expanded[elem.$id];
        if (newValue){
            this._expanded = {};
        }
        else {
            this._isEdit = {};
        }
        this._expanded[elem.$id] = newValue;
        this.broadcast.refresh();
    }

    public toggleEdit(elem){
        let newValue = !this._isEdit[elem.$id];
        if (newValue){
            this._isEdit = {};
        }
        this._isEdit[elem.$id] = newValue;
        this.broadcast.refresh();
    }

}

export class SourceCode extends React.Component<{event:RenderEvent,value:any,statements:any}>{

    codeState = new SourceCodeState();
    tree = new StatementTree();

    constructor(props){
        super(props);
        this.codeState.statements = {};
        let statements = this.props.statements;
        if (statements){
            for(let i = 0; i < statements.length;i++){
                let statement = statements[i];
                this.codeState.statements[statement.name] = statement;
            }
        }
        this.tree.loadStatements(statements,Application.components);
        this.tree.loadSource(this.props.value);
    }
    componentDidMount(): void {
        this.tree.broadcast.connect(this);
    }
    componentWillUnmount(): void {
        this.tree.broadcast.disconnect(this);
    }
    render(): React.ReactNode {

        let event = this.props.event;

        let content =  <Element event={event} node={this.tree.root}/>
        let className = this.shiftKey ? "shift-container" : null;
        return (<div style={{maxHeight:"50vh",overflowY:"auto",fontFamily:"'Red hat Mono'",fontSize:"15px",whiteSpace:"nowrap",
            letterSpacing:"0.25px",lineHeight:"28px",margin:"0 -20px -30px -20px",paddingLeft:15,color:"rgb(0,4,8)"}}
             onMouseMove={this.handleMouseMove} className={className}
             onKeyDown={this.handleKeyDown} onKeyUp={this.handleKeyUp} tabIndex={-1}
             >
            <div>{content}</div>
        </div>)
    }
    shiftKey:boolean;
    handleMouseMove = (e:React.MouseEvent) => {
        if (e.shiftKey != this.shiftKey){
            this.shiftKey = e.shiftKey;
            this.forceUpdate();
        }
    }

    handleKeyDown = (e:React.KeyboardEvent) => {
        console.log("Keydown")
        if (e.shiftKey != this.shiftKey){
            this.shiftKey = e.shiftKey;
            this.forceUpdate();
        }
    }
    handleKeyUp = (e:React.KeyboardEvent) => {
        console.log("keyup")
        if (e.shiftKey != this.shiftKey){
            this.shiftKey = e.shiftKey;
            this.forceUpdate();
        }
    }
}

class Children extends React.Component<{event:RenderEvent,node:StatementNode,spacing?:boolean}>{
    render(): React.ReactNode {
        let elems = [];

        let node = this.props.node;
        let list = node.children;
        let event = this.props.event;
        if (node.hasCursorAsChild){
            elems.push(<div key="cursor" style={{marginLeft:-5}}>
                <Cursor key="cursor" event={this.props.event} node={node}/>
            </div>)
        }
        if (list){
            for(let i = 0 ; i < list.length; i++){
                let item = list[i];
                if (item){
                    if (this.props.spacing){
                        elems.push(<div key={'s' + i} style={{marginTop:15,borderTop:"solid 1px rgb(232,232,232)",paddingTop:15}}/>);
                    }
                    elems.push(<Element event={event} key={item.id} node={item} />);
                }
            }
        }
        return elems;
    }
}

class Element extends React.Component<{event:RenderEvent,node:StatementNode}>{
    render(): React.ReactNode {
        let event = this.props.event;
        let node = this.props.node;

        let children;
        let kind = node.elem.$kind;
        if (node.children){
            let spacing = (kind == "CreatePage");
            
            let propDef:IComponentProp;
            if (node.statementDef){
                propDef =  node.statementDef.props["children"];
            }
            if (propDef && propDef.label){
                children = <div style={{paddingLeft:30}}>
                    <div style={keywordStyle()}>{propDef.label}</div>
                    <div style={{paddingLeft:30}}>
                        <Children event={event} node={node} spacing={spacing}/>
                    </div>
                </div>
            }
            else {
                children = <div style={{paddingLeft:30}}>
                    <Children event={event} node={node} spacing={spacing}/>
                </div>
            }
        }
        let implicitProp;
        let index = 0;
        let propElems:any = [];
        let isExpanded = node.isExpanded;
        
        let isEdit = node.isEdit;
        let asInput = isEdit;
        if (isEdit){
            index = 1;
        }
        let statement = node.statementDef;
        let elem = node.elem;

        if (statement){
            for(let key in statement.props){
                if (key[0] == '$' || key == "children"){
                    continue;
                }
                if (kind == "Func" && key == "name") continue;
                if (key == "comment") continue;
                let propDef = statement.props[key];
                let propLabel = propDef.label || key;
                if (propDef.implicitName && !asInput){
                    propLabel = null;
                }
        
                let value = elem[key];
                if (value){
                    if (index > 0 || value.$kind || Array.isArray(value)){
                       
                        propElems.push(<ElementProp key={index} propDef={propDef} event={event} asInput={asInput} label={propLabel} node={node} propName={key} />)
                        
                    }
                    else if (value != "STYLE_LIST") {
                        implicitProp = <PropValue event={event} propName={key} value={value} node={node}/>
                    }
                }
                else if (isEdit){
                    
                    propElems.push(<ElementProp key={index} event={event} propDef={propDef} asInput={asInput} label={propLabel} node={node} propName={key} 
                         help={propDef.help} />)
                }
                index++;
            }
            if (isEdit){
                propElems.push(<input key="end-focus" style={{height:0,opacity:0,width:0,display:"block"}} onFocus={this.handleEndFocus}/>)
            }
           
        }
        let statementElem;
        
        let color = keywordColor;
        let marginBottom;
        if (statement && statement.statementType == "declare"){
            marginBottom = 10;
        }

        let fontWeight = (statement && statement.statementType == "declare") ? 600 : 400;
        if (isEdit || (kind != "ApplyStyle" && kind != "StyleList" && kind !="UI.PutField" && kind != "Expr")){
            let label;
            if (kind.startsWith("UI.")){
                let component = Application.getKind(kind);
                if (component && component.$def){
                    if (statement.label && statement.label.toLowerCase().startsWith("in ")){
                        label = statement.label;
                    }
                    else {
                        label = "PUT " + statement.label.replaceAll(" ","-");
                    }
                }
                else {
                    label = "PUT " + kind.substring(3);
                }
            }
            else {
                if (kind == "Func"){
                    label = node.elem["name"];
                }
                else if (statement && statement.label){
                    label = statement.label;
                }
                else {
                    label = kind;
                }
            }
            statementElem = (<>
                <div style={keywordStyle({color,fontWeight})}>{label}</div>
                <div style={{width:10}}/>
            </>)
        }
        let backgroundColor;
        if (isExpanded){
            propElems = <div>
                {propElems}
            </div>
            backgroundColor = "rgba(209,29,208,0.04)";
        }
        let toolbar;
        let fillColor = "rgb(72,79,93)";
        if (isExpanded){
           
            let className = (isExpanded) ? "" : "hover-opacity-0";
            toolbar = (
                <div style={{marginLeft:20,display:"flex",alignItems:"center",gap:10,width:"100%"}} className={className}>
                   
                    <div style={{marginLeft:"auto",paddingRight:20, display:"flex",alignItems:"center",gap:14}}>
                    
                    <Icon icon="robot" fill={fillColor} size={20} />
                    <Icon icon="arrow-left" fill={fillColor} size={20} />
                    <Icon icon="arrow-right" fill={fillColor} size={20} />
                    <div style={{transform:"rotate(90deg)"}}>
                        <Icon icon="arrow-left" fill={fillColor} size={20} />
                    </div>
                    <div style={{transform:"rotate(270deg)"}}>
                        <Icon icon="arrow-left" fill={fillColor} size={20} />
                    </div>
                    <Icon icon="square" fill={fillColor} size={20} />
                    <div style={{marginLeft:20}}>
                       
                            <Icon icon="trash" fill={fillColor} size={20} />
                        </div>
                    </div>

                </div>
            )
        }
        let editBar;
        let openingParenthesis;
        let closingParenthesis;

        if (!node.isPropNode){
            editBar = (
            <div style={{position:"absolute",left:-200,top:-12,right:0,height:28}} 
                    onClick={this.handleClickCursorBelow}  className="shift-hover-opacity-0" >
                <div style={{position:"absolute",width:25,height:28,top:0,left:192}}>
                    <div style={{backgroundColor:keywordColor,width:9,height:9,borderRadius:99,position:"absolute",left:0,top:6}}
                        />
                </div>
                <div style={{backgroundColor:keywordColor,position:"absolute",left:192,height:1,right:0,top:11}}/>
            </div>
            )

        }
        else {
            openingParenthesis = <div>(</div>
            closingParenthesis = <div>)</div>
        }

        let cursor;
        if (node.hasCursorAfter){
            cursor = <div key="cursor" style={{marginLeft:-5}}>
                <Cursor key="cursor" event={this.props.event} node={node}/>
            </div>
        }
        let marker;
        if ((node.tree.cursor && node.tree.cursor.containerId == node.id) ||
            (this.containsExpanded(node))
            ){
            marker = <div style={{position:"absolute",top:0,bottom:0,left:6,width:1,backgroundColor:keywordColor,opacity:0.3}}/>
        }
        return (<div style={{position:"relative",cursor:"pointer"}} >
             
            <div style={{display:"flex",alignItems:"center",cursor:"pointer",backgroundColor,paddingLeft:0,marginBottom}} 
                  onClick={this.handleClick} tabIndex={-1} onKeyDown={this.handleElementKeyDown}>
                {statementElem}
                {openingParenthesis}
                {implicitProp}
                {toolbar}
                {editBar}
            </div>
            <div style={{position:"relative"}}>
                <div onClick={this.handleClickProps}>
                {propElems}
                </div>
                {children}
                {closingParenthesis}
                {cursor}

                {marker}
            </div>
        </div>)
    }

    containsExpanded(node:StatementNode):boolean {
        let children = node.children;
        if (!children) return false;
        for(let i =0 ; i < children.length;i++){
            let child = children[i];
            if (child.isExpanded) return true;
        }
        return false;
    }

    handleEndFocus = (e:React.FocusEvent) => {
        let node = this.props.node;
        node.tree.cursor = {
            containerId:node.parent.id,
            afterId:node.id
        }
        /*
        if (node.children){
            node.tree.cursor = {
                containerId:node.id,
                afterId:null
            }
        }
        else {
            node.tree.cursor = {containerId:node.parent.id,afterId:node.id};
        }
        */
        node.tree.clearExpanded();
        node.tree.broadcast.refresh();
    }

    handleElementKeyDown = (e:React.KeyboardEvent) => {
        if (e.key == "Enter"){
            e.preventDefault();
            e.stopPropagation();
            let node = this.props.node;
            node.tree.cursor = {
                containerId:node.parent.id,
                afterId:node.id
            }
            /*
            if (node.children){
                node.tree.cursor = {
                    containerId:node.id,
                    afterId:null
                }
            }
            else {
                node.tree.cursor = {containerId:node.parent.id,afterId:node.id};
            }
            */
            node.tree.clearExpanded();
            node.tree.broadcast.refresh();
        }
    }
    handleClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        if (e.shiftKey){
            this.handleClickCursorBelow(e);
            return;
        }
        let node = this.props.node;
        if (!node.isExpanded){
            
            node.setExpanded(true);
            node.setEdit(true);
            return;
        }
        if (node.isEdit){
            node.setEdit(false);
            return;
        }
        else {
            node.setEdit(true);
            return;
        }
       
    }

    handleClickCursorAbove = (e:React.MouseEvent) => {
        e.stopPropagation();
        let node = this.props.node;

    }

    handleClickCursorBelow = (e:React.MouseEvent) => {
        e.stopPropagation();
        let node = this.props.node;
        let prevSibling = node.getPrevSibling();
        if (prevSibling){
            node.tree.cursor = {
                containerId:prevSibling.parent.id,
                afterId:prevSibling.id
            }
        }
        else {
            node.tree.cursor = {
                containerId:node.parent.id,
                afterId:null
            }
        }
        /*
        if (node.children){
            node.tree.cursor = {
                containerId:node.id,
                afterId:null
            }
        }
        else {
            node.tree.cursor = {containerId:node.parent.id,afterId:node.id};
        }
        */
        node.tree.clearExpanded();
        node.tree.broadcast.refresh();
    }

   
    handleClickProps = (e:React.MouseEvent) => {
        e.stopPropagation();
        let node = this.props.node;
        if (!node.isExpanded){
    
            node.setExpanded(true);
            node.setEdit(true);
            return;
        }
    }
}

class ElementProp extends React.Component<{event:RenderEvent,node:StatementNode,label:string,propName:string,
        help?:any,asInput?:boolean,propDef:IComponentProp}>{
    render(): React.ReactNode {
        let node = this.props.node;
        let elem = node.elem;
        let propName = this.props.propName;

        if (node.elem.$kind == "Func" && propName == "name") return null;

        
        let value = elem[this.props.propName];
        let valueElem = <PropValue event={this.props.event} node={node} propName={this.props.propName} 
            value={value}  help={this.props.help}/>
        let nameElem;
        let style:React.CSSProperties;
        let labelElem;
        let marginLeft;
        let inputWidth = 400;
        let displayFlex = true;
        let closing;
        let valueStyle:React.CSSProperties = {};
        if (this.props.asInput){
        
            let color = "rgb(82,89,101)";
            let inputBackground = "rgb(246,247,248)";
            let propDef = this.props.propDef;

            if (value && value.$kind){
                style = {fontWeight:400,color};
                if (propDef.implicitName){
                    style.fontStyle = "italic";
                    style.textTransform = "lowercase";
                }
                labelElem = <div style={{display:"flex",alignItems:"center"}}>
                    <div style={{color:"rgb(192,192,192)",marginRight:8,opacity:0.5,lineHeight:1}}>[</div>
                    <div style={keywordStyle(style)}>{this.props.label}</div>
                   
                </div>
                closing = <div style={{color:"rgb(192,192,192)",opacity:0.5,lineHeight:1}}>]</div>
                displayFlex = false;
                valueStyle.marginLeft = 50;
            }
            else if (value && Array.isArray(value)){
                style = {fontWeight:400,color};
                labelElem = <div style={{display:"flex",alignItems:"center"}}>
                    <div style={{color:"rgb(192,192,192)",marginRight:8,opacity:0.5,lineHeight:1}}>[</div>
                    <div style={keywordStyle(style)}>{this.props.label}</div>
                   
                </div>
                closing = <div style={{color:"rgb(192,192,192)",opacity:0.5,lineHeight:1}}>]</div>
                displayFlex = false;
            }
            else {
                style = {fontWeight:400,minWidth:220,color};
                let inputValue = value || "";
                if (propDef.implicitName){
                    style = {fontWeight:400,minWidth:220,color,fontStyle:"italic",textTransform:"lowercase"};
                    labelElem = <div style={{display:"flex",alignItems:"center"}}>
                        <div style={{color:"rgb(192,192,192)",marginRight:8,opacity:0}}>[</div>
                        <div style={keywordStyle(style)}>{this.props.label}</div>
                        <input type="text" style={{border:"none",backgroundColor:inputBackground,width:inputWidth,marginLeft:10,marginBottom:2,
                            marginTop:2,padding:"1px 8px",color:"#000"}} value={inputValue} onChange={this.handleChange} 
                            onBlur={this.handleBlur} onKeyDown={this.handleKeyDown} placeholder={this.props.help}/>
                        <div style={{color:"rgb(192,192,192)",marginLeft:12,opacity:0}}>]</div>
                    </div>
                }
                else if (propDef.required){
                    labelElem = <div style={{display:"flex",alignItems:"center"}}>
                        <div style={{color:"rgb(192,192,192)",marginRight:8,opacity:0}}>[</div>
                        <div style={keywordStyle(style)}>{this.props.label}</div>
                        <input type="text" style={{border:"none",backgroundColor:inputBackground,width:inputWidth,marginLeft:10,marginBottom:2,
                            marginTop:2,padding:"1px 8px",color:"#000"}} value={inputValue} onChange={this.handleChange} 
                            onBlur={this.handleBlur}  onKeyDown={this.handleKeyDown} placeholder={this.props.help}/>
                        <div style={{color:"rgb(192,192,192)",marginLeft:12,opacity:0}}>]</div>
                    </div>
                }
                else {
                    labelElem = <div style={{display:"flex",alignItems:"center"}}>
                        <div style={{color:"rgb(192,192,192)",marginRight:8,opacity:0.5,lineHeight:1}}>[</div>
                        <div style={keywordStyle(style)}>{this.props.label}</div>
                        <input type="text" style={{border:"none",backgroundColor:inputBackground,width:inputWidth,marginLeft:10,marginBottom:2,
                            marginTop:2,padding:"1px 8px",color:"#000"}} value={inputValue} onChange={this.handleChange} 
                            onBlur={this.handleBlur}  onKeyDown={this.handleKeyDown} placeholder={this.props.help}/>
                        <div style={{color:"rgb(192,192,192)",marginLeft:12,opacity:0.5,lineHeight:1}}>]</div>
                    </div>
                }
                valueElem = null;
            }
        }
        else {
            if (value && value.$kind){
                displayFlex = false;
            }
            labelElem = <div style={keywordStyle(style)}>{this.props.label}</div>
        }
        let showName = true;
        if (propName == "text"){
           // showName = false;
        }
        if (showName && this.props.label){
            nameElem = <>
                {labelElem}
                <div style={{width:10}}/>
            </>
        }

  

        let containerStyle:React.CSSProperties = {paddingLeft:30,marginLeft};
        
        if (displayFlex){
            containerStyle.display = 'flex';
            containerStyle.alignItems = "flex-start";
        }else if (nameElem){
            if (this.props.asInput){
                valueStyle.marginLeft = 50;
            }
            else {
                valueStyle.marginLeft = 25;
            }
            
        }

        return (<div style={containerStyle}>
            {nameElem}
            
            <div style={valueStyle}>{valueElem}</div>
            {closing}
        </div>);
    }

    handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let node = this.props.node;
        node.elem[this.props.propName] = e.target.value;
        node.tree.broadcast.refresh();;
    }

    handleKeyDown = (e:React.KeyboardEvent) => {
        if (e.key == "Enter"){
            e.preventDefault();
            e.stopPropagation();
            this.addCursor();
        }
    }

    addCursor(){
        let node = this.props.node;
        node.tree.cursor = {
            containerId:node.parent.id,
            afterId:node.id
        }
        /*
        if (node.children){
            node.tree.cursor = {
                containerId:node.id,
                afterId:null
            }
        }
        else {
            node.tree.cursor = {containerId:node.parent.id,afterId:node.id};
        }
        */
        node.tree.clearExpanded();
        node.tree.broadcast.refresh();
    }
    handleBlur = (e:React.FocusEvent) => {
        let node = this.props.node;
        let value = node.elem[this.props.propName];
        this.props.node.tree.broadcast.refresh();
    }
}


class PropValue extends React.Component<{event:RenderEvent,node:StatementNode,propName:string,value:any,help?:any}>{
    render(): React.ReactNode {
        let value = this.props.value;
        let node = this.props.node;
        if (value && value.$kind){
            let propNode = node.propNodes ? node.propNodes[this.props.propName] : null;
            if (propNode){
                return <Element event={this.props.event} node={propNode} />
            }
        }
        else if (Array.isArray(value)){
            return <Children event={this.props.event} node={node} />
        }
        else if (this.props.help){
            return <span style={{color:"rgb(200,200,200)",fontStyle:"italic",fontWeight:400}}>{this.props.help}</span>
        }
        else {
            let style:React.CSSProperties;
            if (value && value.startsWith && value.startsWith("'")){
                style = {color:"rgb(183,0,0)"}
            }
            else if (value && value.startsWith && value.startsWith('@')){
                let i  = value.indexOf('.');
                let target;
                let rec;
                if (i != -1){
                    target = <span style={{}}>{value.substring(i + 1)}</span>
                    rec = <span>{value.substring(1, i + 1)}</span>
                }   
                else {
                    rec = <span style={{}}>{value.substring(1)}</span>
                }
                
                value = <>
                    <span style={{marginRight:1}}>@</span>
                    {rec}
                    {target}
                </>
            }
            return <span style={style}>{value}</span>
        }
    }
}


class Cursor extends React.Component<{event:RenderEvent,node:StatementNode},{value:string}>{

    state = {value:""};
    inputRef = React.createRef<HTMLInputElement>();
    static nextId = 10000;

    componentDidMount(){
        this.inputRef.current.focus();
    }



    render(): React.ReactNode {

        let helpers;
        let node = this.props.node;
        let container:StatementNode;
        if (node.tree.cursor.afterId){
            container = node.parent;
        }
        else {
            container = node;
        }
        if (container.elem.$kind == "UI.ActionBar"){
            helpers = <>
                <div onClick={this.buttonHelperClick}>add-customer</div>
                <div>search</div>
                <div>add-order</div>
            </>
        }
        else {
            helpers = <>
                <div onClick={this.handleHelperClick}>=</div>
                <div>IS</div>
                <div>IS-TRUE</div>
                <div>IS-FALSE</div>
            </>
        }
        return <div style={{position:"relative",left:-3,display:"flex",alignItems:"center",gap:15}}>
            <input type="text" ref={this.inputRef} onKeyDown={this.handleKeyDown} 
                style={{width:200,backgroundColor:"rgb(247,248,249)",border:"none",borderBottom:"solid 1px rgb(230,231,232)",padding:"1px 8px",outline:"none"}} value={this.state.value}
                onChange={this.handleChange}/>
            {helpers}
            
        </div>
    }

    handleHelperClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        let node = this.props.node;
        let newElem = {$kind:"Condition",compare:"=",$id:(Cursor.nextId++).toString()};
        let newNode:StatementNode;
     
        
        if (node.tree.cursor.afterId){
            newNode = node.tree.createNode(node.section,newElem,node.parent);
            let index = node.getIndex();
            node.tree.cursor = null;     
            node.parent.children.splice(index + 1,0,newNode);
        }
        else {
            node.tree.cursor = null;     
            newNode = node.tree.createNode(node.section,newElem,node);
            node.children.splice(0,0,newNode);
        }
        newNode.setExpanded(true);
        newNode.setEdit(true);
        node.tree.broadcast.refresh();
    }

    buttonHelperClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        let node = this.props.node;
        let newElem = {$kind:"UI.Button",action:"add-customer",$id:(Cursor.nextId++).toString()};
        let newNode:StatementNode;
     
        
        if (node.tree.cursor.afterId){
            newNode = node.tree.createNode(node.section,newElem,node.parent);
            let index = node.getIndex();
            node.tree.cursor = null;     
            node.parent.children.splice(index + 1,0,newNode);
        }
        else {
            node.tree.cursor = null;     
            newNode = node.tree.createNode(node.section,newElem,node);
            node.children.splice(0,0,newNode);
        }
        newNode.setExpanded(true);
        newNode.setEdit(true);
        node.tree.broadcast.refresh();
    }
    handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        this.setState({value:e.target.value});
    }

    handleKeyDown = (e:React.KeyboardEvent) => {
        let node = this.props.node;
        if (e.key == "Tab"){
            e.preventDefault();
            let tree = node.tree;
            if (e.shiftKey){
                if(tree.cursor.afterId){
                    tree.cursor = {
                        containerId:node.parent.parent.id,
                        afterId:node.parent.id
                    };
                }
                else {
                    tree.cursor = {
                        containerId:node.parent.id,
                        afterId:node.id
                    }
                    
                }
                tree.broadcast.refresh();
            }
            else {
                if (node.children){
                    let lastChild = node.children[node.children.length - 1];
                    if (lastChild){
                        tree.cursor = {
                            containerId:node.id,
                            afterId:lastChild.id
                        }
                        tree.broadcast.refresh();
                    }
                    else {
                        tree.cursor = {
                            containerId:node.id,
                            afterId:null
                        }
                        tree.broadcast.refresh();
                    }
                }
            }
        }
        else if (e.key == "Escape"){
            node.tree.cursor = null;
            node.tree.broadcast.refresh();
        }
        else if (e.key == "ArrowUp"){
            let prevSibling = node.getPrevSibling();
            if (prevSibling){
                node.tree.cursor = {
                    containerId:prevSibling.parent.id,
                    afterId:prevSibling.id
                }
            }
            else {
                node.tree.cursor = {
                    containerId:node.parent.id,
                    afterId:null
                }
            }
            node.tree.broadcast.refresh();
        }
        else if (e.key == "ArrowDown"){
            if (!node.tree.cursor.afterId){
                if (node.children.length){
                    node.tree.cursor = {
                        containerId:node.id,
                        afterId:node.children[0].id
                    }
                }
                else {
                    node.tree.cursor = {
                        containerId:node.parent.id,
                        afterId:node.id
                    }
                }
            }
            else {
                let nextSibling = node.getNextSibling();
                if (nextSibling){
                    if (nextSibling.children){
                        node.tree.cursor = {
                            containerId:nextSibling.id,
                            afterId:null
                        }
                    }
                    else {
                        node.tree.cursor = {
                            containerId:nextSibling.parent.id,
                            afterId:nextSibling.id
                        }
                    }
                }
                else {
                    node.tree.cursor = {
                        containerId:node.parent.parent.id,
                        afterId:node.parent.id
                    }
                }
            }
            node.tree.broadcast.refresh();
        }
        else if (e.key == "Enter"){
            e.preventDefault();
            let node = this.props.node;
            let kind = this.state.value;
            let newElem;

            let statement = node.tree.statements[kind];
            if (statement){
                 newElem = {$kind:this.state.value,$id:(Cursor.nextId++).toString()};
            }
            else if (kind[0] == '@')(
                newElem = {$kind:"UI.PutField",expr:kind,$id:(Cursor.nextId++).toString()}
            )
            if (newElem){
                let newNode:StatementNode;
            
                
                if (node.tree.cursor.afterId){
                    newNode = node.tree.createNode(node.section,newElem,node.parent);
                    let index = node.getIndex();
                    node.parent.children.splice(index + 1,0,newNode);
                }
                else { 
                    newNode = node.tree.createNode(node.section,newElem,node);
                    node.children.splice(0,0,newNode);
                }
                /*
                node.tree.cursor = {
                    containerId:newNode.parent.id,
                    afterId:newNode.id
                } 
                */
                node.tree.cursor = null;   
                newNode.setExpanded(true);
                newNode.setEdit(true);
                node.tree.broadcast.refresh();
            }
        }
    }
}