import React, { ReducerAction } from 'react';
import {RenderEvent} from 'core/RenderEvent';
import { Icon } from './Icon';
import { ActionRef, Application, Canvas, PageContainer, Url } from 'core';
import { BrowseNavigator } from './BrowseNavigator';
import { StackLayerContainer } from './helpers/StackLayerContainer';
import { TabSetPresenter } from './Tabs';

import { MainMenuLaunch } from './MainNavMenu';
import { Dialog } from 'core/Dialog';
import { TabSetState } from 'core/TabState';


export class Workspace extends React.Component<{event:RenderEvent,label:string,hideMenu?:boolean,$id?:string}>{
   

    tabState:TabSetState;

    constructor(props){
        super(props);
        this.tabState = new TabSetState();
        this.tabState.createTabs(props.children);

    }

    componentDidMount(){
        let canvas = this.props.event.canvas;
        let app = canvas.app;
        app.studioBroadcast.connect(this);
        app.workspaceTabState = this.tabState;
        if (app.currentWorkspaceContent){
            app.spinner.kill();
            Dialog.open(canvas,<PageContainer page={canvas.app.currentWorkspaceContent}/>,null,"dialog");
        }
    }

    componentWillUnmount(){
        let app = this.props.event.canvas.app;
        app.studioBroadcast.disconnect(this);
    }

    render(){
        let content = this.renderContent();     
        return<div style={{position:"absolute",top:0,bottom:0,left:0,right:0,overflow:"hidden"}}>{content}</div>
    }

    renderContent(){
        let event = this.props.event;
        if (!event) return null;;
        let canvas = event.canvas;
        if (!canvas || !canvas.initialized){
            return "Loading...";
        }
     
        let covered:boolean;
        let stackContent = this.renderStackChild(canvas);
        if (stackContent){
            covered = true;
        }
        let title = this.props.label || canvas.title;
        let coveredTitle;
        let titleElem;
        if (this.props.hideMenu){
            titleElem = <div style={{width:30}}/>
        }
        else {
            titleElem = (<div  style={{display:"flex",alignItems:"center",paddingRight:25,paddingBottom:10,paddingLeft:30}} 
            >
            <MainMenuLaunch event={event} style={{marginLeft:-20,paddingLeft:20,paddingRight:10}}/>
            <div  style={{fontSize:"18px",fontWeight:600,
        lineHeight:1,color:"inherit",textDecoration:"none"}}>
                {title}
            </div>
        </div>);
        }
        
        if (covered){
            if (this.props.hideMenu){
                coveredTitle = <div style={{display:"flex",alignItems:"center",paddingBottom:13}}>
                <div style={{fontSize:"18px",fontWeight:600,lineHeight:1}}>{title}</div>
            </div>
            }
            else {
                coveredTitle = <div style={{display:"flex",alignItems:"center",paddingBottom:13}}>
                    <MainMenuLaunch event={event} style={{marginLeft:-20,paddingLeft:20,paddingRight:10}}/>
                    <div style={{fontSize:"18px",fontWeight:600,lineHeight:1}}>{title}</div>
                </div>
            }
        }
        let hideLabels = false; // event.canvas.app.codeDesignerEnabled ? true : false;

        let body = (<TabSetPresenter event={event} title={titleElem} className="RT-TabSet--workspace" noPullPadding 
            tabState={this.tabState} hideLabels={hideLabels}>
            {this.props.children}
        </TabSetPresenter>)
       
        let sidebar;

        let navigator;
        if (canvas.recordBrowse){
            navigator = <BrowseNavigator canvas={canvas}/>
        }

        let label= this.props.label;
        if (!label){
            let event = this.props.event;
            if (event){
                label = event.canvas.title;
            }
        }
        let headerContent;

        return (<StackLayerContainer id={this.props.$id} body={body} header={headerContent} canvas={canvas}  stackContent={stackContent} 
            converedTitle={coveredTitle} sidebar={sidebar}  navigator={navigator}/>);
    }
   
    renderStackChild(canvas:Canvas) {
        if (canvas.stackChild) {
           return canvas.stackChild();
        }
        return null;
    }

    closeLinkContent = () => {
        let canvas = this.props.event.canvas;
        canvas.app.currentWorkspaceContent = null;
        canvas.app.setWorkspaceUrl(true);
        this.forceUpdate();
    }
    
  
}

export class WorkspaceLink extends React.Component<{event:RenderEvent,label:string,icon:string;activity:string,params:any,$id:string,
    action:ActionRef}>{
    render(){
        let canvas = this.props.event.canvas;
        let app = canvas.app;
        let actionName:string;
        if (this.props.action){
            actionName = this.props.action.name;
        }
        let isActive = canvas.app.isWorkspaceContent(actionName);
        let style:React.CSSProperties = {padding:"6px 10px",fontSize:"14px",borderRadius:4,letterSpacing:"0.1px",gap:10,display:"flex",alignItems:"center"};
        if (isActive){
            style.color = "#0073ea";
            style.backgroundColor = "#dff0ff";
        }
        else {
            style.color = "rgb(50,51,52)";
            style.backgroundColor = "transparent";
        }
        let icon = this.props.icon || "folder";
        let href = Url.stringify(app,app.currentWorkspace,app.currentWorkspaceLink);
        return <a href={href} style={style} onClick={this.handleClick}>
            <Icon icon={icon} size={16} fill="currentColor" />
            {this.props.label}
        </a>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let action = this.props.action;
        if (action){
            let canvas = this.props.event.canvas;
            canvas.app.setWorkspaceScreen(canvas.app.currentWorkspace,action.name,{pushState:true});
            action.trigger();
            return;
        }
    }
}



class WorkspaceNav extends React.Component<{event:RenderEvent,label:string}>{
    render(){
        let label = this.props.label || "Workspace";
        let single = label[0].toUpperCase();
        let labelBadgeBackground = "rgb(10,81,87)"; //rgb(242, 121, 242)
        return <div style={{position:"absolute",top:0,left:0,right:0,bottom:0,padding:15,backgroundColor:"#fff",display:"flex",flexDirection:"column"}}>
            <div style={{display:"flex",alignItems:"center",padding:"10px 5px",gap:10}}>
                <div style={{width:22,height:22,backgroundColor:labelBadgeBackground,borderRadius:8,color:"#fff",fontSize:"16px",fontWeight:500,
                    display:"flex",justifyContent:"center",alignItems:'center'}}>
                    <div>{single}</div>
                </div>
                <div style={{fontSize:"15px",fontWeight:700}}>{label}</div>
            </div>
            <div style={{flexGrow:1,overflowY:"auto",marginTop:10}}>
                {this.props.children}
            </div>
        </div>
    }
   
}



