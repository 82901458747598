import { ComponentStyles, FieldFormatter } from "core";
import { IStyleIcon } from "core/types";

export class StyledText {
    public static formatOld(value:any){
        if (typeof(value) === "string"){
            let elems = [];
            let segments = value.split('__');
            if (segments.length == 1) return value;
            let bold = false;
            for(let i = 0; i < segments.length;i++){
                let segment = segments[i];
                if (segment){
                    if (bold){
                        elems.push(<span key={i} style={{marginLeft:2,marginRight:2,fontWeight:700}}>{segment}</span>)
                    }
                    else {
                        elems.push(segment);
                    }
                }
                bold = !bold;
            }
            return elems;
        }
        else if (value){
            return (value.toString());
        }
    }

    public static format(value:any){
        if (typeof(value) === "string"){
            let elems = [];
            let segments = value.split('~|');
            if (segments.length == 1) return value;
            if (segments[0]){
                elems.push(segments[0]);
            }
            
            for(let i = 1; i < segments.length;i++){
                let segment = segments[i];
                if (segment){
                    let end = segment.indexOf('|~');
                    let formatted = segment.substring(0,end);
                    let plain = segment.substring(end + 2);
                    if (formatted){
                        let colon = formatted.lastIndexOf(':');
                        if (colon != -1){
                            let styles = StyledText.computeStyles(formatted.substring(colon + 1));
                            let value = formatted.substring(0,colon);
                            if (styles.format){
                                value = FieldFormatter.format(value,null,styles.format);
                            }
                            elems.push(<span key={i} style={styles.style} className={styles.className}>{value}</span>)
                        }
                        else {
                            elems.push(formatted);
                        }
                    }
                    if (plain){
                        elems.push(plain);
                    }
                }
            }
            return elems;
        }
        else if (value){
            return (value.toString());
        }
    }

    public static computeStyles(defined:string,override?:string):ComponentStyles{
       
        let styles:string[] = [];
        if (defined){
            styles.push(defined);
        }
        if (override){
            styles.push(override);
        }
        if (!styles.length) return {style:null,className:null};
        
        let classNames:string[] = [];
        let style:React.CSSProperties = {};
        let format:string;
        let iconName:string;
        let iconClasses:string[];
        for(let s = 0; s < styles.length; s++){
            let segments = styles[s].split(';');
            for(let i = 0; i < segments.length;i++){
                let segment = segments[i];
                let p = segment.indexOf("=");
                if (p == -1){
                    if (segment.startsWith("format-")){
                        format = segment.substring(7);
                        continue;
                    }
                    else if (segment.startsWith("icon-")){
                        if(segment.startsWith("icon-name-")){
                        iconName = segment.substring(10);
                        }
                        else {
                            iconClasses = iconClasses || [];
                            iconClasses.push(segment);
                        }
                        continue;
                    }
                    classNames.push(segment);
                }
                else {
                    style[segment.substring(0,p)] = segment.substring(p + 1);
                }
            }
        }
        let icon:IStyleIcon;
        if (iconName || iconClasses){
            icon = {name:iconName,className:iconClasses ? iconClasses.join(' ') : null};
        }
        return {style,className:classNames.join(' '),format,icon};
    }
}