import { ActionRef, ComponentStyles, FieldFormatter, FieldType, makeComponent, RenderEvent } from '../core';
import React from 'react';
import { ClickThrottle } from './helpers/ClickThrottle';

export const Value = makeComponent( class extends React.Component<{event:RenderEvent,value:any;
    styles?:string,labelStyles?:string,label?:any,onClick?:ActionRef,icon?:any,suffix?:any,labelPosition?:string}>{
    render1(){
      
        let styles = this.props.event.computeStyles(null,this.props.styles);
        let textElem;
       
        if (styles.format){
            textElem = FieldFormatter.format(this.props.value,null,styles.format);
        }
        else {
            textElem = this.props.value;
        }
       
        let className = ['RT-Text'];
       
        className.push(styles.className);
        return <span style={styles.style} className={className.join(' ')}>{textElem}</span>
    }

    render(){

        let styles = this.props.event.computeStyles(this.props.styles);
        let value;
        let labelStyles = this.props.event.computeStyles(this.props.labelStyles);
       
        if (styles.format){
            value = FieldFormatter.format(this.props.value,null,styles.format);
        }
        else {
            value = this.props.value;
        }

        let content;
       
        let contentClasses = ['RT-Static-Field__content'];
       
    
        if (this.props.styles){
            contentClasses.push(styles.className);
        }
        

       
        let valueElem;
        let isLogical = false;
        if (isLogical){
            if (value){
                valueElem = <div style={{display:"flex",alignItems:"center"}}>
                    {this.renderStaticCheckmark()}
                    <div>Yes</div>
                </div>
            }
            else {
                valueElem = "No";
            }
    
        }
        else {
            valueElem = value;
        }
        if (this.props.onClick) {
            content = (
                <a
                    style={{ display: "block" }}
                    href="#"
                    onClick={this.handleHyperlinkClick}
                >
                    {valueElem}
                </a>
            );
        } else {
            content = valueElem;
        }
        let labelElem;
        let label = this.props.label;
        if (label) {
            let labelClassName = ["RT-Static-Field__label RT--readonly"];
            if (labelStyles){
                labelClassName.push(labelStyles.className);
            }
            labelElem = (
                <label style={labelStyles.style} className={labelClassName.join(' ')}>
                    {label}
                </label>
            );
        }
       
        let contentStyle:React.CSSProperties = styles.style;

        if (!content && content !== 0){
            content = '\u00A0';
        }
        else if (this.props.suffix || this.props.icon){
            let suffix;
            if (this.props.suffix){
                suffix =   <div style={{opacity:0.55}}>{this.props.suffix}</div>
            }
            contentStyle.gap = 5;
            contentStyle.display = "flex";
            contentStyle.alignItems = "center";
            content = <>
                {this.props.icon}
                {content}
                {suffix}
            </>
        }
        let outerClass = "RT-Static-Field";
        
        let outerStyle:React.CSSProperties = {};
        let bullet;
        if (this.props.labelPosition == "bullet"){
            outerStyle.display = "flex";
            outerStyle.alignItems = "center";
            outerStyle.gap = 4;
            bullet = <div style={{color:"var(--rt-label-color)"}}>{'\u2022'}</div>
        }

        return (
            <div className={outerClass} style={outerStyle}>
                {labelElem}
                {bullet}
                <div style={contentStyle} className={contentClasses.join(' ')}>
                    {content}
                </div>
            </div>
        );
    }

    clickThrottle = new ClickThrottle();

    handleHyperlinkClick = (e: React.MouseEvent) => {
        this.clickThrottle.handle(e,null,()=> {
            e.preventDefault();
            e.stopPropagation();
            if (this.props.onClick) {
                this.props.onClick.trigger();
            }
        });
    };

    renderStaticCheckmark(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{display:"block",width:20,height:20,fill:"var(--rt-primary-color)"}}><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>);
    }
});




