import React from 'react';

import { FieldRef, makeComponent, RenderEvent} from '../core';
import {Checkbox} from './display/Checkbox';
import { Icon } from './Icon';

export const ToggleCard  = makeComponent(class extends React.Component<{event?:RenderEvent,field:FieldRef,label?:string,$id?:string}>{
    render() {
       let label = this.props.label;
       let field = this.props.field;
       let backgroundColor = field.value ? "#fff" : "rgb(248,249,250)";
       let style:React.CSSProperties = {backgroundColor,display:"flex",alignItems:"center"};
       let content;
       if (this.props.children){
           content = <div style={{marginTop:3}}>{this.props.children}</div>
       }
        return (<div className="RT-Section" style={style} >
            <div style={{padding:"8px 20px 8px 5px"}} onClick={this.handleClick}>
                <Checkbox event ={this.props.event} field={field}/>
            </div>
            <div>
                <div style={{fontWeight:500}}>{label}</div>
                 {content}
            </div>
        </div>);
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let value = this.props.field.value;
        if (value){
            this.props.field.setValue(false);
        }
        else {
            this.props.field.setValue(true);
        }
    }
 });


 export const CardGroup  = makeComponent(class extends React.Component<{event?:RenderEvent,label?:string,$id?:string}>{
    render() {
       let label = this.props.label;
       return <div style={{marginTop:25}}>
           <div style={{fontSize:"16px",fontWeight:500,marginBottom:10}}>{label}</div>
           {this.props.children}
       </div>
        
    }
 });

 export class LogicalValue extends React.Component<{event:RenderEvent,field:FieldRef,label?:string}>{
     render(): React.ReactNode {
         let field = this.props.field;
         let icon;
         if (field.value){
             icon = (
                <div>
                    <Icon icon='circleCheck' size={25} fill="var(--rt-primary-color)"/>
                </div>
             );
         }
         else {
             icon = <div style={{width:25,height:25,borderRadius:99,backgroundColor:"rgb(244,246,248)"}}/>
         }

         let label = this.props.label || field.label;
        
         let children;
         let containerStyle:React.CSSProperties = {display:"flex",gap:5};

         if (this.props.children){
            children = <div style={{opacity:0.8,marginTop:2}}>{this.props.children}</div>
         }
         else {
            containerStyle.alignItems = "center";
         }
         return (
            <div style={containerStyle}>
                <div style={{width:30,flexGrow:0, flexShrink:0}}>
                    {icon}
                </div>
                <div>
                    <div style={{fontWeight:500}}>{label}</div>
                    {children}
                </div>
            </div>

         )
     }
 }

 export class Annotation extends React.Component<{event:RenderEvent,label:string,icon:string}>{
    render(): React.ReactNode {
       
        let icon;
        if (this.props.icon){
            icon = (
               <div style={{width:30,flexGrow:0, flexShrink:0,marginTop:5}}>
                   <Icon icon={this.props.icon} size={30} fill="var(--rt-primary-color)"/>
               </div>
            );
        }

       
        return (
           <div style={{paddingTop:15,paddingLeft:10,display:"flex",gap:10,paddingBottom:15}}>
               {icon}
               <div >
                   <div style={{fontWeight:500,marginBottom:1}}>{this.props.label}</div>
                   <div>{this.props.children}</div>
               </div>
           </div>

        )
    }
}

export class List extends React.Component<{event:RenderEvent,className:string}>{
    render(): React.ReactNode {
        let items = [];
        let itemClass = "list-item";
        if (this.props.className){
            itemClass += " " + this.props.className;
        }
        React.Children.forEach(this.props.children,(child:any,index:number) => {
            if (React.isValidElement(child)){
                items.push(<div key={index} className={itemClass}>{child}</div>)
            }
        });
        return <div className="list">
            {items}
        </div>
    }
}