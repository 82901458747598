import { ActionRef, CollectionRef, makeComponent, RenderEvent } from '../core';
import React from 'react';

type RenderFunc = (event:RenderEvent) => any;

export const ForEach = makeComponent( class extends React.Component<{event:RenderEvent,$id:string,collection:CollectionRef,whenEmpty?:any,render_row?:RenderFunc}>{
    render() {
        return null; // rendered in dynamic render
    }
});

export class NumberedList extends React.Component<{event:RenderEvent,$id:string,collection:CollectionRef,whenEmpty?:any,render_row?:RenderFunc}>{
    render(): React.ReactNode {

        let collection = this.props.collection;
        if (!collection && !collection.rows) return null;

        let event = this.props.event;
        let rows = collection.rows;
        let elems = [];
        let index = 0;
        let renderRow = this.props.render_row;
        if (rows.length){
            for(let i = 0 ; i < rows.length;i++){
                let row = rows[i];
                let rowEvent = event.forCollectionRow(collection,row,i)
                let rowContent = renderRow(rowEvent);
                if (rowContent){
                    elems.push(<NumberedListItem key={i} index={index} event={rowEvent} collection={collection} row={row}>{rowContent}</NumberedListItem>);
                    index++;
                }
            }
        }
        else if(this.props.whenEmpty){
            elems = this.props.whenEmpty;
        }
        return <div className="Product-Table divide-y-1" style={{display:"flex",flexDirection:"column"}}>
            {elems}
        </div>
    }
}

class NumberedListItem extends React.Component<{event:RenderEvent,collection:CollectionRef,row:any,index:number}>{
    render(){
        let rowIndex = this.props.index + 1;
        let currentRow = this.props.collection.currentRow;
        let selected = (this.props.row == currentRow);
       
        let indexStyle:React.CSSProperties = {display:"flex",alignItems:'center',justifyContent:'center',width:26,height:26,
        backgroundColor:"rgb(232,234,240)",borderRadius:99,marginRight:12,fontWeight:500,fontSize:"13px",lineHeight:0};
        if (selected){
            indexStyle.backgroundColor = "#468ffd";
            indexStyle.color = "#fff";
        }
        let rowIndexElem = (<div style={indexStyle}>
            {rowIndex}
        </div>)
        let cardStyle:React.CSSProperties = {
        padding:"10px 15px",borderRadius:4,flexGrow:1};
        let cardClass = "hover-selector";
        if (selected){
            cardClass += " is-active";
        }
        return <div style={{display:"flex",alignItems:"center",gap:20}} onClick={this.handleClick}>
            {rowIndexElem}
            <div className={cardClass} style={cardStyle}>
                {this.props.children}
            </div>
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let event = this.props.event;
        let collection = this.props.collection;
        collection.currentRow = this.props.row;
      
        if (collection.schema.onRowClick){
            let action = new ActionRef(event.canvas,this.props.collection.schema.onRowClick,this.props.event.scope);
            action.trigger();
        }
        else {
            event.canvas.update();
        }
    }
}

