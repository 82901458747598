import React from 'react';
import { IQueryGroupFooter } from 'core/CollectionGrouper';

export class DataGridGroupFooter extends React.Component<{footer:IQueryGroupFooter,isLastRow:boolean}>{
    render(){   
      
        let footer = this.props.footer;
    
        let footerElems = this.props.children;
        //  footerElems.push(<div key="label" className="RT-DataTable__group-labels">Total</div>);
    
        let className = "RT-DataTable__group-footer";
        if (this.props.isLastRow){
            className += " RT-DataTable--last-row";
        }
        else if (footer.innermost){
            className += " RT-DataTable--innermost";
        }
        else {
            className += " RT-DataTable--outer";
        }
        return (<div className={className}>
            <div className="RT-DataTable__group-footer-inner">{footerElems}</div>
        </div>);
    }
}