
import { timeStamp } from 'console';
import { ActionRef, ContextMenuHandler, ContextMenuHandlerArgs, RenderEvent } from 'core';
import { HoverPopupHandler, HoverPopupHandlerArgs } from 'core/HoverPopupHandler';
import React from 'react';
import { AnchorPoint } from './helpers/AnchorPoint';
import { ClickThrottle } from './helpers/ClickThrottle';
import { Icon } from './Icon';
import { Popup } from './popups/Popup';
import { StudioEditorChildren} from './studio/StudioEditorChildren';


export class Container extends React.Component<{event:RenderEvent,styles?:string,onClick?:ActionRef,$id:string,$elem:any}>{

    clickThrottle:ClickThrottle;
    hoverPopupHandler:HoverPopupHandler;
   

    componentDidMount(){
        let canvas = this.props.event.canvas;
        if (this.props.onClick){
          //  this.hoverPopupHandler = canvas.app.hoverPopupManager.createHandler(canvas,this.handleShowHover);
        }
      
    }

    componentWillUnmount(){
        let canvas = this.props.event.canvas;
        if (this.hoverPopupHandler){
            canvas.app.hoverPopupManager.disconnect(this.hoverPopupHandler);
        }
    
    }
    render(){
        let styles = this.props.event.computeStyles(this.props.styles);
        let style:React.CSSProperties;
        let hoverPopup;
        let dataHoverPopupId;
        let contextMenuId;

        if (this.props.onClick){
            style = {...styles.style,cursor:"pointer"};
            if (this.hoverPopupHandler){
                dataHoverPopupId = this.hoverPopupHandler.id;
            }
        }
        else {
            style = styles.style;
        }
        if (this.hoverPopupHandler && this.hoverPopupHandler.isOpen){
            hoverPopup = this.renderHoverPopup();
        }
       
        let content = this.props.children;
        let event = this.props.event;
        if (event.designMode){
            let elem = this.props.$elem;
            content = <StudioEditorChildren event={event} children={this.props.children} $elem={elem} $id={this.props.$id}/>
        }
        return (<><div style={style} className={'RT-Container ' + styles.className} data-hover-popup={dataHoverPopupId} data-context-menu={contextMenuId}
            onClick={this.handleClick}>
            {content}
        </div>
        {hoverPopup}
        </> ) 
    }

    handleClick = (e:React.MouseEvent) => {
        if (this.props.onClick){
            
            this.clickThrottle = this.clickThrottle || new ClickThrottle();
            this.clickThrottle.handle(e,null,() => {
            
                e.preventDefault();
                e.stopPropagation();
                let target = e.target as HTMLElement;
                let targetPos;
                if (target){
                    targetPos = {x:e.clientX,y:e.clientY};
                }
                this.props.onClick.trigger({targetPos});
            });
        
        }
    }

    renderHoverPopup(){
        let handler = this.hoverPopupHandler;
        return (<Popup
            attachedRef={{x:handler.x,y:handler.y}}
            anchorPoint={AnchorPoint.BottomAlignLeft}
            onForceClose={this.handlePopupForceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderHoverMenu}
        />);
    }

    handlePopupForceClose = () => {
        if (this.hoverPopupHandler){
            this.hoverPopupHandler.close();
        }
        this.forceUpdate();
    }
   

    renderHoverMenu = ()=> {
        return (<div data-hover-popup={this.hoverPopupHandler.id} style={{padding:20,backgroundColor:"#fff"}}>This is a hover popup</div>)
    }

  

    handleShowHover = (args:HoverPopupHandlerArgs) => {

       
        this.hoverPopupHandler.x = args.x + 20;
        this.hoverPopupHandler.y = args.y;
        this.hoverPopupHandler.isOpen = true;
        this.forceUpdate();
  
      
    }
};


export class MenuLaunch extends React.Component<{event:RenderEvent,onClick:ActionRef}>{

    render(){
        return <div style={{cursor:"pointer",width:24,height:20,display:"flex",alignItems:'center',justifyContent:'center   '}} onClick={this.handleClick}>
            <Icon icon="menuDots" size={16} fill="currentColor"/>
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onClick){
            let target = e.target as HTMLElement;
            let targetPos;
            if (target){
                targetPos = {x:e.clientX,y:e.clientY};
            }
            this.props.onClick.trigger({targetPos});
        }
    }
}
