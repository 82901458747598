import React from 'react';
import {PieChart,Pie} from 'recharts';
import {ActionRef, CollectionRef, ComponentStyles, FieldFormatter, makeComponent, RenderEvent} from 'core';
import {Wrapper} from './Wrapper';
import { Icon } from 'components/Icon';

export const ValueLimitChart  = makeComponent(class extends React.Component<{event:RenderEvent, 
    title: string;
    value:any;
    limit:number;
    label:string,styles:string,format:string,
    onClick:ActionRef,
    warningValue:number,
    dangerValue:number,
    smallerIsBetter:boolean
}>{

    render(): React.ReactNode {
       
        let icon;
        let styles = this.props.event.computeStyles(this.props.styles);
        if (styles.icon){
            icon = <Icon icon={styles.icon.name} className={"RT-Static-Field__icon " + styles.icon.className} />
        }
        let content;
        
        
        let progress;
        if (this.props.limit){
            let percentage = (this.props.value * 100)/this.props.limit;
            let width = percentage + "%";
            let progressColor = "#65a1f0";
            if (this.props.smallerIsBetter){
                if (this.props.dangerValue && percentage >= this.props.dangerValue){
                    progressColor = "var(--rt-text-color-danger)";
                }
                else if (this.props.warningValue && percentage >= this.props.warningValue){
                    progressColor = "var(--rt-text-color-warning)";
                }
                else {
                    progressColor = "var(--rt-text-color-success)";
                }
            }
            else if (this.props.dangerValue || this.props.warningValue){
                if (this.props.dangerValue && percentage <= this.props.dangerValue){
                    progressColor = "var(--rt-text-color-danger)";
                }
                else if (this.props.warningValue && percentage <= this.props.warningValue){
                    progressColor = "var(--rt-text-color-warning)";
                }
                else {
                    progressColor = "var(--rt-text-color-success)";
                }
            }
            progress = <div style={{marginTop:5,height:3,backgroundColor:"rgb(220,222,226"}}>
                <div style={{backgroundColor:progressColor,width,height:"100%"}}/>
            </div>
            content = FieldFormatter.formatNumber(percentage,"##0") + "%";
        }
        else {
            progress = <div style={{marginTop:5,height:3}}/>
            let format = this.props.format || styles.format;
            if (format){
                content = FieldFormatter.format(this.props.value,null,format);
            }
            else {
                content = this.props.value;
            }
        }
        if (!content && content !== 0){
            content = '\u00A0';
        }
        let containerStyle:React.CSSProperties = {};
        if (this.props.onClick){
            containerStyle.cursor = "pointer";
        }
        return <div className="RT-Static-Field" style={containerStyle} onClick={this.handleClick}>
            <label className="RT-Static-Field__label RT--readonly">{this.props.label}</label>
            <div style={{height:40}}>
                {progress}
                <div className="RT-Static-Field__content" style={{display:"flex",gap:5,alignItems:"center",fontSize:"19px",lineHeight:1}}>
                    {icon}
                    {content}
                </div>
            </div>
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        if (this.props.onClick){
            this.props.onClick.trigger();
        }
    }
    renderDonutContent(){
        let widgetLabel = this.props.label;
        let percent: number = 0.71;
        let startAngle = 90;
        let endAngle: number;
        endAngle = startAngle - (percent * 360);
        let valueText = Math.round(percent * 100) + "%";
        let data = [{ name: widgetLabel, value: 1 }];
        return(<div style={{position:"relative",width:70}}>
        <PieChart width={70} height={70}>
                <Pie dataKey="value" startAngle={startAngle} endAngle={endAngle} data={data} cx={30} cy={30} outerRadius={30} innerRadius={20} fill="#8884d8" />
            </PieChart>
            <div style={{ position: "absolute", top: "50%", left: "50%", fontSize: 14 }}>
                <div>
                    <div style={{lineHeight:1,marginTop:-8,marginLeft:"-50%",width:"100%"}}>{valueText}</div>
                </div>
            </div>
        </div>)
    }
    renderDonut() {
        
        let collection = this.props.value;
        let row;
        if (collection && collection.rows){
            row = collection.rows[0];
        }    
        let value;
        let limit;
        if (row){
            value = row["value"];
            limit = row["limit"];
        }
        let widgetLabel = this.props.label;
        let percent: number;
        let startAngle = 90;
        let endAngle: number;
        let valueText: string;

        if (limit) {
            percent = value / limit;
            if (percent > 1) {
                percent = 1;
            }
            if (percent < 0) {
                percent = 0;
            }
        }

        let content;

        if (isNaN(percent)){
            content = null;
        }
        else {
            endAngle = startAngle - (percent * 360);
            valueText = Math.round(percent * 100) + "%";
            let data = [{ name: widgetLabel, value: 1 }];
            content =  (<>
                <PieChart width={250} height={300}>
                <Pie dataKey="value" startAngle={startAngle} endAngle={endAngle} data={data} cx={125} cy={150} outerRadius={80} innerRadius={60} fill="#8884d8" />
            </PieChart>
            <div style={{ position: "absolute", top: 136, left: 20, right: 0, textAlign: 'center', fontSize: 26 }}>{valueText}</div>
            </>);
        }
        
 //, width: 300
        return (<Wrapper>
            <div style={{ position: "relative" }} >
                <div style={{ fontSize: 18, fontWeight: 500, letterSpacing: "0.25px" }}>{widgetLabel}</div>
                <div style={{ fontSize: 14, fontWeight: 400, letterSpacing: "0.25px" }}>{this.props.title}</div>
                <div style={{ position: "relative",width:250,margin:"auto" }}>
                   {content}
                </div>
            </div>
        </Wrapper>)
    }
});


export const ValueChart  = makeComponent(class extends React.Component<{event:RenderEvent, 
    title: string;
    value:CollectionRef;
    limit:number;
    label:string}>{

    render() {
    
        
        let value;
        let collection = this.props.value;
        if (collection && collection.rows){
            let row = collection.rows[0];
            value = row["value"];
        }
        let widgetLabel = this.props.label;

        return (<Wrapper>
            <div style={{ position: "relative",display:"flex",flexDirection:"column" ,height:"100%"}} >
                <div style={{ fontSize: 18, fontWeight: 500, letterSpacing: "0.25px" }}>{widgetLabel}</div>
                <div style={{ fontSize: 14, fontWeight: 400, letterSpacing: "0.25px" }}>{this.props.title}</div>
                <div style={{ position: "relative",textAlign:"center",flexGrow:1,display:"flex",alignItems:'center',justifyContent:'center',
                    paddingBottom:30}}>
                    <div style={{fontSize:80,fontWeight:500}}>{value}</div>
                </div>
            </div>
        </Wrapper>)
    }
});
