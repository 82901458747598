import { StringFormatter } from "./FieldFormatter";

export interface IParsedTime {
    raw?: string;
    invalid?: boolean;
    hour?: number;
    minute?: number;
    second?: number;
}


export class TimeFormatter {
    public static format(value):string {
        if (value == null) {
            return "";
        }

        var parsed = this.parse(value);
        if (parsed == null || parsed.invalid) return "";
        if (parsed.hour == 12){
            return parsed.hour + ":" + StringFormatter.padZero(parsed.minute.toString(), 2) + " PM";
        }
        else if (parsed.hour > 12){
            return (parsed.hour - 12) + ":" + StringFormatter.padZero(parsed.minute.toString(), 2) + " PM";
        }
        else if (parsed.hour == 0){
            return "12:" + StringFormatter.padZero(parsed.minute.toString(), 2) + " AM";
        }
        else {
            return parsed.hour + ":" + StringFormatter.padZero(parsed.minute.toString(), 2) + " AM";
        }
    }

    public static parse(value: string): IParsedTime {
        if (value == null || value == '') return null;
        var parsed: IParsedTime = {
            raw: value,
            invalid: undefined,
            hour:0,
            minute:0,
            second:0
        }
        var segments = ['hour', 'minute', 'second']
        if (value != null && typeof value == "string") {
            value = value.trim();
            var parts = value.split(/[-\s:]/)
            for (var i = 0; i < segments.length; i++) {
                var segment = segments[i];
                var part = parts[i];
                var num = parseInt(part, 10);
                if (isNaN(num)) {
                    parsed.invalid = true
                    parsed[segment] = part
                } else {
                    parsed[segment] = num
                }
            }
        }
    
        return parsed;
    }

    static parseFromInput(value: string): string {
        if (!value) return null;
        value = value.trim();
        
        let segments = value.split(/\W+/);

        let hour: number;
        let minute: number;
        let second: number;
        let isPM:boolean;

        if (segments.length == 0 ) return null;
        let lastIndex = segments.length  - 1;
        let lastSegment = segments[lastIndex];
        if (lastSegment.toUpperCase){
            let up = lastSegment.toUpperCase();
            if (up == "P" || up == "PM"){
                isPM = true;
                segments.pop();
            }
            else if (up == "A" || up == "AM"){
                segments.pop();
            }
            else if (up.endsWith("PM")){
                isPM = true;
                segments[lastIndex] = lastSegment.substring(0,lastSegment.length - 2);
            }
            else if (up.endsWith("P")){
                isPM = true;
                segments[lastIndex] = lastSegment.substring(0,lastSegment.length - 1);
            }
            else if (up.endsWith("AM")){
                segments[lastIndex] = lastSegment.substring(0,lastSegment.length - 2);
            }
            else if (up.endsWith("A")){
                segments[lastIndex] = lastSegment.substring(0,lastSegment.length - 1);
            }
        }


        if (segments.length == 1) {
            // assume entered only hour
            hour = parseInt(segments[0], 10);
            if (isFinite(hour) && hour > 0 && hour <= 24) {
                if (hour < 12 && isPM){
                    hour += 12;
                }
                return this.storedTimeFormat(hour,0,0);
            }
            else {
                return null;
            }
        }
        else if (segments.length == 2) {
            // assume entered hour and minute
            hour = parseInt(segments[0], 10);
            minute = parseInt(segments[1], 10);
            if (!isFinite(hour) || hour < 0 || hour > 23) {
                return null;
            }

            if (!isFinite(minute) || minute < 0 || minute > 59) {
                return null;
            }
            if (hour < 12 && isPM){
                hour += 12;
            }
            return this.storedTimeFormat(hour,minute,0);
        }
        else {
            hour = parseInt(segments[0], 10);
            minute = parseInt(segments[1], 10);
            second = parseInt(segments[2], 10);
            if (!isFinite(hour) || !isFinite(minute) || !isFinite(second)) {
                return null;
            }
           
            if (hour < 0 || hour > 23) {
                return null;
            }
            if (minute < 0  || minute > 59) {
                return null;
            }
            if (second < 0 || second > 59) {
                return null;
            }

            if (hour < 12 && isPM){
                hour += 12;
            }
            return this.storedTimeFormat(hour, minute, second);
        }
    }

    public static storedTimeFormat(hour: number, minute: number, second: number): string {
        return hour + ':' + StringFormatter.padZero(minute.toString(), 2) + ':' + StringFormatter.padZero(second.toString(), 2);
    }
}