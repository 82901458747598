
import { Canvas } from "./Canvas";


export class MenuHandler {
    public id:string;
    public onClose:() => void;
    public isOpen:boolean;
    public canvas:Canvas;
   
    public manager:MenuManager;

    public open(){
        this.isOpen = true;
        this.canvas.update();
    }
    public close(){
        this.isOpen = false;
        if (this.onClose){
            this.onClose()
        }
        this.canvas.update();
    }


}


export class MenuManager {
    private _handlers:MenuHandler[] = [];
    private _nextId = 1;

    public createHandler(canvas:Canvas,onClose:() => void):MenuHandler{
        let handler = new MenuHandler();
        handler.canvas = canvas;
        handler.onClose = onClose;
        handler.id = (this._nextId++).toString();
        return handler;
    }

    public connect(handler:MenuHandler){
       this._handlers.push(handler);
    }
    
    public disconnect(handler:MenuHandler){
        let i = this._handlers.indexOf(handler);
        if (i != -1){
            this._handlers.splice(i,1);
        }
    }

    public close(id:string){
        let handler = this.findHandler(id);
        if (handler){
            handler.close();
        }
    }

    private findHandler(id:string):MenuHandler {
        for(let i =0 ; i < this._handlers.length;i++){
            let handler = this._handlers[i];
            if (handler.id == id) return handler;
        }
        return null;
    }

   
}