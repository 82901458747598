import { IMenuState, ITab, TabSetState } from 'core/TabState';
import { IVisibilityContainer } from 'core/types';
import React from 'react';
import { makeComponent, RenderEvent,RecordMeta, ActionRef, Broadcast} from '../core';
import { AnchorPoint } from './helpers/AnchorPoint';
import { Icon } from './Icon';
import { Popup } from './popups/Popup';


export const TabSet = makeComponent(class extends React.Component<{event?:RenderEvent, variant?:string,$id?:string,title?:any,
    className?:string,noPullPadding?:boolean},{activeTab:string,stackContent:any}>{

    tabState:TabSetState;
    constructor(props){
        super(props);
        let canvas = this.props.event.canvas;
        let stateKey = "tab:" + this.props.$id;
        let tabState = canvas.blockState[stateKey];
        if (!tabState){
            this.tabState = new TabSetState();
            canvas.blockState[stateKey] = this.tabState;
        }
        else {
            this.tabState = tabState;
        }
        this.tabState.createTabs(props.children);
        
        

    }

    render(): React.ReactNode {
        let props = this.props;
        return <TabSetPresenter event={props.event} variant={props.variant} $id={props.$id} title={props.title}
            className={props.className} noPullPadding={props.noPullPadding} tabState={this.tabState}>
                {props.children}
            </TabSetPresenter>
    }
});



export class TabSetPresenter extends React.Component<{event?:RenderEvent, variant?:string,$id?:string,title?:any,
    className?:string,noPullPadding?:boolean,tabState:TabSetState,hideLabels?:boolean}>{ 
    
    componentDidMount(): void {
        this.props.tabState.broadcast.connect(this);
    }

    componentWillUnmount(): void {
        this.props.tabState.broadcast.disconnect(this);
    }

    
    render(){
        let panels = [];
        let tabs = [];
        let children = this.props.children;

        let event = this.props.event;
        if (!event) return null;

       
        let variant = this.props.variant;
        let isMenu = variant == "menu";
        let activeLabel:string;
        let pullPadding = true;
        if (this.props.noPullPadding || variant == "vertical" || variant == "menu"){
            pullPadding = false;
        }

        let tabState = this.props.tabState;
        if (children) {
            React.Children.forEach(children,(child:any,index:number) => {
                let isActive:boolean;
               
                let tabName = child.props.$id;

                let renderContent = child.props.render_children;

                let tab = tabState.tabs[tabName];
                if (tab){
                    isActive = !tab.hidden;
                    if (isActive){
                        activeLabel = child.props.label;
                    }
                }
                else {
                    isActive = false;
                }
              
                if (isMenu){
                    tabs.push(<MenuItem key={tabName} label={child.props.label} name={tabName} isActive={isActive} onClick={this.handleTabClick}/>);
                }
                else {
                    tabs.push(<TabLabel key={tabName} id={tabName} label={child.props.label} icon={child.props.icon} name={tabName} isActive={isActive} onClick={this.handleTabClick}/>);
                }
                let contentKey = tabName + tab.contentKey;
                panels.push(<TabPanelContainer key={contentKey} id={tabName} tab={tab} isActive={isActive} renderContent={renderContent} event={event} 
                    pullPadding={pullPadding}/>);
            });
            if (tabState.stackContent){
                panels.push(<div key="stack-content" className="RT-TabSet__tab-content">
                    {tabState.stackContent}
                </div>);
            }
        }
        if (variant == "menu"){
            return <MenuTabSet id={this.props.$id} tabs={tabs} panels={panels} menuState={tabState.menuState} activeLabel={activeLabel} />
        }
        else if (variant == "vertical"){
            return <VerticalTabSet id={this.props.$id} tabs={tabs} panels={panels} scrollable/>
        }
        else {
            return <HorizontalTabSet id={this.props.$id} title={this.props.title} className={this.props.className} tabs={tabs} 
                panels={panels} scrollable hideLabels={this.props.hideLabels}/>
        }

    }
    
    handleTabClick = (tabName:string,stackContent?:any) =>{
      
        let tabState = this.props.tabState;
        tabState.menuState.open = false;
        tabState.stackContent = stackContent;
        tabState.activateTab(tabName);

        this.props.event.canvas.app.executeCanvasPollCycle();
    }
}


class TabLabel extends React.Component<{id:string,name:string,label:string,isActive:boolean,icon?:string,onClick:(name:string) => void}>{
    render(){
        let className = ['RT-TabSet__link'];
        if (this.props.isActive) {
            className.push('active');
        }
        let icon;
        if (this.props.icon){
            let fill = this.props.isActive ? "var(--rt-active-tab-border-color)" : "currentColor"
            icon = <Icon size={16} icon={this.props.icon} fill={fill} />
        }
        return <li className="RT-TabSet__label" data-elem-id={this.props.id}>
            <a onClick={this.handleClick} className={className.join(' ')} href='#' data-studio-click data-studio-select>{icon}{this.props.label}</a>
        </li>;
    }

    handleClick = async (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.name);
    }
}

export const Tab = makeComponent(class extends React.Component<{name?:string,label:string,$isActive?:boolean,icon?:string,
    onClick?:ActionRef,$onClick?:(name:string,stackContent:any) => void}>{

    render(){
        let className = ['RT-TabSet__link'];
        if (this.props.$isActive) {
            className.push('active');
        }
        let icon;
        if (this.props.icon){
            icon = <Icon size={16} icon={this.props.icon} fill="currentColor" />
        }
        return <li className="RT-TabSet__label" >
            <a onClick={this.handleClick} className={className.join(' ')} href='#' data-studio-click data-studio-select>{icon}{this.props.label}</a>
        </li>;
    }

    handleClick = async (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let stackContent = null;
        if (this.props.onClick){
            let showStackContent = (content:any)=> {
                stackContent = content;
            }
            await this.props.onClick.trigger({showStackContent});
        }
        if (this.props.$onClick){
            this.props.$onClick(this.props.name,stackContent);
        }
     
    }
});


class TabPanelContainer extends React.Component<{ id?:string;isActive: boolean,
     tab:ITab,pullPadding?:boolean,
     renderContent?:(event:RenderEvent)=> any;event:RenderEvent }, { hasBeenActive: boolean }>{

    constructor(props) {
        super(props);
        this.state = {
            hasBeenActive: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isActive && !state.hasBeenActive) {
            return { hasBeenActive: true };
        }
        return null;
    }

    render() {

        let { isActive } = this.props;

        if (isActive || this.state.hasBeenActive) {
            let display: string;
            if (isActive) {
                display = "block";
            }
            else {
                display = "none";
            }
            let childEvent = this.props.event.create(this.props.event.scope);
            childEvent.visibilityContainer = this.props.tab;
           
            let content;
            if (this.props.renderContent){
                content = this.props.renderContent(childEvent);
            }
            let className = "RT-TabSet__tab-content rt-scrollbars";
            if (this.props.pullPadding){
                className += " RT-pull-body-padding";
            }
            return (
                <div style={{ display }} data-elem-id={this.props.id} className={className}>
                    <TabPanelContent isVisible={isActive}>
                        {content}
                    </TabPanelContent>
                </div>
            )
        }
        return null;
    }

}

class TabPanelContent extends React.Component<{isVisible:boolean}>{
   
    shouldComponentUpdate(nextProps){
        if (nextProps.isVisible) return true;
        return false;
    }
    

    render(){
        return this.props.children || null;
    }
}


class HorizontalTabSet extends React.Component<{id?:string;tabs:any,title?:any;panels:any,hideLabels?:boolean,
    scrollable?:boolean,insideDialog?:boolean,className?:string}>{
    render(){

       

        let className = "RT-TabSet RT-TabSet--horizontal";
        if (this.props.scrollable){
            className += " RT-TabSet--scrollable";
        }
        if (this.props.insideDialog){
            className += " RT-TabSet--inside-dialog";
        }
        if (this.props.className){
            className += " " + this.props.className;
        }
    
        let tabs;
        if (this.props.hideLabels){
            className += " RT-TabSet--hide-labels";
        }
        else if (this.props.title){
            tabs = <div style={{display:"flex",alignItems:"flex-end",backgroundColor:"#fff"}}>
                <div className="RT-TabSet__labels--horizontal" >
                    {this.props.title}
                </div>
                <ul className="RT-TabSet__labels--horizontal" style={{flexGrow:1}}>
                    {this.props.tabs}
                </ul>
            </div>
        }
        else if (this.props.tabs.length > 1){
            tabs = <ul className="RT-TabSet__labels--horizontal">
                {this.props.tabs}
            </ul>
        }
        
        return <div className={className} data-elem-id={this.props.id}>
            {tabs}
            {this.props.panels}
        </div>
    
    }
}

export class VerticalTabSet extends React.Component<{id?:string;tabs:any,panels:any,buttons?:any;scrollable?:boolean}>{
    render(){
        let className = "RT-TabSet RT-TabSet--vertical";
        if (this.props.scrollable){
            className += " RT-TabSet--scrollable";
        }
    
       
        let tabs = <ul className="RT-TabSet__labels--vertical">
            {this.props.tabs}
            {this.props.buttons}
        </ul>
       
        return <div style={{paddingTop:10}} className={className} data-elem-id={this.props.id}>
            {tabs}
            {this.props.panels}
        </div>
    }
}




class MenuTabSet extends React.Component<{id?:string;tabs:any,panels:any,activeLabel:string,menuState:IMenuState}>{

    dropRef:React.RefObject<HTMLDivElement> = React.createRef();
   
    render(){
        let label = this.props.activeLabel;
       
        let popup;
        if (this.props.menuState.open){
            popup = this.renderPopup();
        }
        return <div className="RT-TabSet--menu" style={{height:"100%",display:"flex",flexDirection:'column'}}>
            <div style={{flexShrink:0,fontSize:"14px",fontWeight:500,display:'flex',alignItems:'center',cursor:"pointer",
                padding:"10px 0 10px 30px",letterSpacing:"0.35px",
                backgroundColor:"#fff",borderBottom:"solid 1px rgb(232,232,232)"}} 
                onClick={this.handleClick} ref={this.dropRef}>
                <div>{label}</div>
                <div style={{marginLeft:8}}>
                    {this.renderAngleDown()}
                </div>
            </div>
            {popup}
            {this.props.panels}
        </div>
    }

    renderPopup(){
        return (<Popup
            attachedRef={this.dropRef.current as HTMLElement}
            anchorPoint={AnchorPoint.BottomAlignLeft}
            onForceClose={this.handlePopupForceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderMenu}
        />);
    }

    renderMenu = ()=> {
        let content = <div style={{ width: 300, backgroundColor: "#fff", boxShadow: "rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px", borderRadius: 4,
            padding:"8px 0",border:"solid 2px rgb(0 0 0 / 8%)",marginTop:-6 ,marginLeft:2}}>
            {this.props.tabs}
        </div>
        return content;


    }
   
    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.menuState.open = true;
        this.forceUpdate();
    }

    handlePopupForceClose = () => {
        this.props.menuState.open = false;
        this.forceUpdate();
    }

    renderAngleDown(){
        return (<svg xmlns="http://www.w3.org/2000/svg" style={{display:"block",width:"20px",height:"20px",fill:"rgb(21,27,38)"}} viewBox="0 0 24 24">
           <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
        </svg>);
    }

}

class MenuItem extends React.Component<{name:string,label:string,isActive:boolean, onClick:(name:string) => void}>{
    render(){
        let check;
        if (this.props.isActive){
            check = <svg viewBox="0 0 52 52" style={{width:24,height:24,strokeWidth:4}}><path stroke="var(--rt-primary-color)" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path></svg>
        }
        else {
            check = <div style={{width:24}}/>
        }
        return <a href='#' style={{display:"flex",color:"rgb(21,27,38)",textDecoration:"none",fontSize:"13px",fontWeight:500,

            padding:"8px 10px 8px 3px",letterSpacing:"0.35px",alignItems:"center"}} onClick={this.handleClick}>
            {check}
            {this.props.label}
        </a>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.name);
    }
}