import { ActionRef } from "core/ActionRef";
import { Canvas } from "core/Canvas";
import { DragTool, DropPosition } from "core/DragState";

export class StudioCommandBuffer {
    commands:any[] = [];
    canvas:Canvas;

    constructor(canvas:Canvas){
        this.canvas = canvas;
    }
    move(target,destination,position:DropPosition){
        let command =  {command:"Move",target,destination,position};
        this.commands.push(command);
    }

    toolDrop(tool:DragTool,destination,position:DropPosition){
        let command =  {command:"Tool",tool:tool.name,value:tool.value,destination,position};
        this.commands.push(command);
    }

    setProp(target:string,name:string,value:string){
        let command = { command: "SetProp", target, name, value };
        this.commands.push(command);
    }
    appendLine(target:string){
        this.commands.push({command:"AppendLine",target});
    }

    setAsset(target:string,content:any,filename:string){
        let command =  {command:"SetAsset",target,name:"name",content,filename};
        this.commands.push(command);
    }

    menuClick(target:string,name:string,value:string){
        let command = {command:"MenuClick",target,name,value};
        this.commands.push(command);
    }

    loadToolbox(name:string,value:string){
        let command = {command:"LoadToolbox",name,value};
        this.commands.push(command);
    }

    swapPosition(target:string){
        this.commands.push({command:"SwapPosition",target});
    }

    moveLeft(target:string){
        this.commands.push({command:"MoveLeft",target});
    }
    moveRight(target:string){
        this.commands.push({command:"MoveRight",target});
    }
    
    
    async send(){
        if (!this.commands.length) {
            console.log("empty command buffer, nothing to save");
            return;
        }
        console.log("command buffer size: " + this.commands.length);
        let commandsToSend = this.commands;
        this.commands = [];
        var action = new ActionRef(this.canvas,"studio-content-updated",{});
        await action.trigger({value:commandsToSend});
    }
}

