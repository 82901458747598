import { Application, PageContainer, Url } from '../core';
import React from 'react';
import { ISessionResult } from 'core/AppSession';
import { Dialog, DialogContainer, DialogResult, ErrorDialogContainer } from 'core/Dialog';
import { IPageLaunchParams } from 'core/types';
import { DragImage, DropBar } from 'core/DragState';



export class Navigator extends React.Component<{appName:string,domain:string,branch?:string;screenProps:any,screenName?:boolean}>{

    ready = false;  
    loadError = null;


    async componentDidMount(){
        let app = Application.instance;
        app.pageNavigate.connect(this);
        app.domain = this.props.domain;
        app.appName = this.props.appName;
        app.branch = this.props.branch;
        window.addEventListener("message",this.handleMessage);
        window.addEventListener("popstate",this.handlePopState);
       

        let result = await this.start();
        if (result){
            let windowUrl = Url.parse(document.location.pathname,document.location.search);
            if (windowUrl.search["pwdtoken"]){
                return;  // do not display any page
            }
           
            /*
            if (result.didSignin){
                window.location.reload();
                return;
            }
            */

            this.ready = true;

            if (!app.currentWorkspace && app.session.homePage){
                app.openWorkspace(app.session.homePage);
                return;
            }
            else {
                this.forceUpdate();
            }
        }
        else {
            this.forceUpdate();
        }
    }

    
    componentWillUnmount(){
         Application.instance.pageNavigate.disconnect(this);
        window.removeEventListener("message",this.handleMessage);
    }
    render(){
        let app = Application.instance;
        if (this.loadError){
            return (
                <div key="nav-error" style={{position:"absolute",top:0,left:0,right:0,bottom:0}}>            
                    <StartupError message={this.loadError}/>
                </div>
            )
        }

       

        let page:IPageLaunchParams = {name:"pages/user/theme"};
        
        let content = (this.ready) ? <PageContainer page={page}/> : null;
        return <>
            <div style={{position:"absolute",top:0,left:0,right:0,bottom:0}}>
                {content}
                <DialogContainer key="app-dialogs" app={app} width="100%" isStudio={false}/>
                <DialogContainer key="studio-dialogs" app={app} width="100%" isStudio={true}/>
                <ErrorDialogContainer key="errorDialog" app={app}/>
               
            </div>
        </>
    }

    handleMessage = (event) => {
        let data = event.data;
        let action = data.action;
        if (action == "studio.ready"){
            Application.instance.studioWindow = event.source;
            Application.instance.studioOpen = true;
            let activeScreen = Application.instance.activeScreens[0];
            if (activeScreen && activeScreen.canvas.pageId){
                Application.instance.studioWindow.postMessage({action:"showScreen",screen:activeScreen.canvas.launchParams.name},"*");
            }
        }
        else if (action = "screen.reload"){
            let screen = data.screen;
            Application.instance.reloadScreen(screen);
        }
    }

    handlePopState = (event:PopStateEvent) => {
       
        let app = Application.instance;
        app.setWorkspaceUrl(false);
        return;
        /*
        let url = Url.parse(document.location.pathname,document.location.search);
        app.openWorkspace("user/workspaces/" + url.folder);
        this.forceUpdate();
        */
    }

    async start():Promise<DialogResult>{

        
        let app = Application.instance;
        let result:DialogResult;

        let res:any;
        try {
            res = await Application.instance.doPostRequest("/bootstrap",{domain:app.domain,branch:app.branch});
            this.loadConfig(app,res);
            let windowUrl = Url.parse(document.location.pathname,document.location.search);
            let params = {"@pathname":document.location.pathname};
            if (windowUrl.search){
                for(let key in windowUrl.search){
                    params["@" + key] = windowUrl.search[key];
                }
            }
            result = await app.session.signin(app,null,{},params);
            if (!result.continue){
              //  this.loadError = "Unable to log in";
                return result;
            }
        }
        catch(e){
            let err = e as Error;
            if (err && err.message){
                this.loadError = err.message
            }
            return;
        }

        if (!res) {
            this.loadError = "Unable to communicate with server at the moment...";
            return;
        }
      

        Navigator.pingInterval = setInterval(()=> {
            app.session.ping();
        },5 * 60 * 1000); 
        return result;
    }
   
    private loadConfig(app:Application,res:any){
        let config = res.config;
        if (config){
            let components = config.components;
            for(let i = 0 ; i < components.length;i++){
                let component = components[i];
                let componentClass = Application.components[component.name] as any;
                if (componentClass){
                componentClass.$def = component;
                }
            }
        }
        app.version = res.version;
        app.portalTitle = res.portalTitle;
    }

    static pingInterval;

}




class StartupError extends React.Component<{message:string}>{
    render(){
        return <div style={{padding:50,textAlign:'center'}}>
            {this.props.message}
            <div style={{marginTop:40}}>
                <button style={{border:"none",backgroundColor:"#54accd",color:"#fff",borderRadius:5,fontSize:"15px",padding:8}}
                    onClick={this.handleRetry}>Retry</button>
            </div>
        </div>
    }

    handleRetry = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        window.location.reload();
    }
}


