 import React from 'react';
import { FieldRef, makeComponent, makeInput } from '../../core';
import { DisplayProps } from '../PutField';
import { FieldError } from './FieldError';
import { FieldLabel } from './FieldLabel';
 
 export const Select = makeInput(class extends React.Component<DisplayProps>{
    
    componentDidMount(){
        let input = this.props.input;
        if (input.focusElemRef.current && input.field.shouldFocus) {
            input.focusElemRef.current.focus();
        }
    }

    componentDidUpdate(){
        let input = this.props.input;
        if (input.focusElemRef.current && input.field.shouldFocus){
            input.focusElemRef.current.focus();
        }
    }


    render(){
        let input = this.props.input;
       
        let field = input.field;
        
        let containerClass = ["RT-TextBox"];
        let inputClassName = ["RT-TextBox__select"];

        let helpElem;
        if (this.props.help) {
            helpElem = <small className="form-text text-muted">{this.props.help}</small>;
        }
        let errorElem;

        if (field.error) {
            containerClass.push("RT-TextBox--has-error");
            errorElem = <FieldError input={input}/>
        }


        let optionElems = [];
        if (field.options) {
            let placeholder = field.placeholder || "Select";
            for (let i = 0; i < field.options.length; i++) {
                let opt = field.options[i];
                let label = (opt.label == "?") ? "-- " + placeholder + " --" : opt.label
                optionElems.push(<option key={i} value={opt.value}>{label}</option>);
            }
        }

        let labelElem;
        let label = this.props.label;
        if (label) {
            labelElem = <FieldLabel label={label} styles={this.props.labelStyles} required={this.props.required}/>
        }

        inputClassName.push(this.props.styles.className);

        let inputStyle:React.CSSProperties = {};
        /*
        if (width){
            inputStyle.width = this.width;
        }
        */
    
        let value:string;
        if (input.type == "integer" || input.type == "decimal" || input.type == "money"){
            let v = field.value;
            if (v === undefined || v === null){
                value = "";
            }
            else {
                value = v.toString();
            }
        }
        else {
            value = field.value || "";
            
        }
        if (value && value.toUpperCase){
            value = value.toUpperCase();  
        }
        return (
            <div className={containerClass.join(" ")}>
                {labelElem}
                <select
                    ref={input.focusElemRef as any}
                    className={inputClassName.join(' ')}
                    style={inputStyle}
                    onChange={this.handleChange}
                    disabled={field.disabled}
                    value={value}
                    tabIndex={this.props.noTabStop ? -1 : null}
                >
                    {optionElems}
                </select>
                {errorElem}
            </div>
        );
    }

    handleChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
        let value = e.target.value;
        this.props.input.setValue(value);
    }
});