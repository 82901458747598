import { RenderEvent } from 'core';
import React from 'react';

export class StudioNode extends React.Component<{event:RenderEvent,node_id:string}>{
    render(){
        return <div data-studio-node={this.props.node_id}>
            {this.props.children}
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        let target = e.target as HTMLElement;
        let prop = target.closest('[data-prop-name]');
        
    }
}
