import React from 'react';
import {IProductRow, ProductTableStore} from './ProductTableStore';
import { RenderEvent } from 'core';
import { Sidebar } from './Sidebar';
import { EmptyState } from 'components/EmptyState';
import { CartItem } from './CartItem';
import { ScreenRef } from 'core/ActionEvent';


export class CartDetail extends React.Component<{event:RenderEvent}>{

    loaded = false;
    store:ProductTableStore = new ProductTableStore();
    inventoryCollectionName = "@inventory";

    componentDidMount(){
        this.store.broadcast.connect(this);
        this.loadData();
        this.loaded = true;
        let canvas = this.props.event.canvas;
        let collection = this.props.event.canvas.getCollectionRef(this.inventoryCollectionName,null);
        canvas.connectDataListener(this,collection.name,()=> {
            let col = canvas.getCollectionRef(this.inventoryCollectionName,null);
            this.store.rows = col.rows;
            this.store.mapRows();
        });
        this.forceUpdate();
    }

    componentWillUnmount(){
        this.store.broadcast.disconnect(this);
        let canvas = this.props.event.canvas;
        canvas.disconnectDataListener(this);
    }

    loadData(){
        let store = this.store;
        let event = this.props.event;
        store.loadSidebarAction = event.action("load-sidebar");
        store.loadTheme();
        let collection = event.canvas.getCollectionRef(this.inventoryCollectionName,null);
        store.rows = collection.rows;
        store.mapRows();
        store.setPrices("price1");
        store.addToCart = async (row:IProductRow,qty:number) => {
            store.activeRowId = null;
            let screenRef = new ScreenRef(event.canvas,{});
            await screenRef.restart();
            event.canvas.update();
        }
    }

    render(){
        let store = this.store;
        let isEmpty:boolean;
        if (!this.loaded) return null;

        return ( <div style={{display:"flex",height:"100%"}}>
                <div key={store.tableKey} className="rt-scrollbars" style={{overflow:"auto",width:"100%",paddingRight:8,marginTop:20}}>
                    <div className="Product-Table" style={{display:"inline-block",minWidth:"100%"}}>
                        {this.renderMessage()}
                        {this.renderCartItems()}
                    </div>
                </div>
                {this.renderSidebar(store,isEmpty)}
        </div>)
    }

    renderMessage(){
        let event = this.props.event;
        let backorderNote = event.canvas.getFieldValue("@messages.backorder_note");
        if (!backorderNote) return null;
        let backorderDate = event.canvas.getFieldValue("@messages.backorder_date")
        /*
        let message = <>
            <span>This cart contains backordered items and can ship complete on approx.</span>
            <span style={{fontWeight:500,marginLeft:8}}>Sep 5</span>
        </>
        message = <>
            <span>This cart contains backordered items and has an extended lead time.</span>
            <span style={{fontWeight:500,marginLeft:8}}>Contact MRS for an approximate date.</span>
        </>
        */
        let message = <>
            <span>{backorderNote}</span>
            <span style={{fontWeight:500,marginLeft:8}}>{backorderDate}</span>
        </>
        return <div style={{marginBottom:20,fontSize:"16px",padding:10,backgroundColor:"rgb(248,249,250)",border:'solid 1px rgb(232,233,236)'}}>
            <span style={{color:"rgb(255,119,19)",fontWeight:500,marginRight:8}}>NOTE:</span>
            {message}
        </div>
    }


    renderCartItems(){
        let event = this.props.event;
        let canvas = event.canvas;
        let collection = canvas.getCollectionRef("@items",null);
        let elems = [];
        let rows = collection.rows;
        if (!rows || !rows.length){
            return <EmptyState icon="cart" label="Cart is empty." />   
        }
        for(let i =0 ; i < rows.length;i++){
            let row = rows[i];
            let productRow = this.findStoreRow(row.product_id);
            if (productRow){
                productRow.price = row.price;
                let data:any = row;
                if (i){
                    elems.push(<div key={'spacer' + i} style={{height:15}}/>);
                }
                let rowElem = <CartItem key={i} event={event} item={data} actions={null} row={productRow} store={this.store}/>
                elems.push(rowElem);
            }
        }
        return elems;
    }

    findStoreRow(product_id:string):IProductRow {
        let rows = this.store.rows;
        for(let i = 0; i < rows.length;i++){
            let row = rows[i];
            if (row.sku == product_id){
                return row;
            }
        }
        return null;
    }

    renderSidebar(store:ProductTableStore,isEmpty:boolean){
        
        let sidebarContent;
        let sidebarBackground;
        if (store.activeRow){
            sidebarContent = <Sidebar event={this.props.event} row={store.activeRow} key={store.activeRow.id} store={store} />
            sidebarBackground = "rgb(251,251,252)";
        }
        else if (!isEmpty){
            sidebarContent = <EmptyState icon="cart" label="Click on a cart item row view details"/>
            sidebarBackground = "rgb(234,236,240)";
        }
       
        if (sidebarContent){
            return( <div className="rt-scrollbars" style={{width:320,flexShrink:0,backgroundColor:sidebarBackground,
                padding:"10px 30px 0 30px",marginLeft:20,marginTop:20,marginRight:15,overflowY:"auto"}}>
               {sidebarContent}
           </div>);
        }
        return null;
    }
}