import React from 'react';
import { RenderEvent } from '../core';
import { DynamicRender } from '../core/DynamicRender';

export class PageSource extends React.Component<{event:RenderEvent,source:any}>{

    render(): React.ReactNode {
        return <div>
            {DynamicRender.render(this.props.event,this.props.source)}
        </div>
    }
}