import { Application, ISigninSettings, PageContainer } from 'core';
import { ISessionResult } from 'core/AppSession';
import { IPageLaunchParams } from 'core/types';
import { prototype } from 'node:events';
import React from 'react';

export interface ISigninProps {
    app:Application;
    done: (result:ISessionResult) => void;
    token?: string;
    tokenType?:string;
    username?:string;
    settings:ISigninSettings
}
export default class Signin extends React.Component<ISigninProps,
    {
        mode: string;
        username: string;
        password: string;
        confirmPassword: string;
        pwdToken: string;
        error: string;
        busy: boolean;
    }
> {

    supportLinkLabel: string;
    supportLinkHref: string;
    usernameLabel: string;
    submitLabel: string;
    registerLabel: string;
    forgotPasswordLabel: string;
    passwordResetInstruction: string;
    resetSentMessage: string;
    logoFile: string;
    logoSize:string;

    constructor(props) {
        super(props);
        let mode = "login";
        let pwdToken;
        let username:string;

        if (props.token) {
            pwdToken = props.token;
            if (props.tokenType == "welcome"){
                mode = "welcome";
                username = props.username;
            }
            else {
                mode = "reset";
            }
        }

        this.state = {
            mode,
            username,
            password: "",
            confirmPassword: "",
            pwdToken,
            error: "",
            busy: false
        };

        
        let app = this.props.app;
        let settings = this.props.settings;
        this.supportLinkLabel = settings.supportLinkLabel;
        this.supportLinkHref = settings.supportLinkHref;
        this.usernameLabel = settings.usernameLabel;
        this.submitLabel = settings.submitLabel;
        this.registerLabel = settings.registerLabel;
        this.forgotPasswordLabel = settings.forgotPasswordLabel;
        this.resetSentMessage = settings.resetSentMessage;
        this.logoFile = settings.logoFile;
        this.logoSize = settings.logoSize;
        this.passwordResetInstruction = settings.passwordResetInstruction;
    }

    render() {
        let page:IPageLaunchParams = {name:"pages/public/log_in"};
        return (
            <div className="RT-Login__container">
                <PageContainer page={page} onClose={this.closePage}/>
            </div>
        );
        /*
        return (
            <div className="RT-Login__container">
                {this.renderLogo()}
                {this.renderContent()}
            </div>
        );
        */
    }

    closePage = ()=> {
        let result:ISessionResult = {success:true};
        this.props.done(result);
    }

    submit = async e => {
        e.preventDefault();
        let app = this.props.app;
        try {
            this.setState({ busy: true });
            let result =  await app.session.attemptSignin(this.state.username, this.state.password);
            this.setState({ busy: false });
            if (result.success){
                return this.props.done(result);
            }
            else {
                this.setState({error:result.error});
            }
        } catch (e) {
            let error = this.getErrorMessage(e);
            this.setState({ error, busy: false });
        }
    };

    getErrorMessage(e: any): string {
        if (!e.body || !e.body.error || !e.body.error.message) {
            return "An error occurred, unable to " + this.submitLabel;
        }
        return e.body.error.message;
    }
    requestToken = (requestType: string) => async e => {
        e.preventDefault();
        let app = this.props.app;
        try {
            this.setState({ busy: true });
            let result= await app.session.requestToken(this.state.username);
            if (result.success){
                this.setState({ mode: requestType, busy: false, error: "" });
            }
            else {
                this.setState({ error:result.error, busy: false });
            }
        } catch (e) {
            let error = this.getErrorMessage(e);
            this.setState({ error, busy: false });
        }
    };

    signup = e => {
        e.preventDefault();
    };
    resetPassword = async e => {
        e.preventDefault();

        let app = this.props.app;
        try {
            this.setState({ busy: true });
            let result = await app.session.resetPassword(this.props.token, this.state.password);
            if (!result.success) {
                this.setState({
                    busy: false,
                    error: result.error
                });
                return;
            }
            this.setState({
                mode: "login",
                busy: false,
                password: "",
                error: ""
            });
        } catch (e) {
            let error = this.getErrorMessage(e);
            this.setState({ error, busy: false });
        }
    };
    handleInputChange = e => {
        let name: "username" | "password" | "confirmPassword" =
            e.target.name;
        var value = e.target.value;
        let stateChange = { [name]: value };
        this.setState(stateChange as any);
    };

    handleRegisterClick = e => {
        e.preventDefault();
        this.setState({
            mode: "register",
            error: "",
            password: "",
            confirmPassword: ""
        });
    };
    handleForgotClick = e => {
        e.preventDefault();
        this.setState({
            mode: "forgot",
            error: "",
            password: "",
            confirmPassword: ""
        });
    };
    handleReturnToLoginClick = e => {
        e.preventDefault();
        this.setState({
            mode: "login",
            error: "",
            password: "",
            confirmPassword: ""
        });
    };

    renderContent() {
        var mode = this.state.mode;
        if (mode == "login") {
            return this.renderLoginScreen();
        } else if (mode == "register") {
            return this.renderRegister();
        } else if (mode == "forgot") {
            return this.renderForgot();
        } else if (mode == "requestregister") {
            return this.renderRequestSent("requestregister");
        } else if (mode == "requestforgot") {
            return this.renderRequestSent("requestforgot");
        } else if (mode == "reset") {
            return this.renderReset();
        }
        else if (mode == "welcome"){
            return this.renderWelcome();
        }
    }

    renderLoginScreen() {
        let assistanceLink;
        if (this.supportLinkHref) {
            assistanceLink = (
                <a href={this.supportLinkHref}>{this.supportLinkLabel}</a>
            );
        } else {
            assistanceLink = this.supportLinkLabel;
        }
        let assistance = (
            <div className="RT-Login__help">
                <div>Need assistance?</div>
                <div>{assistanceLink}</div>
            </div>
        );
        let register;
        if (this.registerLabel) {
            register = (
                <a
                    href=""
                    className="RT-Login__link"
                    onClick={this.handleRegisterClick}
                >
                    {this.registerLabel}
                </a>
            );
        }

        let forgot;
        if (this.forgotPasswordLabel) {
            forgot = (
                <a
                    href=""
                    onClick={this.handleForgotClick}
                    className="RT-Login__link"
                >
                    {this.forgotPasswordLabel}
                </a>
            );
        }
        let submitButton;
        let buttonStyle:React.CSSProperties = {width:"100%",borderRadius:99,padding:15,fontSize:"16px",fontWeight:500};
        if (this.state.busy) {
            submitButton = (
                <button
                    disabled
                    style={buttonStyle}
                    type="submit"
                    className="RT-Button RT-Button--primary RT-Button--block"
                >
                    Signing in ...
                </button>
            );
        } else {
            submitButton = (
                <button type="submit" style={buttonStyle} className="RT-Button RT-Button--primary RT-Button--block">
                    {this.submitLabel}
                </button>
            );
        }

        return (
            <div className="RT-Login__form">
                <form onSubmit={this.submit}>
                    {this.renderError()}
                    <label className="RT-Login__label">
                        {this.usernameLabel}
                    </label>
                    <input
                        type="text"
                        name="username"
                        className="RT-Login__input"
                        value={this.state.username}
                        onChange={this.handleInputChange}
                    />
                    <label className="RT-Login__label">Password</label>
                    <input
                        type="password"
                        name="password"
                        className="RT-Login__input"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                    />

                    <div className="RT-Login__button-container">
                        {submitButton}
                    </div>

                    <div className="RT-Login__link-container" style={{justifyContent:"center"}}>
                        {register}
                        {forgot}
                    </div>
                    {assistance}
                </form>
            </div>
        );
    }

    renderError() {
        if (this.state.error) {
            return (
                <div className="RT-Login__error">{this.state.error}</div>
            );
        }
        return null;
    }

    renderRegister() {
        //this.requestToken("requestregister")
        return (
            <div className="RT-Login__form">
                <form onSubmit={this.signup}>
                    {this.renderError()}
                    <label className="RT-Login__label">
                        Enter your email address to set up your password.
                </label>

                    <input
                        type="text"
                        name="username"
                        className="RT-Login__input"
                        value={this.state.username}
                        onChange={this.handleInputChange}
                    />
                    <div className="RT-Login__button-container">
                        <button
                            type="submit"
                            className="RT-Button RT-Button--primary RT-Button--block"
                        >
                            Continue
                    </button>
                    </div>
                    <div className="RT-Login__link-container">
                        <a
                            href=""
                            onClick={this.handleReturnToLoginClick}
                            className="RT-Login__link"
                        >
                            Return to {this.submitLabel}
                        </a>
                    </div>
                </form>
            </div>
        );
    }

    renderForgot() {
        let submitButton;
        if (this.state.busy) {
            submitButton = (
                <button
                    disabled
                    type="submit"
                    className="RT-Button RT-Button--primary RT-Button--block"
                >
                    Working ...
                </button>
            );
        } else {
            submitButton = (
                <button type="submit" className="RT-Button RT-Button--primary RT-Button--block">
                    Reset Password
                </button>
            );
        }
        return (
            <div className="RT-Login__form">
                <form onSubmit={this.requestToken("requestforgot")}>
                    {this.renderError()}
                    <div>
                        {this.passwordResetInstruction}
                    </div>
                    <label className="RT-Login__label">
                        Username
                    </label>
                    <input
                        type="text"
                        name="username"
                        className="RT-Login__input"
                        value={this.state.username}
                        onChange={this.handleInputChange}
                    />
                    <div style={{display:"flex",alignItems:'center'}}>
                        <div className="RT-Login__button-container">
                            {submitButton}
                        </div>
                        <div className="RT-Login__link-container" style={{marginLeft:40}}>
                            <a
                                href=""
                                onClick={this.handleReturnToLoginClick}
                                className="RT-Login__link"
                                style={{textDecoration:"underline",color:"var(--rt-primary-color)",fontWeight:500}}
                            >
                                Return to {this.submitLabel}
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    renderLogo() {
        if (this.logoFile) {
            var src = Application.instance.getImageUrl(this.logoFile);
            return <div className="RT-Login__logo"><img src={src} style={{display:"inline",width:this.logoSize}}/></div>
        }
        return null;
    }
    renderRequestSent(requestType: string) {
        var textDesc = this.resetSentMessage;

        return (
            <div className="RT-Login__form">
                <form onSubmit={this.requestToken(requestType)}>
                    {this.renderError()}
                    <div className="RT-Login__label">{textDesc}</div>
                    <div className="RT-Login__link-container">
                        <a
                            href=""
                            onClick={this.handleReturnToLoginClick}
                            className="RT-Login__link"
                            style={{textDecoration:"underline",color:"var(--rt-primary-color)",fontWeight:500}}
                        >
                            Return to {this.submitLabel}
                        </a>
                    </div>
                </form>
            </div>
        );
    }

    renderReset() {
        let submitButton;

        if (this.state.busy) {
            submitButton = (
                <button
                    disabled
                    type="submit"
                    className="RT-Button RT-Button--primary RT-Button--block"
                >
                    Working...
                </button>
            );
        } else {
            submitButton = (
                <button type="submit" className="RT-Button RT-Button--primary RT-Button--block">
                    Reset Password
                </button>
            );
        }
        return (
            <div className="RT-Login__form">
                <form onSubmit={this.resetPassword}>
                    {this.renderError()}
                    <div className="RT-Login__label">
                        Enter new password
                </div>
                    <input
                        type="password"
                        name="password"
                        className="RT-Login__input"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                    />
                    <div className="RT-Login__label">Confirm password</div>
                    <input
                        type="password"
                        name="confirmPassword"
                        className="RT-Login__input"
                        value={this.state.confirmPassword}
                        onChange={this.handleInputChange}
                    />
                    <div className="RT-Login__button-container">
                        {submitButton}
                    </div>
                    <div className="RT-Login__link-container">
                        <a
                            href=""
                            onClick={this.handleReturnToLoginClick}
                            className="RT-Login__link"
                            style={{textDecoration:"underline",color:"var(--rt-primary-color)",fontWeight:500}}
                            
                        >
                            Return to {this.submitLabel}
                        </a>
                    </div>
                </form>
            </div>
        );
    }

    renderWelcome() {
        let submitButton;

        if (this.state.busy) {
            submitButton = (
                <button
                    disabled
                    type="submit"
                    className="RT-Button RT-Button--primary RT-Button--block"
                >
                    Working...
                </button>
            );
        } else {
            submitButton = (
                <button type="submit" className="RT-Button RT-Button--primary RT-Button--block">
                    Activate account
                </button>
            );
        }
        return (
           
            <div className="RT-Login__form">
                 <div style={{marginBottom:15,fontSize:"16px",fontWeight:500}}>Activate your new portal account.</div>
                 <div className="RT-Login__label">Username</div>
                 <div style={{fontSize:"15px",fontWeight:500}}>{this.state.username}</div>
                <form onSubmit={this.resetPassword}>
                    {this.renderError()}
                    <div className="RT-Login__label">
                        Enter password
                </div>
                    <input
                        type="password"
                        name="password"
                        className="RT-Login__input"
                        value={this.state.password}
                        onChange={this.handleInputChange}
                    />
                    <div className="RT-Login__label">Confirm password</div>
                    <input
                        type="password"
                        name="confirmPassword"
                        className="RT-Login__input"
                        value={this.state.confirmPassword}
                        onChange={this.handleInputChange}
                    />
                    <div className="RT-Login__button-container">
                        {submitButton}
                    </div>
                </form>
            </div>
        );
    }
}