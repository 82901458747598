
import {Canvas} from './Canvas';
import React from 'react';
import { IRecordChangedEvent, RecordChangedHandler, IPageLaunchParams} from './types';
import { FieldRef } from './FieldRef';
import { RecordBrowse } from './RecordBrowse';
import { Application } from './Application';
import { ActionRef } from './ActionRef';
import {Broadcast} from './Broadcast';
import {  PageContainer } from './PageContainer';
import { RenderEvent } from './RenderEvent';
import { IRectangle } from 'components/helpers/AnchorPoint';

export type DialogResult = {continue:boolean,value?:any,label?:any};

type DialogStack = any[];

function getDialogStack(isStudio:boolean):DialogStack {
    return isStudio ? Dialog.studioStack : Dialog.appStack;
}

function setDialogStack(isStudio:boolean,stack:DialogStack){
    if (isStudio){
        Dialog.studioStack = stack;
    }
    else {
        Dialog.appStack = stack;
    }
}

export class DialogContainer extends React.Component<{app:Application;width:any,isStudio:boolean}>{
    componentDidMount(){
        Dialog.broadcast.connect(this);
        this.props.app.studioBroadcast.connect(this);
    }
    componentWillUnmount(){
        Dialog.broadcast.disconnect(this);
        this.props.app.studioBroadcast.disconnect(this);
    }

    render(){
        let dialogStack = getDialogStack(this.props.isStudio);
        if (this.props.isStudio) return this.renderStudioStack(dialogStack);
        let elems = [];
       
        
        for(let i = 0;i < dialogStack.length;i++){
            let style:React.CSSProperties = {position:"absolute",top:0,left:0,bottom:0,zIndex:9000 + (i * 10),width:this.props.width};
            let elem = <div key={i}  style={style}>{dialogStack[i]()}</div>
            elems.push(elem);
        }
        return <>
            {elems}
        </>
    }

    renderStudioStack(stack:DialogStack):any[]{
        let elems = [];
       
        
        for(let i = 0;i < stack.length;i++){
            let style:React.CSSProperties = {position:"absolute",bottom:"15vh",right:15,
                width:550,height:"65vh",
                borderRadius:10,
                overflow:"hidden",
                zIndex:9000 + (i * 10)};
            let elem = <div key={i} className="studio shadow-2xl" style={style}>{stack[i]()}</div>
            elems.push(elem);
        }
        return elems;
    }
}

export class ErrorDialogContainer extends React.Component<{app:Application}>{

    componentDidMount(){
        ErrorDialog.errorBroadcast.connect(this);
    }
    componentWillUnmount(){
        ErrorDialog.errorBroadcast.disconnect(this);
    }

    render(){
        let elems = [];
        let dialogStack = ErrorDialog.errorStack;
       
        for(let i = 0;i < dialogStack.length;i++){
            let elem = <div key={i} style={{position:"absolute",top:0,left:0,bottom:0,right:0,zIndex:19000 + (i * 10)}}>{dialogStack[i]()}</div>
            elems.push(elem);
        }
        return <>
            {elems}
        </>
    }
}




export class Dialog {

    static nextKey = 1;
    
    static appStack:DialogStack = [];
    static studioStack:DialogStack = [];
  

    static broadcast:Broadcast = new Broadcast();

    static open(canvas:Canvas,dialog:any,onRecordChanged:ActionRef,layer:'dialog' | 'lookup'):Promise<DialogResult> {
        let isStudioPage = canvas.launchParams.name.toLowerCase().startsWith("studio/");

       
        let zIndex = isStudioPage ? 20000 : 9000;

        if (canvas.layer == "dialog" || canvas.layer == "lookup"){
            zIndex = canvas.layerZIndex + 10;
        }
    
     
        canvas.app.spinner.pause();
    
        let promise = new Promise<DialogResult>((resolve,reject) => {

            let stackIndex:number;

            let onClose = (result:DialogResult)=> {
                setDialogStack(isStudioPage,getDialogStack(isStudioPage).slice(0,stackIndex));
                this.broadcast.refresh();
               
                if (result.continue){
                    resolve(result);
                    canvas.app.spinner.resume();
                }
                else {
                    canvas.app.spinner.kill();
                    resolve(result);
                }
            }

            let handleRecordChanged:RecordChangedHandler;
            if (onRecordChanged){
                handleRecordChanged = async (recordEvent:IRecordChangedEvent) => {
                    await canvas.triggerAction(onRecordChanged,{value:recordEvent.rowKey,isRecordChanged:true});
                }
            }
            let targetCanvas = Stack.getTargetCanvas(canvas) || canvas;
            if (targetCanvas.stackChild){
                targetCanvas.stackChild = null;
                targetCanvas.update();
            }

            let stack = getDialogStack(isStudioPage);
            stackIndex = stack.length;
            
            dialog = React.cloneElement(dialog,{key:Dialog.nextKey++,layer:layer,onClose:onClose,layerZIndex:zIndex, handleRecordChanged,parent:canvas});
            let render = () => dialog;
           
            stack.push(render);
            Dialog.broadcast.refresh();
        })
        return promise;
    }

    static async openLookup(field:FieldRef, eventValue:any, previous:any ,options:{isAutoLaunch?:boolean}):Promise<DialogResult>{
        let lookup = field.lookup;
        let lookupScreen:any;
    
        let screen = lookup.name;

        let params = field.canvas.createLaunchParameters(lookup.params,{value:eventValue,scope:field.scope}) || {};
        params["@event-value"] = eventValue;
        if (lookup.name){
            let asLookupFor = {
                page:field.canvas.launchParams.name,
                field:field.qualifiedName
            }
            let page:IPageLaunchParams = {
                name:screen,
                asLookupFor,
                props:params
            }
            lookupScreen = <PageContainer page={page} />
        }
        let result =  Dialog.open(field.canvas,lookupScreen,null,"lookup");
        return result;
    }
}

export class ErrorDialog {
    static errorStack:any[] = [];
    static errorBroadcast:Broadcast = new Broadcast();
    
    static get isVisible():boolean{
        return (ErrorDialog.errorStack.length > 0);
    }
    
    static show(app:Application,dialog:any):Promise<DialogResult> {
        let zIndex = 9000;
       
        app.spinner.kill();

        let promise = new Promise<DialogResult>((resolve,reject) => {

            let stackIndex:number;

            let onClose = (result:DialogResult)=> {
               
                ErrorDialog.errorStack = ErrorDialog.errorStack.slice(0,stackIndex);
                ErrorDialog.errorBroadcast.refresh();
            }

        
            dialog = React.cloneElement(dialog,{key:Dialog.nextKey++,layer:"dialog",onClose:onClose});
            let render = () => dialog;
            stackIndex = ErrorDialog.errorStack.length;
            ErrorDialog.errorStack.push(render);
            ErrorDialog.errorBroadcast.refresh();
        })
        return promise;
    }
}

export class Stack {

    static nextKey = 1;
    static open(canvas:Canvas,stacked:any,onRecordChanged:ActionRef,options:{browse?:RecordBrowse,showStackContent?:(content:any) => void,
        asSidebar?:boolean,isBrowseMove?:boolean,targetPos?:{x:number,y:number},asPopup?:boolean,position?:string,asReplace?:boolean} = {}):Promise<DialogResult>  {
       
        if (options.asReplace){
            canvas = canvas.parent;
        }
        
        let handleRecordChanged:RecordChangedHandler;
        if (onRecordChanged){
            handleRecordChanged = async (recordEvent:IRecordChangedEvent) => {
                await canvas.triggerAction(onRecordChanged,{value:recordEvent.rowKey,isRecordChanged:true});
            }
        }
        
        if (options.asPopup){
            canvas.app.spinner.pause();
        }

        let promise = new Promise<DialogResult>((resolve,reject) => {
            let targetCanvas:Canvas;
            let onClose:any = (result:DialogResult)=> {
                if (targetCanvas){
                    targetCanvas.closeStack();
                }
                if (result.continue){
                    resolve(result);
                    if (options.asPopup){
                        canvas.app.spinner.resume();
                    }
                }
                else {
                    if (options.asPopup){
                        canvas.app.spinner.kill();
                    }
                    resolve(result);
                }
            }
            
            if (options.asSidebar){
                stacked = React.cloneElement(stacked,{key:Stack.nextKey++,layer:"sidebar",handleRecordChanged,parent:canvas,onClose:onClose});
                canvas.sidebarContent = () => stacked;
                return;
            }
        
            
            
            let layer = (canvas.layer == "dialog" || canvas.layer == "dialog-stack" || canvas.layer == "popup") ? "dialog-stack" : "stack"; 
            if (options.asPopup){
                layer = "popup";
            }
            stacked = React.cloneElement(stacked,{key:Stack.nextKey++,layer,handleRecordChanged,parent:canvas,onClose:onClose});
        
        
            if (options.showStackContent){
                options.showStackContent(stacked);
                return;
            }
            
            if (options.asPopup){
                targetCanvas = canvas;
                if (canvas.closed){
                    targetCanvas = canvas.parent;
                }
                let rect:IRectangle = {top:0,bottom:0,left:0,right:0};
                if (options.targetPos){
                    rect.left = options.targetPos.x;
                    rect.right = options.targetPos.x;
                    rect.top = options.targetPos.y;
                    rect.bottom = options.targetPos.y;
                }
                canvas.openPopup({
                    rect,
                    render: ()=> stacked
                })
                targetCanvas.stackPositionOverride = "top";
                targetCanvas.update();
                return;
            }
            
            targetCanvas = Stack.getTargetCanvas(canvas);

            if (!targetCanvas) return;

            targetCanvas.stackChild = ()=> stacked;
            targetCanvas.stackStyle = null;
            targetCanvas.recordBrowse = options.browse;
        
            if (canvas.layer == "dialog" || canvas.layer == "dialog-stack" || canvas.layer == "popup"){
                targetCanvas.stackPositionOverride = "top";
            }
            else if (!options.isBrowseMove){
                targetCanvas.stackPositionOverride = options.position as any;
            }
            
            if (canvas.layer != "stack" &&  canvas.layer != "dialog" && canvas.layer != "dialog-stack" && canvas.layer != "popup"){
                targetCanvas.coveredTitle = canvas.title;
            }
            
            if (targetCanvas != canvas || options.asReplace){
                targetCanvas.update();
            }
    
        
        });
        return promise;
    }

    public static getTargetCanvas(canvas:Canvas):Canvas {
        while (canvas){
            if (Stack.isStackTarget(canvas)) return canvas;
            canvas = canvas.parent;
        }
        return null;
    }

    public static isStackTarget(canvas:Canvas):boolean {
        return (canvas.layer != "embedded" && canvas.layer != "sidebar");
    }


    static browse(canvas:Canvas,recordScreen:any,options:IBrowseOptions){

        let recordBrowse = new RecordBrowse();
        recordBrowse.triggerAction = options.triggerAction;
        recordBrowse.rows = options.rows;
        recordBrowse.currentIndex = options.rows.indexOf(options.current);
        recordBrowse.label = options.label;
        recordBrowse.onRecordChanged = options.onRecordChanged;
        Stack.open(canvas,recordScreen,options.onRecordChanged,{browse:recordBrowse,isBrowseMove:options.isBrowseMove});
    }
}

export interface IBrowseOptions {onRecordChanged?:ActionRef,label?:any,renderRecord?:(row:any)=> any,rows?:any[],current?:any,triggerAction?:ActionRef,
isBrowseMove?:boolean};