import { Icon } from 'components/Icon';
import { ActionRef, Application, RenderEvent } from 'core';
import React from 'react';
import { TextBox } from '../display/TextBox';
import { DefaultTheme, ProductTableStore } from './ProductTableStore';


export class ProductTitleBar extends React.Component<{ event:RenderEvent,store: ProductTableStore; }> {
    render() {

        let cart;
        let store = this.props.store;
        let app = Application.instance;
        /*
        if (app.cart.cart_id){
            let cartItemCount = app.cart.cart_item_count;
            cart = (<div style={{cursor:"pointer",marginBottom:15}} onClick={this.handleShowCartClick}>
                <div style={{display:"flex",alignItems:'center',marginBottom:5}}>
                    <div style={{marginRight:10}}>
                        <Icon icon="cart" size={16} fill={store.theme.cartValueColor} />
                    </div>
                    <div style={{fontSize:"16px",fontWeight:500}}>
                        {app.cart.cart_name}
                    </div>
                </div>
                <div style={{display:"flex",justifyContent:"center"}}>
                    <div style={{borderRadius:99,width:24,height:24,marginLeft:5,backgroundColor:store.theme.cartCountBackground,color:"#fff",fontSize:"15px",fontWeight:500,
                            display:"flex",alignItems:"center",justifyContent:"center"}}>
                            {cartItemCount}
                    </div>
                </div>
            </div>)
        }
        else {
            cart = <div style={{}}>
                <a className="RT-Link" href='#' onClick={this.handleSwitchCartClick} >Select or create cart</a>
            </div>
        }
        */
    
        let settings = store.settings;
       
        let style: React.CSSProperties = {
            borderBottom:"solid 1px rgb(210,212,216)" ,paddingBottom: 0, marginBottom: 3,
            paddingRight: 20, paddingLeft: 20, paddingTop:10,display: "flex", alignItems: "center"
        };

        
        /*
         <div style={{marginLeft:40}}>
                    <SingleValueFilter label={store.price_level.label} value={store.price_level.value || ""} options={store.price_level.options} onChange={store.handlePriceLevelChange}/>
                </div>
        */

        let title = settings.productTitleSub + " " + settings.productTitleMain;
        return (<div style={style}>
            
            <div style={{marginLeft:10,marginBottom:15 }}>
                <label className="RT-Static-Field__label">Product</label>
                <div style={{fontSize:"24px",lineHeight:1}}>{title}</div>
            </div>
            <div style={{ marginLeft: "auto", display: "flex", alignItems: "center",paddingRight:20}}>
                <div style={{marginLeft:0}} className="RT-FilterSet">
                    <TextBox event={this.props.event} type="date" styles={{className:"font-bold"}} labelStyles={{}} label={store.available_date.label} field={store.available_date} />
                </div>   
                <div style={{marginLeft:40}}>
                    {cart}
                </div>
            </div>
        </div>);
    }


    handleShowCartClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let action = this.props.event.action("show-cart");
        action.trigger();
    }

    handleSwitchCartClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let action = this.props.event.action("switch-cart");
        action.trigger();
    }
    

}

export class CartStatus extends React.Component<{event:RenderEvent,cartName:any,cartItemCount:any,branchName:any,
    onclick:ActionRef,onClickClose:ActionRef}>{
    render() {

        let cart = null;
        let app = Application.instance;
        let theme = DefaultTheme;
        let closeIcon;
        if (this.props.onClickClose){
            closeIcon = (<div className="hover-opacity-30" onClick={this.handleCartCloseClick}>
                <Icon icon="close" size={20} fill="var(--rt-text-color)" />
            </div>)
        }
  
        if (this.props.cartName){
            let cartItemCount;
            if (this.props.cartItemCount && this.props.cartItemCount !== "0"){
                /*
                cartItemCount = (<div style={{display:"flex",justifyContent:"center"}}>
                    <div style={{borderRadius:99,width:22,height:22,backgroundColor:theme.cartCountBackground,color:"#fff",fontSize:"15px",fontWeight:500,
                            display:"flex",alignItems:"center",justifyContent:"center"}}>
                            {cartItemCount}
                    </div>
                </div>)
                */
                let countText = (this.props.cartItemCount == 1) ? "item" : "items";
                 cartItemCount = (<div style={{display:"flex",justifyContent:"center"}}>
                 <div style={{borderRadius:99,padding:"2px 12px",backgroundColor:theme.cartCountBackground,color:"#fff",fontSize:"15px",fontWeight:500,
                         display:"flex",alignItems:"center",justifyContent:"center"}}>
                         Checkout {this.props.cartItemCount} {countText}
                 </div>
             </div>)
            }
            cart = (<div style={{cursor:"pointer",color:"var(--rt-text-color)"}} onClick={this.handleShowCartClick}>
                <div style={{display:'flex',alignItems:'center',gap:5}}>
                    <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                        <div style={{fontSize:"16px",fontWeight:500,display:"flex",gap:10,alignItems:"center"}}>
                            <Icon icon="cart" size={16} fill="var(--rt-primary-bg-color)"/>
                            {this.props.cartName}
                            {cartItemCount}
                        </div>
                        <div style={{fontWeight:500,marginTop:2}}>
                            {this.props.branchName}
                        </div>
                    </div>
                    {closeIcon}
                </div>
              
            </div>)
        }
      
        return cart;
    }

    handleShowCartClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onclick){
            this.props.onclick.trigger();
        }
    }

    handleCartCloseClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onClickClose){
            this.props.onClickClose.trigger()
        }
    }

    /*
    handleSwitchCartClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let action = this.props.event.action("switch-cart");
        action.trigger();
    }
    */
    
}
