import React from 'react';

export class ScrollRegion extends React.Component<{className?:string,style?:React.CSSProperties},{scrolled:boolean}>{
    
    state = {scrolled:false};
    
    containerRef = React.createRef<HTMLDivElement>();

    render(): React.ReactNode {
        let className = "rt-scrollbars ";
        if (this.props.className){
            className += this.props.className;
        }
        if (this.state.scrolled){
            className += " container-is-scrolled";
        }
        return <div ref={this.containerRef} onScroll={this.handleScroll} style={{...this.props.style,overflowY:"auto",height:"100%"}} className={className}>{this.props.children}</div>
    }

    handleScroll = (e:React.UIEvent<HTMLDivElement>) => {
        let elem = this.containerRef.current;
        if (elem.scrollTop){
            if (!this.state.scrolled){
                this.setState({scrolled:true});
            }
        }
        else if (this.state.scrolled){
            this.setState({scrolled:false});
        }
    }
}