import { makeComponent, RenderEvent} from 'core';
import { DateFormatter } from 'core/FieldFormatter';
import React from 'react';



export const Timeline = makeComponent(class extends React.Component<{event?:RenderEvent}>{
    render(){
        let children = this.props.children;
        let event = this.props.event;
        if (!event) return null;
        let lastDate:string;

        return <div style={{backgroundColor:"#fff",padding:20}}>
            {React.Children.map(this.props.children,(child:any,index:number) => {
                let timestamp = child.props.timestamp;
                let formatted = DateFormatter.format(timestamp,"mmm d, yyyy|h:nn a");
                let segments = formatted.split('|');
                let date:string;
                let time:string;

                if (segments[0] == lastDate){
                    time = segments[1];
                }
                else {
                    date = segments[0];
                    time = segments[1];
                    lastDate = segments[0];
                }
                return React.cloneElement(child,{date,time});
            })}
        </div>
    }
});

export const TimelineItem = makeComponent(class extends React.Component<{isActive:boolean,date:any,time:any,label:string,username:string,richText:any}>{
    render(){
        let className = "RT-Timeline__marker";
        if (!this.props.isActive){
            className += " RT--info";
        }
        let date;
        let time;
        if (this.props.date){
            date = <div>{this.props.date}</div>
        }
        if (this.props.time){
            time = <div>{this.props.time}</div>
        }
        let user;
        if (this.props.username){
            user = <div>{this.props.username}</div>
        }
        let label = this.props.label;
        let richText;
        if (this.props.richText){
            richText = renderRichText(this.props.richText);
        }
        return <div className="RT-Timeline__item" style={{display:"flex"}}>
            <div style={{marginRight:20,fontSize:"13px",position:"relative",flexShrink:0,top:-4,paddingBottom:15,width:100,textAlign:"right",minHeight:60}}>
                {date}
                {time}
                {user}
            </div>
            <div className={className}/>
            <div style={{position:"relative",top:-4,paddingBottom:15}}>
                <div style={{fontWeight:400}}>{label}</div>
                <div style={{fontWeight:400}}>{richText}</div>
            </div>     
        </div>
    }
});

function renderRichText(value:string){
    if (!value) return null;
    let json;
    try {
        json = JSON.parse(value);
    }
    catch(e){
        return value;
    }
    if (!Array.isArray(json)){
        return value;
    }
    var items:any[] = json;
    let out:any[] = [];
    for(let i =0 ; i < items.length;i++){
        let item = items[i];
        if (item.Text){
            let text = item.Text + " ";
            if (item.Target){
                out.push(<span style={{fontWeight:500,color:"var(--rt-primary-color)"}} key={i}>{text}</span>)
            }
            else {
                out.push(text)
            }
        }
    }
    return out;
}

