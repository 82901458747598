
import { ActionEvent } from './ActionEvent';
import { IComponentDefinition } from './ComponentDefinition';

export const COMMENT = "Comment";
export const KIND = {
    IF:"If",
    ELSE_IF:"ElseIf",
    ELSE:"Else",
    COMMENT:"Comment",
    PASS:"Pass",
    RETURN:"Return",
    PARAM_CONTENT:"ParamContent"
}
export interface ISidebarFrame {
    name:string;
    label:string;
    content?:any;
}
export class SidebarState {
    frames:ISidebarFrame[];
    activeFrame:string;
    isOpen:boolean;
}


export class NavbarState {
    isCollapsed:boolean;
}

export interface IActionResult {
    continue:boolean;
    error?:string;
}

export interface IAsLookupFor {
    page:string;
    field:string;
}

export interface IEventOptions {
    current?:any,
    value?:any,
    returnValue?:any,
    table?:any[],
    scope?:any;
    showStackContent?:(content:any) => void;
    actionName?:string;
    title?:string;
    noUpdate?:boolean;
    isRecordChanged?:boolean;
    isBrowseMove?:boolean;
    triggerSearch?:boolean;
    targetPos?:{x:number,y:number};
    isCachedVersionRefresh?:boolean;
 }

 export interface IFilterValue {
     $type:"filter",
     value?:any;
     compare?:string;
     toValue?:any
     bucket?:string;
 }

 export type RecordChangedAction = "created" | "updated" | "deleted";

 export interface IRecordChangedEvent {
    action:RecordChangedAction;
    rowKey:string;
    table:string;
 }
 
 export type RecordChangedHandler = (recordEvent:IRecordChangedEvent) => Promise<void>;



export function makeComponent<T>(c:T,def?:IComponentDefinition):T {
    let comp = c as any;
    comp.$def = def;
    return c;
}

export type ActionHandler = (event?:ActionEvent)=> Promise<any>;


export type FieldType = 'character' | 'logical' | 'date' | 'datetime' | 'time'
| 'integer' | 'money' | 'decimal' 
| 'longchar' | 'uid' | 'record' | 'collection' | 'action';


export interface IEventScope {
    enterKeyAction?:string;
}

export type FilterOp = "=" | ">" | ">=" | "<" | "<=" | "begins" | "contains" | "between" | "ends" | "<>" | 'or' | 'and' | 'is empty' | 'is not empty';

export type FieldErrorType = 'VALIDATION' | 'NOTFOUND' | 'ERROR' | 'FATAL' | 'INFORMATION' | 'WARNING' | 'NO-DIALOG';

export type ICodeError = {
    id:string,
    message:string
}
export interface ICanvasError {
    showDialog?:boolean;
    messages:string[];
    sourceCode?:any;
    codeErrors?:ICodeError[];
    validationErrors?:IValidationError[];
    $$error:true
    name?:string;
    render?:any;
}

export interface IValidationError {
    field?:string;
    type?:FieldErrorType;
    message?:string;
    label?:string;
    noDialog?:boolean;
}



export interface IActionDefinition {
    label?:string;
    help?:string;
    icon?:string;
    styles?:string;
    type?:string;
    statements?:any[];
    disableIf?:string;
}

export interface IPageLaunchParams {
    name:string;
    layout?:string;
    view?:string;
    props?:any;
    asLookupFor?:IAsLookupFor;
    draft_id?:string;
    designer_sections?:string;
}

export interface IStyleIcon {name?:string,className?:string};

export type ComponentStyles = {style?:any,className?:string,format?:string,icon?:IStyleIcon};

export function getActionName(action:ActionHandler | string):string{
    if (typeof action == "string"){
        return action;
    }
    if (action) return (action as any).$name;
}


export function getTargetName(target:string | ActionHandler):string{
    if (typeof target == "string") return target;
    if (target) return (target as any).$name;
}

export interface ISigninSettings {
    supportLinkLabel?:string;
    supportLinkHref?:string;
    usernameLabel?:string;
    submitLabel?:string;
    registerLabel?:string;
    forgotPasswordLabel?:string;
    passwordResetInstruction?:string;
    resetSentMessage?:string;
    logoFile?:string;
    logoSize?:string;
    redirect?:string;
}

export interface IStudioSettings {
    enabled:boolean;
    launchUrl:string;
    repo:string;
}

export interface IVisibilityContainer {
    hidden:boolean;
}

