import React from 'react';
import {  ActionRef, RenderEvent } from '../core';
import { Icon } from './Icon';
import { InlineTextPropEditor } from './studio/InlineTextPropEditor';
import { StudioIconPropEditor } from './studio/StudioIconChooser';


export class NavLink extends React.Component<{
    event:RenderEvent;
    action:ActionRef;
    label: string;
    name?: string;
    description?:string;
    icon?:string;
    params?: any;
    asDialog?:boolean;
    minHeight?:any;
    onClick?:ActionRef;
    $elem:any;
    $id?:string;
}> {
    render() {
        let label;
        let description;
        let iconName = this.props.icon;
        label = this.props.label;
        description = this.props.description;
        let event = this.props.event;
        if (event.designMode){
            let icon = this.renderIcon(iconName,null);
            return (<div className="RT-NavLink" style={{minHeight:this.props.minHeight}}>
                <div style={{display:"flex"}}>
                    <div className="RT-NavLink__icon" onClick={this.chooseIcon}>{icon}</div>
                    <div style={{flexGrow:1}}>
                        <InlineTextPropEditor event={event} className="RT-NavLink__label" $id={this.props.$id} propName="label" placeHolder="Click to edit label"
                            $elem={this.props.$elem}/>
                        <InlineTextPropEditor event={event} className="RT-NavLink__description" $id={this.props.$id} propName="description"  placeHolder="Click to edit description"
                            $elem={this.props.$elem}/>
                    </div>
                </div>
            </div>);
            
        
        }
        if (!label) return null;
       
        if (description){
            description = <div className="RT-NavLink__description">{description}</div>
        }
        let text = <>
            <div className="RT-NavLink__label">{label}</div>
            {description}
        </>
        let content;
        if (iconName){
            let icon = this.renderIcon(iconName,null)
            content = <div style={{display:"flex"}}>
                <div className="RT-NavLink__icon">{icon}</div>
                <div>{text}</div>
            </div>
        }
        else {
            content = text;
        }
        return (
            <div className="RT-NavLink" style={{minHeight:this.props.minHeight}}>
                <a href="#" onClick={this.handleClick}>
                    {content}
                </a>
            </div>
        );
    }

    renderIcon(icon:string,fill:string):any {
        return <Icon icon={icon} fill={fill} size={16}/>
    }

    handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    
        // form.closeMenu();
        if (this.props.action){
            this.props.action.trigger();
        }
        else if (this.props.onClick){
            this.props.onClick.trigger();
        }
    };

    chooseIcon = (e:React.MouseEvent) => {
        e.stopPropagation();
        StudioIconPropEditor.open(e.currentTarget as HTMLElement,this.props.event,{$id:this.props.$id,prop:"icon"});
    }
    
    
}



export class Menu extends React.Component<{event:RenderEvent,itemMinHeight:any}>{
    render(): React.ReactNode {
        /*
        let elems = [];
       
        let collection = this.props.collection;
        if (!collection) return null;
        let rows = collection.rows;
        let event = this.props.event;
        let render_children = this.props.render_children;
        if (!render_children) return null;
    

        for(let index = 0; index < rows.length;index++){
            let row = rows[index];
            let rowEvent = event.create({...event.scope,[collection.name]:row});
            rowEvent.keyPrefix = index.toString();
            let content = render_children(rowEvent);
            if (content){
                elems.push(content);
            }
        }
        */
       let elems = [];
       let itemMinHeight = this.props.itemMinHeight;
       React.Children.forEach(this.props.children,child => {
            if (child){
                if (itemMinHeight){
                    child = React.cloneElement(child as any,{minHeight:itemMinHeight});
                }
                elems.push(child);
            }
        });
        
        if (!elems.length) return null;

        let columns = [];
        let numCols = 3;
        let flexBasis = (100/numCols) + "%";
        let perColumn = Math.ceil(elems.length / numCols);
        let children = [];
        let count = 0;
        let totalCount = 0;
        let columnIndex = 0;
        for(let i = 0; i < elems.length;i++){
            let elem = elems[i];
            children.push(elem);
            count++;
            totalCount++;
            if (count >= perColumn){
                columns.push(this.renderColumn(children,columnIndex++,flexBasis));
                children = [];
                count = 0;
            }
        }

        if (!totalCount) return null;

        if (count > 0){
            columns.push(this.renderColumn(children,columnIndex++,flexBasis));
        }
       
        return <div style={{backgroundColor:"#fff",padding:15,marginTop:15,display:"flex",gap:20,alignItems:"flex-start"}}>
            {columns}
        </div>
    }

    renderColumn(children:any[],index:number,flexBasis:any){
        return <div key={index} style={{flexGrow:1,flexBasis}}>{children}</div>
    }
}