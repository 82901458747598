import {  ContextMenuHandlerArgs, RenderEvent } from 'core';
import { DragState } from 'core/DragState';
import React from 'react';
import { Icon } from '../Icon';

export class StudioEditorChildren extends React.Component<{event:RenderEvent,$id?:string,
        $elem?:any,placeholder?:string,hideAppend?:boolean,noDrop?:boolean;}>{

    containerRef  = React.createRef<HTMLDivElement>();


    render(): React.ReactNode {
  
        let event = this.props.event;
        let dragState = event.canvas.dragState;
        let elems = [];
        let index = 0;
        React.Children.forEach(this.props.children,(child:any)=> {
            if (child){
                let isColumnContainer = (child.props.$src_kind == "UI.Line")
                elems.push(<ContentItem event={event} key={index} index={index}  $id={child.props.$id} $kind={child.props.$src_kind}
                    dragState={dragState} addMargin isColumnContainer={isColumnContainer}>{child}</ContentItem>);
                index++;
            }

        });
        let style:React.CSSProperties = {};
        let appendElem;
        if (!this.props.hideAppend){
            let appendStyle:React.CSSProperties = {display:"flex",
            alignItems:"center",justifyContent:"center",color:"rgb(180,190,200)",fontSize:"13px",fontWeight:400,
            border:"dashed 2px rgb(232,232,232)"};
            let appendClass = "studio-hide-unless-dragging";
            if (index){
                appendStyle.height = 30;
                appendClass += " hover-opacity-0";
            }
            else {
                appendStyle.height = 60;
            }
            appendElem = (<div data-drop-target={this.props.$id} data-drop-position="append" style={appendStyle} className={appendClass}>
                {this.props.placeholder || "Add elements here"}
            </div>)
        }
        else {
            style.marginBottom = -10;
        }
       
        let dropZone = (!this.props.noDrop) ? "true" : null;
        return (<div ref={this.containerRef} style={{position:'relative'}} className="hover-container">
            <div data-drop-zone={dropZone} style={style}>
                {elems}
                {appendElem}
            </div>
        </div>)
    }

}


export class StudioHorizontalChildren extends React.Component<{event:RenderEvent,$id?:string,
    $elem?:any,placeholder?:string,spanPattern:string}>{


    containerRef  = React.createRef<HTMLDivElement>();


    render(): React.ReactNode {

        let event = this.props.event;
        let dragState = event.canvas.dragState;
        let elems = [];
        let index = 0;
        let children = this.props.children;
        let childNodes = [];
        if (children){
            React.Children.forEach(children,child => {
                if (child){
                    childNodes.push(child);
                }

            });
        }

        let spanSegments:any[];
        if (this.props.spanPattern){
            spanSegments = this.props.spanPattern.split(",");
        }
        else {
            spanSegments = [];
        }
    
        for(let i = 0; i < childNodes.length;i++){
            let child = childNodes[i];
            let isLast =  (i == childNodes.length - 1);
            elems.push(<ContentItem event={event} key={index} index={index} $kind={child.props.$src_kind} 
                isLast={isLast} $id={(child as any).props.$id} dragState={dragState} asColumn spanSize={spanSegments[i]}>{child}</ContentItem>);
            index++;
        }

    
        return (<div ref={this.containerRef} data-drop-zone="true" style={{position:'relative',display:"flex"}} className="hover-container studio-columnar-container">
             {elems}
        </div>)
    }

}


class ContentItem extends React.Component<{event:RenderEvent,index:number,isLast?:boolean;dragState:DragState,$id:string,$kind:string,addMargin?:boolean,
    isColumnContainer?:boolean,asColumn?:boolean,spanSize?:any}>{

  
    state = {dragging:false};

    render(){
        let style:React.CSSProperties = {borderStyle:"solid",
            borderColor:"transparent",position:"relative",paddingLeft:2,letterSpacing:"0.35px",marginRight:-20};
        if (this.props.addMargin){
            if (this.props.index > 0){
                style.marginTop = 10;
            }
            style.marginBottom = 10;
        }

        if (this.state.dragging){
            style.opacity = 0.3;
        }
        else {
            style.opacity = 1;
        }


        let swapIcon;

        
        let dataDropTarget:string;
        if (this.props.asColumn){
            style.flexGrow = 1;
            style.flexBasis = this.props.spanSize || "50%";
            let moveLeft;
            let moveRight;

            if (this.props.index){
                moveLeft = <div style={{cursor:"pointer"}} onClick={this.handleClickLeft}>
                    <Icon icon="arrow-left" fill="#fff" size={20} />
                </div>
            }
            if (!this.props.isLast){
                moveRight = <div style={{cursor:"pointer"}} onClick={this.handleClickRight}>
                <Icon icon="arrow-right" fill="#fff" size={20} />
                </div>
            }
            
            swapIcon = <div className="studio-columnar-container-hover-child studio-hide-while-dragging" style={{position:"absolute",top:-18,left:"50%",
                height:22,width:50,marginLeft:-25,backgroundColor:"rgb(153,161,177)",zIndex:50,
                borderRadius:5,display:"flex",justifyContent:"center",alignItems:"center",gap:5}}
                  >
                    {moveLeft}
                    {moveRight}
                </div>
            
        }
        if (this.props.$kind != "UI.Stacked"){
            dataDropTarget = this.props.$id;
        }

        let canvas = this.props.event.canvas;
        let dotsClassname = "studio-selector";
        let contentStyle:React.CSSProperties = {flexGrow:1,paddingBottom:5,paddingLeft:3,border:"solid 1px transparent",
            borderRadius:6,height:"100%",display:"flex"};

        if (canvas.activeDesignElement && this.props.$id == canvas.activeDesignElement){
            dotsClassname += " is-active";
           // contentStyle.border = "solid 1px var(--rt-primary-color)";
        //    style.borderLeft = "solid 1px rgb(216,216,216)";
        }

        if (this.props.isColumnContainer){
            contentStyle = {flexGrow:1,display:'flex',flexDirection:'column'};
            style.display = "flex";
            style.paddingRight = 20;
            style.minHeight = 30;
            return <div  className="shuffle-item studio-selector-container" data-drop-target={this.props.$id} data-elem-id={this.props.$id} style={style}  >
                <div style={contentStyle}>
                    {this.props.children}
                </div>
                <div tabIndex={-1} onMouseDown={this.handleMouseDown}  style={{position:"absolute",right:-10,top:0,
                    zIndex:20,marginTop:1,flexShrink:0,display:"flex",alignItems:"center",
                    justifyContent:"center",width:28,height:28,backgroundColor:"rgb(153, 161, 177)",cursor:"pointer",
                    borderRadius:3,transition:"background-color 0.15s"}} className={dotsClassname} onClick={this.handleDotsClick}>
                    <Icon icon="columns" fill="#fff" size={20}/>
                </div>
                {swapIcon}
            </div>
        }
        let itemSelector;
        if (this.props.$kind != "UI.Stacked"){
            itemSelector = (  <div tabIndex={-1} onMouseDown={this.handleMouseDown}  style={{marginTop:1,flexShrink:0,display:"flex",alignItems:"center",
                justifyContent:"center",width:24,height:24,cursor:"pointer",
                borderRadius:3,transition:"background-color 0.15s"}} className={dotsClassname} onClick={this.handleDotsClick}>
                <Icon icon="vertical-dots" fill="currentColor" size={20}/>
            </div>);
        }
        else {
            contentStyle.paddingLeft = 0;
            contentStyle.border = "none";
            style.paddingLeft = 0;
        }

        let targetKind = this.props.$kind;

        return <div  className="shuffle-item" data-drop-target={dataDropTarget} data-elem-id={this.props.$id} data-target-kind={targetKind} style={style}  >
            <div style={{display:'flex',alignItems:'flex-start',position:"relative",paddingRight:20,height:"100%"}} className="studio-selector-container">
                {itemSelector}
                <div style={contentStyle} className="studio-shuffle-content">
                    {this.props.children}
                </div>
            </div>
            {swapIcon}
        </div>
    }

    handleMouseDown = (e:React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        let dragState = this.props.dragState;
        dragState.onDragStart = () => {this.setState({dragging:true});}
        dragState.onDragEnd = () => setTimeout(() => this.setState({dragging:false}),30);
        dragState.renderDragImage = this.renderDragImage;
        let target = e.target as HTMLElement;
        let elem = target.closest('[data-elem-id]');
        
        let container = elem.parentElement.closest("[data-drop-target]");
        let containerId = container ? container.getAttribute("data-drop-target") : null;
        this.props.dragState.startDrag(this.props.$id,containerId,e.clientX,e.clientY);

    }

    renderDragImage = () => (
        <div style={{padding:"5px 60px 5px 15px",cursor:"grab",display:"flex",alignItems:"center",border:"solid 1px rgb(232,232,232)",borderRadius:5,backgroundColor:"#fff"}}>
            <div style={{marginRight:12,width:20,height:20,display:"flex",justifyContent:'center',alignItems:"center",
                backgroundColor:"rgb(242,242,242)"}}><Icon icon="square" size={16} /></div>
            <div style={{fontSize:"14px",fontWeight:500,color:'rgb(19,33,68)',letterSpacing:"0.35px"}}>{this.props.$kind}</div>
        </div>
    )


    handleDotsClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        
        if (this.state.dragging) return;
        let canvas = this.props.event.canvas;
        let rect = e.currentTarget.getBoundingClientRect();
        let args:ContextMenuHandlerArgs = {target:e.currentTarget as any,rect:{top:rect.top,bottom:rect.bottom,left:rect.left,right:rect.right}};
        canvas.app.contextMenuManager.handleOpen(args);
        
    }

    handleSwapClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        canvas.studioCommandBuffer.swapPosition(this.props.$id);
        canvas.studioCommandBuffer.send();
    }

    handleClickLeft = (e:React.MouseEvent) => {
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        canvas.studioCommandBuffer.moveLeft(this.props.$id);
        canvas.studioCommandBuffer.send();
    }

    handleClickRight = (e:React.MouseEvent) => {
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        canvas.studioCommandBuffer.moveRight(this.props.$id);
        canvas.studioCommandBuffer.send();
    }
}

