import React, { ReactNode } from "react";

export class PutBlock extends React.Component {
    render(): ReactNode {
        return null; // rendered inline server side
    }
}

export class Body extends React.Component {
    render(): ReactNode {
        return null; // rendered inline server side
    }
}

export class TableRow extends React.Component {
    render(): ReactNode {
        return null; // rendered inline server side
    }
}