import { ActionRef, FieldRef } from 'core';
import React from 'react';

export class NavBarValueSelector extends React.Component<{value:any,action:ActionRef}>{
    render(){
        let value;
        let link;
        if (this.props.value){
            value = <div style={{fontSize:'15px',fontWeight:500,color:"var(--rt-text-color)"}}>{this.props.value}</div>
        }
        if (this.props.action){
            link = <a href='#' style={{display:"block",fontSize:'12px',color:"var(--rt-primary-color)"}}>{this.props.action.label}</a>
        }
        return (<div style={{textAlign:"center",cursor:"pointer"}} onClick={this.handleClick}>
            {value}
            {link}
        </div>)
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.action){
            this.props.action.trigger({});
        }
    }
    
}