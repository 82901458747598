import React from 'react';


export class FilterPanel extends React.Component<{}>{
    render(){
        return <div className="rt-scrollbars" style={{height:"100%",overflowY:'auto',paddingRight:30,
            paddingLeft:30,paddingBottom:15,borderRight:"solid 3px #e3e6ec",marginTop:15,marginBottom:15}}>
            {this.props.children}
        </div>
    }
}