import { Broadcast } from "core";
import { Canvas } from "./Canvas";
import { Collection,CollectionArray, ICollectionMeta } from "./Collection";
import { RecordMeta } from "./RecordMeta";
import { IRecordSchema } from "./Schema";
import { IEventScope } from "./types";

export class CollectionRef {
    canvas:Canvas;
    name:string;
    scope:IEventScope;
    rows:CollectionArray;
    schema:IRecordSchema;

    constructor(canvas:Canvas,scope:IEventScope,rows:any[],name:string,schema:IRecordSchema){
        this.canvas = canvas;
        this.scope = scope;
        this.rows = rows;
        this.name = name;
        this.schema = schema;
    }

    get currentRow():any {
        return Collection.getCurrentRow(this.canvas,this.name);
    }

    set currentRow(value:any){
        Collection.setCurrentRow(this.canvas,this.name,value);
    }

    locate(rowid:any):any {
        let index = this.findRow(rowid);
        if (index != -1){
            this.currentRow = this.rows[index];
        }
        else {
            this.currentRow = null;
        }
        return this.currentRow;
    }

    addRow(options:{position?:null | 'first' | 'current'} = {}){
        let rows = this.rows;
        if (!rows){
            rows = [];
            this.canvas.setRecord(this.name,rows);
        }
        let newRow = RecordMeta.initalizeRecord(this.schema,{});
        rows.push(newRow);
        this.currentRow = newRow;
    }

    removeRow(options:{rowid?:any,mode?:null | 'all'}){
        let rows = this.rows;
        if (!rows) return;
        if (options.mode == "all"){
            rows.splice(0,rows.length);
            Collection.incrementVersion(rows);
            return;
        }
        if (options.rowid){
            let index = this.findRow(options.rowid);
            if (index){
                rows.splice(index,1);
                Collection.incrementVersion(rows);
            }
            return;
        }
        let row = this.scope[this.name];
        if (row){
            let index = rows.indexOf(row);
            if (index != -1){
                rows.splice(index,1);
                Collection.incrementVersion(rows);
            }
        }
        
    }

    findRow(rowid:any):number {
        let rows = this.rows;
        for(let i =0 ; i < rows.length;i++){
            let row = rows[i];
            let rowKey = Collection.getRowKey(row,this.schema);
            if (rowKey == rowid) {
               return i;
            }
        }
        return -1;
    }

    clearErrors(){
        let rows = this.rows;
        if (!rows) return;
        for(let i = 0; i < rows.length;i++){
            let row = rows[i];
            if (row){
                RecordMeta.clearErrors(row);
            }
        }
    }

    getMeta():ICollectionMeta {
        if (this.rows) return this.rows.$$meta;
        return null;
    }

    setMetaProps(values:any):ICollectionMeta{
        if (this.rows){
            Collection.setMetaProps(this.rows,values);
            return this.getMeta();
        }
    }

    refresh(){
        let meta = this.getMeta();
        if (meta && meta.broadcast){
            meta.broadcast.refresh();
        }
    }

    connect(component:any,handler:(action:string) => void){
        let meta = this.getMeta();
        if (!meta || !meta.broadcast){
            meta = this.setMetaProps({broadcast:new Broadcast()})
        }
        meta.broadcast.connect(component,"*",handler);
    }

    disconnect(component:any){
        let meta = this.getMeta();
        if (meta && meta.broadcast){
            meta.broadcast.disconnect(component);
        }
    }
    

}