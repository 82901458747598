import { ActionRef, CollectionRef, FieldFormatter, RenderEvent } from 'core';
import { DateFormatter } from 'core/FieldFormatter';
import { RenderFunc } from 'core/RenderEvent';
import React from 'react';

export class TaskList extends React.Component<{event:RenderEvent,collection:CollectionRef,onTaskClick:ActionRef}>{
    render(): React.ReactNode {
        let items:any[] = [];

        let collection = this.props.collection;
        if (!collection) return null;
        let rows = collection.rows;
        let event = this.props.event;
        let collectionName = collection.name;
        let onTaskClick = this.props.onTaskClick;
        for(let i = 0; i < rows.length;i++){
            let row = rows[i];
            let rowEvent = event.create({[collectionName]:row});
            items.push(<Item key={i} event={rowEvent} row={row} onTaskClick={onTaskClick}/>)
        }
        return <div>
            {items}
            <div style={{height:20,backgroundColor:"#fff"}}/>
        </div>
    } 
}

class Item extends React.Component<{event:RenderEvent,row:any,onTaskClick:ActionRef}>{
    render(): React.ReactNode {
        let row = this.props.row;
        let channels:any[] = [];
        if (row.channels != null){
            for(let i = 0; i < row.channels.length;i++){
                let channel = row.channels[i];
                channels.push(<div key={i} style={{display:"flex",alignItems:"center",gap:5}}>
                    <div style={{color:"rgb(80,90,100)",fontSize:"12px"}}>{channel.name}</div>
                    <div>{'\u2022'}</div>
                    <div style={{fontWeight:500,color:"var(--rt-primary-color)"}}>{channel.label}</div>
                </div>)
            }
        }
        let duration;
        let due_date = row["due_date"];
        if (due_date){
            let dt = DateFormatter.parse(due_date);
            let time = new Date(dt.year,dt.month - 1,dt.day,dt.hour,dt.minute);
            duration = DateFormatter.getDurationText(time.getTime());
        }
   
        //          /*<div style={{position:"absolute",width:2,top:49,bottom:-10,left:28,backgroundColor:"rgb(232,232,232)",zIndex:1}}/>/*}

        return (<div style={{padding:15,position:"relative",display:"flex",backgroundColor:"#fff",overflow:"visible",letterSpacing:"0.2px",
            cursor:"pointer"}} onClick={this.handleClick}>
            <div style={{width:28,height:28,backgroundColor:row.color,borderRadius:99,display:"flex",alignItems:"center",
            justifyContent:'center',opacity:0.9}}>
                <div style={{fontSize:"14px",fontWeight:500,color:"#fff",letterSpacing:"0.5px",lineHeight:1}}>{row.initials}</div>
            </div>
  
            <div style={{padding:"0 15px"}}>
                <div style={{display:"flex",alignItems:"center",gap:20,fontSize:"13px",fontWeight:400}}>
                   
                    <div style={{fontWeight:500,color:"rgb(120,130,140)"}}>Due {duration}</div>
                    <div>{row.user_name}</div>
                </div>
                <div style={{fontSize:"15px",fontWeight:500,marginTop:1}}>{row.message}</div>     
                <div style={{display:"flex",alignItems:"center",gap:25,marginTop:1}}>
                    {channels}
                </div>
            </div>

        </div>)
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onTaskClick){
            this.props.onTaskClick.trigger({scope:this.props.event.scope});
        }
    }
}