import React from 'react';
import { IProductRow, ProductTableStore } from './ProductTableStore';
import { CellValue } from './CellValue';

export class PivotList extends React.Component<{ store:ProductTableStore,count: number; values: IProductRow[]; style: React.CSSProperties; showAvailOnly: boolean; activeRow: IProductRow; onSelectCell: (row: IProductRow) => void; }> {
    render() {
        let values = this.props.values;
        if (!values)
            return null;
        let elems = [];
        let isActive: boolean;
        let store = this.props.store;
        for (let i = 0; i < this.props.count; i++) {
            let row = values[i];

            let style = this.props.style;
            if (i == this.props.count - 1) {
                style = { ...style, borderRight: null };
            }
            if (row) {
                isActive = (this.props.activeRow == row);
            }
            else {
                isActive = false;
            }
            elems.push(<CellValue key={i} store={store} row={row} style={style} onSelectCell={this.props.onSelectCell} isActive={isActive} />);
        }
        return elems;
    }
}
