import { Canvas, PageContainer, RenderEvent } from 'core';
import { IPageLaunchParams } from 'core/types';
import React from 'react';

export class StudioInlinePage extends React.Component<{event:RenderEvent,
    page:string,draft_id:string,sections:string,params:any,mode:'edit' | 'preview'},{key:number,prevParams:any}>{
    
    constructor(props){
        super(props);
        this.state = {key:1,prevParams:null};
    }
    static getDerivedStateFromProps(props, state) {
        let prevParams = state.prevParams;
        let nextParams = {sections:props.sections};
        
        if (prevParams){
            if (prevParams.sections != props.sections){
                return {key:state.key + 1,prevParams:nextParams};
            }
            return null;
        }
        else {
            return {key:1,prevParams:nextParams}
        }
    }
    
    render(): React.ReactNode {
        let event = this.props.event;
        let page:IPageLaunchParams;
        page = {
            name:this.props.page,
            draft_id:this.props.draft_id,
            designer_sections:this.props.sections,
            props:this.props.params
        };
        return <PageContainer key={this.state.key} page={page} event={event} layer="embedded" 
            onLoaded={this.handleLoaded}
        />
    }

    handleLoaded = (canvas:Canvas) => {
        if (this.props.mode == "edit"){
            canvas.renderDesignSlot = this.renderDesignSlot;
            canvas.update();
        }
    }

    renderDesignSlot = (event:RenderEvent,section:string) => {
        let canvas = this.props.event.canvas;
        let content = canvas.slotContent[section];
        if (content){
            return this.props.event.render(content);
        }
        return null;
    }

}
