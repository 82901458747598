import { IValidationError,ICanvasError, KIND, IRecordChangedEvent, IPageLaunchParams } from './types';
import {ScreenRef} from './ActionEvent';
import { Collection } from './Collection';
import { IScreenParameter, Schema } from './Schema';
import { PageContainer } from './PageContainer';
import { IfBlockRunner } from './IfBlockRunner';
import {Request} from './Request';
import {FileDownloadManager} from './FileDownloadManager';
import { StyledText } from 'components/helpers/StyledText';
import { ActionRef } from './ActionRef';
import { DesignerToolbox } from './studio/DesignerToolbox';
import { StudioCommandBuffer } from './studio/StudioCommandBuffer';
import { Application } from './Application';
import { servicesVersion } from 'typescript';

type commandHandler = (screen:ScreenRef,elem:any) => void | Promise<any>;
var map:{[name:string]:commandHandler} = {
    "Set":setValue,
    "Assign":setValue,
    "SetCompare":setCompare,
    "Trigger":trigger,
    "Activate":activateTab,
    "AddRow":addRow,
    "CacheStrategy":cacheStrategy,
    "ClearErrors":clearErrors,
    "Close":close,
    "Disable":disable,
    "Download":download,
    "OutputPage":outputPage,
    "Draw":draw,
    "Droplist":setOptions,
    "Enable":enable,
    "EnterKey":setEnterKey,
    "Error":setError,
    "Focus":focus,
    "Format":setFormat,
    "HasUnsavedChanges":hasUnsavedChanges,
    "Help":setHelp,
    "Hide":hide,
    "IfBlock":ifBlock,
    "ResetRecord":resetRecord,
    "ResetCollection":resetCollection,
    "InitFilter":initFilter,
    "Label":setLabel,
    "Layout":layout,
    "Lookup":setLookup,
    "NavigateUrl":navigateUrl,
    "LoadDropValues":loadDropValues,
    "Locate":locate,
    "Message":message,
    "NotRequire":optional,
    "OpenPage":openPage,
    "PageSettings":pageSettings,
    "PatchCollection":patchCollection,
    "SetCollection":setCollection,
    "SetRecord":setRecord,
    "SetReturnValue":setReturnValue,
    "Placeholder":setPlaceholder,
    "RecordChanged":recordChanged,
    "RedrawContent":redrawContent,
    "Remove":removeRow,
    "Required":required,
    "Restart":restart,
    "Stop":stop,
    "UnHide":unhide,
    "ValueLabel":setLookupLabel,
    "Warning":showWarning,
    "CallBatch":callBatch,
    "BotDialog":botDialog,
    "BotPrompt":botPrompt,
    "ShowSidebar":showSidebar,
    "HideSidebar":hideSidebar,
    "TrackUnsavedChanges":trackUnsavedChanges,
    "RestartOpenPages":restartOpenPages,
    "AddIcon":addIcon,
    "ClearSelection":clearSelection,
    "OpenWorkspace":openWorkspace,
    "SetSession":setSession,
    "DesignMode":designMode,
    "DesignFocus":designFocus,
    "ViewDraft":viewDraft,
    "SlotContent":slotContent,
    "Title":title,
    "RunOnPage":runOnPage,
    "StartDesigner":startDesigner
}



export type CommandElem = {$kind:string} & any;

export async function executeCommands(screen:ScreenRef,commands:CommandElem[]){
    if (!commands) return;
    let command:CommandElem;

    try {
        for(let i =0 ; i < commands.length;i++){
            command = commands[i];
            let kind = command.$kind;
            if (kind == KIND.RETURN) {
                if (command.value || command.value === 0){
                    screen.eventOptions.returnValue = screen.getValue(command.value);
                }
                throw "stopped";
            }
            let func = map[kind];
            if (func){
                let result = func(screen,command);
                if (result && result.then){
                    await result;
                }
            }
            else {
                throw "invalid command";
            }
        }

       
    }
    catch(e:any){
        if (e != "stopped" && !e.$$error){
            throw "Error on command: " + command.$kind + " , " + e.toString();
        }
        throw e;
    }
}

function setValue(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    let fieldName = elem.name;
    if (fieldName == "@return-value"){
        screen.eventOptions.returnValue = value;
        return;
    }

    let field = screen.canvas.getFieldRef(elem.name,screen.eventOptions.scope);
    if (value && value.$type == "filter"){
        field.setCompare(value.compare,false);
        field.setToValue(value.toValue);
        field.setValueNoUpdate(value.value);
    }
    else {
        field.setValueNoUpdate(value);
    }
}


function setCompare(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    let field = screen.canvas.getFieldRef(elem.name,screen.eventOptions.scope);
    field.setCompare(value,false);
}

function initFilter(screen:ScreenRef,elem:any){
    let fieldName = elem.name;
    let compare = elem.compare;
    let value = exprVal(screen,elem,"value");
    let toValue = exprVal(screen,elem,"toValue");
    screen.setFilter(fieldName,{value,op:compare,toValue});
}

async function trigger(screen:ScreenRef,elem:any){
    let name = actionName(screen,elem,"name");
    let statements = elem.statements;
    let value;
    let currentOptions = screen.eventOptions;
    if (elem.value){
        value = exprVal(screen,elem,"value");
    }
    else {
        value = currentOptions.value;
    }
    return screen.triggerAction(name,statements,{noUpdate:true,value,scope:currentOptions.scope,targetPos:currentOptions.targetPos});
}

function activateTab(screen:ScreenRef,elem:any){
    screen.activateTab(safeName(elem.name));
}

function addRow(screen:ScreenRef,elem:any){
    screen.addRow(elem.name,{position:elem.mode});
}

function close(screen:ScreenRef,elem:any){
    let stop = elem.stop;
    let shouldContinue = !stop;
    let value = exprVal(screen,elem,"returnValue");
    let label = exprVal(screen,elem,"returnLabel");
    screen.close({continue:shouldContinue,value,label});
}

async function callBatch(screen:ScreenRef,elem:any){
    let callMethod = elem.name;
    let parameters = elem.params;
    let paramValues = screen.canvas.createScriptCallParameters(parameters,screen.eventOptions);
    paramValues["@event-value"] = screen.eventOptions.value;
    paramValues["@return-value"] = screen.eventOptions.returnValue;
    let projections = {};
    let results = Schema.getRecordSchema(screen.canvas,"@results");
    if (results){
        paramValues["@results:projection"]  = results.projection;
    }
    await Request.callBatch(screen,"batch",callMethod,paramValues);
}


function disable(screen:ScreenRef,elem:any){
    screen.disable(safeName(elem.name),exprVal(screen,elem,"help"));
}

function enable(screen:ScreenRef,elem:any){
    screen.enable(safeName(elem.name));
}   

function setOptions(screen:ScreenRef,elem:any){
    screen.setOptions(elem.name,elem.options);
}

function setError(screen:ScreenRef,elem:any){
    let message = exprVal(screen,elem,"message");
    screen.error(elem.field,message);
}

function focus(screen:ScreenRef,elem:any){
    screen.focus(elem.name);
}

function setFormat(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.format(elem.name,value);
}

function setHelp(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.setHelp(elem.name,{text:value});
}

function hide(screen:ScreenRef,elem:any){
    screen.hide(safeName(elem.name));
}   

function setLabel(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.setLabel(elem.name,{text:value});
}

function setLookup(screen:ScreenRef,elem:any){
    screen.setLookup(elem.field,{name:elem.name,params:elem.params});
}
async function message(screen:ScreenRef,elem:any):Promise<any>{
    let text = exprVal(screen,elem,"text");
    let asDialog = elem.asDialog;
    let style = elem.style || "success";
    text = StyledText.format(text);
    await screen.message(text,{style,asDialog});
}

function optional(screen:ScreenRef,elem:any){
    screen.optional(elem.name);
}

function required(screen:ScreenRef,elem:any){
    screen.manditory(elem.name);
}


function hasUnsavedChanges(screen:ScreenRef,elem:any){
    screen.canvas.hasUnsavedChanges = elem.value;
}

async function runOnPage(screen:ScreenRef,elem:any){
    let page = elem.page;
    let statements = elem.statements;
    let targetScreen = screen.canvas.app.findScreen(page);
    let targetScreenRef = new ScreenRef(targetScreen.canvas,{});
    await executeCommands(targetScreenRef,statements);
    targetScreen.canvas.update();
}

function startDesigner(screen:ScreenRef,elem:any){
    let page = elem.page;
    screen.canvas.app.codeDesignerEnabled = true;
    screen.canvas.app.designerTargetPage = page;
    screen.canvas.app.codeDesignBroadcast.refresh();
}
async function openPage(screen:ScreenRef,elem:any){
   
    let params:any = {};
    
    if (elem.params){
        if (elem.$computed){
            params = {};
            for(let i = 0; i < elem.params.length;i++){
                let p = elem.params[i];
                params[p.name] = p.value;
            }
        }
        else { 
            let parameters:IScreenParameter[] = [];
            for(let i = 0; i < elem.params.length;i++){
                let child = elem.params[i];
                if (child.$kind == KIND.PASS){
                    parameters.push({name:child.name,value:child.value});
                }
            }
            params = screen.canvas.createLaunchParameters(parameters,screen.eventOptions);
        }
    }

    let pageName:string = exprVal(screen,elem,"name"); // elem.name;
    let layout = elem.layout;
    let view = elem.view;

    let page:IPageLaunchParams = {
        name:pageName,
        layout,
        view,
        props:params
    }
    let content = <PageContainer page={page} />

    let onRecordChanged:ActionRef;
    if (elem.onRecordChanged){
        onRecordChanged = screen.action("ON_RECORD_CHANGED",elem.onRecordChanged);
    }

    if (elem.mode == "dialog"){
        let result = await screen.openDialog(content,{onRecordChanged});
        if (result.continue){
            screen.eventOptions.returnValue = result.value;
        }
        else {
            throw "stopped";
        }
        return;
    }
    if (screen.canvas.layer == "bot-message"){
        let question = "show " + pageName;
        for(let key in params){
            question += " " + key + ":" + params[key];
        }
        screen.canvas.botInfo.botState.addQuestion(question,null);
        return;
    }
    if (elem.mode == "sidebar"){
        screen.openSidebar(content,{onRecordChanged});
        return;
    }
    let withBrowse = elem.withBrowse;
    let browseLabel = exprVal(screen,elem,"browseLabel");
    if (withBrowse && !browseLabel){
        browseLabel = screen.canvas.title;
    }
    if (browseLabel){
        screen.browse(content,{onRecordChanged,label:browseLabel});
    }
    else {
        let asPopup = (elem.mode == "popup");
        if (asPopup){
            let result = await screen.openOnStack(content,{onRecordChanged,asPopup});
            if (result.continue){
                screen.eventOptions.returnValue = result.value;
            }
            else {
                throw "stopped";
            }
            return;
        }
        let asReplace = (elem.mode == "replace");
    
        screen.openOnStack(content,{asReplace,onRecordChanged,asPopup,position:elem.position});

        
    }
    // todo: navigate
}


function setPlaceholder(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.setPlaceholder(elem.name,{text:value});
}


function setReturnValue(screen:ScreenRef,elem:any){
    screen.returnValue = elem.value;
}

function recordChanged(screen:ScreenRef,elem:any){
    let recordChangedEvent:IRecordChangedEvent = {
        action:elem.mode,
        rowKey:exprVal(screen,elem,"rowKey"),
        table:elem.table
    }
    screen.recordChanged(recordChangedEvent);
}


function removeRow(screen:ScreenRef,elem:any){
    let rowid = exprVal(screen,elem,"rowid");
    screen.removeRow(elem.name,{rowid,mode:elem.mode});
}

function stop(screen:ScreenRef,elem:any){
    throw "stopped";
}

function outputPage(screen:ScreenRef,elem:any){
    let canvas = screen.canvas;
    if (elem.cache){
        canvas.cacheStrategy = elem.cache;
    }
    else if (canvas.layer != "dialog"){
        canvas.cacheStrategy = "optimistic";
    }
    canvas.displayContent =  elem.content || elem.children;
    screen.onEnterKey = elem.onEnterKey;
    
    canvas.setRecord("@$today",elem["@$today"]);
}

function draw(screen:ScreenRef,elem:any){
    let canvas = screen.canvas;
    if(!canvas.displayContent){
        canvas.displayContent = elem.children;
    }
    else {
        canvas.displayContent = canvas.displayContent.concat(elem.children);
    }
}

function cacheStrategy(screen:ScreenRef,elem:any){
    let canvas = screen.canvas;
    if (elem.value){
        canvas.cacheStrategy = elem.value;
    }
}

function trackUnsavedChanges(screen:ScreenRef,elem:any){
    let canvas = screen.canvas;
    canvas.trackUnsavedChanges = elem.value;
}

function layout(screen:ScreenRef,elem:any){
    let canvas = screen.canvas;
    if (!canvas.title){
        canvas.title = exprVal(screen,elem,"title");
        canvas.subtitle = exprVal(screen,elem,"subtitle");
        canvas.icon = exprVal(screen,elem,"icon")
    }
    canvas.width = elem.width;
    canvas.height = elem.height;
    canvas.pageType = elem.pageType;
    canvas.scrollable = elem.scrollable;
    canvas.showClose = elem.showClose;
    if (elem.onEnterKey !== undefined){
        screen.onEnterKey = elem.onEnterKey;
    }
    canvas.styles = exprVal(screen,elem,elem.styles);
    if (elem.cache){
        canvas.cacheStrategy = elem.cache;
    }
    else if (canvas.layer != "dialog"){
        canvas.cacheStrategy = "optimistic";
    }
    canvas.displayContent =  [{$kind:"UI.Page",children:elem.children,$id:elem.$id,$sec:elem.$sec}];
    canvas.setRecord("@$today",elem["@$today"]);
}


function setEnterKey(screen:ScreenRef,elem:any){
    let action = elem.action;
    screen.onEnterKey = action;
}

function unhide(screen:ScreenRef,elem:any){
    screen.unhide(elem.name);
}

function setLookupLabel(screen:ScreenRef,elem:any){
    let value = exprVal(screen,elem,"value");
    screen.setLookupLabel(elem.name,value);
}

async function showWarning(screen:ScreenRef,elem:any):Promise<any>{
    let message = StyledText.format(exprVal(screen,elem,"message"));
    let style = elem.style;
    let component = elem.component;
    await screen.showWarning({message,style,block:component});
}

function locate(screen:ScreenRef,elem:any){
    let rowid = exprVal(screen,elem,"rowid");
    if (!rowid){
        let collectionRef = screen.canvas.getCollectionRef(elem.name,screen.eventOptions.scope);
        if (collectionRef){
            let record = screen.canvas.getRecord(elem.name,screen.eventOptions.scope);
            if (record && !Array.isArray(record)){
                collectionRef.currentRow = record;
            }
        }
        return;
    }
    screen.locate(elem.name,rowid);
}

function navigateUrl(screen:ScreenRef,elem:any){
    var url = exprVal(screen,elem,"url");
    var newWindow = elem.newWindow;
    if (newWindow){
        window.open(url);
    }
    else {
        window.location.assign(url);
    }
}


async function restart(screen:ScreenRef,elem:any){
    let canvas = screen.canvas;
    if (!elem.noDelay && screen.eventOptions.isRecordChanged){      
        canvas.pendingRestart = true;
        return;
    }
    await screen.restart();
}

function pageSettings(screen:ScreenRef,elem:any){
    let title = exprVal(screen,elem,"value");
    screen.canvas.title = title;
    screen.canvas.stackPosition = elem.stackPosition;
}

function redrawContent(screen:ScreenRef){
    screen.canvas.hardRefreshKey++;
}

function resetRecord(screen:ScreenRef,elem:any){
    screen.setRecord(elem.name,{},elem.forEdit);
}

function resetCollection(screen:ScreenRef,elem:any){
    screen.setCollection(elem.name,[],elem.forEdit,elem.meta);
}

function clearErrors(screen:ScreenRef,elem:any){
    screen.clearErrors(elem.name);
}

function setRecord(screen:ScreenRef,elem:any){
    screen.setRecord(elem.name,elem.data,elem.forEdit);
}

function setCollection(screen:ScreenRef,elem:any){
    screen.setCollection(elem.name,elem.data,elem.forEdit,elem.meta);
}

function patchCollection(screen:ScreenRef,elem:any){

    let collectionName:string = elem.name;
    let dataName = safeName(collectionName.substring(1));

    let data = screen.canvas.data;
    let schema = Schema.getRecordSchema(screen.canvas,collectionName);
    
    let currentRows:any[] = data[dataName];
    let readonly = !elem.forEdit;
    if (!currentRows){
        currentRows= [];
        data[dataName] = currentRows;
        
        Collection.bindSchema(schema,currentRows,readonly);
    }
    let newRows = elem.data;
    let keyField = elem.keyField || "Id";
    if (!newRows || !newRows.length){
        if (elem.replaceRowkey){
            let rowIndex = findRowIndex(currentRows,elem.replaceRowkey,keyField);
            if (rowIndex != -1){
                currentRows.splice(rowIndex,1);
            }
        }
    }
    else {
        for(let i = 0; i < newRows.length;i++){
            let row = newRows[i];
            if (row){
                let rowIndex = findRowIndex(currentRows,row[keyField],keyField);
                if (rowIndex == -1){
                    if (elem.action == "update-append"){
                        currentRows.push(row);
                    }
                    else {
                        currentRows.unshift(row);
                    }
                }
                else {
                    currentRows.splice(rowIndex,1,row);
                }
            }
        }
    }
    Collection.bindSchema(schema,currentRows,readonly);
    Collection.incrementVersion(currentRows);
   
}




function loadDropValues(screen:ScreenRef,elem:any){
    let name = elem.name;
    let options = elem.data || [];
    
    let isConfigure = false;
    if (isConfigure){
        let fieldDef = Schema.getQualifiedFieldDef(screen.canvas,name);
        if (fieldDef){
            fieldDef.options = options;
        }
        return;
    }
    screen.setOptions(name,options);
}

function openWorkspace(screen:ScreenRef,elem:any){
    let name = elem.name;
    let app = screen.canvas.app;
    if (elem.page){
        let page:IPageLaunchParams = {name:elem.page,props:elem.parameters}; 
        app.openWorkspace(name,{page});
    }
    else {
        app.openWorkspace(name);
    }
    
}

function setSession(screen:ScreenRef,elem:any){
    let app = screen.canvas.app;
    app.session.firstName = elem.firstName;
    app.session.lastName = elem.lastName;
}

function download(screen:ScreenRef,elem:any){
    let autoOpen = elem.autoOpen;
    let content = elem.content;
    let filename =elem.filename;
    FileDownloadManager.downloadFile(filename,content,autoOpen);
}

function addIcon(screen:ScreenRef,elem:any){
    screen.canvas.icons[elem.name] = elem;
}

function designMode(screen:ScreenRef,elem:any){
    
    let app = screen.canvas.app;

    let action = elem.action;
    if (action == "open"){
        let toolbox = new DesignerToolbox();
        screen.canvas.designerOpen = true;
        screen.canvas.useDragState();
        toolbox.canvas = screen.canvas;
        toolbox.content = elem.toolbox;
        screen.canvas.designerToolbox = toolbox;
        screen.canvas.studioCommandBuffer = new StudioCommandBuffer(screen.canvas);
        app.studioBroadcast.refresh();
    }
    else {
        let toolbox = screen.canvas.designerToolbox;
        if (toolbox){
            toolbox.run(elem);
        }
    }
}

function viewDraft(screen:ScreenRef,elem:any){
    let draft_id = elem.draft_id;
    let page = elem.page.toLowerCase();
    let canvas = screen.canvas;
    canvas.app.currentDraftView[page] = draft_id;
    restartPages(canvas.app,page);
}

function slotContent(screen:ScreenRef,elem:any){
    screen.canvas.slotContent[elem.name] = elem.value;
}

function designFocus(screen:ScreenRef,elem:any){
    if (elem.name){
        screen.canvas.designFocus = elem.name;
    }
}

function title(screen:ScreenRef,elem:any){
    let canvas = screen.canvas;
    canvas.title = exprVal(screen,elem,"value");
    canvas.subtitle = exprVal(screen,elem,"subtitle");
    canvas.icon = exprVal(screen,elem,"icon")
}

function clearSelection(screen:ScreenRef,elem:any){
    var collection:any[] = screen.canvas.getRecord(elem.name,null);
    if (!collection) return null;
    for(let i = 0; i < collection.length;i++){
        let row = collection[i];
        if (Collection.isRowSelected(row)){
            Collection.setRowSelected(row,false);
        }
    }
}

function botDialog(screen:ScreenRef,elem:any){
    screen.canvas.displayContent = {content:[
        {$kind:"UI.Bot.Dialog",message:elem.message,options:elem.options}
    ]}
}

function botPrompt(screen:ScreenRef,elem:any){
    screen.canvas.displayContent= {content:[
        {$kind:"UI.Bot.Dialog",message:elem.message}
    ]}
    screen.canvas.botInfo.botState.setPromptValue(screen.canvas.botInfo.command + " ");
}

function showSidebar(screen:ScreenRef,elem:any){
    screen.canvas.sidebarState.isOpen = true;
}

function hideSidebar(screen:ScreenRef,elem:any){
    screen.canvas.sidebarState.isOpen = false;
}

function safeName(value:string):string {
    return value; // value.replace(/\-/g,"_");
}

function exprVal(screen:ScreenRef,elem:any,propName:string):any {
    let value = elem[propName];
    if (elem.$computed) return value;
    if (value){
        if (value[0] == '"'){
            return JSON.parse(value);
        }
    }
    return screen.getValue(value);
}

function numVal(screen:ScreenRef,elem:any,propName:string):number {
    let value = elem[propName];
    return value;
}

function boolVal(screen:ScreenRef,elem:any,propName:string):boolean {
    let value = elem[propName];
    return (value) ? true : false;
}


function actionName(screen:ScreenRef,elem,propName:string):string {
    let value:string = elem[propName];
    return safeName(value);
}



function findRowIndex(rows:any[],id:any,keyField:string):number {
    if (!rows) return -1;
    for(let i =0 ; i < rows.length;i++){
        let row = rows[i];
        if (row[keyField]== id) return i;
    }
    return -1;
}

async function ifBlock(screen:ScreenRef,elem:any):Promise<any>{
    await IfBlockRunner.runIfBlock(screen,elem);
}

function restartOpenPages(screen:ScreenRef,elem){
    let pageName = elem.name.toLowerCase();
    let app = screen.canvas.app;
    setTimeout(()=> {
        restartPages(app,pageName);
    },50);
}

function restartPages(app:Application,pageName:string){
    for(let i = 0; i < app.activeScreens.length;i++){
        let activeScreen = app.activeScreens[i];
        let canvas = activeScreen.canvas;
        if (canvas.pageId && canvas.pageId.toLowerCase() == pageName){
            canvas.restart({triggerSearch:true});
        }
    }
}

export function processErrors(screen:ScreenRef,commands:any[],errorRender:any):ICanvasError{
    let showDialog:boolean;
    if (!commands) return null;
    let validationErrors:IValidationError[] = [];
    let canvas = screen.canvas;
    for(let i = 0; i < commands.length;i++){
        let command = commands[i];
        if (command.$kind == "Error"){
            let fieldName = command.field;
            if (!fieldName || !command.noDialog){
                showDialog = true;
            }
            if (fieldName){
                let fieldDef = Schema.getQualifiedFieldDef(canvas,fieldName);
                let label:string;
                if (fieldDef){
                    label = fieldDef.label || fieldName;
                }
                else {
                    label = fieldName;
                }
                validationErrors.push({field:fieldName,label,message:command.message,type:command.type});
            }
            else {
                validationErrors.push({message:command.message,type:command.type});
            }
        }
    }
    if (!validationErrors.length && !errorRender) return null;

    return {
        showDialog,
        messages:['Error'],
        validationErrors,
        $$error:true,
        render:errorRender
    }
}