import { Icon } from 'components/Icon';
import React from 'react';
import {  makeComponent } from '../../core';
import { ClickThrottle } from '../helpers/ClickThrottle';
import { DisplayProps } from '../PutField';
import { LongCharText } from './LongCharText';


export const ReadonlyField = makeComponent(class extends React.Component<DisplayProps>{

    
    render(){

        let field = this.props.field;
        let value;
        if (field){
            value = field.formattedValue;
        }

        let content;
       
        let contentClasses = ['RT-Static-Field__content'];
       
    
        if (this.props.styles){
            contentClasses.push(this.props.styles.className);
        }
        

        let textAlignClass:string;
        if (this.props.textAlign){
            textAlignClass = "text-align-" + this.props.textAlign;
        }

        let valueElem;
        if (field.lookup) {
            let lookupLabel = field.lookupLabel;
            valueElem = (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>{value}</div>
                    <div style={{ marginLeft: 8 }}>
                        {lookupLabel}
                    </div>
                </div>
            );
        } 
        else if (field.type == "logical"){
            if (field.value){
                valueElem = <div style={{display:"flex",alignItems:"center"}}>
                    {this.renderStaticCheckmark()}
                    <div>Yes</div>
                </div>
            }
            else {
                valueElem = "No";
            }
    
        }
        else if (field.type == "longchar"){
            valueElem = (<div><LongCharText text={value}/></div>);
        }
        else {
            valueElem = value;
        }
        if (this.props.onClick || field.onClickAction) {
            content = (
                <a
                    style={{ display: "block" }}
                    href="#"
                    onClick={this.handleHyperlinkClick}
                >
                    {valueElem}
                </a>
            );
        } else {
            content = valueElem;
        }
        let labelElem;
        let label = this.props.label;
        if (label && !this.props.hideLabel) {
            let labelContent:any;
            if (label && label.indexOf('|') != -1){
                labelContent = this.splitLabel(label);
            }
            else {
                labelContent = label;
            }
            let labelClassName = ["RT-Static-Field__label RT--readonly"];
            if (this.props.labelStyles){
                labelClassName.push(this.props.labelStyles.className);
            }
            labelElem = (
                <label style={this.props.labelStyles.style} className={labelClassName.join(' ')}>
                    {labelContent}
                </label>
            );
        }

        let contentStyle = {...this.props.styles.style};
        contentStyle.gap = 5;
        contentStyle.display = "flex";
        contentStyle.alignItems = "center";
       
        let icon;
        if (this.props.icon){
            icon = this.props.icon;
        }
        else if (this.props.styles.icon){
            icon = <Icon icon={this.props.styles.icon.name} className={"RT-Static-Field__icon " + this.props.styles.icon.className} />
        }
        if (!content && content !== 0){
            content = '\u00A0';
        }
        else if (this.props.suffix || icon){
            let suffix;
            if (this.props.suffix){
                suffix =   <div style={{opacity:0.55}}>{this.props.suffix}</div>
            }
            content = <>
                {icon}
                {content}
                {suffix}
            </>
        }
        let outerClass = "RT-Static-Field";
        if (textAlignClass){
            outerClass += " " + textAlignClass;
        }
        let outerStyle:React.CSSProperties = {};
        let inner;

        if (this.props.labelPosition == "bullet"){
            outerStyle.display = "flex";
            outerStyle.alignItems = "center";
            outerStyle.gap = 4;
            inner = <>
                {labelElem}
                <div style={{color:"var(--rt-label-color)"}}>{'\u2022'}</div>
                <div style={contentStyle} className={contentClasses.join(' ')}>
                    {content}
                </div>
            </>
        }
        else if (this.props.labelPosition == "bottom"){
            inner = <>
                <div style={contentStyle} className={contentClasses.join(' ')}>
                    {content}
                </div>
                {labelElem}
            </>
        }
        else {
            inner = <>
                {labelElem}
                <div style={contentStyle} className={contentClasses.join(' ')}>
                    {content}
                </div>
            </>
        }
        

        return (
            <div className={outerClass} style={outerStyle} data-elem-id={this.props.$id}>
                {inner}
            </div>
        );
    }

    clickThrottle = new ClickThrottle();

    handleHyperlinkClick = (e: React.MouseEvent) => {
        this.clickThrottle.handle(e,null,()=> {
            e.preventDefault();
            e.stopPropagation();
            if (this.props.onClick) {
                this.props.onClick.trigger();
            }
            else {
                this.props.field.fireOnClick();
            }
        });
    };

    renderStaticCheckmark(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{display:"block",width:20,height:20,fill:"var(--rt-primary-color)"}}><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>);
    }

    splitLabel(label:string){
        let segments = label.split('|');
        let elems = [];
        for(let i = 0; i < segments.length;i++){
            if (i > 0){
                elems.push(<br/>);
            }
            elems.push(segments[i]);
        }
        return elems;
    }
});