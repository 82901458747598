
import React from 'react';
import {makeComponent, RenderEvent} from '../core';
import { ClickThrottle } from './helpers/ClickThrottle';
import { StackLayerContainer } from './helpers/StackLayerContainer';
import { Icon } from './Icon';
import { DialogContainer } from './popups/DialogContainer';
import {DialogIcons} from './popups/DIalogIcons';


export const Dialog = makeComponent(class extends React.Component<{event?:RenderEvent,$id?:string;padding?:string,icon?:string,
        dialogSize?:string,dialogHeight?:string,title?:any;style?:string,footer?:any,actions?:any;
        sidebar?:any,header?:any,navbar?:any,toolbox?:any}>{


    render(){
        
        let canvas = this.props.event.canvas;
        let zIndex = canvas.layerZIndex;

        let animate = false;
        if (!canvas.animationComplete){
            animate = true;
            canvas.animationComplete = true;
        }
        let size = this.props.dialogSize || "small";
        let height = this.props.dialogHeight;
        let event = this.props.event;


        let stackContent;
        let coveredTitle;
        if (canvas.stackChild) {
            stackContent =  canvas.stackChild();
            coveredTitle =  <div style={{fontSize:"14px",fontWeight:600,color:"var(--rt-primary-color)"}}>{canvas.title}</div>
        }

        let title = this.props.title; //  || event.canvas.title;

        let header = <Dialog.Header event={event} label={title} icon={this.props.icon}>{this.props.actions}</Dialog.Header>
        let body = this.props.children;
        let footer = this.props.footer;
       
        let isLookup = (canvas.layer == "lookup");
        if (size == "full" || size == "designer"){
            let addOnClass = (size == "designer") ? "RT-Modal--designer" : null;
            body = <StackLayerContainer id={this.props.$id} canvas={canvas} body={body} 
            stackContent={stackContent} converedTitle={coveredTitle} navbar={this.props.navbar} />
            return this.renderFullScreenDialog(zIndex,animate,header,body,footer,isLookup,addOnClass);
        }
        let className = "RT-Action-Dialog__body";
        if (this.props.padding){
            className += " RT-Action-Dialog--padding-" + this.props.padding;
        }
        else {
            className += " RT-Action-Dialog--padding-none";
        }
       
        if (this.props.footer && this.props.footer.props){
            let footerProps = this.props.footer.props;
            footer = <Dialog.Footer event={this.props.event} $id={footerProps.$id}>{footerProps.children}</Dialog.Footer>
        }
        else {
            footer = <Dialog.Footer event={this.props.event}  />
        }
    
        if (canvas.layer == "bot-message"){
            return (<div>
                {header}
                <div className = {className}>
                    {body}
                </div>
                {footer}
            </div>)
        }
        let sidebar = this.props.sidebar;
        let bodyHeader;
        if (this.props.header){
            bodyHeader = this.props.header;
        }
        let scrollable = canvas.scrollable;
        let bodyClass = "px-30 py-15";
        let footerClass = "px-30 pb-15";
        let headerClass = "px-30";
        body = <StackLayerContainer id={this.props.$id} canvas={canvas} body={body} scrollable={scrollable} footer={this.props.footer} bodyClass={bodyClass}
            stackContent={stackContent} converedTitle={coveredTitle}  sidebar={sidebar} header={bodyHeader} headerClass={headerClass} navbar={this.props.navbar} 
            footerClass={footerClass}/>

        if (canvas.layer == "dialog-stack"){
            return (<div style={{height:"100%",overflow:"hidden",display:"flex",flexDirection:"column"}} data-context-menu={canvas.contextMenuId}>
                {header}
                <div className = {className}>
                    {body}
                </div>
                {footer}
            </div>)
        }
        let content;
        if (stackContent){
            content = <div style={{flexGrow:1,position:"relative"}}>
                {body}
            </div>
        }
        else {
            content = <>
                {header}
                <div className = {className}>
                    {body}
                </div>
            </>
        }
        if (canvas.layer == "popup"){
            return content;
        }
        return (<DialogContainer size={size} contextMenuId={canvas.contextMenuId} zIndex={zIndex} animate={animate} height={height} isLookup={isLookup} $id={this.props.$id} >
            {content}
        </DialogContainer>);
    }

    renderFullScreenDialog(zIndex:number,animate:boolean,header:any,body:any,footer:any,isLookup:boolean,addOnClassName:string){
        let maxWidth = 1400;
        let className = "RT--full-screen";
        if (addOnClassName){
            className += " " + addOnClassName;
        }
        return (<DialogContainer size="full-screen" zIndex={zIndex} animate={animate} height="full-screen" className={addOnClassName} isLookup={isLookup}>
            <div  style={{backgroundColor:"#fff",boxShadow:"0 3px 3px #f2f3f4",position:"relative",zIndex:1,
                paddingTop:5,paddingBottom:5}}>
                <div style={{marginLeft:"auto",marginRight:"auto",maxWidth,display:'flex',alignItems:"center",paddingLeft:25,paddingRight:25}}>
                    <div className={className}>{header}</div>
                    <div className={className} style={{marginLeft:"auto"}}>{footer}</div>
                </div>
            </div>
            <div className="rt-scrollbars" style={{height:"100%",overflow:"auto",display:"flex",flexDirection:"column",backgroundColor:"#f3f4f5",
                padding:"30px 10px"}}>
                <div style={{marginLeft:"auto",marginRight:"auto",maxWidth,width:"100%"}}>
                    {body}
                </div>
            </div>
            {/*
            <div style={{backgroundColor:"#f8f9fa",paddingBottom:15}}>
                <div className={className} style={{marginLeft:'auto',marginRight:'auto',maxWidth}}>
                    {footer}
                </div>
            </div>
    */}
        </DialogContainer>);
    }

    
    static Header = makeComponent(class extends React.Component<{event:RenderEvent;label?:string,style?:'success' | null,icon?:string}>{
        render(){
            let label = this.props.label;
            let icon;
            if (this.props.icon){
                icon = (<div style={{marginRight:10}}>
                    <Icon icon={this.props.icon} fill="var(--rt-primary-color)" size={26}/> 
                </div>)
            }
            else if (this.props.style == "success"){
                icon = (<div style={{marginRight:10}}>
                    {DialogIcons.renderSuccessIcon({fill:"#3fc5d9",width:26,height:26})}
                </div>)
            }
            let actions;
            if (this.props.children){
                actions = <div style={{marginLeft:'auto',display:'flex',gap:15}}>{this.props.children}</div>
            }
            if (!label && !icon && !actions){
                return <div className="RT-Action-Dialog__header" style={{padding:0,height:6,backgroundColor:"#f4f6f8",boxShadow:"none"}}/>
            }
            let style:React.CSSProperties;
            let canvas = this.props.event.canvas;
            if (canvas.layer == "dialog-stack"){
                style = {padding:"10px 25px",borderTopLeftRadius:0,borderTopRightRadius:0};
            }
            let close;
            if ((canvas.layer == "dialog" && canvas.showClose) || canvas.layer == "popup"){
                close = <div onClick={this.handleClickClose} className="hover-opacity-20" style={{position:"absolute",right:15,top:0,bottom:0,display:'flex',alignItems:'center',cursor:"pointer"}}>
                    <Icon icon="close" size={26} />
                </div>
            }
            
            return (<div className="RT-Action-Dialog__header" style={style} onClick={this.handleClick}>
                <div style={{display:"flex",alignItems:"center",gap:15}}>
                    <div className="RT-Action-Dialog__title">
                        {icon}
                        <div>{label}</div>
                    </div>
                    {actions}
                </div>
                {close}
            </div>);
        }

        handleClick = (e:React.MouseEvent) => {
          
            if (e.ctrlKey){
                e.preventDefault();
                e.stopPropagation();
                let canvas = this.props.event.canvas;
                canvas.viewSource();

            }
        }

        handleClickClose = (e:React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            this.props.event.canvas.closeDialogBox({continue:false});
        }
        
    });

    static Footer = makeComponent(class extends React.Component<{event?:RenderEvent;$id:string}>{

        clickThrottle = new ClickThrottle();
    
        render(){
            
            return (<div className="RT-Action-Dialog__footer" data-elem-id={this.props.$id}>
                {this.props.children}
    
            </div>)
        }
    
        handleCancel = (e:React.MouseEvent) => {
            this.clickThrottle.handle(e,null,()=> {
                e.preventDefault();
                e.stopPropagation();
                if (!this.props.event) return;
                let canvas = this.props.event.canvas;
                if (canvas.closeDialogBox){
                    canvas.closeDialogBox({continue:false});
                }
            });
        }
    });
});