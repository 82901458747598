import { makeComponent, RenderEvent } from '../core';
import React from 'react';
import { INavbarProps, StackLayerContainer } from './helpers/StackLayerContainer';
import { Dialog } from './Dialog';
import { BrowseNavigator } from './BrowseNavigator';
import { DialogContainer } from './popups/DialogContainer';
import { SectionEditor } from './SectionEditor';


export const Page = makeComponent( class extends React.Component<{event:RenderEvent;$id?:string,padding?:string,
   header?:any,navbar?:any,sidebar:any,actions?:any,footer?:any,title?:any,toolbox?:any,$elem?:any}>{
   

    render(){
       
        let canvas = this.props.event.canvas;
        let contextMenuId = canvas.contextMenuId;
    
        let layer = canvas.layer;

     

        if (layer == "popup" && !this.props.title && !this.props.header){
            return this.props.children || null;
        }
       

        let title;
        if (this.props.title){
            title = this.props.title;
        }
        else if (this.props.header && this.props.header.props && this.props.header.props.title){
            title = this.props.header.props.title;
        }

       
        
        if (layer == "dialog" || layer == "lookup" || layer == "dialog-stack" || layer == "popup"){

            return <Dialog event={this.props.event} dialogSize={canvas.width} dialogHeight={canvas.height} header={this.props.header} 
                footer={this.props.footer}
                actions={this.props.actions}
                sidebar={this.props.sidebar}
                title={title}
                children={this.props.children}
                navbar={this.props.navbar}
                toolbox={this.props.toolbox}
                
            />
        }
        
        if (layer == "workspace" || layer == "layout"){
            return this.props.children || null;
        }
        
        let scrollable = canvas.scrollable;
        let noPadding = canvas.noPadding;

        let asWidget = canvas.asWidget;

        if (layer == "embedded" && asWidget){
            title = null;
            scrollable = false;
            noPadding = true;
        }

        let actionElem;
        let actions = this.props.actions;
        if (actions && actions.props && actions.props.children){
            actionElem = <PageActions event={this.props.event} children={actions.props.children}/>
        }   

        let bodyClass:string = "";
        let headerClass:string = "";
        let footerClass:string = "";

        if (!noPadding){
            bodyClass = "px-30 pb-30";
            headerClass = "px-30";
            footerClass = "px-30";
        }
        let pageType= canvas.stackStyle;
        
        if (canvas.pageType == "help"){
            bodyClass += " bg-white";
            headerClass += " bg-white";
        }

        let headerFieldsElem = this.props.header;

        let headerContent;
        let navbarElem;
        let navbarProps:INavbarProps;

        if (this.props.navbar){
            navbarProps = this.props.navbar.props;
            navbarElem = <>
                {title}
                {this.props.navbar}
            </>
            if (headerFieldsElem || actionElem){
                headerContent = <PageBanner event={this.props.event}  headerFields={headerFieldsElem} actions={actionElem} noSpaceAbove/>          
            }
        }
        else {     
            if (headerFieldsElem || title || actionElem){
                headerContent = <PageBanner event={this.props.event} title={title} actions={actionElem} headerFields={headerFieldsElem}/>
            }
            else if (!asWidget) {
                headerContent = actionElem;
                headerClass = "p-0";
            }

        }
       
        
        let stackContent;
        let coveredTitle;
        if (canvas.stackChild) {
            stackContent =  canvas.stackChild();
            coveredTitle =  <div style={{fontSize:"16px",fontWeight:600,color:"var(--rt-primary-color)"}}>{canvas.title}</div>
        }
       
        let body = this.props.children;
    
        let styles = this.props.event.computeStyles(canvas.styles);
        let containerClass = styles.className;
        headerClass += " " + styles.className;
        bodyClass += "  " + styles.className;

       
        let sidebarElem = this.props.sidebar;

        let navigator;
        if (canvas.recordBrowse){
            navigator = <BrowseNavigator canvas={canvas}/>
        }
        
        
        let container = (
            <StackLayerContainer id={this.props.$id}  body={body} header={headerContent} 
            scrollable={scrollable}
            canvas={canvas}
            headerClass={headerClass}
            containerClass={containerClass}
            bodyClass={bodyClass}
            footer = {this.props.footer}
            footerClass = {footerClass}
            navigator={navigator}
            converedTitle={coveredTitle} sidebar={sidebarElem} 
            navbar={navbarElem} stackContent={stackContent}
            navbarProps={navbarProps}
            contextMenuId={contextMenuId}
            pageType={pageType}
            />
        );
        if (asWidget){
            return <div style={{width:"100%"}}>{container}</div>
        }
       
        return container;
    }

 
});




class PageBanner extends React.Component<{event:RenderEvent,title?:any,actions?:any,headerFields:any,noSpaceAbove?:boolean}>{
   
    render(){
        
        
        let actions;
        let fields;
        let paddingBottom;
        
        let layer = this.props.event.canvas.layer;
        let marginTop = this.props.noSpaceAbove ? 0 : 20;
        if (this.props.actions){
            if (layer == "sidebar"){
                actions = <div style={{marginTop}}>{this.props.actions}</div>
            }
            else {
                actions =  (<div style={{marginLeft:"auto",paddingLeft:30}}>
                    {this.props.actions}
                </div>);
            }
        }

        if (this.props.headerFields){

            fields = (<div style={{marginTop}}>
                {this.props.headerFields}
            </div>)
                paddingBottom = 10;
        }
        else {
            paddingBottom = 20;
        }  
        let contentRow;
        if (layer == "sidebar"){
            contentRow = <>
                {this.props.title}
                {actions}
            </>
        }
        else {
            let marginLeft = (layer == "workspace-tab") ? 5 : 0;
            contentRow = (<div style={{display:"flex",alignItems:'center',marginLeft}}>
                {this.props.title}
                {actions}
            </div>);
        }
        let className = "RT-Record-Header RT-Record-Header--" + layer;
        return(<div className={className} style={{ position: "relative",paddingBottom}} onClick={this.handleClick}>
            {contentRow}
            {fields}
        </div>);
    }

    handleClick = (e:React.MouseEvent) => {
            
        if (e.ctrlKey){
            e.preventDefault();
            e.stopPropagation();
            let canvas = this.props.event.canvas;
            canvas.viewSource();

        }
    }
}

class PageActions extends React.Component<{event?:RenderEvent}>{
    render(){
        return <div style={{display:"flex",alignItems:'flex-end',gap:20}}>{this.props.children}</div>
    }
}


