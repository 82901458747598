import { Icon } from 'components/Icon';
import { CollectionRef } from 'core/CollectionRef';
import { DateFormatter, NumberFormatter } from 'core/FieldFormatter';
import React from 'react';

interface IItem {
    expected_date:string;
    qty:number;
}

export class FutureStock extends React.Component<{available_qty:number,items:CollectionRef,theme:any}>{
    render(){
        let availQty = this.props.available_qty;
        let collection = this.props.items;
        let items:IItem[];
        if (collection){
            items = collection.rows;
        }
       
        let theme = this.props.theme;
        if (!theme){
            theme = {circleCheckColor:"rgb(7,136,0)"};
        }
        let elems:any[] = [];
        let showFirstAvail = true;
        if (availQty > 0){
            // showFirstAvail = false;
        }
        if (items){
            for(let i =0 ; i < items.length;i++){
                let line = items[i];
                availQty = availQty + line.qty;
                let icon;
                if (availQty > 0 && showFirstAvail){
                    icon = <Icon icon="circleCheck" size={18} fill={theme.circleCheckColor} />;
                    //showFirstAvail = false;
                }
                elems.push( <div key={i} style={{ display: "flex", alignItems: "center", fontSize: "15px", gap: 5 }}>
                    <div style={{ width: 50 }}>{DateFormatter.format(line.expected_date,"mmm d")}</div>
                    <div style={{ width: 70, textAlign: 'right' }}>+{NumberFormatter.formatNumber(line.qty)}</div>
                    <div style={{ marginLeft: "auto", width: 70, textAlign: 'right', fontWeight: 500, fontSize: "18px", opacity: 0.7 }}>{NumberFormatter.formatNumber(availQty)}</div>
                    <div style={{ width: 20 }}>{icon}</div>
                </div>);
            }
        }
        if (!elems.length){
            return <div style={{marginTop:20}}>No future stock</div>;
        }

        return (<>
            <div style={{
                fontSize: "11px", letterSpacing: "0.5px", marginTop: 20, fontWeight: 500, opacity: 0.6, textTransform: "uppercase",
                paddingBottom: 2, borderBottom: "solid 2px rgb(200,200,200)", display: "flex", alignItems: "flex-end"
            }}>
                <div>Future Stock</div>
                <div style={{ marginLeft: "auto", paddingRight: 20 }}>Available Qty</div>
            </div>
            {elems}

        </>);
    }
    
}