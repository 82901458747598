import React from 'react';
import {  Collection, CollectionRef,FieldFormatter,RenderEvent } from 'core';
import { EmptyState } from '../EmptyState';
import { MenuStore, ProductMenu } from './ProductMenu';
import { IProductRow, ProductTableStore } from './ProductTableStore';
import { Sidebar } from './Sidebar';
import { FilterItem } from './FilterItem';
import { FilterGroup } from './FilterGroup';
import { PivotList } from './PivotList';
import { SingleValueFilter } from './SingleValueFilter';
import { ProductRow } from './ProductRow';
import { ProductTitleBar } from './ProductTitleBar';

var imageBase = "/pages/";
var menuStore = new MenuStore();

export class ProductTable extends React.Component<{event:RenderEvent,collection:CollectionRef,sidebar?:any}>{

    loaded = false;
    store:ProductTableStore = new ProductTableStore();

    componentDidMount(){
        this.store.broadcast.connect(this);
        this.loadData();
        this.loaded = true;
        let canvas = this.props.event.canvas;
        this.store.canvas = canvas;
        canvas.connectDataListener(this,this.props.collection.name,()=> {
            let col = canvas.getCollectionRef(this.props.collection.name,null);
            this.store.formatData(col.rows);
        });
        this.forceUpdate();
    }

    componentWillUnmount(){
        this.store.broadcast.disconnect(this);
        let canvas = this.props.event.canvas;
        canvas.disconnectDataListener(this);
    }

    loadData(){
        let store = this.store;
        let canvas = this.props.event.canvas;
        store.addToCart = async (row:IProductRow,qty:number) => {
            let action = this.props.event.action("search");
            action.trigger();
        }
        store.load(this.props.event,this.props.collection);

        menuStore.menu = canvas.getRecord("@menu",null);
    
    }
    
    renderedVersion;

    render(){
        if (!this.loaded) return null;
       
        let store = this.store;
        let theme = store.theme;
        let collection = this.props.collection;
        /*
        let dataVersion = Collection.getVersion(collection.rows);
        if (dataVersion != this.renderedVersion){
            store.formatData(collection.rows);
            this.renderedVersion = dataVersion;
        }
        */
        let rows = store.rows;
        if (!rows) return null;
        let tableElems = [];
       
        let last:any = {};
        let tbody:any[];
        let colorValues:any[];
        let rowOptions:any;
        let shadeBody = false;
        let groupByFamily = store.groupByFamily;
        let colorSet:string[];
        let tables:any = [];
        let isEmpty:boolean;
        let tableBorderRadius = 0;
        if (!groupByFamily){
            if (!store.colors.length){
                tables.push(<EmptyState key="empty-state" icon="list" label={store.settings.emptyStateMessage}/>);
                isEmpty = true;
            }
            else {
                tableElems.push(this.renderTableHeader(store.colors));
                colorSet = store.colors;
                let table = (<div key="main-table">
                    <div>
                        <table style={{backgroundColor:"#fff",borderRadius:tableBorderRadius,overflow:"hidden"}}>
                            {tableElems}
                        </table>
                    </div>
                </div>);
                tables.push(table);
            }
        }
       
        let showAvailOnly = false;
        let familyFilter = store.family.value;
        let profileFilter = store.profile.value;

        for(let i =0; i < rows.length; i++){
            let row = rows[i];
            if (store.colors.indexOf(row.color) == -1) continue;
            if (familyFilter && row.family != familyFilter) continue;
            if (profileFilter && row.profile != profileFilter) continue;
            let profileContent;
            let sameProduct = true;
            let forceBreak:boolean;

            if (groupByFamily && row.family != last.family){
                forceBreak = true;
                shadeBody = false;
                colorSet = store.buildFamilyColors(row.family);
                tables.push();
                tableElems = [];
                //borderTop:"solid 3px " + theme.headerBackgroundColor,
                let table = (<div key={row.family} style={{
                    marginBottom:30}}>
                    <div style={{fontSize:"20px",fontWeight:500,color:'rgb(10,10,100)',marginBottom:15
                }}>{row.family_text}</div>
                    <div>
                        <table key={row.family} style={{backgroundColor:"#fff",borderRight:"solid 1px rgb(221,226,215)",borderRadius:tableBorderRadius,overflow:"hidden"}}>
                            {tableElems}
                        </table>
                    </div>
                </div>);
                tables.push(table);
                tableElems.push(this.renderTableHeader(colorSet));
            }
            if (forceBreak || row.profile != last.profile){
                tbody = [];
                let rowStyle:React.CSSProperties = {fontSize:theme.bodyFontSize,letterSpacing:"0.35px"};
                if (shadeBody){
                    rowStyle.backgroundColor = "rgb(248,249,250)";
                }
             
                tableElems.push(<tbody key={row.profile} style={rowStyle}>
                    <tr><td style={{height:15}} colSpan={99}></td></tr>
                    {tbody}
                    <tr><td style={{height:15}} rowSpan={0} colSpan={99}></td></tr>
                </tbody>);
                let profileFontSize = groupByFamily ? "16px" : "17px";
                let img; // (<img src={this.getImageUrl(row.profile)} style={{width:150,display:'block',marginLeft:"auto"}}/>);
                profileContent = (<td rowSpan={0} style={{verticalAlign:"top",padding:"10px 20px",backgroundColor:"inherit"}}>
                    <div style={{display:"flex",alignItems:"center",gap:20}}>
                        <div style={{letterSpacing:"-0.5px",fontSize:profileFontSize,fontWeight:500}}>{row.profile_text}</div>
                        {img}
                    </div>
                    
                </td>)
                sameProduct = false;
                shadeBody = !shadeBody;
            }
            else {
                if (row.family != last.family){
                    tbody.push(<tr key={row.id + ":s"} style={{height:10}}><td colSpan={99}></td></tr>);
                    sameProduct = false;
                }
                else if (row.size != last.size || row.length != last.length){
                    sameProduct = false;
                }
            }
            if (!sameProduct){
                colorValues = [];
                rowOptions = {};
                let tdStyle:React.CSSProperties = {
                    padding:"1px 12px"
                }
                let numStyle:React.CSSProperties = {
                    padding:"1px 12px",
                    textAlign:"right"
                }
                let qtyStyle:React.CSSProperties = {
                    textAlign:"right",
                    borderLeft:"solid 1px rgb(178 228 122)"

                }
                let familyText
                if (!groupByFamily){
                    familyText = row.family_text;
                }
                // <td style={numStyle}>{FieldFormatter.format(row.price,"money")}</td>
                let sizeStyle:React.CSSProperties = {...tdStyle,whiteSpace:"nowrap"};
                tbody.push(<ProductRow key={row.id} options={rowOptions}>
                    {profileContent}
                    <td style={sizeStyle}>{row.size} <span style={{color:"rgb(110,120,130)"}}>x</span> <span style={{fontWeight:500}}>{row.length}'</span></td>
                    <td style={tdStyle}>{familyText}</td>
                    
                    <PivotList store={store} count={colorSet.length} values={colorValues} style={qtyStyle} 
                        showAvailOnly={showAvailOnly} onSelectCell={store.handleSelectCell} activeRow={store.activeRow}/>
                </ProductRow>);
            }
            
            let colorIndex = colorSet.indexOf(row.color);
            colorValues[colorIndex] =  row;
            if (store.activeRow && row == store.activeRow){
                rowOptions.className = "is-active-row";
            }
        
            last = row;
        }
       
         return <div className="Product-Table" style={{display:"flex",height:"100%",flexDirection:"column"}}>
             <ProductTitleBar event={this.props.event} store={store}/>
             <div style={{flexGrow:1,overflow:"hidden",marginTop:10,marginBottom:15}}>
                <div style={{display:"flex",height:"100%"}}>
                    <div className="rt-scrollbars" style={{paddingLeft:30,paddingRight:20,marginRight:10,overflowY:"auto",overflowX:"hidden",flexShrink:0}}>
                        <div style={{width:190}}>
                            <SingleValueFilter label={store.family.label} value={familyFilter || ""} options={store.family.options} onChange={store.handleFamilyFilterChange}/>
                            {this.renderColorFilter(store,familyFilter)}
                            <SingleValueFilter label={store.profile.label} value={profileFilter || ""} options={store.profile.options} onChange={store.handleProfileFilterChange}/>
                        </div>
                    </div>
                    <div key={store.tableKey} className="rt-scrollbars" style={{overflow:"auto",width:"100%",paddingRight:8,marginTop:20,textAlign:"center"}}>
                        <div style={{display:"inline-block",textAlign:"left"}}>
                            {tables}
                        </div>
                    </div>
                    {this.renderSidebar(store,isEmpty)}
                </div>
             </div>
         </div>
    }

   
    renderColorFilter(store:ProductTableStore,familyFilter:string){
        let filterElems:any[] = [];
        let familyColorSet:string[];
        if (familyFilter){
            familyColorSet = store.familyColors[familyFilter];
        }
        for(let i = 0; i < store.colorOptions.length;i++){
            let colorOption = store.colorOptions[i];
            let value = colorOption.value;
            if (!value) continue;
            let disabled:boolean;
            if (familyColorSet && familyColorSet.indexOf(value) == -1){
                disabled = true;
            }
            filterElems.push(  <FilterItem key={value} store={store} label={colorOption.label} disabled={disabled} value={value} onChange={store.handleColorChange}/>)
        }
        return (<FilterGroup label={store.color.label} onSelectAll={store.handleSelectAll} onClear={store.handleSelectNone}>
             {filterElems}
         </FilterGroup>);
    }

    renderSidebar(store:ProductTableStore,isEmpty:boolean){
        let sidebar = this.props.sidebar;
        if (!sidebar) return null;
        let width;
        if (sidebar.props){
            width = sidebar.props.width;
        }
        return (<div style={{width,flexShrink:0}}>{sidebar}</div>)
        /*
        let sidebarContent;
        let sidebarBackground;
        if (store.activeRow){
            sidebarContent = <Sidebar event={this.props.event} row={store.activeRow} key={store.activeRow.id} store={store} />
            sidebarBackground = "rgb(251,251,252)";
        }
        else if (!isEmpty){
            sidebarContent = <EmptyState icon="cart" label="Click on a qty cell to view details"/>
            sidebarBackground = "rgb(234,236,240)";
        }
       
        if (sidebarContent){
            return( <div className="rt-scrollbars" style={{width:320,backgroundColor:sidebarBackground,padding:"10px 30px",marginLeft:20,
                marginTop:20,marginRight:15,flexShrink:0,overflowY:"auto"}}>
               {sidebarContent}
           </div>);
        }
        return null;
        */
    }

    getImageUrl(profile:string):string {
       
        return imageBase + this.store.profileImages[profile];
    }

    renderTableHeader(colors:string[]){
        let colorHeaders = [];
        let theme = this.store.theme;
        let thStyle:React.CSSProperties = {
            backgroundColor:theme.headerBackgroundColor,
            color:theme.headerColor,
            fontSize:theme.headerFontSize,
            letterSpacing:"1px",
            textTransform:"uppercase",
            textAlign:'center',
            padding:"8px 8px",
            borderRight:"solid 3px #fff"
        }

        let last = {...thStyle,borderRight:null};
        let profileStyle = {...thStyle,minWidth:210};
        let descripStyle = {...thStyle,minWidth:280};
        let store = this.store;

        for(let i = 0 ; i < colors.length;i++){
            let colorStyle = {...thStyle,color:"#000",backgroundColor:"#fff",borderTop:"solid 2px #fff",verticalAlign:"bottom",
            borderRight:"solid 3px #fff",padding:null,fontSize:"13px",textTransform:null,letterSpacing:0,minWidth:105};
            if (i == colors.length -1){
                colorStyle.borderRight = null;
            }
            colorHeaders.push(<th key={i} style={colorStyle}><div style={{borderBottom:"solid 2px " + theme.headerBackgroundColor,padding:"3px 6px"}}>{store.colorNames[colors[i]]}</div></th>)
        }
      
        return (<tbody key="table-header">
            <tr>
                <th style={profileStyle}>Profile</th>
                <th colSpan={2} style={descripStyle}>Description</th>
                <th colSpan={99} style={last}>{store.settings.pivotColumnLabel}</th>
            </tr>
            <tr>
                <th/>
                <th colSpan={2}/>
                {colorHeaders}
            </tr>
        </tbody>)
    }

}




