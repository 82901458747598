import { Icon,icons} from 'components/Icon';
import { FieldRef, RenderEvent } from 'core';
import React from 'react';


export class StudioIconPropEditor{

   
    static open(target:HTMLElement,event:RenderEvent,options:{$id?:string,prop?:string,field?:FieldRef}) {
    
        let canvas = event.canvas;
    
        canvas.openPopup({
            target,
            render:() => {
                let elems:any[] = [<IconOption key="$none" event={event} icon="" $id={options.$id} prop={options.prop} field={options.field} />];
                for(let key in icons){
                    elems.push(<IconOption key={key} event={event} icon={key} $id={options.$id} prop={options.prop} field={options.field} />)
                }
                return (<div style={{display:"flex",flexWrap:"wrap",alignItems:"center",gap:15,padding:15,overflowY:"auto"}} className="rt-scrollbars">
                    {elems}
                </div>)

            }
        });
    }
}

class IconOption extends React.Component<{event:RenderEvent,icon:string,$id:string,prop:string,field:FieldRef}>{
    render(){
        let iconElem;
        if (!this.props.icon){
            return <div onClick={this.handleClick} className="studio-toolbox-item" style={{width:44,height:44,cursor:"pointer",display:"flex",justifyContent:"center",
                alignItems:"center"}}>
                <div style={{fontSize:"12px"}}>None</div>
            </div>
        }
        return <div onClick={this.handleClick} className="studio-toolbox-item" style={{padding:12,cursor:"pointer"}}>
            <Icon icon={this.props.icon} size={20} fill="currentColor"/>
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        canvas.closePopup();
        let value;
        if (this.props.icon){
            value = "'" + this.props.icon + "'";
        }
        if (this.props.field){
            this.props.field.setValue(value);
        }
        else if (this.props.$id && this.props.prop){
            canvas.studioCommandBuffer.setProp(this.props.$id,this.props.prop,value);
            canvas.studioCommandBuffer.send();
        }
    }
}

