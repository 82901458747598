import React from 'react';
import { makeInput } from '../../core';

import { DisplayProps } from '../PutField';
import { FieldError } from './FieldError';
import { FieldLabel } from './FieldLabel';



 export const TextArea = makeInput(class extends React.Component<DisplayProps>{
   
    componentDidMount(){
        let input = this.props.input;
        if (input.focusElemRef.current && input.field.shouldFocus) {
            input.focusElemRef.current.focus();
            input.focusElemRef.current.select();
        }
    }

    componentDidUpdate(){
        let input = this.props.input;
        if (input.focusElemRef.current && input.field.shouldFocus){
            input.focusElemRef.current.focus();
            input.focusElemRef.current.select();
        }
    }


    render(){
        let input = this.props.input;
        
        let field = input.field;
        
        let hideValue:boolean;
        if (input.type == "uid"){
            hideValue = true;
        }

        let containerClass = ["RT-TextBox"];
        let inputClassName = ["RT-TextBox__textarea"];
    
        let containerStyle:React.CSSProperties = {};
        /*
        if (this.props.width){
            containerStyle.width = this.props.width;
        }
        */

        let errorElem;

        if (field.error) {
            containerClass.push("RT-TextBox--has-error");
            errorElem = <FieldError input={input}/>
        }
        if (hideValue){
            containerClass.push("RT-TextBox--hide-value");
        }

        let helpElem;
        if (this.props.help) {
            helpElem = (
                <small className="form-text text-muted">{this.props.help}</small>
            );
        }

        let labelElem;
        let label = this.props.label;
        if (label) {
            labelElem = <FieldLabel styles={this.props.labelStyles} label={label} required={this.props.required}/>
        }
        inputClassName.push(this.props.styles.className);

      
      
        return (
            <>
                <div
                    className={containerClass.join(" ")}
                    style={containerStyle}
                >
                    {labelElem}
                    <div className="RT-TextBox__container">
                        
                        <textarea
                            ref={input.focusElemRef as any}
                            className={inputClassName.join(" ")}
                            style={this.props.styles.style}
                            placeholder={this.props.placeholder}
                            value={input.editValue || ""}
                            tabIndex={this.props.noTabStop ? -1 : null}
                            readOnly={field.disabled}
                            onBlur={input.onBlur as any}
                            onChange={input.onChange as any}
                            onKeyDown={input.onKeyDown as any}
                            onKeyPress={input.onKeyPress as any}
                            autoComplete="custom-data"
                        />
                        {errorElem}
                    </div>
                </div>
            </>
        );
    }
});