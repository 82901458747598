import { Icon } from 'components/Icon';
import { DisplayProps } from 'components/PutField';
import { IFieldOption } from 'core/RecordMeta';
import React from 'react';


const filterCheckedBackground = "var(--rt-primary-bg-color)";
const filterCheckedFill = "#fff";

export class MultiOptionFilter extends React.Component<DisplayProps> {
    render(){
      
        return <Presenter {...this.props} allowMultiple={true} />
    }
}

export class SingleOptionFilter extends React.Component<DisplayProps> {
    render(){
      
        return <Presenter {...this.props} allowMultiple={false} />
    }
}

class Presenter extends React.Component<DisplayProps & {allowMultiple?:boolean},{expanded:boolean}> {

    state = {expanded:false};

    render() {
        let field = this.props.field;
        if (!field) return null;
        let label =  field.label;
        let options = this.renderOptions();
        let clearElem;
        if (this.props.allowMultiple){
            clearElem = (<a href='#' style={{ marginLeft: "auto", fontSize: "11px" }} onClick={this.handleClear}>CLEAR</a>);
        }
        let toggleElem;
        if (!this.state.expanded){
            toggleElem= <div style={{width:22}} onClick={this.handleToggle}>
                <Icon icon="plus" size={16} fill="var(--rt-primary-color)"/>
            </div>
        }
        else {
            toggleElem = <div style={{width:22}} onClick={this.handleToggle}>
                <Icon icon="minus" size={16} fill="var(--rt-primary-color)"/>
            </div>
        }
        
        return <div style={{marginBottom:10}}>
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{display:"flex",alignItems:"center",cursor:'pointer'}} onClick={this.handleToggle}>
                    {toggleElem}
                    <div style={{ fontSize: "12px", fontWeight: 500, textTransform: "uppercase" }}>{label}</div>
                </div>
               
                {/* <a href='#' style={{ marginLeft: "auto", fontSize: "11px", marginRight: 8 }} onClick={this.handleSelectAll}>ALL</a>| */}
                {clearElem}
            </div>
            <div style={{ marginTop: 10, marginBottom: 20,marginLeft:-5 }} >{options}</div>
        </div>;
    }

    renderOptions(){
        let elems:any[] = [];
        let field = this.props.field;
        let options = field.options;
        let selectedValues:string[] = [];
        if (field.value && field.value.split){
            let fieldValue = field.value;
            if (fieldValue && fieldValue.toUpperCase){
                fieldValue = fieldValue.toUpperCase();
            }
            selectedValues = fieldValue.split(',');
        }
        let allowMultiple = this.props.allowMultiple;
        let expanded = this.state.expanded;

        if (options){
            for(let i = 0; i < options.length; i++){
                let option = options[i];
                let checked = this.containsOption(option.value,selectedValues);
                if (!expanded && !checked) continue;

                elems.push(<FilterItem key={option.value || i} option={option} checked={checked} isMultiSelect={allowMultiple} onChange={this.handleOptionClick} />)
            }

        }

        return elems;
    }

    
    containsOption(optionValue:string,selectedValues:string[]):boolean{
        if (!optionValue && selectedValues.length == 0) return true;

       
        for(let i = 0; i < selectedValues.length;i++){
            if (optionValue == selectedValues[i]) return true;
        }
        return false;
    }

    getOptionIndex(optionValue:string,selectedValues:string[]):number{
       
        for(let i = 0; i < selectedValues.length;i++){
            if (optionValue == selectedValues[i]) return i;
        }
        return -1;
    }

    handleToggle = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState(state => ({expanded:!state.expanded}));
    }

    handleSelectAll = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        
    };

    handleClear = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({expanded:false});
        this.props.field.setValue("");
    };

    handleOptionClick = (option:IFieldOption) => {
        let value = option.value;
        let field = this.props.field;
        let currentValue = field.value;
        if (!value){
            if (!currentValue && !this.state.expanded){
                this.setState({expanded:true});
            }
            field.setValue("");
            return;
        }
        if (!this.props.allowMultiple){
            field.setValue(value);
            return;
        }

        let selectedValues:string[] = [];
        
        if (field.value && field.value.split){
            selectedValues = field.value.split(',');
        }
        let i = this.getOptionIndex(value,selectedValues);
        if (i == -1){
            selectedValues.push(value);
        }
        else {
            selectedValues.splice(i,1);
        }
        field.setValue(selectedValues.join(','));
    }
}

export class FilterItem extends React.Component<{ option:IFieldOption; checked:boolean,disabled?: boolean; onChange: (option: IFieldOption) => void;isMultiSelect:boolean }> {
    render() {
        let icon;
        let style: React.CSSProperties = { fontSize: "13px" };
        let iconOpacity;
        let cursor = "pointer";
        let backgroundColor;
        if (this.props.disabled) {
            style.opacity = 0.5;
            iconOpacity = 0.25;
            cursor = null;
        }
        else {
            backgroundColor = "#fff";
        }
        let checked = this.props.checked;
        let boxBackground;
        let boxBorder;
    
        if (checked) {
            if (!this.props.isMultiSelect){
                icon = <div style={{marginLeft:1,marginTop:1,borderRadius:9999,border:'solid 2px #fff',width:12,height:12}}/>
            }
            else {
                icon = <div style={{ position: "absolute", top: -3, left: -3 }}>
                    <Icon icon="check" size={20} fill="#fff" />
                </div>;
            }
            style.fontWeight = 500;
            backgroundColor = "#fff";
            boxBackground = filterCheckedBackground;
            boxBorder = "solid 1px " + filterCheckedBackground;
        }
        else {
            backgroundColor = "#fff";
            boxBorder = "solid 1px #000";
        }
        let label = this.props.option.label;
        if (label == "?"){
            label = "All";
        }
        let borderRadius = this.props.isMultiSelect ? 0 : 9999;

        return <div style={{
            display: "flex", alignItems: "center", gap: 6, padding: "5px 12px", marginBottom: 2, backgroundColor,
            cursor
        }} onClick={this.handleClick}>
            <div style={{ width: 16, height: 16, border: boxBorder, borderRadius, background: boxBackground, position: "relative", opacity: iconOpacity }}>
                {icon}
            </div>
            <div style={style}>{label}</div>
        </div>;
    }

    handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.disabled)
            return;
        this.props.onChange(this.props.option);


    };
}
