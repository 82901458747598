import { FieldFormatter, FieldType, makeComponent, RenderEvent } from '../core';
import React from 'react';
import { InlineTextPropEditor } from './studio/InlineTextPropEditor';


export const Text = makeComponent( class extends React.Component<{event:RenderEvent,text:string;textAlign?:string;$id?:string,
    styles?:string,
    format?:string,fieldType?:FieldType,$elem?:any}>{
    
    render(){
      
        let event = this.props.event;

        let styles = this.props.event.computeStyles(null,this.props.styles);
        let textElem;
        let format = styles.format || this.props.format;
        if (format){
            textElem = FieldFormatter.format(this.props.text,this.props.fieldType,format);
        }
        else {
            textElem = this.props.text; 
        }
        let className = ['RT-Text'];
        switch (this.props.textAlign) {
            case "left": className.push("text-left"); break;
            case "center": className.push("text-center"); break;
            case "right": className.push("text-right"); break;
        }
        className.push(styles.className);

        if (event.designMode){
            return <InlineTextPropEditor event={event} $id={this.props.$id} propName="text" style={styles.style} 
                className={className.join(' ')} $elem={this.props.$elem} placeHolder="Click to edit text"
                allowEnterForNewLine />
        }
        return <span style={styles.style} className={className.join(' ')} data-elem-id={this.props.$id}>{textElem}</span>
    }
});



