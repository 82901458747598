import { makeComponent, RenderEvent } from '../core';
import React from 'react';
import { Icon } from './Icon';

const color = 'rgb(146 186 199)';

export const Spacer = makeComponent( class extends React.Component<{event:RenderEvent,size?:string;divider?:string,$id:string}>{
    render(){
        
        if (this.props.event.designMode){
            let sizePadding = 0;
            if (this.props.size == "medium"){
                sizePadding = 6;
            }
            else if (this.props.size == "large"){
                sizePadding = 12;
            }
            return <div style={{paddingLeft:30,borderTop:"solid 1px " + color,borderBottom:"solid 1px " + color,maxWidth:80,
                paddingTop:sizePadding,paddingBottom:sizePadding}}>
                
                <Icon icon="arrows-v" size={20} fill={color}/>
            </div>
        }
        let className = "RT-Spacer";    
        if (this.props.size){
            className += " RT-Spacer--" + this.props.size;
        }
        let divider;
        if (this.props.divider){
            divider= <div className={"RT-Spacer__divider-" + this.props.divider} />
        }
        return <div className={className}>{divider}</div>
    }
});