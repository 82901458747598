import { ActionRef, Collection, CollectionRef, FieldRef, RenderEvent } from 'core';
import React from 'react';
import { Icon } from './Icon';

type RenderFunc = (event:RenderEvent) => any;

export class SearchableList extends React.Component<{event:RenderEvent,$id:string,collection:CollectionRef,whenEmpty?:any,render_row?:RenderFunc,
    matchExpr:FieldRef,keyword:FieldRef}>{

        focusElemRef = React.createRef<HTMLInputElement>();

        componentDidMount(){
            
            if (this.focusElemRef.current && this.props.keyword && this.props.keyword.shouldFocus) {
                this.focusElemRef.current.focus();
                this.focusElemRef.current.select();
            }
        }
    
        componentDidUpdate(){
            if (this.focusElemRef.current && this.props.keyword && this.props.keyword.shouldFocus) {
                this.focusElemRef.current.focus();
                this.focusElemRef.current.select();
            }
        }    

    render(): React.ReactNode {

        let collection = this.props.collection;
        if (!collection && !collection.rows) return null;

        let event = this.props.event;
        let rows = collection.rows;
        let elems = [];
        let index = 0;
        let renderRow = this.props.render_row;
        let keywordField = this.props.keyword;
        if (!keywordField) return null;
        let testValue:string;
        if (keywordField){
            testValue = keywordField.value;
            if (testValue && typeof(testValue)=== "string"){
                testValue = testValue.toLowerCase();
            }
        }

        let matchExpr = this.props.matchExpr;
        let matchFieldName:string = "";
        if (matchExpr){
            matchFieldName = matchExpr.name;
        }
        if (rows.length){
            for(let i = 0 ; i < rows.length;i++){
                let row = rows[i];
                let test = this.isMatch(testValue,row[matchFieldName]);
                if (!test) continue;
                let rowEvent = event.forCollectionRow(collection,row,i)
                let rowContent = renderRow(rowEvent);
                if (rowContent){
                    elems.push(<SearchableListItem key={i} index={index} event={rowEvent} collection={collection} row={row}>{rowContent}</SearchableListItem>);
                    index++;
                }
            }
        }
        else if(this.props.whenEmpty){
            elems = this.props.whenEmpty;
        }
        
        return <div style={{height:"100%",display:"flex",flexDirection:"column",overflow:"hidden"}}>
            <div style={{border:"solid 2px rgb(232,232,232",position:"relative",backgroundColor:"#fff",marginBottom:15,marginRight:5}}>
                <input ref={this.focusElemRef} type="text" value={keywordField.value} style={{border:0,padding:"6px 8px 6px 28px",width:"100%",backgroundColor:"#fff"}} placeholder="Search" onChange={this.handleChange}/>
                <div style={{position:"absolute",left:4,top:8}}>
                    <Icon icon="search" size={20} fill="rgb(200,203,208)"/>
                </div>
            </div>
            <div className="rt-scrollbars" style={{flexGrow:1,overflowY:"auto",paddingRight:5,}}>
                {elems}
            </div>
        </div>
    }

    handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        this.props.keyword.setValue(e.target.value);
        this.forceUpdate();
    }

    isMatch(testValue:string,rowLabel:string){
        if (!testValue) return true;
        if (!rowLabel) return false;
        if (testValue.length == 1){
            return rowLabel.toLowerCase().startsWith(testValue);
        }
        if (rowLabel.toLowerCase().indexOf(testValue) != -1){
            return true;
        }
        return false;
    }
}

class SearchableListItem extends React.Component<{event:RenderEvent,collection:CollectionRef,row:any,index:number}>{
    render(){
        return this.props.children || null;
    }
}