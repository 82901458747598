import React from 'react';

export class LongCharText extends React.Component<{text:string}>{
    render(): React.ReactNode {
        let text = this.props.text;
        if (!text) return null;
        let segments = text.split('\n');
        let elems = [];
        for(var i = 0; i < segments.length;i++){
            if (segments[i]){
                elems.push(<div key={i}>{segments[i]}</div>);
            }
            else {
                elems.push(<br key={i}/>);
            }
        }
        return elems;
    }
}