import { Canvas } from "./Canvas";

export class AutoRefreshSettings {
    public canvas:Canvas;
    private frequencyMilliSeconds:number;
    private _lastRefresh:number;

    constructor(canvas:Canvas,frequencySeconds:number){
        this.canvas = canvas;
        this.frequencyMilliSeconds = frequencySeconds * 1000;
        this._lastRefresh = Date.now();
    }

    public refreshIfStale(){
        if (this.shouldRefresh){
            this._lastRefresh = Date.now();
            this.canvas.restart();
            console.log("auto refreshing: " + this.canvas.pageId);
        }
    }

    public get shouldRefresh():boolean {
        let now = Date.now();
        if ((now - this._lastRefresh) >= this.frequencyMilliSeconds){
            if (this.canvas.getIsVisible()){
                return true;
            }
        }
        return false;
    }

    
}