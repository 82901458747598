import { FieldRef, RenderEvent } from 'core';
import { IResult, ISearchProduct, KeywordFilter, KeywordLexer } from 'core/search/KeywordFilter';
import React from 'react';
import ReactDOM from 'react-dom';
import { AnchorPoint } from './helpers/AnchorPoint';
import { Icon } from './Icon';
import { Popup } from './popups/Popup';

export class SearchBox extends React.Component<{event:RenderEvent,field:FieldRef,keywordSet:any}>{

    dirty:boolean;
    editValue:string = "";
    filterValue:string = "";
    activeIndex:number = -1
    results:IResult[];
    keywordSet:ISearchProduct[];
    containerRef = React.createRef<HTMLDivElement>();
    inputRef = React.createRef<HTMLInputElement>();
    popupOpen:boolean;
    

    constructor(props){
        super(props);
        this.keywordSet = this.props.keywordSet;
    }

    componentDidMount(){
        let field = this.props.field;
        if (this.inputRef.current && field.shouldFocus) {
            this.inputRef.current.focus();
            this.inputRef.current.select();
        }
    }

    render(): React.ReactNode {


        let popupList = null;
        let popupPortal;

       
        let results = this.results;
        let elems = [];
        if (results && results.length && this.popupOpen){
            for(let i = 0; i < results.length;i++){
                let result = results[i];
                let isActive = (i == this.activeIndex);
                elems.push(<SearchBoxItem key={i} result={result} index={i} isActive={isActive} 
                    onClick={this.handleItemClick}
                />);
                
            }
    
            let rect = this.inputRef.current.getBoundingClientRect();
            let popupStyle:React.CSSProperties = {maxHeight:500,width:rect.width,backgroundColor:"#fff",overflowY:"auto"};
            popupList = <div ref={this.containerRef} style={popupStyle} className="shadow-xl rt-scrollbars">
                {elems}
            </div>
            popupPortal = <Popup
                attachedRef={this.inputRef.current}
                onForceClose={this.handleClose}
                zIndex={20000}
                render={() => popupList}
                anchorPoint={AnchorPoint.BottomAlignLeft}
                
            />
                

        }

        let field = this.props.field;
        let clearIcon;
        let value = this.dirty ? this.editValue :field.value;
        if (value && value.length){
            clearIcon = (<div style={{position:"absolute",right:8,top:10,width:20,height:20,
                backgroundColor:"rgb(232,232,232)",zIndex:5,
                borderRadius:999,cursor:"pointer",padding:2}} onClick={this.handleClearClick}>
                <Icon icon="close" fill="rgb(14,36,60)" size={16}/>
            </div>);
        }
        let labelElem;
        if (field.label){
            labelElem =  <label>{field.label}</label>
        }
       
        return <div className="RT-TextBox RT-TextBox--character" 
            style={{width:500}}>
            {labelElem}
            <div style={{position:"relative"}}>
                <input ref={this.inputRef}className="RT-TextBox__input" 
                    style={{padding:"8px 20px 8px 40px",backgroundColor:"#fff"}} 
                    value={value} 
                    onBlur={this.handleBlur}
                    onChange={this.handleChange} onKeyDown={this.onKeyDown}/>
                {popupPortal}
                <div style={{position:"absolute",left:10,top:10,zIndex:5}}>
                    <Icon icon="search" fill="#495057" size={20}/>
                </div>
                {clearIcon}
            </div>
        </div>
    }

    handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        this.editValue = e.target.value;
        this.filterValue = this.editValue;
        this.dirty = true;
        this.activeIndex = -1;
        let results = this.getResults();
        if (results && results.length){
            this.popupOpen = true;
        }
        else {
            this.popupOpen = false;
        }
        this.forceUpdate();
    }

    handleBlur = (e:React.FocusEvent) => {
        if (this.dirty){
            this.props.field.setValue(this.editValue);
            this.dirty = false;
        }
    }

    handleClose = () => {
        this.popupOpen = false;
        this.forceUpdate();
    }
    
    handleClearClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.editValue = ""
        this.filterValue = "";
        this.results = [];
        this.popupOpen = false;
        this.props.field.setValue("");
        this.inputRef.current.focus();
        this.forceUpdate();
    }

    handleItemClick = (result:IResult) => {
        this.editValue = this.getResultText(result);
        this.filterValue = this.editValue;
        this.activeIndex = -1;
        this.popupOpen = false;
        this.props.field.setValue(this.editValue);
        this.dirty = false;
        this.inputRef.current.focus();
        this.forceUpdate();
    }

    onKeyDown = (e:React.KeyboardEvent) => {
        if (e.key == "ArrowUp"){
            e.preventDefault();
            if (!this.popupOpen) return;
            if (this.activeIndex > 0){
                this.activeIndex--;
                let elem = this.containerRef.current.querySelector('[data-index="' + this.activeIndex + '"]');
                if (this.isOutside(elem as any)){
                    elem.scrollIntoView();
                }
                this.editValue = this.getResultText(this.results[this.activeIndex]);
                this.forceUpdate();
            }
        }
        else if (e.key == "ArrowDown"){
            e.preventDefault();
            if (!this.popupOpen) return;
            if (this.activeIndex < (this.results.length - 1)){
                this.activeIndex++;
                let elem = this.containerRef.current.querySelector('[data-index="' + this.activeIndex + '"]');
                if (this.isOutside(elem as any)){
                    elem.scrollIntoView(false);
                }
                this.editValue =this.getResultText(this.results[this.activeIndex]);
                this.forceUpdate();
            }
        }
        else if (e.key == "Tab"){
            if (!this.popupOpen) return;

            e.preventDefault();
            if (this.activeIndex >=0 && this.activeIndex < this.results.length){
                this.editValue = this.getResultText(this.results[this.activeIndex]) + " ";
                this.filterValue = this.editValue;
                this.activeIndex = -1;
                this.getResults();
                this.forceUpdate();
            }
        }
        else if (e.key == "Enter"){
            e.preventDefault();
            if (!this.popupOpen) {
                if (this.dirty){
                    this.props.field.setValue(this.editValue);
                    this.dirty = false;
                }
                this.props.field.handleEnterKey();
                return;
            }

            if (this.activeIndex >=0 && this.activeIndex < this.results.length){
                this.editValue = this.getResultText(this.results[this.activeIndex]);
                this.filterValue = this.editValue;
                this.popupOpen = false;
                this.props.field.setValue(this.editValue);
                this.dirty = false;
                this.props.field.handleEnterKey();
                this.forceUpdate();
            }
        }
        else if (e.key == "Escape"){
            if (!this.popupOpen) return;
            this.popupOpen = false;
            this.forceUpdate();
        }
    
    }

    isOutside(elem:HTMLDivElement):boolean {
        let elemRect = elem.getBoundingClientRect();
        let containerRect = this.containerRef.current.getBoundingClientRect();
        if (elemRect.top < containerRect.top) return true;
        if (elemRect.bottom > containerRect.bottom) return true;
        return false;
    }

    getResultText(result:IResult):string {
        return result.prefix + result.keywords.join(' ');
    }

    getResults():IResult[] {
    
        let results:IResult[] = [];
        let match = this.filterValue;
        if (!match || match.length < 2) {
            this.results = [];
            return null;
        }

        let matchKeywords = KeywordLexer.parse(match);
        let isPartial = !this.filterValue.endsWith(" ");
    
        let searchEngine = new KeywordFilter();
        searchEngine.products = this.keywordSet;
        if (isPartial){
            results = searchEngine.getResults(matchKeywords.slice(0,-1),matchKeywords[matchKeywords.length -1]);
        }
        else {
            results = searchEngine.getResults(matchKeywords,"");
        }
        if(results.length > 0){
            this.activeIndex = 0;
        }
      

        this.results = results;
        return results;
    }
}

class SearchBoxItem extends React.Component<{result:IResult,index:number,isActive:boolean,onClick:(result:IResult) => void}>{
    render(): React.ReactNode {
        
        let result = this.props.result;

        let style:React.CSSProperties = {padding:"5px 10px 5px 12px",display:"flex",
        alignItems:"center",fontSize:"1rem",cursor:"pointer"};
        if (this.props.isActive){
            style.backgroundColor = "rgb(240,241,242)";
        }
        let content = [];
        for(let k = 0;k < result.keywords.length;k++){
            let fontWeight = result.bold[k] ? 500 : 400;
            content.push(<span key={k} style={{marginRight:6,fontWeight}}>{result.keywords[k]}</span>)
        }

        return(<div data-index={this.props.index} style={style} onClick={this.handleClick}>
            <Icon icon="search" fill="rgb(176,180,184)" size={20}/>
            <div style={{marginLeft:10}}>
                {result.prefix}{content}
            </div>
        </div>);
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.result);
    }
}

