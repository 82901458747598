import React from 'react';
import { IFilterOptions } from './ProductTableStore';

export class SingleValueFilter extends React.Component<{ value: string; label: string; options: IFilterOptions; onChange: (value: string) => void; }> {
    render() {
        let style: React.CSSProperties = { padding: "4px 8px", width: "100%" };
        if (this.props.value) {
            style.fontWeight = 500;
        }
        let labelStyle: React.CSSProperties = { fontSize: "13px", textTransform: "uppercase", fontWeight: 500, marginBottom: 5 };
        let optionElems = [];
        let options = this.props.options;
        for (let i = 0; i < options.length; i++) {
            let option = options[i];
            optionElems.push(<option key={i} value={option.value}>{option.label}</option>);
        }
        return <div style={{ marginBottom: 15 }}>
            <div style={labelStyle}>{this.props.label}</div>
            <select value={this.props.value} onChange={this.handleChange} style={style}>
                {optionElems}
            </select>
        </div>;
    }
    handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.onChange(e.target.value);
    };
}
