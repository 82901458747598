import { ActionRef, Collection, RenderEvent } from 'core';
import React from 'react';
import { Icon } from './Icon';


export class RowSelector extends React.Component<{event:RenderEvent,row:any,onSelect?:ActionRef}>{
    render(){
        let selected = Collection.isRowSelected(this.props.row);
       
        let checkboxElem;
        if (selected){
            checkboxElem = <div style={{width:18,height:18,border:"solid 2px var(--rt-primary-color)",borderRadius:3,display:"flex",alignItems:"center",
                justifyContent:'center'}}>
                    <Icon icon="check" size={24} fill="var(--rt-primary-color)"/>
                </div>
        }
        else {
             checkboxElem = (<div style={{width:18,height:18,border:"solid 2px rgb(202,204,208)",borderRadius:3}}/>)
        }
        return <div onClick={this.handleClick} style={{padding:4}}>{checkboxElem}</div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let event = this.props.event;
        Collection.setRowSelected(this.props.row,!Collection.isRowSelected(this.props.row));
      
        if (this.props.onSelect){
            this.props.onSelect.trigger({scope:event.scope});
        }
        else {
            event.canvas.update();
        }
    }
}