import { ScreenRef } from "./ActionEvent";
import { executeCommands } from "./StatementRunner";


export class IfBlockRunner {

    static async runIfBlock(screen:ScreenRef, elem:any):Promise<any> {
        let statements = elem.statements;
        if (!statements) return;
        for(let i = 0; i < statements.length;i++){
            let statement = statements[i];
            if (statement.$kind == "Else"){
                await executeCommands(screen,statement.children);
                return;
            }
            if (statement.$kind == "If" || statement.$kind == "ElseIf"){
                let test = screen.getValue(statement.condition);
                if (test){
                    await executeCommands(screen,statement.children);
                    return;
                }
            }
        }
    }

   
}