import { Icon } from "components/Icon";
import { FieldRef, RenderEvent } from "core";
import { NumberFormatter } from "core/FieldFormatter";
import React  from "react";
import {theme} from "./ProductBreadcrumbs";

export class AvailableQtyCell extends React.Component<{event:RenderEvent,field:FieldRef,cartQty?:number}>{
    render(): React.ReactNode {
        let cart_qty = this.props.cartQty;
        let value;
        let field = this.props.field;
        if (this.props.field){
            value = field.value;
        }
        
      
        if (cart_qty > 0) {
            let fill = (value >= cart_qty) ? theme.cartValueColor: theme.cartValueWarningColor;
            let icon = <Icon icon="cart" size={18} fill={fill} />;
            return (
                <div style={{ display: "flex", padding: "1px 3px 1px 12px", gap: 6, alignItems: "center", justifyContent: "flex-end" }}>

                    <div style={{ fontWeight: 500, minWidth: 36 }}>{NumberFormatter.formatNumber(cart_qty)}</div>
                    <div style={{ width: 20 }}>{icon}</div>
                </div>);
        }
        else if (value > 0) {
            let icon = <Icon icon="circleCheck" size={18} fill={theme.circleCheckColor} />;
            return (
                <div style={{ display: "flex", padding: "1px 3px 1px 12px",  gap: 6, alignItems: "center", justifyContent: "flex-end" }}>

                    <div style={{ fontWeight: 500, minWidth: 36 }}>{NumberFormatter.formatNumber(value)}</div>
                    <div style={{ width: 20 }}>{icon}</div>
                </div>);
        }
        else {
            let formatted = (!value) ? '\u00A0' : NumberFormatter.formatNumber(value);
            return <div style={{ padding: '1px 29px 1px 12px' }}>{formatted}</div>
        }
    }

}