import React from 'react';
import { ActionRef, ComponentStyles, FieldRef, FieldType, InputControl, makeComponent, RenderEvent } from "../core";
import {TextBox} from './display/TextBox';
import { ReadonlyField } from './display/ReadonlyField';
import { Checkbox } from './display/Checkbox';
import { Select } from './display/Select';
import { TextArea } from './display/TextArea';
import { Icon } from './Icon';


export type DisplayProps = {
    event:RenderEvent;
    field?:FieldRef,
    label?:string,
    span?:string;
    required?:boolean,
    placeholder?:string,
    hideLabel?:boolean,
    labelPosition?:null | 'bullet';
    format?:string,
    help?:string,
    onValueChanged?:ActionRef,
    type?:FieldType,
    textAlign?:null | 'left' | 'center' | 'right',
    lookupLabel?:string,
    onClick?:ActionRef,
    noTabStop?:boolean;
    input?:InputControl;
    $id?:string;
    $elem?:any;
    suffix?:string;
    styles?:ComponentStyles;
    labelStyles?:ComponentStyles;
    icon?:any;
    expr?:any;
    readonly?:boolean;
    autoComplete?:string;
}

export const PutField = makeComponent((props:DisplayProps & {styles:string;labelStyles:string} & {asCalendar:boolean}) => {
    
    let event = props.event;
    let field = props.field;
    if (!field){
        field = new FieldRef(event.canvas,null,null,null,null);
        field.isExpression = true;
        field.exprValue = props.expr;
        field.def = {} as any;
    }
    if (field.hidden) return null;
    //if (!field || field.hidden) return null;
    /*
    if (field.def.isRange){
        return <FilterField field={field}/>
    }
    */
    let styles = event.computeStyles(field.styles,props.styles);
    let labelStyles = event.computeStyles(field.labelStyles,props.labelStyles);
    
    field.labelOverride = props.label;
    if (styles.format){
        field.formatOverride = styles.format;
    }

    let type = props.type || field.type;
    let label;
    if (!props.hideLabel){
        label = props.label || field.label;
    }
    let placeholder = props.placeholder || field.placeholder;
    let format = field.format;
    let help = props.help || field.help;
    let required = props.required || field.required;
    let suffix = props.suffix || field.suffix;
    
   
   
  
    let componentProps = {...props,key:props.$id,field,type,label,placeholder,format,help,required,styles,labelStyles,suffix};
    if (field.readonly || props.readonly) {
        return <ReadonlyField {...componentProps}/>
    }
    if (event.designMode){
        return <DesignModeField $id={props.$id} elem={props.$elem}/>
    }
    if (field.options && !props.asCalendar){
        return <Select {...componentProps}/>
    }

    switch (type){
        case "logical":return <Checkbox {...componentProps}/>
        case "longchar":return <TextArea {...componentProps}/>
        case "date":
        case "time":
        case "character":
        default:return <TextBox {...componentProps} />
    }
    return null;
});

class DesignModeField extends React.Component<{$id:string,elem:any}>{
    render(){
        let elem = this.props.elem;
        let label = elem["$src_label"];
        let expr = this.renderExpr(elem.$src_expr); // 
        return <div className="RT-Static-Field" style={{border:"solid 2px rgb(229,230,234)",borderRadius:3,padding:"2px 6px"}} > 
            <div className="RT-Static-Field__label RT--readonly">{label || "\u00A0"}</div>
            <div style={{display:"flex",alignItems:"center",gap:4}}>
                <div style={{fontSize:"15px",letterSpacing:"0.2px"}}>{expr}</div>
            </div>
        </div>
    }

    renderExpr(expr:string){
        if (!expr) return expr;
        let segments = expr.split('.');
        let n = segments.length;
        let elems = [];

        for(let i = 0; i < segments.length;i++){
            let segment = segments[i];
            if (i > 1){
                elems.push(<span key={"d" + i} style={{marginLeft:2,marginRight:2,opacity:1,fontWeight:500}}>.</span>)
            }
            if (segment[0] == "@" && n > 1){
                // elems.push(<span key={i} style={{color:"rgb(220,222,226)"}}>{segment}</span>)
            }
            else {
                elems.push(<span key={i} style={{fontWeight:400}}>{segment}</span>)
            }
        }
        return elems;
    }
}