export type KeywordSet = string[];
export interface ISearchProduct {productId:string,keywords:KeywordSet,productLine:string};
export interface IResult {
    prefix?:string;
    keywords:string[];
    bold:boolean[];
    length:number;
    hitCount:number;
}

type KeywordMap = {[key:string]:IResult};

export class KeywordFilter {
    products:ISearchProduct[];

    getResults(match:KeywordSet,partial:string):IResult[]{
        let filtered:ISearchProduct[];
        if (match && match.length){
            filtered = this.filterProducts(this.products,match);
        }
        else {
            filtered = this.products;
        }

        let results:IResult[] = [];
        let map:KeywordMap = {};
        let extraMap:KeywordMap = {};
        
        let matches:ISearchProduct[] = [];
        for(let i = 0; i < filtered.length;i++){
            let product = filtered[i];
            let found = this.addMatches(product.keywords,partial,match,map,extraMap);
            if (found){
                matches.push(product);
            }
        }
        filtered = matches;

        this.addResultsFromMap(results,match,map,extraMap);
       
        if (results.length && results.length < 5 && (!match || !match.length)){
            let autoKeyword = results[0].keywords[0];
            let addOnMatch = [autoKeyword];
            filtered = this.filterProducts(filtered,addOnMatch);
            let addOnMap = {};
            let addOnExtraMap = {};
            for(let i = 0; i < filtered.length;i++){
                this.addMatches(filtered[i].keywords,"",addOnMatch,addOnMap,addOnExtraMap);
            }
            let newMap = {};
            for(let key in addOnMap){
                let check = autoKeyword + "|" + key;
                if (!extraMap[check] && !map[check]){
                    newMap[key] = addOnMap[key];
                }
            }
            let addOnResults:IResult[] = [];
            this.addResultsFromMap(addOnResults,addOnMatch,newMap,addOnExtraMap);
            for(let i = 0; i < addOnResults.length; i++){
                let result = addOnResults[i];
                result.length = 2;
                results.push(result);
            }
        }

       
        results.sort((a,b) => {
            if (a.length < b.length){
                return -1;
            }
            else if (a.length > b.length){
                return 1;
            }
            if (a.hitCount == b.hitCount){
                if (a.keywords[0] < b.keywords[0]) return -1;
                if (a.keywords[0 ]> b.keywords[0]) return 1;
                return 0;
            }
            else {
                return b.hitCount - a.hitCount;
            }
        });
      
        if (results.length > 50){
            results = results.slice(0,50);
        }
        return results;
    }

    addResultsFromMap(results:IResult[],match:KeywordSet,map:KeywordMap,extraMap:KeywordMap){
        let prefix:string = (match && match.length) ? match.join(' ') + ' ' : "";
        for(let key in map){
            let result = map[key];
            result.prefix = prefix;
            results.push(result);
        }
        if (extraMap){
            for(let key in extraMap){
                let result = extraMap[key];
                result.prefix = prefix;
                results.push(result);
            }
        }
    }

    filterProducts(products:ISearchProduct[],match:KeywordSet):ISearchProduct[]{
        let filtered:ISearchProduct[] = [];
        for(let i = 0; i < products.length; i++){
            let product = products[i];
            if (this.containsAllKeys(product.keywords,match)){
                filtered.push(product);
            }
        }
        return filtered;
    }

    containsAllKeys(keys:KeywordSet,matches:KeywordSet):boolean {
        for(let i = 0 ; i < matches.length;i++){
            if (!keys.includes(matches[i])) return false;
        }
        return true;
    }

    addMatches(keys:KeywordSet,partial:string,ignore:string[],map:KeywordMap,extraMap:KeywordMap):boolean{
        let found = false;
        for(let i = 0; i < keys.length;i++){
            let key = keys[i];
            if (ignore.includes(key)) continue;
            if (key.startsWith(partial)){
                let keyBold = partial ? false : true;
                this.addToMap(map,[key],[keyBold]);
                found = true;
                if (extraMap){
                    let prev = (i > 0) ? keys[i - 1] : undefined;
                    let next = (i < keys.length - 1) ? keys[i + 1] : undefined;
                    if (prev && !ignore.includes(prev)){
                        this.addToMap(extraMap,[prev,key],[true,keyBold])
                        if (next && !ignore.includes(next)){
                            this.addToMap(extraMap,[prev,key,next],[true,keyBold,true]);
                        }
                    }
                    if (next && !ignore.includes(next)){
                        this.addToMap(extraMap,[key,next],[keyBold,true]);
                    }
                }
            }
            
        }
        return found;
    }
    addToMap(map:KeywordMap,keys:string[],bold:boolean[]){
        var key = keys.join('|');
        let length = keys.length > 1 ? 2 : 1;
        if (map[key] === undefined){
            map[key] = {keywords:keys,hitCount:1,length,bold};
        }
        else {
            map[key].hitCount++;
        }
    }
}

export class KeywordLexer {
    public source:string;
    index:number = 0;
    keywords:string[] = [];

    static parse(source:string):string[] {
        if (!source) return null;
        let instance = new KeywordLexer();
        instance.source = source.toLowerCase();
        return instance.getKeywords();
    }

    getKeywords(){
        if (!this.source)
        this.index = 0;
        while(this.index < this.source.length){
            let ch = this.source[this.index];
            if (ch >='a' && ch <='z'){
                this.scanString();
            }
            else if (ch >='0' && ch<='9'){
                this.scanNumber();
            }
            else {
                this.index++;
            }
        }
        return this.keywords;
    }

    scanString(){
        let s:string = this.source[this.index];
        this.index++;
        while(this.index < this.source.length){
            let ch = this.source[this.index];
            if ((ch >= 'a' && ch <= 'z') || (ch >='0' && ch <= '9') || ch == '-' || ch == '/'){
                s += ch;
                this.index++;
            }
            else {
                this.keywords.push(s);
                return;
            }
        }
        this.keywords.push(s);
    }

    scanNumber(){
        let s:string = this.source[this.index];
        this.index++;
        while(this.index < this.source.length){
            let ch = this.source[this.index];
            if ((ch >= 'a' && ch <= 'z') || (ch >='0' && ch <= '9') || ch == '-' ||
                ch == '.' || ch == "'" || ch == '"'){
                s += ch;
                this.index++;
            }
            else {
                this.keywords.push(s);
                return;
            }
        }
        this.keywords.push(s);
    }
}