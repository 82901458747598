import {  RenderEvent } from 'core';
import React, { ReactText } from 'react';
import { MainMenuLaunch } from './MainNavMenu';

export class PortalBanner extends React.Component<{event:RenderEvent,company?:string,themeColor?:string;
    logoFile?:string,logoSize?:string}>{
    render(): React.ReactNode {
        if (this.props.event.canvas.app.codeDesignerEnabled) return null;
        
        let content:any[];
        if (this.props.children){
            content = [];
            let index = 0;
            React.Children.forEach(this.props.children,(child:any) => {
                if (child && child.props){
                    if (content.length) {
                        content.push(<div key={"s" + index} style={{marginLeft:15,marginRight:15,opacity:0.35}}>|</div>);
                    }
                    content.push(child);
                    index++
                }
            });
           
        }
        let launch;
        launch = <div style={{marginLeft:15,marginRight:-10}}>
            <MainMenuLaunch event={this.props.event} style={{paddingLeft:10,paddingRight:10}}/>
        </div>
        return (
            <div style={{display:"flex",height:70,alignItems:"center",color:this.props.themeColor,zIndex:10,padding:"8px 30px 8px 15px",
                        backgroundColor:"#fff",justifyContent:"center",borderBottom:"solid 1px rgb(230,232,235)"}}>
                {this.renderLogo()}
                <div style={{fontWeight:500,marginLeft:10}}>{this.props.company}</div>
                <div style={{marginLeft:"auto",display:"flex",alignItems:'center',letterSpacing:"0.3px"}}>
                    {content}
                    {launch}
                </div>
                
            </div>
        );
    }

    renderLogo(){
        let src = this.props.event.getImageUrl(this.props.logoFile);
        return <div style={{textAlign:"center",cursor:"pointer"}} onClick={this.handleLogoClick}>
            <img src={src} style={{display:"inline",width:this.props.logoSize}}/>
        </div>
    }

    handleLogoClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let app = this.props.event.canvas.app;
        if (app.workspaceTabState){
            let tab = app.workspaceTabState.tabList[0];
            if (tab){
                app.workspaceTabState.activateTab(tab.name,true);
            }
        }
    }

}