import { Icon } from 'components/Icon';
import { RenderEvent } from 'core';
import React from 'react';

export class StudioEditorDialog extends React.Component<{event:RenderEvent,icon?:string;title:string,actions?:any}>{
    render(): React.ReactNode {

        let icon;
        if (this.props.icon){
            icon = <Icon icon={this.props.icon} size={18} fill="currentColor"/>
        }
        return <div>
            <div style={{display:"flex",alignItems:"center",padding:"0px 10px",backgroundColor:"rgb(48, 57, 76)",color:"#fff"}}>
                <div style={{display:'flex',alignItems:'center',gap:8}}>
                    {icon}
                    <div style={{fontSize:"13px",fontWeight:500,lineHeight:1}}>{this.props.title}</div>
                </div>
                <div className="studio-dialog-header" style={{marginLeft:"auto",display:"flex",alignItems:"center",gap:10}}>{this.props.actions}</div>
            </div>
            <div style={{padding:15}}>
                {this.props.children}
            </div>
        </div>
    }
}