import { Icon } from 'components/Icon';
import { RenderEvent } from 'core';
import React from 'react';


export class StudioToolbox extends React.Component<{event:RenderEvent}>{

    render(): React.ReactNode {
       let event = this.props.event;
       let maxHeight = "50vh";
       let height = "30vh";
       let toolbox = event.canvas.designerToolbox;

       let items;
       if (toolbox.activeFolderContent){
            items = event.render(toolbox.activeFolderContent);
       }
       else {
            items = <div>Loading...</div>
       }

       return <div className="shadow-xl" style={{backgroundColor:"#fff",borderRadius:8,display:"flex",overflow:"hidden",height,maxHeight}}>
            <div style={{backgroundColor:"#30394c",color:"#fff",display:"flex",flexDirection:"column"}}>
                <div style={{padding:"8px 15px",fontSize:"14px",fontWeight:500}}>Add element</div>
                <div style={{flexGrow:1,overflow:"auto"}} className="rt-scrollbars">
                    {this.props.children}
                </div>
            </div>
            <div style={{display:"flex",flexDirection:"column"}}>
                <div>
                    <Search onChange={this.handleSearchChange}/>
                </div>
                <div style={{flexGrow:1,overflowY:"auto"}} className="rt-scrollbars">
                    {items}
                </div>
            </div>       
       </div>
    }

    handleSearchChange = (value:string) => {

    }

 
    renderHelpPages(){
        let event = this.props.event;
        let elems = [];
        elems.push(<StudioToolboxItem key="123" event={event} name="123" label="Creating carts" icon="help" />);
        elems.push(<StudioToolboxItem key="234" event={event} name="234" label="Discarding carts" icon="help" />)
        return elems;
    }
}


export class StudioToolboxFolder extends React.Component<{event:RenderEvent,name:string,label:string,icon?:string}>{
    render(): React.ReactNode {

        let canvas = this.props.event.canvas;
        let isActive = (canvas.designerToolbox.activeFolder == this.props.name);
        let icon;
        if (this.props.icon){
            icon = <div>
                <Icon icon={this.props.icon} fill="#fff" size={16} />
            </div>
        }
        let backgroundColor = (isActive) ? "rgb(88 96 112)" : "transparent";

        return <div onClick={this.handleClick} style={{padding:"15px 35px 15px 15px",color:"#fff",display:'flex',alignItems:"center",
            gap:10,cursor:"pointer",backgroundColor}}>
            <div style={{lineHeight:1}}>{this.props.label}</div>
            {icon}
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        canvas.designerToolbox.activateFolder(this.props.name);
    }


}
export class StudioToolboxItem extends React.Component<{event:RenderEvent,name:string,value?:string,label:string,icon?:string,isFolder?:boolean}>{
    render(): React.ReactNode {
        let childContent;
        if (this.props.isFolder){
            childContent = <ToolboxFolderContent event={this.props.event} name={this.props.value} label={this.props.label}/>
        }
        return <>
            <div onMouseDown={this.handleMouseDown} className="studio-toolbox-item"
                style={{padding:"10px 45px 10px 15px",cursor:"grab",display:"flex",alignItems:"center",borderRadius:5}}>
                <div style={{marginRight:12,width:24,height:24,display:"flex",justifyContent:'center',alignItems:"center",
                    backgroundColor:"rgb(248,248,248)",border:"solid 1px rgb(242,242,242)",borderRadius:3}}>
                        <Icon icon={this.props.icon} size={16} fill="rgb(80,90,100)"/>
                </div>
                <div style={{fontSize:"14px",fontWeight:500,color:'rgb(19,33,68)',letterSpacing:"0.35px"}}>{this.props.label}</div>
            </div>
            {childContent}
        </>
    }

    renderTool = () => (
        <div style={{padding:"5px 60px 5px 15px",cursor:"grab",display:"flex",alignItems:"center",border:"solid 1px rgb(232,232,232)",borderRadius:5,backgroundColor:"#fff"}}>
            <div style={{marginRight:12,width:20,height:20,display:"flex",justifyContent:'center',alignItems:"center",
                backgroundColor:"rgb(242,242,242)"}}><Icon icon={this.props.icon} size={16} /></div>
            <div style={{fontSize:"14px",fontWeight:500,color:'rgb(19,33,68)',letterSpacing:"0.35px"}}>{this.props.label}</div>
        </div>
    )

    handleMouseDown = (e:React.MouseEvent) => {
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        canvas.dragState.renderDragImage = this.renderTool;
   
        canvas.dragState.startToolDrag({name:this.props.name,value:this.props.value},e.clientX,e.clientY)
    };

}

class ToolboxFolderContent extends React.Component<{event:RenderEvent,label:string,name:string}>{
    render(): React.ReactNode {
        let event = this.props.event;
        let toolbox = event.canvas.designerToolbox;
        let content = toolbox.getFolderContent(this.props.name);
        if (!content){
            return <div style={{fontSize:"13px",cursor:"pointer",marginLeft:40}} onClick={this.handleClickLoad}>Load {this.props.label} fields</div>
        }
        else {
            return <div style={{paddingLeft:30}}>
                {event.render(content)}
            </div>
        }
    }

    handleClickLoad = (e:React.MouseEvent) => {
        e.preventDefault();
        let event = this.props.event;
        let toolbox = event.canvas.designerToolbox;
        toolbox.fetchFolderContent(this.props.name);
    }
}

class Search extends React.Component<{onChange:(value:string)=> void},{editValue:string}>{

    state = {editValue:""};

    render(): React.ReactNode {
        return <div style={{borderTop:"solid 1px rgb(232,232,232)",borderBottom:"solid 1px rgb(232,232,232)",padding:"5px 10px"
            ,display:"flex",alignItems:"center",gap:10}}>
            <Icon icon="search" size={16} fill="rgb(208,208,208)"/>
            <input type="text" value={this.state.editValue} onChange={this.handleChange} style={{border:"none",outline:"none",display:'block',flexGrow:1,
                fontSize:"15px"}}/>
        </div>
    }

    handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        this.setState({editValue:e.target.value});
        this.props.onChange(e.target.value);
    }
}