import { ActionRef, Broadcast, CollectionRef, FieldRef, RenderEvent } from 'core';
import { Collection, ICollectionGroup, ICollectionSortField } from 'core/Collection';
import { CollectionGrouper, IQueryGroupItem, IQueryGroupMap, IReportTotal, ITotalAccumulator, TotalAvg, TotalRowCount, TotalSum } from 'core/CollectionGrouper';
import { HtmlRender } from 'core/HtmlRender';
import React from 'react';

interface IRow {
    $tag:"tr",
    children:any[];
}

class TableState {
    broadcast:Broadcast = new Broadcast();
    collection:CollectionRef;
    tableChildren:any[];
    rows:IRow[] = [];
    firstRow:IRow;
    thead:any;
    groups:ICollectionGroup[] = [];
    rowMap:IQueryGroupMap;
    footer:any;


    get columnSort():ICollectionSortField[] {
        let meta = this.collection.getMeta();
        if (meta) return meta.sort;
        return null;
    }
    getColumnSort(colName:string):{direction:'asc' | 'desc'}{
        let columnSort = this.columnSort;
        if (!columnSort) return null;
        for(let i =0 ; i < columnSort.length; i++){
            let item  = columnSort[i];
            if (item.name == colName){
                if (item.descending){
                    return {direction:'desc'}
                }
                else {
                    return {direction:'asc'};
                }
            }
        }
        return null;
    }

    setColumnSort(colName:string){
        
        let current = this.getColumnSort(colName);
        let descending = current && current.direction == 'asc';
        this.collection.setMetaProps({sort:[{name:colName,descending}]});

        this.buildRowMap();
        this.broadcast.refresh();
    }

    init(event:RenderEvent,tableChildren:any){
       
        this.tableChildren = tableChildren;

      
        let footerIndex = -1;
        for(let i = 0; i < tableChildren.length;i++){
            let child = tableChildren[i];
            if (child.$tag == "thead"){
                if (child.role == "rowgroup"){
                    let field:FieldRef = HtmlRender.getPropValue(event,child.break_field);
                    let fieldName = field.name;
                    this.groups.push({
                        name:fieldName,
                        sort:[{name:fieldName}],
                        headerContent:this.buildRows(child.children)
                    });
                    footerIndex++;
                }
                else {
                    this.thead = child;
                }
            }
            else if (child.$tag == "tbody"){
                this.rows = this.buildRows(child.children);
                this.firstRow = this.rows[0];
            }
            else if (child.$tag == "tfoot"){
                if (child.role == "rowgroup"){
                    if (footerIndex >= 0){
                        this.groups[footerIndex].footerContent = this.buildRows(child.children);
                    }
                    footerIndex--;
                }
                else {
                    this.footer = this.buildRows(child.children);
                }
            }
        }
        this.buildRowMap();
       
    }

    buildRowMap(){
        let sort:ICollectionSortField[] = [];
        let descending = false;
        for(let i =0 ; i < this.groups.length;i++){
            let group = this.groups[i];
            sort = sort.concat(group.sort);
        }
        let columnSort = this.columnSort;
        if (columnSort){
            sort = sort.concat(columnSort);
        }
     
        if (sort.length){
            Collection.sort(this.collection.rows,this.collection.schema,sort);
        }
        let grouper = new CollectionGrouper();
        let meta = this.collection.getMeta();
        let pageSize = meta.pageSize;
        grouper.groups = this.groups;
        grouper.pageSize = pageSize;
        grouper.definedTotals = [];
        let totals = this.collection.schema.totals;
        if (totals){
            for(let i = 0; i < totals.length;i++){
                let total = totals[i];
                let createTotal:() => ITotalAccumulator;
                if (total.totalType == "sum"){
                    createTotal = () => new TotalSum(total.field);
                }
                else if (total.totalType == "count"){
                    createTotal = () => new TotalRowCount();
                }
                else if (total.totalType == "avg"){
                    createTotal = () => new TotalAvg(total.field);
                }
                if (createTotal){
                    var repTotal:IReportTotal = {
                        name:total.name,
                        label:"",
                        fieldType:total.fieldType,
                        createTotal
                    }
                    grouper.definedTotals.push(repTotal);
                }
            }
        }
      
        
        this.rowMap = grouper.groupRows(this.collection.rows);
        this.collection.setMetaProps({numPages:this.rowMap.pages.length,currentPage:1});
    }

    buildRows(children:any[]):IRow[]{
        let rows:IRow[] = [];
        for(let i = 0; i < children.length; i++){
            rows.push(this.buildRow(children[i]));
        }
        return rows;
    }

    buildRow(elem:any):IRow {
        let colElems = [];
        let children = elem.children;
        for(let i = 0; i < children.length;i++){
            let child = children[i];
            let colindex = child.colindex;
            if (!colindex){
                colElems.push({...child,colspan:undefined});
            }
            /*
            else {
                let c = parseInt(colindex,10);
                if (c > maxCol) maxCol = c;
            }
            */
        }
        for(let i = 0; i < children.length; i++){
            let child = children[i];
            let colindex = child.colindex;
            if (colindex){
                let c = parseInt(colindex,10);
                colElems[c - 1] = {...child,colspan:undefined};
            }
        }
        let curCol:any;
        let output:any[] = [];
        for(let i = 0; i < colElems.length; i++){
            let col = colElems[i];
            if (!col){
                if (!curCol){
                    col = {$tag:"td"};
                    output.push(col)
                    curCol = col;
                }
                else {
                    let span = curCol.colspan;
                    if (!span){
                        curCol.colspan = 2;
                    }
                    else {
                        curCol.colspan++;
                    }
                }
            }
            else {
                output.push(col);
                curCol = col;
            }
        }
        return {$tag:"tr",children:output};
    }
}

export class Table extends React.Component<{event:RenderEvent,tableProps:any}>{

    tableState:TableState;

    constructor(props){
        super(props);
        this.tableState = new TableState();
        let tableProps = this.props.tableProps;
        this.tableState.collection = tableProps.collection;
        this.tableState.init(this.props.event,tableProps.children);
    }

    componentDidMount(): void {
        this.tableState.broadcast.connect(this);
        this.tableState.collection.connect(this,action => {
            if (action == "rebuild"){
                this.tableState.buildRowMap();
            }
            this.tableState.broadcast.refresh();
        })
    }

    componentWillUnmount(): void {
        this.tableState.broadcast.disconnect(this);
        this.tableState.collection.disconnect(this);
    }

    render(): React.ReactNode {
        let tableProps = this.props.tableProps;
        let event = this.props.event;

        let collection:CollectionRef = this.tableState.collection;

        if (!collection) return null;
        
        
        let map = this.tableState.rowMap;

        let mapItems = map.items;
        let meta = collection.getMeta();
        let currentPage = meta.currentPage ? meta.currentPage - 1 : 0;
        let page = map.pages[currentPage];
        
        let bodySet = new BodySet();

        for(let dr = page.start; dr < page.end;dr++){
            let item = mapItems[dr];
            let dataRow = item.row;

            if (item.type == 'header' && item.header.group.headerContent){
                let rowScope = {[collection.name]:dataRow,[collection.name + "$totals"]:item.header.totals};
                let rowEvent = event.create(rowScope);
                bodySet.flush();
                let groupContent = this.renderGroupContent(rowEvent,item.header.group.headerContent);
                bodySet.tbodies.push(<tbody className="table-group-header" key={dr}>{groupContent}</tbody>)
            }
            else if (item.type == 'row'){
                let rowEvent = event.createScope(collection.name,dataRow);
                if (this.tableState.rows){
                    for(let r = 0; r < this.tableState.rows.length;r++){
                        let row = this.tableState.rows[r];
                        if (row){
                            let colElems = rowEvent.render(row.children);
                            bodySet.tableRows.push(<TableRow key={dr} event={rowEvent} tableProps={tableProps} item={item}>{colElems}</TableRow>);
                        }
                    }
                }
            }
            else if (item.type == 'footer' && item.footer.group.footerContent){
                let rowScope = {[collection.name]:dataRow,[collection.name + "$totals"]:item.footer.totals};
                let rowEvent = event.create(rowScope);
                bodySet.flush();
                let groupContent = this.renderGroupContent(rowEvent,item.footer.group.footerContent);
                bodySet.tbodies.push(<tbody className="table-group-footer" key={dr}>{groupContent}</tbody>)
            }
            else if (item.type == "last-row" && this.tableState.footer){
                let rowScope = {[collection.name]:dataRow,[collection.name + "$totals"]:item.footer.totals};
                let rowEvent = event.create(rowScope);
                bodySet.flush();
                let groupContent = this.renderGroupContent(rowEvent,this.tableState.footer);
                bodySet.tbodies.push(<tbody className="table-group-footer" key={dr}>
                    <tr style={{borderTop:"solid 1px #000",height:1}} />
                    {groupContent}
                </tbody>)
            }
        }
        bodySet.flush();
        return <div style={{overflowY:"auto",height:"100%"}} className="rt-scrollbars">
            <table className="table rt-scrollbars" onClick={this.handleClick} >
                {this.renderTableHeader(event)}
                {bodySet.tbodies}
            </table>
        </div>
    }

    renderGroupContent(event:RenderEvent,content:any){
        if (!content) return null;
        let outputRows = [];
        for(let i = 0 ; i < content.length; i++){
            let row = content[i];
            let colElems = event.render(row.children);
            outputRows.push(<tr key={i}>{colElems}</tr>)

        }
        return outputRows;
    }

    
    renderTableHeader(event:RenderEvent){
        let tableState = this.tableState;
        if (tableState.thead){
            return event.render(tableState.thead);
        }
        else if (tableState.firstRow && tableState.firstRow.children){
            let thElems = [];
            for(let i =0 ; i < tableState.firstRow.children.length; i++){
                let child = tableState.firstRow.children[i];
                let label = HtmlRender.getPropValue(event,child.label);
                let className = HtmlRender.getPropValue(event,child.class);
                let sortField:FieldRef = HtmlRender.getPropValue(event,child.sort_field);
                if (sortField){
                    thElems.push(<HeaderCell key={i} tableState={tableState} className={className} colName={sortField.name}>{label}</HeaderCell>)
                }
                else {
                    thElems.push(<th key={i}>{label}</th>)
                }
            }
            return <thead className="table-header">
                <tr>
                    {thElems}
                </tr>
            </thead>
        }
    }

    handleClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        let elem = e.target as HTMLElement;
        let row = elem.closest('[data-row-index]');
        if (!row) return;
        let rowIndex = parseInt(row.getAttribute('data-row-index'),10);
        let dataRow = this.tableState.collection.rows[rowIndex];
        let action:ActionRef = this.props.tableProps.onclick;
        if (action){
            action.trigger({scope:{[this.tableState.collection.name]:dataRow}});
        }
    }

   

}

class BodySet {
    public tableRows:any[] = [];
    public tbodies:any[] = [];

    public flush(){
        if (this.tableRows.length){
            this.tbodies.push(<tbody className="table-rows" key={"tb" + this.tbodies.length}>{this.tableRows}</tbody>);
            this.tableRows = [];
        }
    }
}
class TableRow extends React.Component<{event:RenderEvent,item:IQueryGroupItem,tableProps:any,children:any}> {
    render(): React.ReactNode {
        return <tr data-row-index={this.props.item.rowIndex}>
            {this.props.children}
        </tr>
    }
}

class HeaderCell extends React.Component<{tableState:TableState,className:string,colName:string,onResize?:() => void}>{
    render() {
        let sort = this.props.tableState.getColumnSort(this.props.colName);
        let sortElem;
        if (sort){
            if (sort.direction == 'desc') {
                sortElem = <span className="RT-DataTable__header-sort-icon"><SortDirectionDesc /></span>;
            }
            else {
                sortElem = <span className="RT-DataTable__header-sort-icon"><SortDirectionAsc /></span>;
            }
        }
        else {
            sortElem = <div style={{width:16}} />
        }

       // let resizeHandle = <ColumnResizeHandle layout={this.props.layout} column={col} onResize={this.props.onResize}/>
        let resizeHandle;
        let content;
        let className = this.props.className;
        if (className && className.indexOf("text-right") != -1){
            content = (<div  style={{display:"flex",alignItems:"center",justifyContent:"flex-end"}}>
                {sortElem}
                {this.props.children}
            </div>)
        }
        else {
            content=(<div  style={{display:"flex",alignItems:"center"}}>
                {this.props.children}
                {sortElem}
            </div>)
        }
        return <th style={{cursor:"pointer"}} onClick={this.handleClick}>
            {content}
        </th>;
    }



    handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.tableState.setColumnSort(this.props.colName);
    }
}

class SortDirectionDesc extends React.Component {
    render(){
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path d="M 12 3 C 11.448 3 11 3.448 11 4 L 11 17.070312 L 7.1367188 13.207031 C 6.7457187 12.816031 6.1126563 12.816031 5.7226562 13.207031 L 5.6367188 13.292969 C 5.2457187 13.683969 5.2457187 14.317031 5.6367188 14.707031 L 11.292969 20.363281 C 11.683969 20.754281 12.317031 20.754281 12.707031 20.363281 L 18.363281 14.707031 C 18.754281 14.316031 18.754281 13.682969 18.363281 13.292969 L 18.277344 13.207031 C 17.886344 12.816031 17.253281 12.816031 16.863281 13.207031 L 13 17.070312 L 13 4 C 13 3.448 12.552 3 12 3 z"></path>
    </svg>
    }
}

class SortDirectionAsc extends React.Component {
    render(){
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
        <path d="M 12 3.3417969 C 11.744125 3.3417969 11.488469 3.4412187 11.292969 3.6367188 L 5.6367188 9.2929688 C 5.2457188 9.6829687 5.2457187 10.316031 5.6367188 10.707031 L 5.7226562 10.792969 C 6.1126563 11.183969 6.7457187 11.183969 7.1367188 10.792969 L 11 6.9296875 L 11 20 C 11 20.552 11.448 21 12 21 C 12.552 21 13 20.552 13 20 L 13 6.9296875 L 16.863281 10.792969 C 17.253281 11.183969 17.886344 11.183969 18.277344 10.792969 L 18.363281 10.707031 C 18.754281 10.317031 18.754281 9.6839688 18.363281 9.2929688 L 12.707031 3.6367188 C 12.512031 3.4412187 12.255875 3.3417969 12 3.3417969 z"></path>
    </svg>
    }
}

export class Pagination extends React.Component<{event:RenderEvent,collection:CollectionRef}>{

    componentDidMount(): void {
        let collection = this.props.collection;
        if (collection){
            collection.connect(this,action => {
                this.forceUpdate()
            });
            this.forceUpdate();
        }
    }

    componentWillUnmount(): void {
        let collection = this.props.collection;
        if (collection){
            collection.disconnect(this);
        }
    }

    render(){
        let meta = this.props.collection.getMeta();
        if (!meta) return null;
        let numPages = meta.numPages;
        let currentPage = meta.currentPage;
        let style:React.CSSProperties = {userSelect:"none",whiteSpace:"nowrap"};
        if (numPages > 7){
            
            let prev;
            let next;
           
            let start = currentPage - 2;
            if (start < 2){
                start = 2;
            }
            let end = start + 4;
            if (end > numPages - 1){
                end = numPages - 1;
                start = end - 4;
            }
            
            if (start > 2){
                prev = <PageIconButton name="prev" onClick={this.handleIconClick} numPages={numPages} currentPage={currentPage}/>
                start++;
            }
            if (end < numPages -1){
                next = <PageIconButton name="next" numPages={numPages} currentPage={currentPage} onClick={this.handleIconClick} />
                end--;
            }
            let rangeElems = [];
            let i = start;
            while (i <= end){
                rangeElems.push(<PageButton key={i} pageNumber={i} currentPage={currentPage} onClick={this.handlePageClick} />)
                i++;
            }
            return <div style={style}>
                <PageButton pageNumber={1} leftEdge currentPage={currentPage} onClick={this.handlePageClick} />
                {prev}
                {rangeElems}
                {next}
                <PageButton pageNumber={numPages} rightEdge currentPage={currentPage} onClick={this.handlePageClick} />
            </div>
        }
        else {
            let elems = [];
            for(let i = 1; i <= numPages;i++){
                elems.push(<PageButton key={i} pageNumber={i} currentPage={currentPage} onClick={this.handlePageClick} />)
            }
            return <div style={style}>
                <PageIconButton name="prev" numPages={numPages} currentPage={currentPage} onClick={this.handleIconClick} />
                {elems}
                <PageIconButton name="next" numPages={numPages} currentPage={currentPage} onClick={this.handleIconClick} />
            </div>
        }
    }

    handleIconClick = (name:string) => {
        let pageNumber:number;
        let collection = this.props.collection;
        let meta = collection.getMeta();
        if (!meta) return null;
    
        let currentPage = meta.currentPage;
        let numPages = meta.numPages;

        if (name == "prev"){
            if (currentPage > 1){
                pageNumber = currentPage -1;
                collection.setMetaProps({currentPage:pageNumber});
                collection.refresh();
            }
        }
        else if (name == "next"){
            if (currentPage < numPages){
                pageNumber = currentPage + 1;
                collection.setMetaProps({currentPage:pageNumber});
                collection.refresh();
            }
        }
    }


    handlePageClick = (pageNumber:number) => {
        let collection = this.props.collection;
        collection.setMetaProps({currentPage:pageNumber});
        collection.refresh();
    }
}

class PageIconButton extends React.Component<{name:string,numPages:number,currentPage:number;onClick:(name:string) => void}>{
    render(){
        let name = this.props.name;
        let style:React.CSSProperties = {outline:"none",width:36,height:36,
            display:"inline-block",padding:8,fontSize:"14px",color:"rgb(0,0,0)"};
        let icon;

        if (name == "prev"){
            if (this.props.currentPage < 2){
                style.opacity = 0.4;
            }
            icon = "<";
        }
        else {
          
            icon = ">";
            if (this.props.currentPage >= this.props.numPages){
                style.opacity = 0.4;
            }
        }
        return <button style={style}
            onClick={this.handleClick}>{icon}</button>
    }
    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.name);
    }
}

class PageButton extends React.Component<{pageNumber:number,currentPage:number,onClick:(pageNumber:number) => void,leftEdge?:boolean,rightEdge?:boolean}>{
    render(){
        let style:React.CSSProperties = {outline:"none",textAlign:'center',
        width:36,height:36,display:"inline-block",padding:8,fontSize:"14px",color:"rgb(0,0,0)"};
        if (this.props.pageNumber == this.props.currentPage){
            style.backgroundColor = "rgb(230,233,237)";
            style.color = "#000";
            style.fontWeight = 500
        }
        else {
           // style.backgroundColor = "#fff";
        }
    
        return <button style={style}
            onClick={this.handleClick}>{this.props.pageNumber}</button>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.pageNumber);

    }
}

