import { Broadcast } from "core/Broadcast";
import { IComponentDefinition } from "core/ComponentDefinition";
import { nodeModuleNameResolver } from "typescript";

export class StatementNode {
    public id:string;
    public elem:any;
    public tree:StatementTree;
    public section:string;
    public parent:StatementNode;
    public statementDef:IComponentDefinition;
    public children:StatementNode[];
    public propNodes:{[name:string]:StatementNode};
    public isPropNode:boolean;

    public get isExpanded():boolean {
        return this.tree.isExpanded(this);
    }

    public get isEdit():boolean {
        return this.tree.isEdit(this);
    }

    public setEdit(value:boolean,options?:{}){
        this.tree.setEdit(this,value);
    }

    public setExpanded(value:boolean,options?:{}){
        this.tree.setExpanded(this,value);
    }

    public get hasCursorAfter():boolean {
        return (this.tree.cursor && this.tree.cursor.afterId == this.id);
    }

    public get hasCursorAsChild():boolean {
        return (this.tree.cursor && !this.tree.cursor.afterId && this.tree.cursor.containerId == this.id);
    }

    public getPrevSibling():StatementNode {
        if (!this.parent || !this.parent.children) return null;
        let index = this.getIndex();
        if (index){
            return this.parent.children[index - 1];
        }
        else {
            return null;
        }
    }

    public getNextSibling():StatementNode {
        if (!this.parent || !this.parent.children) return null;
        let index = this.getIndex();
        if ((index + 1) < this.parent.children.length){
            return this.parent.children[index + 1];
        }
        else {
            return null;
        }
    }

    public getIndex():number {
        if (!this.parent || !this.parent.children) return 0;
        let children = this.parent.children;
        for(let i = 0 ; i < children.length;i++){
            let child = children[i];
            if (child.id == this.id){
                return i;
            }
        }
        return 0;
    }

}


export class StatementTree {

    statements:{[name:string]:any};
    public broadcast:Broadcast = new Broadcast();
    public root:StatementNode;
    private _expandedId:string;
    private _editId:string;
    public cursor: {
        containerId:string;
        afterId:string;
    }
    
    public createNode(section:string,elem:any,parent:StatementNode):StatementNode {
        let node = new StatementNode();
        node.id = section + ":" + elem.$id;
        node.elem = elem;
        node.tree = this;
        node.section = section;
        node.parent = parent;
        let statementDef = this.statements[elem.$kind];
        node.statementDef = statementDef;
        for(let propName in statementDef.props){
            if (propName == "children"){
                node.children = [];
                if (elem.children){
                    for(let i =0 ; i < elem.children.length;i++){
                        let child = elem.children[i];
                        if (child){
                            node.children.push(this.createNode(section,child,node));
                        }
                    }
                }
            }
            else {
                let value = elem[propName];
                if (value && value.$kind){
                    node.propNodes = node.propNodes || {};
                    let propNode = this.createNode(section,value,node);
                    propNode.isPropNode = true;
                    node.propNodes[propName] = propNode;
                }
            }
        }
        
        return node;
    }

    public loadStatements(statements:any,components:any){
        this.statements = {};
        if (statements){
            for(let i = 0; i < statements.length;i++){
                let statement = statements[i];
                this.statements[statement.name] = statement;
            }
        }
        for(let key in components){
            this.statements[key] = components[key].$def;
        }
    }

   

    public loadSource(elem:any){
        this.root= new StatementNode();
        this.root.id = "root";
        this.root.elem = elem;
        this.root.tree = this;
        this.root.children = [];

        let children = elem.children;
        for(let i =0 ; i < children.length;i++){
            let child = children[i];
            let section = child.$id;
            let sectionNode = this.createNode(section,child,this.root);
            this.root.children.push(sectionNode);
        }
        
    }

    public isExpanded(node:StatementNode):boolean {
        return node.id == this._expandedId;
    }

    public isEdit(node:StatementNode):boolean {
        return node.id == this._editId;
    }

    public setEdit(node:StatementNode,value:boolean,options?:{}){
        if (value == true){
            this._editId = node.id;
            this.broadcast.refresh();
            return;
        }
        if (node.id == this._editId){
            this._editId = null;
            this.broadcast.refresh();
        }
    }

    public setExpanded(node:StatementNode,value:boolean,options?:{}){
        if (value == true){
            this._expandedId = node.id;
            this.broadcast.refresh();
            return;
        }
        if (node.id == this._expandedId){
            this._expandedId = null;
            this.broadcast.refresh();
        }
    }

    public clearExpanded(){
        this._editId = null;
        this._expandedId = null;
    }
}