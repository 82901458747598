import { ActionRef, CollectionRef, FieldType, makeComponent, RenderEvent } from 'core';
import { NumberFormatter } from 'core/FieldFormatter';
import React from 'react';
import {Area, AreaChart, Bar, BarChart, CartesianGrid, Cell,  Legend, Line, LineChart, Pie, PieChart, Tooltip, XAxis, YAxis} from 'recharts';


export const SeriesChart = makeComponent(class extends React.Component<{ event:RenderEvent,
    chartType:'bar' | 'line' | 'pie' | 'area',
    label?:string,
    collection?:CollectionRef,
    settings?:SeriesChartSettings,
    onRowClick?:ActionRef}>{

    containerRef = React.createRef<HTMLDivElement>();

    componentDidMount(){
        this.forceUpdate();
    }

    render() {
        let chartType = this.props.chartType;
      
        let content;
        let settings:SeriesChartSettings = this.props.settings;
        let collection = this.props.collection;
        if (!settings || !this.containerRef.current){
            content = this.renderEmptyState();
        }
        else {
            if (chartType == "pie"){
                content =  this.renderPieChart(collection,settings);
            }
            else if (chartType == "line"){
                content = this.renderLineChart(collection,settings);
            }
            else if (chartType == "area"){
                content = this.renderAreaChart(collection,settings);
            }
            else {
                content = this.renderBarChart(collection,settings)
            }
        }
        return (
            <div ref={this.containerRef} className="RT-Section">
                <div className="RT-Section__title">{this.props.label}</div>
                <div style={{marginTop:15,display:"flex",justifyContent:'center'}}>
                    {content}
                </div>
            </div>

        );
    }

    renderEmptyState(){
        return(<div style={{display:"flex",flexDirection:"column",height:"100%",textAlign:"center"}}>
            <div>No results found</div>
        </div>);

    }
    colors = ['#65a1f0','#82ca9d']; // ['#8884d8','#82ca9d'];

    renderLineChart(collection:CollectionRef, settings:SeriesChartSettings){
        let lines:any[] = [];
        let dataKeys = settings.dataKeys;
        let colorIndex = 0;
        for(let i = 0; i < dataKeys.length;i++){
            let dataKey = dataKeys[i];
            let color = this.colors[colorIndex++];
            lines.push(<Line type="monotone" dataKey={dataKey.name} stroke={color} name={dataKey.label} activeDot={{r:8}} />);
            if (colorIndex > this.colors.length){
                colorIndex = 0;
            }
        }
        let width = this.containerRef.current.offsetWidth;
        return (
            <LineChart
                width={width}
                height={285}
                data={collection.rows}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
                onClick={this.handleBarClick}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={settings.labelField} />
                <YAxis />
                <Tooltip />
                <Legend />
                {lines}
            </LineChart>
        );
    }

    renderAreaChart(collection:CollectionRef, settings:SeriesChartSettings){
        let lines:any[] = [];
        let dataKeys = settings.dataKeys;
        let colorIndex = 0;
        for(let i = 0; i < dataKeys.length;i++){
            let dataKey = dataKeys[i];
            let color = this.colors[colorIndex++];
            lines.push(<Area type="monotone" dataKey={dataKey.name} name={dataKey.label} stroke={color} fill={this.colors[0]} activeDot={{r:8}} />);
            if (colorIndex > this.colors.length){
                colorIndex = 0;
            }
        }
        let width = this.containerRef.current.offsetWidth;
        return (
            <AreaChart
                width={width}
                height={285}
                data={collection.rows}
                margin={{
                    top: 5, right: 30, left: 20, bottom: 5,
                }}
                onClick={this.handleBarClick}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={settings.labelField} />
                <YAxis />
                <Tooltip />
                <Legend />
                {lines}
            </AreaChart>
        );
    }


    renderBarChart(collection:CollectionRef, settings:SeriesChartSettings) {
        let bars:any[] = [];
        let dataKeys = settings.dataKeys;
        let colorIndex = 0;
        for(let i = 0; i < dataKeys.length;i++){
            let dataKey = dataKeys[i];
            let color = this.colors[colorIndex++];
            bars.push( <Bar dataKey={dataKey.name} fill={color} name={dataKey.label}
             />);
            if (colorIndex > this.colors.length){
                colorIndex = 0;
            }
        }
        let labelField = settings.labelField;
        let width = this.containerRef.current.offsetWidth;
        return (
            <BarChart
                width={width}
                height={285}
                data={collection.rows}
                onClick={this.handleBarClick}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={labelField}  minTickGap={0} height={60} tick={<CustomizedAxisTick/>} />
                <YAxis tickFormatter={this.formatYTick} />
                <Tooltip formatter={this.formatValue}/>
                <Legend />
                {bars}
            </BarChart>
        );
    }

    formatYTick = (value) => {
        let fieldType = this.props.settings.valueType;
        if (fieldType == "money")
            return NumberFormatter.formatMoney(value,"$#,###;0");
        if (fieldType == "integer")
            return NumberFormatter.formatInt(value,"#,##0;0");
        if (fieldType == "decimal")
            return NumberFormatter.formatNumber(value,"#,##0;0");
        return value;
    }

    formatValue = (value) => {
        let fieldType = this.props.settings.valueType;
        if (fieldType == "money")
            return NumberFormatter.formatMoney(value,"$#,##0;0");
        if (fieldType == "integer")
            return NumberFormatter.formatInt(value,"#,##0;0");
        if (fieldType == "decimal")
            return NumberFormatter.formatNumber(value,"#,##0;0");
        return value;
    }

    handleBarClick = (data,index) => {
        
        if (this.props.onRowClick && data){
            let dataRow = data.activePayload[0].payload;
            let scope = {[this.props.collection.name]:dataRow}
            this.props.onRowClick.trigger({scope});
        }
    }

    handlePieClick = (data,index) => {
        if (this.props.onRowClick && data){
            let dataRow = data.payload.payload;
            let scope = {[this.props.collection.name]:dataRow}
            this.props.onRowClick.trigger({scope});
        }
    }
  
    renderPieChart(collection:CollectionRef, settings:SeriesChartSettings){
        let colors = ['#82ca9d','#b5dec5','#8884d8','#b3b1da'];
        let n = colors.length;

        let width = this.containerRef.current.offsetWidth;
        let dataKeys = settings.dataKeys;
        let dataKey = dataKeys[0];

        return (
            <PieChart width={width} height={285}>
                <Pie
                    dataKey={dataKey.name}
                    name={dataKey.label}
                    isAnimationActive={false}
                    data={collection.rows}
                    cx={width/2}
                    cy={120}
                    outerRadius={80}
                    fill="#8884d8"
                    label={this.renderLabel}
                    onClick={this.handlePieClick}
                >
                    {collection.rows.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colors[index % n]}
                        />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        );
    }

    renderLabel = (data:any) => {
        let settings = this.props.settings;
        return data[settings.labelField];
    }

  
});

class CustomizedAxisTick extends React.PureComponent<any> {
    render() {
      const { x, y, stroke, payload } = this.props;
        let value = payload.value;
        let segments;
        let format;
        if (value){
            if (typeof(value) === "string"){
                let i = value.indexOf(":");
                if (i != -1){
                    format = value.substring(i + 1);
                    value = value.substring(0,i);
                } 
                segments = value.split('|');
            }
            else {
                segments = [value.toString()];
            }
        }
        else {
            segments = [null];
        }
        let fontWeight = (format == "bold") ? 700 : 400;
        let fill = "#666";
        let dt;
        if (segments.length > 1){
            dt = (<text x={x}  y={y} dy={32}  textAnchor="middle"  fontWeight={fontWeight} fill={fill}  >
            {segments[1]} 
        </text>);
        }
        return (
          <g>
            <text x={x} y={y} dy={16} fontWeight={fontWeight} textAnchor="middle" fill={fill}  >
                {segments[0]}  
            </text>
            {dt}
        </g>
      );
    }
  }

export type SeriesChartSettings = {dataKeys:DataKey[],labelField?:string,valueType?:FieldType}

export type DataKey = {
    name:string;
    label:string;
}