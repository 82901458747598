import { ActionRef, RenderEvent } from 'core';
import { Expressions } from 'core/studio/Expressions';
import React from 'react';



export class InlineTextPropEditor extends React.Component<{ event: RenderEvent; $id: string; $elem: any; propName: string; 
    className?: string; style?: React.CSSProperties; allowEnterForNewLine?: boolean;placeHolder:string }> {

    dirty: boolean;
    editValue: string;
    ref = React.createRef<HTMLTextAreaElement>();

    componentDidMount() {
        let canvas = this.props.event.canvas;
        if (this.props.$id == canvas.designFocus) {
            canvas.designFocus = null;
            this.ref.current.focus();
        }
    }

    render() {

        let divStyle: React.CSSProperties = { ...this.props.style, opacity: 0 ,paddingRight:20};
        let textAreaStyle: React.CSSProperties = {
            ...this.props.style, position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "transparent",
            outline: "none", resize: "none"
        };
        let sourceElem = this.props.$elem;
        let value = this.dirty ? this.editValue : Expressions.toDisplayValue(sourceElem["$src_" + this.props.propName]);
        let displayValue;
        if (value){
            displayValue = value;
            divStyle.opacity = 0;
        }
        else {
            displayValue = this.props.placeHolder;
            divStyle.opacity = 0.6;
        }
        return <div style={{ position: "relative" }}>
            <div style={divStyle} className={this.props.className}>{displayValue || '\u00A0'}</div>
            <textarea data-elem-id={this.props.$id} ref={this.ref} onChange={this.handleChange} onBlur={this.handleBlur} onKeyPress={this.handleKeyPress} style={textAreaStyle} className={this.props.className} value={value || ''} />
        </div>;

    }

    handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key == "Enter") {
            e.preventDefault();
            let propName = this.props.propName;

            let canvas = this.props.event.canvas;
            let commandBuffer = canvas.studioCommandBuffer;

            if (this.dirty) {
                let expr = Expressions.fromDisplayValue(this.editValue);
                commandBuffer.setProp(this.props.$id,propName,expr);
            }
            if (this.props.allowEnterForNewLine) {
                commandBuffer.appendLine(this.props.$id);
            }
            commandBuffer.send();
            this.dirty = false;
            this.forceUpdate();
        }
    };

    handleBlur = (e: React.FocusEvent) => {
        if (!this.dirty)
            return;
        let propName = this.props.propName;
        let expr = Expressions.fromDisplayValue(this.editValue);
        let canvas = this.props.event.canvas;
        this.props.$elem["$src_" + propName] = expr; // set temp value
        canvas.studioCommandBuffer.setProp(this.props.$id,propName,expr);
        setTimeout(()=> canvas.studioCommandBuffer.send(),100);
        
        this.dirty = false;
        this.forceUpdate();
    };

    handleChange = (e: React.ChangeEvent) => {
        var target = e.target as HTMLTextAreaElement;
        this.dirty = true;
        this.editValue = target.value;
        this.forceUpdate();
    };



}
