export class Expressions {
    public static toDisplayValue(expr:any):string {
        if (!expr) return "";
        if (expr[0] == "@"){
            return "{" + expr + "}";
        }
        if (expr[0] == "'"){
            expr = expr.substring(1);
        }
        if (expr.length && expr[expr.length - 1] == "'"){
            expr = expr.substring(0,expr.length - 1);
        }
        return expr;
    }

    public static fromDisplayValue(value:string):any {
        if (!value) return null;
        if (value[0] == "{"){
            value = value.substring(1);
            if (value.endsWith("}")){
                value = value.substring(0,value.length - 1);
            }
            return value;
        }
        return "'" + value.replaceAll("'","''") + "'";
    }
}