import React from 'react';
import { Icon } from '../Icon';
import { NumberFormatter } from 'core/FieldFormatter';
import { IProductRow, ProductTableStore } from './ProductTableStore';

export class CellValue extends React.Component<{ store:ProductTableStore,row: IProductRow; style: React.CSSProperties; onSelectCell: (row: IProductRow) => void; isActive: boolean; }> {
    render() {
        let row = this.props.row;
        let value;
        let cursor;
        let className;
        let style = this.props.style;
        let store = this.props.store;

        if (row) {
            value = row.qty_future;
            cursor = "pointer";
            if (this.props.isActive) {
                className = "hover-selector is-active";
            }
            else {
                className = "hover-selector";
            }
        }
        else {
            return <td style={style}><div style={{ textAlign: 'center', fontSize: "14px", color: "rgb(180,180,180)" }}>-</div></td>;
        }


        if (row.cart_qty > 0) {
            let fill = (value >= row.cart_qty) ? store.theme.cartValueColor: store.theme.cartValueWarningColor;
            let icon = <Icon icon="cart" size={18} fill={fill} />;
            return (<td style={style} onClick={this.handleClick}>
                <div className={className} style={{ display: "flex", padding: "1px 3px 1px 12px", cursor, gap: 6, alignItems: "center", justifyContent: "flex-end" }}>

                    <div style={{ fontWeight: 500, minWidth: 36 }}>{NumberFormatter.formatNumber(row.cart_qty)}</div>
                    <div style={{ width: 20 }}>{icon}</div>
                </div></td>);
        }
        else if (value > 0) {
            let icon = <Icon icon="circleCheck" size={18} fill={store.theme.circleCheckColor} />;
            return (<td style={style} onClick={this.handleClick}>
                <div className={className} style={{ display: "flex", padding: "1px 3px 1px 12px", cursor, gap: 6, alignItems: "center", justifyContent: "flex-end" }}>

                    <div style={{ fontWeight: 500, minWidth: 36 }}>{NumberFormatter.formatNumber(value)}</div>
                    <div style={{ width: 20 }}>{icon}</div>
                </div></td>);
        }
        else {
            let formatted = (value == 0) ? '\u00A0' : NumberFormatter.formatNumber(value);
            return <td style={style} onClick={this.handleClick}><div className={className} style={{ padding: '1px 29px 1px 12px', cursor }}>{formatted}</div></td>;
        }
    }

    handleClick = (e: React.MouseEvent) => {
        this.props.store.clickThrottle.handle(e,null,()=> {
            e.preventDefault();
            e.stopPropagation();
            if (this.props.row) {
                this.props.onSelectCell(this.props.row);
            }
        });
    };
}
