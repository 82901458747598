import { Canvas, RenderEvent, Url } from 'core';
import React from 'react';
import { HelpButton } from './HelpButton';
import { Icon } from './Icon';

export class PageTitle extends React.Component<{event:RenderEvent,recordName?:any,icon?:any,styles?:string,image:string,imageSize:any}>{
    render(){
        let canvas = this.props.event.canvas;
        
        if (canvas.renderDesignSlot){
            return canvas.renderDesignSlot(this.props.event,"title");
        }
    
        let styles = this.props.event.computeStyles(null,this.props.styles);
       
        let title;
        let subtitle;
        if (this.props.recordName){
            title = this.props.recordName;
            subtitle = canvas.subtitle || canvas.title;
        }
        else {
            title = canvas.title;
            subtitle = canvas.subtitle;
        }
       

        if (canvas.layer == "dialog" || canvas.layer == "lookup" || canvas.layer == "dialog-stack" || canvas.layer == "popup"){
            return this.renderForDialog(canvas,title);
        }

        let helpTopic = canvas.getHelpTopic("$page");
      
        let entityLabelElem;
        if (subtitle){
            entityLabelElem = (<label className="RT-Static-Field__label" style={{fontSize:"12px",
                marginBottom:4}}>{subtitle}</label>);
        }
        let recordLink = this.getLinkHref();
        let titleElem;
        let titleStyle:React.CSSProperties;
       
        if (canvas.layer == "stack" && canvas.parent.stackPosition == "overlay"){
            titleStyle = styles.style; // {fontWeight:500,fontSize:"20px",...styles.style};
        }
        else {
            titleStyle = styles.style;
        }
        let titleClass = "RT-Record-Header__title";
        if (styles.className){
            titleClass += " " + styles.className;
        }

        let iconElem;
        if (canvas.icon){
            iconElem = <div className="RT-Icon icon-lg text-gray-400">
                <Icon icon={canvas.icon} size={24} fill="currentColor" />
            </div>
        }
        else {
            iconElem = this.props.icon;
        }
       
        let helpElem;
        if (helpTopic){
            helpElem = <div style={{marginLeft:15}}>
                <HelpButton event={this.props.event} topic={helpTopic} />
            </div>
        }

        if (recordLink){
            titleElem = (
                
                <div>
                    {entityLabelElem}
                    <a href={recordLink} onClick={this.handleTitleClick} className={titleClass} style={titleStyle} >
                        {title}
                        <div className="RT-Record-Header__link">
                            {<Icon icon="link" size={16}/>}
                        </div>
                        {helpElem}
                    </a>
                </div>
            )
        }
        else {
            titleElem = <div>
                {entityLabelElem}
                <div className={titleClass} style={titleStyle}>
                    {title}
                    {helpElem}
                </div>
            </div>
        }

        /*
        let icon = this.props.icon || canvas.icon;
        if (icon){ // rgb(147,166,183)
            iconElem = <div style={{height:40,width:40,backgroundColor:"#468ffd",borderRadius:99, 
                display:"flex",alignItems:'center',justifyContent:'center',marginRight:12}}>
                <Icon icon={this.props.icon} size={26} fill="rgb(255,255,255)" />
            </div>
        }
        */

        let children;
        if (this.props.children){
            children = <div style={{alignSelf:"flex-end",display:"flex",alignItems:"center"}}>
                {this.props.children}
            </div>
        }
        return (<div style={{display:"flex",alignItems:"center",gap:12,minHeight:40}}>
            {iconElem}
            {titleElem}
            {children}
        </div>);

    }

    renderForDialog(canvas:Canvas,title:any){
       
        let icon;
        if (this.props.icon){
            icon = (<div style={{marginRight:10}}>
                {this.props.icon}
            </div>)
        }
        /*
        else if (this.props.style == "success"){
            icon = (<div style={{marginRight:10}}>
                {DialogIcons.renderSuccessIcon({fill:"#3fc5d9",width:26,height:26})}
            </div>)
        }
        */
       let subTitle;
       if (canvas.subtitle){
           subTitle = <div style={{fontSize:"12px",fontWeight:500,color:"var(--rt-label-color)",letterSpacing:"0.4px"}}>{canvas.subtitle}</div>
       }
    
        return(<div style={{display:"flex",alignItems:"center"}}>
            {icon}
            <div style={{textAlign:'left'}}>
                {subTitle}
                <div>{title}</div>
            </div>
        </div>);
    }
    
    handleTitleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let event = this.props.event;
        if (event){
            let app = event.canvas.app;
            let link = this.getLinkHref();
            navigator.clipboard.writeText(link);
            app.notifications.dispatch("show",{text:"Link copied to clipboard",style:"success"});
        }

    }

    getLinkHref():string {
        let event = this.props.event;
        if (event){
            let app = event.canvas.app;
            let link = Url.stringifyScreenLink(app,event.canvas.launchParams);
            return link;
        }
    }

}



