import React from 'react';


export class LoadingDots extends React.Component<{}>{
    render(): React.ReactNode {
        return (<div className="Bot__typing">
            <div className="Bot__typing__dot"></div>
            <div className="Bot__typing__dot"></div>
            <div className="Bot__typing__dot"></div>
        </div>)
    }
}