import { Canvas, PageContainer } from 'core';
import React from 'react';

export class PageContextMenu extends React.Component<{page:string,onClose:() => void,pageParams:any,onLoaded:() => void}>{
    render(): React.ReactNode {
        let pageProps = {
            "@page_name":this.props.page,
            "@page_params":this.props.pageParams
        }
        return <div className="shadow-xl" style={{backgroundColor:"#fff",padding:15,borderRadius:8}}>
             <PageContainer page={{name:"pages/user/pageContextMenu", props:pageProps}} onClose={this.props.onClose} onLoaded={this.handleLoaded}/>
        </div>
    }

    handleLoaded = (canvas:Canvas) => {
        if (this.props.onLoaded){
            this.props.onLoaded();
        }
    }


}