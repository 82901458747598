import React from 'react';
import { Broadcast } from './Broadcast';

export type DragTool = {name:string,value?:string};

export type DropPosition = 'after' | 'before' | 'append' | 'replace';
export class DragState {
    _dragging:boolean;
    
    dragElementId:string;
    dragElementContainerId:string;

    highlightIndex:number = -1;
    highlightMode:number = 0;
    targetHeight:number;
    containerHeight:number;
    containerRef = React.createRef<HTMLDivElement>();
    transitionSpeed:string = "0.25s";
    studioBroadcast:Broadcast;
    dropbarBroadcast:Broadcast = new Broadcast();
    imageBroadcast:Broadcast = new Broadcast();

    onDragEnd:() => void;
    onDragStart:() => void;
    onDrop:(e:HTMLElement) => void;

    dragTool:DragTool;
    dropTargetId:string;
    dropTargetKind:string;
    dropTargetContainerRect:DOMRect;
    

    dropPosition:DropPosition;
    mouseDownPos:{x:number,y:number};

    renderDragImage:() => any;
    dragImageTop:number;
    dragImageLeft:number;

    scrollPulse:NodeJS.Timeout;
    scrollPulseDirection:'up' | 'down';

    expanded:{[name:string]:boolean} = {};

    dropBar: {
        left:number;
        top:number;
        width:number;
    }

    constructor(studioBroadcast:Broadcast){
        this.studioBroadcast = studioBroadcast;
    }
    

    refresh(){
        this.dropbarBroadcast.refresh();
        this.imageBroadcast.refresh();
        //this.canvas.update();
    }

    setDropbar(top:number,left:number,width:number){
        let current= this.dropBar;
        if (current && current.top == top && current.left == left && current.width == width) return;
        this.dropBar = {
            top,
            left,
            width
        };
        this.refresh();
    }
    startDrag(elementId:string,container:string,clientX:number,clientY:number){
       
        this.dragElementId = elementId;
        this.dragElementContainerId = container;
        this.dragTool = null;
        this.initDrag(clientX,clientY);
    }

    endDrag(){
        document.removeEventListener("mousemove",this.handleMouseMove,true);
        document.removeEventListener("mouseup",this.handleMouseUp,true);
        this._dragging = false;
        this.dragElementId = null;
        this.pendingDropRect = null;
        this.dropTargetContainerRect = null;
        this.dropTargetKind = null;
        this.dragTool = null;
        this.dropBar = null;
        this.onDragStart = null;
        this.renderDragImage = null;
        if (this.scrollPulse){
            clearTimeout(this.scrollPulse);
        }
        if (this.onDragEnd){
            this.onDragEnd();
            this.onDragEnd = null;
        }
        this.refresh();
        this.studioBroadcast.refresh();
    }

    startToolDrag(tool:DragTool,clientX:number,clientY:number){
       
        this.dragElementId = null;
        this.dragTool = tool;
        this.targetHeight = 45;
        this.initDrag(clientX,clientY);
    }

    initDrag(clientX:number,clientY:number){
        this._dragging = false;
        this.containerHeight = this.containerRef.current.offsetHeight;
        this.mouseDownPos = {x:clientX,y:clientY};
        document.addEventListener("mousemove",this.handleMouseMove,true);
        document.addEventListener("mouseup",this.handleMouseUp,true);
        this.refresh();
    }

    endToolDrag(){
        this.endDrag();
    }

    get dragging():boolean {
        return this._dragging;
    }

    setHighlight(index:number){
        this.highlightIndex = index;
        this.highlightMode = 0;
        setTimeout(()=> {this.highlightMode = 1;if (this.refresh) this.refresh()},200);
        setTimeout(()=> {this.highlightIndex = -1;if (this.refresh) this.refresh()},1700);
    }

    handleMouseUp = (e:MouseEvent) => {
        if(this.onDrop && this.dropBar){
            this.onDrop(e.target as HTMLElement);
        }
        this.endDrag();
    }
    handleMouseMove = (e:MouseEvent) => {
        if (window.getSelection) {
            let sel = window.getSelection();
            if (sel && sel.removeAllRanges) sel.removeAllRanges();
        }
        if (!this._dragging){
            if (Math.abs(this.mouseDownPos.x - e.clientX) > 5 || Math.abs(this.mouseDownPos.y - e.clientY) > 5){
                this._dragging = true;
                this.studioBroadcast.refresh();
                if (this.onDragStart){
                    this.onDragStart();
                }
            }
            return;
        }

        this.dragImageLeft = e.clientX + 2;
        this.dragImageTop = e.clientY + 3;

        this.imageBroadcast.refresh();

        let elem = document.elementFromPoint(e.clientX,e.clientY);
        let dropBar:HTMLElement;
        if (elem){
            dropBar = elem.closest('[data-drop-bar]');
            if (dropBar) {
                if (this.scrollPulse){
                    clearTimeout(this.scrollPulse);
                }
                return;
            }
        }
        let dropZone:HTMLElement;
        if (elem){
            dropZone = elem.closest('[data-drop-zone]');
        }
        if (!dropZone){
            
            if (this.dropBar){
                this.dropBar = null;
                this.dropTargetKind = null;
                this.setDropZoneHighlight(null);
                this.dropbarBroadcast.refresh();
            }
            if (this.containerRef.current){
                var rect = this.containerRef.current.getBoundingClientRect();
                if (e.clientY < rect.top){
                    if (!this.scrollPulse || this.scrollPulseDirection != "up"){
                        this.scrollPulse = setInterval(this.handleScrollPulse,25);
                        this.scrollPulseDirection = "up";
                    }
                    return;
                }
                else if (e.clientY > rect.bottom){
                    if (!this.scrollPulse || this.scrollPulseDirection != "down"){
                        this.scrollPulse = setInterval(this.handleScrollPulse,25);
                        this.scrollPulseDirection = "down";
                    }
                    return;
                }
            }
            if (this.scrollPulse){
                clearTimeout(this.scrollPulse);
            }
            return;
        }
        if (this.scrollPulse){
            clearTimeout(this.scrollPulse);
        }
    
        
        let dropTarget = elem.closest('[data-drop-target]');
        if (dropTarget){
            let noDrop = elem.closest('[data-no-drop]');
            if (noDrop && dropTarget.contains(noDrop)) return;

            if (!dropZone.contains(dropTarget)) return;
            let isChildofDragElement = dropTarget.closest('[data-elem-id="' + this.dragElementId + '"]');
            if (isChildofDragElement) return;

            let dropTargetRec = dropTarget.getBoundingClientRect();
            this.dropTargetId = dropTarget.getAttribute("data-drop-target");
            let targetContainer = dropTarget.parentElement.closest("[data-drop-target]");
            if (targetContainer){
                this.setDropZoneHighlight(targetContainer.getBoundingClientRect());
                var containerId = targetContainer.getAttribute("data-drop-target");
                if (containerId != this.dragElementContainerId){
                    this.dropTargetKind = targetContainer.getAttribute("data-target-kind");     
                }
                else {
                    this.dropTargetKind = null;
                }
            }
            else {
                this.dropTargetKind = null;
                this.setDropZoneHighlight(null);
            }
            
            let position  = dropTarget.getAttribute("data-drop-position");
            if (position == "append"){
                this.dropPosition = "append";
                this.setDropbar(dropTargetRec.top + (dropTargetRec.height/2),dropTargetRec.left + 20,dropTargetRec.width - 50);
            }
            else if (position == "replace"){
                this.dropPosition = "replace";
                this.setDropbar(dropTargetRec.top + (dropTargetRec.height/2),dropTargetRec.left + 20,dropTargetRec.width - 50);
            }
            else if (e.clientY > dropTargetRec.top + (dropTargetRec.height/2)){
                this.dropPosition = "after";
                this.setDropbar(dropTargetRec.top + dropTargetRec.height + 10,dropTargetRec.left + 20,dropTargetRec.width - 50);
            }
            else {
                this.dropPosition = "before";
                this.setDropbar(dropTargetRec.top,dropTargetRec.left + 20,dropTargetRec.width - 50);
            }
            return;
        }
        
    }

    setDropZoneTimer;
    pendingDropRect:DOMRect;
    setDropZoneHighlight(rect:DOMRect){
        if (!rect){
            if (!this.pendingDropRect){
                return;
            }
        }
        else if (this.pendingDropRect){
            let pending = this.pendingDropRect;
            if (pending.top == rect.top && pending.left == rect.left 
                && pending.width == rect.width && pending.height){
                    return;
            }
        }
        if (this.setDropZoneTimer){
            clearTimeout(this.setDropZoneTimer);
        }
        this.pendingDropRect = rect;
        this.setDropZoneTimer = setTimeout(()=> {
            this.dropTargetContainerRect = this.pendingDropRect
            this.dropbarBroadcast.refresh();
        },200);
    }

    handleScrollPulse = () => {
        let container = this.containerRef.current;
        if (!container){
            clearTimeout(this.scrollPulse);
        }
        if (this.scrollPulseDirection == "up"){
            container.scrollTop -= 20;
        }
        else {
            container.scrollTop += 20;
        }
    }
}

export class DropBar extends React.Component<{dragState:DragState}>{

    componentDidMount(): void {
        this.props.dragState.dropbarBroadcast.connect(this);
    }
    componentWillUnmount(): void {
        this.props.dragState.dropbarBroadcast.disconnect(this);
    }

    render(): React.ReactNode {
        let dragState = this.props.dragState;

        if (!dragState.dragging) return null;
        let dropBar = dragState.dropBar;
        let dropBarElem = null;
        if (dropBar){
            let containerRect = dragState.containerRef.current.getBoundingClientRect();
            if (dropBar.top < containerRect.top || dropBar.top > containerRect.bottom){
                return null;
            }
            let targetContainerOutline;
            if (dragState.dropTargetContainerRect){
                let rect = dragState.dropTargetContainerRect;
                targetContainerOutline = (<div style={{position:"absolute",pointerEvents:"none",zIndex:84900,
                    top:rect.top-4,left:rect.left-4,width:rect.width+8,height:rect.height+8,
                    border:"2px dashed var(--rt-primary-color)",//backgroundColor:"rgba(41,123,230,0.02)",
                    boxShadow:"0 0 0 9999px rgba(10,30,50,0.04)",
                    transition:"top 0.15s ease-in,left 0.15s ease-in,width 0.15s ease-in,height 0.15s ease-in"}}/>);
            }
            dropBarElem = (<>
                <div key="dropbar" data-drop-bar="true" onDragOver={this.dragOver}  onDragEnter={this.dragEnter} onDrop={this.onDrop}
                    style={{pointerEvents:"none",position:"absolute",top:dropBar.top,left:dropBar.left,zIndex:85000,transition:"top 0.15s ease-in,left 0.15s ease-in"}}>
                    <div style={{position:"absolute",left:0,top:- 5,width:10,height:10,borderRadius:99,
                        border:"solid 2px var(--rt-primary-color)"}}/>
                    <div style={{position:"absolute",left:10,top:0,width:dropBar.width,height:2,backgroundColor:"var(--rt-primary-color)"}}/>
                </div>
                {targetContainerOutline}
            </>)
        }
        return dropBarElem;
    }

    dragOver = (e:React.DragEvent) => {
        var target = e.target as HTMLElement;
        e.preventDefault();
        e.stopPropagation();
        e.dataTransfer.dropEffect = "move";
        return true;

    }

    dragEnter = (e:React.DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    onDrop = (e:React.DragEvent) => {
        let dragState = this.props.dragState;
        if (dragState.onDrop){
            dragState.onDrop(e.target as HTMLElement);
        }
    }
}

export class DragImage extends React.Component<{dragState:DragState}>{

    componentDidMount(): void {
        this.props.dragState.imageBroadcast.connect(this);
    }
    componentWillUnmount(): void {
        this.props.dragState.imageBroadcast.disconnect(this);
    }

    render(): React.ReactNode {
        let dragState = this.props.dragState;

        if (!dragState || !dragState.dragging) return null;
        if (!dragState.renderDragImage) return null;
        let target;
        /*
        if (dragState.dropTargetKind){
            target = <div style={{padding:6,backgroundColor:"#fff",border:"solid 1px rgb(232,232,232)",borderRadius:6
                ,marginTop:6,marginLeft:20}}>Move to {dragState.dropTargetKind}</div>
        }
        */
        return <div style={{position:"absolute",top:dragState.dragImageTop,left:dragState.dragImageLeft,zIndex:85001}}>
            {dragState.renderDragImage()}
            {target}
        </div>

    }

}

