import { RenderEvent } from 'core';
import React from 'react';

export class DesignerContentSlot extends React.Component<{event:RenderEvent,name:string}>{
    render(): React.ReactNode {
        let canvas = this.props.event.canvas;
        if (canvas.renderDesignSlot){
            return canvas.renderDesignSlot(this.props.event,this.props.name);
        }
        return null;
    }
}