import { Rectangle } from "recharts";
import { ActionRef } from "./ActionRef";
import { Canvas } from "./Canvas";


export type ContextMenuTargetRect = {top:number,bottom:number,left:number,right:number};
export type ContextMenuHandlerArgs = {rect:ContextMenuTargetRect,target:HTMLElement};

export class ContextMenuHandler {
    public id:string;
    public handleOpen:(args:ContextMenuHandlerArgs) => void;
    public onClose:() => void;
    public isOpen:boolean;
    public canvas:Canvas;
    public row:any;
    public elemId:string;
    public x:number;
    public y:number;
    public rect:ContextMenuTargetRect;

    public manager:ContextMenuManager;

    public open(){
        this.isOpen = true;
        this.canvas.update();
    }
    public close(){
        this.isOpen = false;
        if (this.onClose){
            this.onClose()
        }
        this.canvas.update();
    }

    public trigger(action:string,scope:any){
        this.isOpen = false;
        this.canvas.update();
        let actionRef = new ActionRef(this.canvas,action,scope);
        actionRef.trigger();
    }
}


export class ContextMenuManager {
    private _handlers:ContextMenuHandler[] = [];
    private _nextId = 1;

    public createHandler(canvas:Canvas,handleOpen:(args:ContextMenuHandlerArgs) => void):ContextMenuHandler{
        let handler = new ContextMenuHandler();
        handler.canvas = canvas;
        handler.id = (this._nextId++).toString();
        handler.handleOpen = handleOpen;
        return handler;
    }

    public connect(handler:ContextMenuHandler){
       this._handlers.push(handler);
    }
    
    public disconnect(handler:ContextMenuHandler){
        let i = this._handlers.indexOf(handler);
        if (i != -1){
            this._handlers.splice(i,1);
        }
    }

    public handleOpen(args:ContextMenuHandlerArgs){
        let target = args.target;
        let handlerElem = target.closest('[data-context-menu]');
        if (!handlerElem) return;
        let handlerId = handlerElem.getAttribute('data-context-menu');
        let handler = this.findHandler(handlerId);
        if (handler){
            handler.handleOpen(args);
        }
    }

    private findHandler(id:string):ContextMenuHandler {
        for(let i =0 ; i < this._handlers.length;i++){
            let handler = this._handlers[i];
            if (handler.id == id) return handler;
        }
        return null;
    }

    public closeAll(){
        for(let i =0 ; i < this._handlers.length;i++){
            let handler = this._handlers[i];
            if (handler.isOpen){
                handler.close();
            }
        }
        return null;
    }
}