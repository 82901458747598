import { ActionRef, ContextMenuHandler, ContextMenuHandlerArgs, FieldRef, RenderEvent } from 'core';
import { StudioCommandBuffer } from 'core/studio/StudioCommandBuffer';
import { IEventOptions } from 'core/types';
import React from 'react';
import { AnchorPoint } from './helpers/AnchorPoint';
import { LoadingDots } from './helpers/LoadingDots';
import { Popup } from './popups/Popup';
import { StudioEditorChildren } from './studio/StudioEditorChildren';


export class SectionEditor extends React.Component<{event:RenderEvent,content:FieldRef,placeholder:string,$elem:any,styles?:string,$id:string}>{

    contextMenuHandler:ContextMenuHandler;
    
    constructor(props){
        super(props);
        let canvas = this.props.event.canvas;
        canvas.useDragState();
        canvas.dragState.onDrop = this.handleDrop;
        
        this.contextMenuHandler = canvas.app.contextMenuManager.createHandler(canvas,this.handleContextMenuClick);
        this.contextMenuHandler.onClose = () => {
            canvas.activeDesignElement = null;
        }
    }

    componentDidMount(){
        let canvas = this.props.event.canvas;
        canvas.app.contextMenuManager.connect(this.contextMenuHandler);
        canvas.app.studioBroadcast.connect(this);
        canvas.app.studioBroadcast.refresh();
        
    }

    componentWillUnmount(){       
        let canvas = this.props.event.canvas;
        canvas.app.studioBroadcast.disconnect(this);
        if (this.contextMenuHandler){
            canvas.app.contextMenuManager.disconnect(this.contextMenuHandler);
        }
    }

    render(): React.ReactNode {
        let elem = {...this.props.$elem,$id:"$"};
        let event = this.props.event;
        let contextMenu;
        let contextMenuId;
        if (this.contextMenuHandler){
            contextMenuId = this.contextMenuHandler.id;
            if (this.contextMenuHandler.isOpen){
                contextMenu = this.renderContextMenu();
            }
        }
        
        let styles = this.props.event.computeStyles(this.props.styles);
        let style:React.CSSProperties = {...styles.style,paddingRight:30,paddingTop:20};
        let className = "rt-scrollbars";
        let border;
        if (event.canvas.dragState.dragging){
            className += " studio-dragging";
            border = "dashed 2px transparent";
        }
        else {
            border = "dashed 2px #bed9fd";
        }
        let placeholder = this.props.placeholder || "Drop elements here";
       
        return <div ref={event.canvas.dragState.containerRef} data-context-menu={contextMenuId} 
            style={{height:"100%",overflowY:"auto",border}} className={className}>

            <div style={style} className={styles.className}>
                <StudioEditorChildren event={event} placeholder={placeholder} children={this.props.children} $id="root" $elem={elem}/>
            </div>
            {contextMenu}
        </div>
        
    }

    handleContextMenuClick = (args:ContextMenuHandlerArgs) => {

        let canvas = this.props.event.canvas;
    
        this.contextMenuHandler.rect = args.rect;
        let elem = args.target.closest('[data-elem-id]');
        if (elem){
            this.contextMenuHandler.elemId = elem.getAttribute("data-elem-id");
            canvas.activeDesignElement = this.contextMenuHandler.elemId;
        }
        else {
            this.contextMenuHandler.elemId = null;
        }
        this.contextMenuHandler.open();
      
    }

    renderContextMenu(){
        let handler = this.contextMenuHandler;
        let rect = handler.rect;
      
        return (<Popup
            key="context-menu"
            attachedRef={rect}
            anchorPoint={AnchorPoint.BottomAlignLeft}
            onForceClose={this.handlePopupForceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderMenu}
            withCover
        />);
    }


    renderMenu = (options:{onResize:() => void}) => {
        let event = this.props.event;
        let content = <EditorMenu event={event} elemId={this.contextMenuHandler.elemId} onResize={options.onResize}/>
        let menu = <div style={{paddingTop:4,paddingBottom:4}}>
            <div style={{ width: 480, backgroundColor: "#fff", boxShadow: "rgb(0 0 0 / 10%) 0px 10px 15px -3px, rgb(0 0 0 / 10%) 0px 4px 6px -4px", borderRadius: 6,
            overflow:"hidden"}}>
                {content}
            </div>
        </div>
        return menu;


    }

    handlePopupForceClose = (target:HTMLElement,isTop:boolean) => {
        this.contextMenuHandler.close();
        this.forceUpdate();
    }

    handleDrop = (target:HTMLElement) => {
        let canvas = this.props.event.canvas;
        let dragState = canvas.dragState;
        if (dragState.dragTool){
            canvas.studioCommandBuffer.toolDrop(dragState.dragTool,dragState.dropTargetId,dragState.dropPosition);
        }
        else {
            canvas.studioCommandBuffer.move(dragState.dragElementId,dragState.dropTargetId,dragState.dropPosition);
        }
        canvas.studioCommandBuffer.send();
    }

}

class EditorMenu extends React.Component<{event:RenderEvent,elemId:string,onResize:() => void}>{
    elements:any[];

    async componentDidMount(){
        let action = new ActionRef(this.props.event.canvas,"studio-context-menu",{});
        let options:IEventOptions = {value:this.props.elemId};
        let result = await action.trigger(options);
        this.elements = options.returnValue;
        this.forceUpdate();
        this.props.onResize();
    }

    render(): React.ReactNode {
        if (this.elements){
            let content = this.props.event.render(this.elements);
            return <div>
                {content}
            </div>
        }
        else {
            return <div style={{padding:10}}>
                <LoadingDots/>
            </div>
        }
    }
}