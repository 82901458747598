import { Evaluator } from "./expressions/Evaluator";
import { RenderEvent } from "./RenderEvent";
import React from 'react';
import { DynamicRender } from "./DynamicRender";

export class HtmlRender {
    static renderTag(event:RenderEvent,elem:any,index:number,id:string){
        let tag = elem.$tag;
        if (tag == "text"){
            if (elem.text){
                let propValue = HtmlRender.getPropValue(event,elem.text);
                if (propValue === true) return "Yes";
                if (propValue === false) return "No";
                return HtmlRender.renderItem(event,propValue,index);
            }
            return null;
        }
        if (tag == "fragment"){
            return HtmlRender.renderArray(event,elem.children);
        }
       
        let props:any = {};
        for(let key in elem){
            if (key[0] == '$') continue;
            if (key == "children"){
              
                if (tag == "table"){
                    props["children"] = elem.children;
                }
                else {
                    props["children"] = HtmlRender.renderArray(event,elem.children);
                }
            }
            else {
                var propName = HtmlRender.reactPropMap[key] || key;
                props[propName] = HtmlRender.getPropValue(event,elem[key]);
            }
        }
        props.key = index;
        props.$tag = tag;
        let component = HtmlRender.componentMap[tag];
        if (component){
            return component(event,props);
        }
        if (props.onclick){
            return React.createElement(Wrapper,props);
        }
        return React.createElement(tag,props);
    }

    static renderArray(event:RenderEvent,children:any[]): any[]{
        if (!children) return null;
        let output:any[] = [];
        for(let i = 0; i < children.length;i++){
            let child = children[i];
            let elem = HtmlRender.renderItem(event,child,i);
            if (elem){
                output.push(elem);
            }
        }
        return output;
    }

    static renderItem(event:RenderEvent,item:any,index:number):any {
        if (!item) return null;
        if (item.$tag){
            return HtmlRender.renderTag(event,item,index,null);
        }
        if (item._){
            return HtmlRender.getPropValue(event,item);
        }
        return item;
    }

    static componentMap:{[name:string]:(event:RenderEvent,props:any) => any}


    static getPropValue(event:RenderEvent,value:any){
        if (value && value._){
            value = Evaluator.evaluate(event,value._);
        }
        if (value && value.$tag){
            return HtmlRender.renderTag(event,value,0,null);
        }
        return value;
    }

    static reactPropMap:any = {
        "class":"className"
    }
}

const Wrapper = (props:any) => {
    function onClick(e:React.MouseEvent){
        e.stopPropagation();
        props.onclick.trigger();
    }

    let href= (props.$tag == "a") ? "#" : undefined;
    let newProps:any = {...props,href};
    if (props.onclick){
        newProps.onClick = onClick;
    }
    return React.createElement(props.$tag,newProps);
}