
import { RecordErrorSet } from "./RecordErrorSet";
import { IRecordSchema } from "./Schema";


export interface IFieldOption {
    value: any;
    label: string;
    compare?:any;
    divider?:boolean;
    color?:string;
}
export type IFieldOptions = IFieldOption[];

export interface IFieldCompareOption {
    value:string,
    label:string,
    hasValue:boolean,
    hasToValue:boolean
}

export type IFieldCompareOptions = IFieldCompareOption[];

export interface IFieldMetaProps {
    label:string;
    required:boolean;
    disabled:boolean;
    disabledHelp:string;
    hidden:boolean;
    options:IFieldOptions;
    format:string;
    validationReq:boolean;
    help:string;
    placeholder:string;     
    valueRange:string;
    loading:boolean;
    lookupLabel:string;
    onEnterKeyAction:string;
}

export type FieldMetaProps = {[name:string]:IFieldMetaProps};

export class RecordMeta {
    readonly:boolean;
    metaProps:FieldMetaProps;
    error:RecordErrorSet;
    schema:IRecordSchema;
    index:number;
    
    static forRecord(record:any):RecordMeta{
        if (record){
            return record.$$meta;
        }
    }
    static setRecordReadonly(meta:RecordMeta,value:boolean){
        meta.readonly = value;
    }

    static setRequired(meta:RecordMeta,fieldName:string,value:any){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        fieldMeta.required = this.getBoolean(value);
    }

   
    static setDisabled(meta:RecordMeta,fieldName:string,value:any){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        fieldMeta.disabled = this.getBoolean(value);
    }

    static setDisabledHelp(meta:RecordMeta,fieldName:string,value:any){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        fieldMeta.disabledHelp = value;
    }

    static setHidden(meta:RecordMeta,fieldName:string,value:any){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        fieldMeta.hidden = this.getBoolean(value);
    }

    static setReadonly(meta:RecordMeta,fieldName:string,value:any){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        fieldMeta.disabled = this.getBoolean(value);
    }

    static setOptions(meta:RecordMeta,fieldName:string,options:IFieldOptions){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        if (options){
            if (!hasEmptyOption(options)){
                options.unshift({value:"",label:"?"});
            }
            for(let i = 0; i < options.length;i++){
                let option = options[i];
                if (option.value && option.value.toUpperCase){
                    option.value = option.value.toUpperCase();
                }
            }
        }
        fieldMeta.options = options;
    }

    static setFormat(meta:RecordMeta,fieldName:string,formatString:string){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        fieldMeta.format = formatString;
    }

    static setLabel(meta:RecordMeta,fieldName:string,label:string){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        fieldMeta.label = label;
    }

    static setPlaceholder(meta:RecordMeta,fieldName:string,text:string){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        fieldMeta.placeholder = text;
    }

    static setHelp(meta:RecordMeta,fieldName:string,text:string){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        fieldMeta.help = text;
    }

    static setLookupLabel(meta:RecordMeta,fieldName:string,value:any){
        let fieldMeta = this.getOrCreateFieldMeta(meta,fieldName);
        fieldMeta.lookupLabel = value;
    }


    static getBoolean(value:any):boolean {
        if (!value) return false;
        if (typeof value === "string"){
            let c = value[0];
            if (c == 'Y' || c == 'y' || c=='T' || c=='t'){
                return true;
            }
            else {
                return false;
            }
        }
        return true;
    }


    static getOrCreateFieldMeta(meta:RecordMeta,fieldName:string):IFieldMetaProps {
        let metaProps = meta.metaProps;
        if (!metaProps){
            metaProps = {};
            meta.metaProps = metaProps;
        }
        let fieldMeta = metaProps[fieldName];
        if (fieldMeta){
            return fieldMeta;
        }
        fieldMeta = {} as any;
        metaProps[fieldName] = fieldMeta;
        return fieldMeta;
    }
    
    static getFieldMeta(meta:RecordMeta,name:string):IFieldMetaProps{
        
        if (!meta || !meta.metaProps) return null;
        return meta.metaProps[name];
    }

    static initalizeRecord(schema:IRecordSchema,data:any):any{
        data = data || {};
        let meta = new RecordMeta();
        meta.schema = schema;
        data.$$meta = meta;
        return data;
    }

    static clearErrors(data:any){
        let meta = RecordMeta.forRecord(data);
        if (meta){
            meta.error = null;
        }
    }

}

function hasEmptyOption(options:IFieldOptions):boolean {
    for(let i = 0; i < options.length;i++){
        let value = options[i].value;
        if (!value && value !== 0) return true;
    }
    return false
}