
import ReactDOM from 'react-dom';
import {Application, ISigninProps, ISigninUrlValues} from './Application';
import { Broadcast } from './Broadcast';
import { Canvas } from './Canvas';
import { Dialog, DialogResult } from './Dialog';
import { PageContainer } from './PageContainer';
import { ICanvasError, ISigninSettings, IStudioSettings } from './types';


export interface IWorkspace {
    name:string;
    label:string;
    icon:string;
}

export interface ISessionResult {
    success:boolean;
    didSignin?:boolean;
    error?:string;
    userType?:string;
    firstName?:string;
    lastName?:string;
    homePage?:string;
    signinSettings?:ISigninSettings;
    studioSettings?:IStudioSettings;
    version?:string;
    botConnect?:string;
}

export class AppSession {

    app:Application;
    public homePage:string;
    public broadcast:Broadcast;
    public firstName:string;
    public lastName:string;
    public userType:'employee' | 'account';
    public botConnect:string;

    private values:any = {};

    constructor(app:Application){
        this.app = app;
        this.broadcast = new Broadcast();
    }

    async signin(
        app: Application,
        parentCanvas:Canvas,
        options:{insideRequest?:boolean},
        props:any
       
    ):Promise<DialogResult> {
    
        /*
        let res = await this.tryGetSession(app);
        if (!res){
            throw new Error("Unable to communicate with server at this time...");
        }
        if (res.signinSettings){
            app.signinSettings = res.signinSettings;
            app.studioSettings = res.studioSettings;
        }
        
    
        let params = new URLSearchParams(document.location.search.substr(1));
        let pwdToken = params.get("_pwdtoken");
        if (pwdToken){
            let tokenType = params.get("type");
            this.renderResetPassword(app,{token:pwdToken,tokenType,username:params.get("username")});
            return res;
        }
        */
       
        let dialog = <PageContainer page={{name:"pages/public/log_in",props}}/>;
        let canvas = new Canvas(null);
        canvas.launchParams = {name:"root",props:{}};

        let result = await Dialog.open(canvas,dialog,null,"dialog");
        return result;
    //     res.didSignin = true;
    
    
    
        /*
        res = await this.tryGetSession(app);
        if (res.userType){
            this.firstName = res.firstName;
            this.lastName = res.lastName;
            this.homePage = res.homePage;
            this.botConnect = res.botConnect;
            this.userType = res.userType as any;
            res.didSignin = true;
            return res;
        }
        */
        /*
        let elem = document.createElement("div");
        document.body.appendChild(elem);
        var p = new Promise<ISessionResult>((resolve) => {
            let onClose = (result:ISessionResult)=> {
                document.body.removeChild(elem);
                resolve(result);
            }
            let content = this.renderSignin(app,onClose,{});
            ReactDOM.render(content, elem);
        })
        res = await p;
        app.studioSettings = res.studioSettings;
        this.broadcast.refresh();
        res.didSignin = true;
        */
       
    }
    
    getPostUrl(app:Application,method:string){
        return  "/session/" + method;
    }
    async tryGetSession(app:Application):Promise<ISessionResult>{

        try {
            let json = await Application.instance.doPostRequest(this.getPostUrl(app,"status"),{domain:app.domain,branch:app.branch});
            return json;  
        } catch (e) {
           return null;
        }
    }
    
    renderResetPassword(app: Application,urlValues:ISigninUrlValues ) {
        let container = document.createElement("div");
        document.body.appendChild(container);
        let done = () => {
            document.location.assign("/");
        };
        let content = this.renderSignin(app,done,urlValues);
        ReactDOM.render(content, container);
    }

    renderSignin(app:Application,done:(result:ISessionResult)=> void,urlValues:ISigninUrlValues):any{
        let container = document.createElement("div");
        document.body.appendChild(container);
        return app.renderSignin({app,done,urlValues});
    }


    public async attemptSignin(username:string,password:string):Promise<ISessionResult> {

        let app = Application.instance;
        let res = await app.doPostRequest(this.getPostUrl(app,"signin"),{domain:this.app.domain,username,password});
        return res;
    }

    public async requestToken(username:string):Promise<ISessionResult>{
        let app = Application.instance;
        let res = await app.doPostRequest(this.getPostUrl(app,"send-reset-token"),{domain:this.app.domain,username});
        return res;
    }

    public async resetPassword(token:string,password:string):Promise<ISessionResult>{
        let app = Application.instance;
        let res = await app.doPostRequest(this.getPostUrl(app,"set-password"),{domain:this.app.domain,token,password});
        return res;
    }

    public async ping():Promise<ISessionResult>{
        let app = Application.instance;
        let res = await app.doPostRequest(this.getPostUrl(app,"ping"),{domain:this.app.domain});
        return res;
    }
    
    public getValue(name:string):any {
        if (!name) return;
        return this.values[name.toLowerCase()];
    }


    public async signout():Promise<ISessionResult>{
        let app = Application.instance;
        let res = await app.doPostRequest(this.getPostUrl(app,"signout"),{domain:this.app.domain});
        if (res.success){
            window.top.location.assign("/" + app.appName);
        }
        return res;
    }
    
}