import { ScreenRef } from "./ActionEvent";
import { Application } from "./Application";
import { Canvas } from "./Canvas";
import { IServiceResponse, Response} from './Response';
import { IAsLookupFor } from "./types";

export type IRequestCallType = 'start' | 'batch' | 'refresh-row' | 'method' | 'restart';
export interface IServiceRequest {
    domain:string;
    branch:string;
    client?:string;
    page?:{
        name:string;
        layout?:string;
        view?:string;
        draft_id?:string;
        designer_sections?:string;
    }
    parameters?:any;
    stopOnWarning?:boolean;
    callType:IRequestCallType;
    service?:string;
    method?:string;
    studioOpen?:boolean;
    studioSource?:string;
    asLookupFor?:IAsLookupFor;
}

interface ICachedRequest {
    response:any;
}

const MAX_CACHE_SIZE = 100;
const RECENT_KEY_COUNT = 25;


class PageResponseCache {
    private _items:{[key:string]:ICachedRequest} = {};
    private _recentKeys:string[] = [];
    private _cacheItemCount:number = 0;

    updateCache(cacheKey:string,canvas:Canvas,res:any){
        if (canvas.cacheStrategy == "optimistic"){
            if (!this._items[cacheKey]){
                this._cacheItemCount++;
            }
            this._items[cacheKey] = {response:res};
            this.addKey(cacheKey);
            if (this._cacheItemCount > MAX_CACHE_SIZE){
                this.evict();
            }
        }
        else if (this._items[cacheKey]){
            this.removeFromCache(cacheKey);
        }
    }

    removeFromCache(cacheKey:string){
        delete this._items[cacheKey];
        this.removeKey(cacheKey);
        this._cacheItemCount--;
    }

    getCachedResponse(cacheKey:string):ICachedRequest{
        let item = this._items[cacheKey];
        if (item){
            this.addKey(cacheKey);
            return item;
        }
    }

    private addKey(key:string){
        let i = this._recentKeys.indexOf(key);
        if (i == 0) return; // already at front
        if (i != -1){
            this._recentKeys.splice(i,1);
        }
        if (this._recentKeys.length >= RECENT_KEY_COUNT){
            this._recentKeys.splice(RECENT_KEY_COUNT - 1);
        }
        this._recentKeys.unshift(key);
    }

    private removeKey(key:string){
        let i = this._recentKeys.indexOf(key);
        if (i != -1){
            this._recentKeys.splice(i,1);
        }
    }

    private evict(){
        let newCache:{[key:string]:ICachedRequest} = {};
        // keep all items in the recent key list
        let count = 0;
        for(let i =0 ; i < this._recentKeys.length;i++){
            let key = this._recentKeys[i];
            let item = this._items[key];
            if (item){
                newCache[key] = item;
                count++;
            }
        }
        this._items = newCache;
        this._cacheItemCount = count;
    }
}

export class Request {

    private static cache:PageResponseCache = new PageResponseCache();
    // could just recycle the cache once you hit a certain count 
   
    private static async executeRequest(screen:ScreenRef,callType:IRequestCallType,method:string,body:IServiceRequest):Promise<any> {
        let path = Request.getScreenPath() + "/" + method;
        let app = screen.canvas.app;
        body.studioOpen = app.studioOpen;

       

        let json = await app.doPostRequest(path,body);

        if (json.error && json.error.code == "NOT_SIGNED_IN"){
            app.spinner.pause();
            let result = await Application.instance.session.signin(Application.instance,screen.canvas,{insideRequest:true},{"@no_redirect":true});
            if (!result.continue) return;
            app.spinner.resume();
            /*
            if (sessionResult.version != app.version){
                // version changed
                // todo: display notification about version change
                window.location.reload();
                return;
            }
            */

            json = await app.doPostRequest(path,body);    
        }

        await Response.process(screen,callType,json);   

        return json;  
    }

    static async callBatch(screen:ScreenRef,callType:IRequestCallType,method:string,params:any){
    
        let canvas = screen.canvas;
      
        let layout:string;
        let view:string;
        let screenId:string;
        let draft_id = canvas.launchParams.draft_id;

        if (canvas.pageId){
            screenId = canvas.pageId;
            if (canvas.pageInfo){
               // layout = canvas.pageInfo.layout;
               // view = canvas.pageInfo.view;
            }
        }
        else {
            screenId = canvas.launchParams.name;
            layout = canvas.launchParams.layout;
            view = canvas.launchParams.view;
        }
        
        if (!draft_id){
            draft_id = canvas.app.currentDraftView[screenId.toLowerCase()];
        }
    
        let body:IServiceRequest = {
            domain:canvas.app.domain,
            branch:canvas.app.branch,
            client:"lisa-ui",
            page:{
                name:screenId,
                layout:layout,
                view:view,
                draft_id,
                designer_sections:canvas.launchParams.designer_sections
            },
            asLookupFor:canvas.launchParams.asLookupFor,
            method,
            callType,
            parameters: params,
            stopOnWarning:true,
            studioSource:canvas.studioSource
        };

        let cacheKey:string;
        
        if (callType == "start" && !screen.eventOptions.isCachedVersionRefresh && !draft_id){
            cacheKey = Request.buildCacheKey(body.page.name,body.parameters);
            let cacheItem = Request.cache.getCachedResponse(cacheKey);
            if (cacheItem){
               
                await Response.process(screen,callType,cacheItem.response);
                canvas.loadedFromCache = true;
                return cacheItem.response;
            }
        }
    
        canvas.loadedFromCache = false;
        try {
            let res:IServiceResponse = await Request.executeRequest(screen,callType,method,body);
      
            if (res.retryAfterWarnings){
                body.stopOnWarning = false;
                res = await Request.executeRequest(screen,callType,method,body);
            }  
        
            if (callType == "start" || callType == "restart"){
                if (!screen.eventOptions.isCachedVersionRefresh && canvas.layer != "layout"){
                    canvas.hardRefreshKey++;
                }
               
                cacheKey = Request.buildCacheKey(body.page.name,body.parameters);
                Request.cache.updateCache(cacheKey,canvas,res);
            }
        }
        catch(e){
            if (callType == "start" || callType == "restart"){
                cacheKey = Request.buildCacheKey(body.page.name,body.parameters);
                Request.cache.removeFromCache(cacheKey);
            }
            throw e;
        }
    }

   

    static buildCacheKey(page:string,parameters:any):string {
        let items:any[] = [];
        items.push({page:page.toLowerCase()});
        var keys = Object.keys(parameters);
        keys.sort();
        for(let i =0; i < keys.length;i++){
            let key = keys[i];
            if (key != "screen" && key != "@app.cart_item_count"){
                let value = parameters[key];
                if (value !== null && value !== undefined){
                    items.push({[key]:value});
                }
            }
        }
        return JSON.stringify(items);
    }

    static getScreenPath():string {
        return "/screen";
    }

 

}
