import { Application, RenderEvent } from 'core';
import React, { ReactFragment } from 'react';
import { AnchorPoint } from './helpers/AnchorPoint';
import { Icon } from './Icon';
import { Popup } from './popups/Popup';



export class LayoutNavStrip extends React.Component<{event:RenderEvent}>{

    componentDidMount(){
        let app = this.props.event.canvas.app;
        app.studioBroadcast.connect(this);
    }

    componentWillUnmount(){
        let app = this.props.event.canvas.app;
        app.studioBroadcast.disconnect(this);
    }

    render(){
        let app = this.props.event.canvas.app;
       
        let content= this.renderAppContent();
      
        return <>
            <div style={{position:"absolute",top:0,left:0,right:0,bottom:0,paddingTop:15,backgroundColor:"#292f4c",display:"flex",flexDirection:"column"}}>
                {content}
            </div>
        </>
    }

    renderAppContent(){
        let event = this.props.event;
        let app = event.canvas.app;
        let activeName = app.currentWorkspace;

        let editPage;
        let settings;

        return <>
            <div>
               {this.props.children}
            </div>
            <div style={{marginTop:"auto",paddingBottom:20,paddingTop:20}}>
                {editPage}
                {settings}
                <div style={{width:"100%",paddingTop:20,paddingBottom:10}}>
                    <ProfileIcon app={app} />
                </div>
            </div>
        </>

    }

    handleClickSettings = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let app = this.props.event.canvas.app;
        app.openWorkspace("settings.workspace");
    }

    handleAppClick = (name:string) => {
        let app = this.props.event.canvas.app;
        app.openWorkspace(name);
    }

    handleClickHome = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let app = this.props.event.canvas.app;
        app.openWorkspace(app.session.homePage);
    }

}

export class LayoutNavIcon extends React.Component<{event:RenderEvent;name:string,page?:string,label:string,icon:string;active?:boolean;onClick:(name:string)=> void}>{
    render(){
        let event = this.props.event;
        let page = this.props.page;
        let workspace = this.getWorkspace();
        let isActive:boolean;
        if (page){
            if (!workspace) return null;
            isActive = areEqualNoCase(event.canvas.app.currentWorkspace,workspace);
        }
        else {
            isActive = this.props.active;
        }
        let backgroundColor = isActive ? "rgb(122,127,149)" : "transparent";
        return (
            <div onClick={this.handleClick} title={this.props.label} style={{width:"100%",paddingTop:6,paddingBottom:6,display:"flex",alignItems:"center",justifyContent:"center",cursor:"pointer"}}>
                <div style={{padding:4,backgroundColor,borderRadius:5}}>
                    <Icon icon={this.props.icon} fill="#fff" size={30}/>
                </div>
            </div>
        )
    }

    getWorkspace():string {
        let page = this.props.page;
        if (page){
            let i = page.indexOf('/');
            if (i != -1){
                return page.substring(0,i);
            }   
        }
        return null;
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onClick){
            this.props.onClick(this.props.name);
        }
        else if (this.props.page){
            let app = this.props.event.canvas.app;
            let workspace = this.getWorkspace();
            app.openWorkspace(workspace);
        }
    }
}

function areEqualNoCase(a:string,b:string):boolean{
    if (!a || !b) return false;
    return (a.toLowerCase() == b.toLowerCase());
}

class ProfileIcon extends React.Component<{app:Application},{popupOpen:boolean}>{
    
    state = {popupOpen:false};
    dropRef = React.createRef<HTMLDivElement>();

    render(){
        let app = this.props.app;
        let popup;
        if (this.state.popupOpen){
            popup = <ProfileMenu app={app} width={180} dropRef={this.dropRef.current} forceClose={this.handleForceClose} />
        }
        let initials = this.getInitials(app);
        let initialsBadgeBackground = "rgb(117,196,88)" ; // "#66cdff"

        return <>
            {popup}
            <div style={{width:"100%",padding:"15px 0"}} >
                <div ref={this.dropRef} onClick={this.handleClick} style={{cursor:"pointer",marginLeft:"auto",marginRight:"auto",width:46,height:46,
                borderRadius:99,backgroundColor:initialsBadgeBackground,border:"solid 2px #fff",display:"flex",alignItems:"center",
            justifyContent:"center"}}>
                    <div style={{fontSize:"18px",fontWeight:500,color:"#fff"}}>{initials}</div>
                </div>
            </div>
        </>
       
       
    }

    getInitials(app:Application){
        let session = app.session;
        let initials:string;
        if (session.firstName && session.lastName){
            initials = session.firstName[0] + session.lastName[0];
        }
        else if (session.firstName){
            initials = session.firstName.substr(0,2);
        }
        if (initials){
            initials = initials.toUpperCase();
        }
        return initials;
    }


    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.popupOpen){
            this.setState({popupOpen:false});
        }
        else {
            this.setState({popupOpen:true});
        }
    }

    handleForceClose = () => {
        this.setState({popupOpen:false});
    }
}

class ProfileMenu extends React.Component<{app:Application,dropRef:HTMLElement,forceClose:()=> void,width:number}>{
    render(){
        return (<Popup
            attachedRef={this.props.dropRef}
            anchorPoint={AnchorPoint.BottomLeftCorner}
            onForceClose={this.props.forceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderMenu}
        />);
    }

    renderMenu = ()=>{
       
        let signout:IDropMenuOption = {name:"signout",label:"Sign out"};
        let content = <div style={{ width: this.props.width, backgroundColor: "#fff", boxShadow: "0 0 0 2px hsla(0,0%,0%,0.1)", borderRadius: 4,
            padding:8 }}>
            <DropMenuItem option={signout} onClick={this.handleClick}/>
        </div>
        return content;


    }

    handleClick = (option:IDropMenuOption) => {
        if (option.name == "signout"){
            this.props.app.session.signout();
        }

    }

}


interface IDropMenuOption {
    name:string;
    label:string;
}

class DropMenuItem extends React.Component<{option:IDropMenuOption, onClick:(option:IDropMenuOption) => void}>{
    render(){
        let option = this.props.option;
        return <a href='#' className="RT-DropMenu__item" style={{display:"block",color:"rgb(21,27,38)",textDecoration:"none",fontSize:"14px",fontWeight:500,padding:"8px 10px"}} onClick={this.handleClick}>
            {option.label}
        </a>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.option);
    }
}
