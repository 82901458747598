import React from 'react';
import {makeComponent, RenderEvent} from '../core';
import { Icon } from './Icon';
import { StudioHorizontalChildren } from './studio/StudioEditorChildren';

export const Line = makeComponent(class extends React.Component<{ event:RenderEvent, spacing?:string,align?:string,vertSpacing?:string;
     separator?:string,vertAlign?:string,$id?:string,$elem?:any,spanPattern?:string}>{

    render(){
        return (<div className="RT-Line" data-elem-id={this.props.$id}>
            {this.renderContent()}
        </div>)
    }

    renderContent(){
        
        let event = this.props.event;
        
        let children;
        let spacing = this.props.spacing;

        if (event.designMode && false){
            children = this.props.children;
            let toggle;
            let style:React.CSSProperties = {position:"absolute",right:3,top:3,cursor:"pointer",transition:"transform 0.15s"};
            toggle = <div style={style} onClick={this.handleToggleClick} title="Expand/collapse side-by-side">
                <Icon icon="columns" size={16} fill="currentColor" />
            </div>
            let editorContent;
            /*
            if (canvas.dragState.expanded[this.props.$id] || !children || !children.length){
                style.transform = "rotate(90deg)";
                return (<div>
                    <StudioEditorChildren event={event} placeholder="Drop side-by-side elements here" $id={this.props.$id}>{this.props.children}</StudioEditorChildren>
                    {toggle}
                </div>)
            }
            else {
            
              
            }
            */
            let spanPattern = this.props.spanPattern;

            editorContent = (<StudioHorizontalChildren event={event} placeholder="Drop side-by-side elements here" $id={this.props.$id} spanPattern={spanPattern}>
                {this.props.children}
            </StudioHorizontalChildren>);
            return <div>
                {editorContent}
            </div>
        }

        if (spacing == "sentence"){
            children = [];
            React.Children.forEach(this.props.children,(child:any,index:number) => {
                if (index){
                    children.push(" ");
                }
                
                children.push(React.cloneElement(child,{key:index}));
            })
        }
        else {
            let spanSegments:any[] = [];
            if (this.props.spanPattern){
                spanSegments = this.props.spanPattern.split(',');
            }
            else {
                spanSegments = [];
            }
            let index = 0;
            children = React.Children.map(this.props.children, (child: any) => {
                
                if (!child) return null;
                if (child.props.$kind == "UI.Column"){
                    return child;
                }
                let className = "RT-Line__item";
                let style:React.CSSProperties;
                let span = spanSegments[index];
                if (span){
                    style = {flexBasis:"calc(" + span + " - 10px)",flexGrow:0,flexShrink:0};
                }
                else if (child.props.width){
                    style = {width:child.props.width,flexGrow:0,flexBasis:child.props.width};
                }
                else if (!child.props.span && spacing != "none"){
                    style = {flexGrow:1}; //todo this is for fill line only 
                }

                
               

                if (child && child.props){
                    if(child.props.span){
                        className += " RT--span-" + child.props.span;
                    }
                }
              
                index++;
                return <div className={className} style={style} key={child.key}>{child}</div>
            });

        }
        
        
        
        let className = "RT-Line__content";
        

        if (spacing == "compress"){
            className += " RT-Line__content--compress";
        }
        else if (spacing == "sentence"){
            className += " RT-Line__content--sentence";
        }
        else if (spacing == "none"){
            className += " RT-Line__content--none";
        }
        else if (!spacing){
            className += " RT-Line__content--space-evenly";
        }
        if (this.props.align){
            className += " RT-Line__content--align-" + this.props.align;
        }
        if (this.props.vertSpacing){
            className += " RT-Line__content--vspace-" + this.props.vertSpacing
        }
        if (this.props.separator){
            className += " RT-Line__content--separator-" + this.props.separator;
        }
        if (this.props.vertAlign){
            className += " RT-Line__content--valign-" + this.props.vertAlign;
        }

        
        
        

        return (
            <div className={className}>
                {children}
            </div>
        )
    }

    handleToggleClick = (e:React.MouseEvent) => {
        e.stopPropagation();
        let canvas = this.props.event.canvas;
        canvas.dragState.expanded[this.props.$id] = !canvas.dragState.expanded[this.props.$id];
        this.forceUpdate();
    }
});