import React from 'react';
import { Stack } from '../core/Dialog';
import {  ActionRef, Canvas,  Collection,  makeComponent, RecordBrowse } from '../core';

export const BrowseNavigator = makeComponent(class extends React.Component<{canvas:Canvas}>{

    render(){    
       
        let canvas = this.props.canvas;
        let browse = canvas.recordBrowse;

        let backClass = "RT-Record-Navigator__back";
        let currentIndex = browse.currentIndex;
        let count = browse.rows.length;

        if (currentIndex < 1){
            backClass += " RT--disabled";
        }
        let forwardClass = "RT-Record-Navigator__forward";
        if ((currentIndex + 1) >= count){
            forwardClass += " RT--disabled";
        }
        let position = (currentIndex + 1) + " of " + count;
        let label;
        if (browse.label){
            label = <div className="RT-Record-Navigator__label">{browse.label}</div>
        } 
        return <div className="RT-Record-Navigator" onClick={this.handleClick}>
            {label}
            <div className={backClass} onClick={this.handleClickBack}>
                {this.renderBackIcon()}
            </div>
            <div className="RT-Record-Navigator__position">{position}</div>
            <div className={forwardClass} onClick={this.handleClickNext}>
                {this.renderForwardIcon()}
            </div>
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleClickBack= (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let canvas = this.props.canvas;
        let browse = canvas.recordBrowse;
        browse.move(-1);
        this.renderRecord(canvas,browse);
    }

    handleClickNext = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let canvas = this.props.canvas;
        let browse = canvas.recordBrowse;
        browse.move(1);
        this.renderRecord(canvas,browse);
    }

    renderRecord(canvas:Canvas,browse:RecordBrowse){
    
        let current = browse.current;
        if (current){
            /*
            content = browse.renderRecord(current);
            Stack.open(canvas,content,browse.onRecordChanged);
            canvas.recordBrowse = browse;
            canvas.update();
            */
           let schema = Collection.getSchema(browse.rows);
           let scope;
           if (schema){
                scope = {[schema.name]:current};
           }
            browse.triggerAction.canvas.triggerAction(browse.triggerAction,{scope,table:browse.rows,current,isBrowseMove:true});
        }
    }
   
    renderBackIcon(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
           <path d="M8.29,11.29a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l3,3a1,1,0,0,0,1.42-1.42L11.41,13H15a1,1,0,0,0,0-2H11.41l1.3-1.29a1,1,0,0,0,0-1.42,1,1,0,0,0-1.42,0ZM2,12A10,10,0,1,0,12,2,10,10,0,0,0,2,12Zm18,0a8,8,0,1,1-8-8A8,8,0,0,1,20,12Z"/>
        </svg>);
    }
    renderForwardIcon(){
        return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
           <path d="M15.71,12.71a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-3-3a1,1,0,0,0-1.42,1.42L12.59,11H9a1,1,0,0,0,0,2h3.59l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0ZM22,12A10,10,0,1,0,12,22,10,10,0,0,0,22,12ZM4,12a8,8,0,1,1,8,8A8,8,0,0,1,4,12Z"/>
        </svg>);
    }
});
