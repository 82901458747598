import { Icon } from 'components/Icon';
import { ActionRef, RenderEvent } from 'core';
import React from 'react';

export class StudioMenuItem extends React.Component<{event:RenderEvent;target:string,label:string,description?:string,
    icon?:string,name:string;value:string;onClick:ActionRef,asLink?:boolean,checked?:boolean}>{
    render(){

        let desc;
        if (this.props.description){
            desc = (<div style={{fontSize:'12px',fontWeight:400,color:"var(--rt-text-color)"}}>{this.props.description}</div>);
        }
        let icon;
        if (this.props.icon){
            icon = <Icon icon={this.props.icon} size={16} />
        }

        let style:React.CSSProperties;
        style = {display:"flex",alignItems:"center",gap:6,
        borderRadius:4,margin:5,letterSpacing:"0.3px"};
        let checked = (this.props.checked) ? <div style={{width:16}}><Icon icon="check" size={16} fill="currentColor" /></div> 
            : <div style={{width:16}}/>

        return <a href='#' className="studio-action-link" style={style} onClick={this.handleClick}>
            {checked}
            {icon}
            <div style={{whiteSpace:"nowrap"}}>
                {this.props.label}
                {desc}
                {this.props.children}
            </div>
        </a>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let event = this.props.event;
        event.canvas.app.contextMenuManager.closeAll();
        /*
        if (event.canvas.layer == "dialog"){
            if (Dialog.studioStack.length){
                Dialog.studioStack = [];
                Dialog.broadcast.refresh();
            }
        }
        */
        event.canvas.studioCommandBuffer.menuClick(this.props.target,this.props.name,this.props.value);
        event.canvas.studioCommandBuffer.send();
        /*
        if (this.props.onClick){
            this.props.onClick.trigger({value:{name:this.props.name,target:this.props.target,value:this.props.value}});
        }
        */
    }
}