import { ActionRef } from "./ActionRef";
import { Canvas } from "./Canvas";

export type HoverPopupHandlerArgs = {x:number,y:number,target:HTMLElement};

export class HoverPopupHandler {
    public id:string;
    public handleOpen:(args:HoverPopupHandlerArgs) => void;
    public isOpen:boolean;
    public canvas:Canvas;
    public row:any;
    public x:number;
    public y:number;

    public manager:HoverPopupManager;

    public open(){
        this.isOpen = true;
        this.canvas.update();
    }
    public close(){
        this.isOpen = false;
        this.canvas.update();
    }

    public trigger(action:string,scope:any){
        this.isOpen = false;
        this.canvas.update();
        let actionRef = new ActionRef(this.canvas,action,scope);
        actionRef.trigger();
    }
}


export class HoverPopupManager {
    private _handlers:HoverPopupHandler[] = [];
    private _nextId = 1;

    public activeHandlerId:string;

    public createHandler(canvas:Canvas,handleOpen:(args:HoverPopupHandlerArgs) => void):HoverPopupHandler{
        let handler = new HoverPopupHandler();
        handler.canvas = canvas;
        handler.id = (this._nextId++).toString();
        handler.handleOpen = handleOpen;
        this._handlers.push(handler);
        return handler;
    }

    public disconnect(handler:HoverPopupHandler){
        let i = this._handlers.indexOf(handler);
        if (i != -1){
            this._handlers.splice(i,1);
        }
    }

    public handleOpen(args:HoverPopupHandlerArgs){
        let target = args.target;
        let handlerElem = target.closest('[data-hover-popup]');
        if (!handlerElem) return;
        let handlerId = handlerElem.getAttribute('data-hover-popup');
        let handler = this.findHandler(handlerId);
        if (handler){
            if (this.activeHandlerId){
                this.close();
            }
            this.activeHandlerId = handlerId;
            handler.handleOpen(args);
        }
    }

    public close(){
        this.activeHandlerId = null;
        for(let i =0; i < this._handlers.length;i++){
            let handler = this._handlers[i];
            if (handler.isOpen){
                handler.close();
            }
        }
    }

    private findHandler(id:string):HoverPopupHandler {
        for(let i =0 ; i < this._handlers.length;i++){
            let handler = this._handlers[i];
            if (handler.id == id) return handler;
        }
        return null;
    }
}