import React from 'react';
import { IQueryGroupHeader } from 'core/CollectionGrouper';

export class DataGridGroupHeader extends React.Component<{
    header: IQueryGroupHeader;
    printElems:any;
    continued:boolean;
    columnHeaders?:any
}> {
    private renderedRowDataVersion: number;

    /*
    shouldComponentUpdate(nextProps) {
       
        let query: Data.IQuery = nextProps.query;
        if (query.rowDataVersion != this.renderedRowDataVersion) {
            return true;
        }
     
        */

    render() {
        let header = this.props.header;
        let group = header.group;
        let className = "RT-DataTable__group-header";
        if (header.innermost){
            className += " RT-DataTable--innermost";
        }
        else {
            className += " RT-DataTable--outer";
        }
        let topRight;
        if (this.props.continued){
            topRight = <div className="RT-DataTable__group-row-count">
                <span>continued</span>
            </div>     
        }
        /*
        else {
            topRight = <div className="RT-DataTable__group-row-count">
                Count <span>{header.totals["rowCount"].value}</span>
            </div>
        }
        */
       
        return (
            <div
                style={{
                    position: "relative"
                }}
                className={className}
            >
                <div style={{padding:"10px 100px 0 15px",marginBottom:10}}>
                    {topRight}
                    {this.props.printElems}
                </div>
                <div className="RT-DataTable__group-header-inner">
                    
                    {this.props.columnHeaders}
                </div>
                
            </div>
        );
    }
}