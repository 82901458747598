import { ClickThrottle } from 'components/helpers/ClickThrottle';
import { ActionRef, Collection, RenderEvent } from 'core';
import { IQueryGroupHeader } from 'core/CollectionGrouper';
import { DynamicRender } from 'core/DynamicRender';
import React from 'react';
import { IGridState } from './types';

export class BoardView extends React.Component<{gridState:IGridState}>{
    clickThrottle = new ClickThrottle();

    renderedDataVersion:number = -1;
    renderedGridVersion:number = -1;
    shouldComponentUpdate(nextProps){
        if (nextProps.dataVersion == this.renderedDataVersion && nextProps.layout && nextProps.layout.version == this.renderedGridVersion) return false;
        return true;
    }

    render(){
        let gridState = this.props.gridState;
        let grid = gridState.grid;
        this.renderedDataVersion = gridState.dataVersion;
        this.renderedGridVersion = grid.version;
        let rowElems = [];
        let rows = gridState.rows;
        if (!rows) return null;
    
       
        let groupMap = gridState.groupMap;
        if (groupMap){
        
            let currentPage = gridState.currentPage;
            let items = groupMap.items;
            
            
            let columnElems = [];
            let currentHeader:IQueryGroupHeader;
            let n = items.length;
            for(let i = 0; i < n;i++){
                let item = items[i];
                if (item.type == "row"){
                    rowElems.push(<BoardCard key={i} row={item.row} gridState={gridState} clickThrottle={this.clickThrottle}/>);
                }
                else if (item.type == "header"){
                    currentHeader = item.header;
                }
                else if (item.type == "footer"){
                    let column = <BoardColumn key={item.rowIndex} header={currentHeader} gridState={gridState} 
                    label={currentHeader.group.headerContent}  children={rowElems}/>
                   columnElems.push(column);
                   rowElems = [];
                }
            }
            return <div style={{flexGrow:1,display:"flex",flexDirection:"column",overflow:"hidden"}}>
                <div style={{height:15}}/>
                <div style={{flexGrow:1,display:"flex",gap:20,overflow:"hidden"}}>
                    {columnElems}
                </div>
            </div>
        }
        else {
            for(let i = 0; i < rows.length;i++){
                let row = rows[i];
                rowElems.push(<BoardCard key={i} row={row} gridState={gridState} clickThrottle={this.clickThrottle}/>);
            }
        }
        return rowElems;
    }
   
}


class BoardColumn extends React.Component<{gridState:IGridState,header:IQueryGroupHeader,label:any}>{
    render(){
        let gridState = this.props.gridState;
        let grid = this.props.gridState.grid;
        let header = this.props.header;
        let childEvent = gridState.event.create({[grid.schema.name]:header.row,[grid.schema.name + "$totals"]:header.totals} as any);
        let label = DynamicRender.render(childEvent,this.props.label);
        return <div className="rt-scrollbars" style={{flexGrow:1,minWidth:300,maxWidth:500,overflowY:"auto",paddingRight:10}}>
            <div style={{position:"sticky",top:0,backgroundColor:"#f2f3f4",padding:"5px 15px 10px 15px",marginBottom:15,borderBottom:"solid 2px rgb(222,224,228)",display:"flex",alignItems:"center"}}>
                <div>{label}</div>               
            </div>
            <div style={{display:"flex",flexDirection:"column",gap:15,paddingBottom:15}}>
            
                {this.props.children}
            </div>
        </div>
    }
}

class BoardCard extends React.Component<{gridState:IGridState,row:any,clickThrottle:ClickThrottle}>{
    render(){
        let gridState = this.props.gridState;
        let grid = gridState.grid;
        let renderEvent = gridState.event;
        let childEvent = renderEvent.create({[grid.schema.name]:this.props.row});
        let content = DynamicRender.render(childEvent,grid.cardContent);
        let style:React.CSSProperties = {backgroundColor:"#fff",borderRadius:4,padding:"15px 15px 0 15px"};
        if (gridState.grid.onRowClick){
            style.cursor = "pointer";
        }
        return <div className="shadow" onClick={this.handleClick} style={style}>
            {content}
        </div>
    }

    handleClick = (e:React.MouseEvent<HTMLDivElement>) => {
        this.props.clickThrottle.handle(e,null,()=> {
            e.preventDefault();
            e.stopPropagation();
            let target:HTMLDivElement = e.target as any;
            let cell = target.closest("[data-col-index]");
            let gridState = this.props.gridState;
            let grid = gridState.grid;
            let row = this.props.row;
            if (cell){
                let colIndex = parseInt(cell.getAttribute("data-col-index"),10);
                let column = gridState.grid.columns[colIndex];
                
                let buttonName = column.action || column.onClickAction;
                if (buttonName){
                    var anchor = target.closest("a");
                    if (!anchor) return; 

                    let canvas = gridState.event.canvas;
                    let schema = gridState.grid.schema;

                    gridState.collection.currentRow = row;
                    gridState.event.canvas.update();
                    
                    let buttonRef = new ActionRef(canvas,buttonName,{[schema.name]:this.props.row});
                    buttonRef.trigger();
                    return;
                }
            }
            gridState.collection.currentRow = row;
            gridState.event.canvas.update();
            if (grid.onRowClick){
                
                let scope = {[gridState.grid.schema.name]:row};
                let rowKey = Collection.getRowKey(row,grid.schema);
                grid.onRowClick.trigger({current:row,value:rowKey,table:gridState.rows,scope});
            }
            else {
                gridState.event.canvas.update();
            }
            
            
          
        
        });
    }
}