import { Select } from 'components/display/Select';
import { TextBox } from 'components/display/TextBox';
import { AnchorPoint } from 'components/helpers/AnchorPoint';
import { ComponentStyles, FieldRef, RecordMeta, RenderEvent } from 'core';
import React from 'react';
import { Popup } from './Popup';
import { TextFilter } from 'components/FilterField';


export class DateRangePicker extends React.Component<{event:RenderEvent,from:FieldRef,to:FieldRef,ranges:any}>{
    dropRef:React.RefObject<HTMLDivElement> = React.createRef();
   
    state= {open:false}

    render(){
        let displayValue;
       
        let event = this.props.event;

        let popup;
        if (this.state.open){
            popup = this.renderPopup();
        }
        
        let dates;
        let from = this.props.from;
        let to = this.props.to;

        dates =(<>
            <div className="RT-FilterBox" >
                <label>{from.label}</label>
                <div className="RT-FilterBox__content" style={{borderTopRightRadius:0,borderBottomRightRadius:0,width:140}}>
                    <TextFilter field={from} />
                </div>
            </div>
            <div className="RT-FilterBox">
                <label>{to.label}</label>
                <div className="RT-FilterBox__content" style={{borderTopLeftRadius:0,borderBottomLeftRadius:0,borderLeftWidth:0,width:175}}>
                    <TextFilter field={to} />
                    <div ref={this.dropRef} style={{width:35,flexShrink:0,display:"flex",alignItems:"center",justifyContent:'center',backgroundColor:"#fff",
                        borderLeft:"solid 1px var(--rt-section-border-color)",cursor:"pointer"}} onClick={this.handleRangeSelectorClick}>
                        <div style={{border:"solid 5px",borderColor:"currentColor transparent transparent transparent",width:10,height:10,marginTop:5,marginRight:3}}/>
                        {popup}
                    </div>
                </div>
            </div>
        </>)
        return <div style={{display:"flex"}}>
            {dates}
        </div>
    }

   
    renderPopup(){
        return (<Popup
            attachedRef={this.dropRef.current as HTMLElement}
            anchorPoint={AnchorPoint.BottomAlignRight}
            onForceClose={this.handlePopupForceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderMenu}
        />);
    }

    renderMenu = ()=>{
        let elems = [];
        let current:string = null;
        let ranges = this.props.ranges;
        if (ranges){
            for(let i = 0 ; i < ranges.length;i++){
                let range = ranges[i];
                elems.push(<Option key={i} label={range.label} name={range.label} current={current} onClick={this.handleOptionClick} />)
            }
        }

        let content = <div className="RT-DropMenu" style={{ backgroundColor: "#fff", boxShadow: "0 0 0 2px hsla(0,0%,0%,0.1)", borderRadius: 4,
            padding:8,marginTop:4 }}>
            {elems}
        </div>
        return content;


    }
    handleOptionClick = (name:string) => {
       
       
        let canvas = this.props.event.canvas;
        let range = this.findRange(name);
        if (range){
            this.props.from.setValueNoUpdate(range.from);
            this.props.to.setValue(range.to);
        }
        this.setState({open:false});
    }

    findRange(name:string){
        let ranges = this.props.ranges;
        for(let i = 0; i < ranges.length;i++){
            let range = ranges[i];
            if (range.label == name){
                return range;
            }
        }
        return null;
    }

    handleRangeSelectorClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({open:true});
    }

    handlePopupForceClose = () => {
        this.setState({open:false});
    }
}

class DateRangePickerOld extends React.Component<{event:RenderEvent,record:any}>{
    dropRef:React.RefObject<HTMLDivElement> = React.createRef();
   
    state= {open:false}

    render(){
        let displayValue;
       
        let event = this.props.event;

        let fields = this.getFields();

        let rangeValue = fields.range.value;

        if (rangeValue){
            displayValue = this.getLabel(rangeValue);
        }
        else {
            displayValue = "Select date range";
        }

        let popup;
        if (this.state.open){
            popup = this.renderPopup();
        }
        
        let rangeSelector =( <div>
            <label style={{marginBottom:4}}>{fields.range.label}</label>
            <div style={{fontSize:"1rem",lineHeight:"1.5",display:'flex',alignItems:'center',cursor:"pointer"
                ,backgroundColor:"#fff",padding:"0.375rem 0.75rem",height:"calc(1.5em + 0.75rem + 2px)",border:"1px solid #c4cdd5"
                ,borderRadius:3}} onClick={this.handleClick} ref={this.dropRef}>
                <div style={{whiteSpace:"nowrap"}}>{displayValue}</div>
                <div style={{marginLeft:8}}>
                    {this.renderAngleDown()}
                </div>
            </div>
            {popup}
        </div>);

        let dates;
        if (rangeValue == "from-to"){
            dates =(<>
                <TextBox event={event} field={fields.from} label={fields.from.label}/>
                <TextBox event={event} field={fields.to} label={fields.to.label} />
            </>)
        }
        return <div style={{display:"flex",gap:20}}>
            {rangeSelector}
            {dates}
            <Select event={event} field={fields.bucket} label={fields.bucket.label} styles={{}} labelStyles={{}} />
        </div>
    }

    getFields():{range:FieldRef,bucket:FieldRef,from:FieldRef,to:FieldRef}{
        let record = this.props.record;
        let meta = RecordMeta.forRecord(record);
        let canvas = this.props.event.canvas;
        let event = this.props.event;
        let recordName = meta.schema.name;
        return {
        
            range:event.field(recordName + ".range"),
            bucket:event.field(recordName + ".bucket"),
            from:event.field(recordName + ".from"),
            to:event.field(recordName + ".to")
        }
    }

    getValue():string {
        let record = this.props.record;
        return record["range"];
    }

    getLabel(value:string):string {
        switch(value){
            case "this-year":return "This year";
            case "last-year":return "Last year";
            case "next-year":return "Next year";
            case "this-month":return "This month";
            case "last-month":return "Last month";
            case "next-month":return "Next month";
            case "this-week":return "This week";
            case "last-week":return "Last week";
            case "next-week":return "Next week";
            case "today":return "Today";
            case "yesterday":return "Yesterday";
            case "tomorrow":return "Tomorrow";
            case "from-to":return "Custom";
        }
        return value;
    }
    renderPopup(){
        return (<Popup
            attachedRef={this.dropRef.current as HTMLElement}
            anchorPoint={AnchorPoint.BottomAlignRight}
            onForceClose={this.handlePopupForceClose}
            onWheel={null}
            onAnchored={null}
            zIndex={2990}
            render={this.renderMenu}
        />);
    }

    renderMenu = ()=>{
        let elems = [];
        let fields = this.getFields();
        let current = fields.range.value;
        elems.push(<div key="year" style={{display:"flex",gap:10}}>
            <Option key="this-year" label="This year" name="this-year" current={current} onClick={this.handleOptionClick} />
            <Option key="last-year" label="Last year" name="last-year" current={current} onClick={this.handleOptionClick} />
            <Option key="next-year" label="Next year" name="next-year" current={current} onClick={this.handleOptionClick} />
        </div>);

        elems.push(<div key="month" style={{display:"flex",gap:10}}>
            <Option key="this-month" label="This month" name="this-month" current={current} onClick={this.handleOptionClick} />
            <Option key="last-month" label="Last month" name="last-month" current={current} onClick={this.handleOptionClick} />
            <Option key="next-month" label="Next month" name="next-month" current={current} onClick={this.handleOptionClick} />
        </div>);

        elems.push(<div key="month" style={{display:"flex",gap:10}}>
            <Option key="this-week" label="This week" name="this-week" current={current} onClick={this.handleOptionClick} />
            <Option key="last-week" label="Last week" name="last-week" current={current} onClick={this.handleOptionClick} />
            <Option key="next-week" label="Next week" name="next-week" current={current} onClick={this.handleOptionClick} />
        </div>);

        elems.push(<div key="day" style={{display:"flex",gap:10}}>
            <Option key="today" label="Today" name="today" current={current} onClick={this.handleOptionClick} />
            <Option key="yesterday" label="Yesterday" name="yesterday"current={current} onClick={this.handleOptionClick} />
            <Option key="tomorrow" label="Tomorrow" name="tomorrow" current={current} onClick={this.handleOptionClick} />
        </div>);
        elems.push(<div key="spacer" style={{height:15}}/>);
        elems.push(<Option key="custom" label="Custom" name="from-to"current={current} onClick={this.handleOptionClick} />)
        
        
      
        let content = <div className="RT-DropMenu" style={{ backgroundColor: "#fff", boxShadow: "0 0 0 2px hsla(0,0%,0%,0.1)", borderRadius: 4,
            padding:8,marginTop:4 }}>
            {elems}
        </div>
        return content;


    }
    handleOptionClick = (name:string) => {
        this.setState({open:false});
        let record = this.props.record;
        let meta = RecordMeta.forRecord(record);
        let canvas = this.props.event.canvas;

        let bucket:string;

        if (name == "this-year" || name=="last-year" || name == "next-year"){
            bucket = "month";
        }
        else if (name == "this-month" || name == "last-month" || name == "next-month"){
            bucket = "week";
        }
        else {
            bucket = "day";
        }

        let bucketField = canvas.getFieldRef(meta.schema.name + ".bucket",this.props.event.scope);
        bucketField.setValueNoUpdate(bucket);

        let rangeField = canvas.getFieldRef(meta.schema.name + ".range",this.props.event.scope);
        rangeField.setValue(name);
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({open:true});
    }

    handlePopupForceClose = () => {
        this.setState({open:false});
    }

    renderAngleDown(){
        return (<svg xmlns="http://www.w3.org/2000/svg" style={{display:"block",width:"20px",height:"20px",fill:"rgb(21,27,38)"}} viewBox="0 0 24 24">
           <path d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"/>
        </svg>);
    }
}

class Option extends React.Component<{label:string,name:string,current:string;onClick:(name:string) => void}>{
    render(){
        let fontWeight = 400;
        if (this.props.current == this.props.name){
            fontWeight = 500;
        }
        return (<div className="RT-DropMenu__item" onClick={this.handleClick} style={{padding:10,fontWeight,fontSize:"15px",cursor:"pointer",width:150}}>{this.props.label}</div>)
    }
    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.name);
    }
}