import React from 'react'
import { ReadonlyField } from './display/ReadonlyField';
import { TextBox } from './display/TextBox';
import { DisplayProps } from './PutField';

export class DatePicker extends React.Component<DisplayProps & {styles:string;labelStyles:string}>{
    render(): React.ReactNode {
        let event = this.props.event;
        let field = this.props.field;
        if (!field){
            return;
        }
        if (field.hidden) return null;

        let styles = event.computeStyles(field.styles,this.props.styles);
        let labelStyles = event.computeStyles(field.labelStyles,this.props.labelStyles);
        
        field.labelOverride = this.props.label;
        if (styles.format){
            field.formatOverride = styles.format;
        }
    
        let label;
        if (!this.props.hideLabel){
            label = this.props.label || field.label;
        }
        let placeholder = this.props.placeholder || field.placeholder;
        let format = field.format;
        let help = this.props.help || field.help;
        let required = this.props.required || field.required;
       
       
      
        let componentProps = {...this.props,key:this.props.$id,field,type:field.type,label,placeholder,format,help,required,styles,labelStyles};
        if (field.readonly || this.props.readonly) {
            return <ReadonlyField {...componentProps}/>
        }
        return <TextBox {...componentProps} />
    }
}