import React from 'react';
import {  makeInput } from '../../core';
import { DisplayProps } from '../PutField';
import { FieldError } from './FieldError';

 export const Checkbox = makeInput(class extends React.Component<DisplayProps>{

    
    componentDidMount(){
        let input = this.props.input;
        if (input.focusElemRef.current && input.field.shouldFocus) {
            input.focusElemRef.current.focus();
            input.focusElemRef.current.select();
        }
    }

    componentDidUpdate(){
        let input = this.props.input;
        if (input.focusElemRef.current && input.field.shouldFocus){
            input.focusElemRef.current.focus();
            input.focusElemRef.current.select();
        }
    }

    render(){
        let input = this.props.input;
        let field = input.field;
        if (!field) return null;

        let containerClass = ["RT-Checkbox"];
        let inputClassName = ["RT-Checkbox__input"];
        let errorElem;

        if (field.error) {
            containerClass.push("RT-Checkbox--has-error");
            errorElem = <FieldError input={input}/>
        }
        let helpElem;
        if (this.props.help) {
            helpElem = <small className="form-text text-muted">{this.props.help}</small>;
        }

        let required;
        if (this.props.required) {
            required = <span className="text-danger">* </span>;
        }
        let disabled = field.disabled || field.readonly;
        if (disabled){
            containerClass.push("RT-Checkbox--disabled");
        }
        let checked = field.value;
        let labelElem;
        if (this.props.label && !this.props.hideLabel){
            labelElem =   <span className="RT-Checkbox__label">
                {this.props.label}
            </span>
        }
        return (
            <div className={containerClass.join(' ')}>
                <label className="RT-Checkbox__inner" onClick={this.handleClick}>
                    <input
                        ref={input.focusElemRef}
                        type="checkbox"
                        className={inputClassName.join(' ')}
                        checked={checked}
                        tabIndex={this.props.noTabStop ? -1 : null}
                        disabled={disabled}
                        onChange={this.handleChange}
                    />
                    <span className="RT-Checkbox__faux">{this.renderCheckmark()}</span>
                    {labelElem}
                </label>
                {errorElem}
            </div>
        );
    }

    handleClick = (e:React.MouseEvent) => {
        e.stopPropagation();
    }

    handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.checked;
        this.props.input.setValue(value);
    }

    renderCheckmark() {
        return <svg viewBox="0 0 52 52"><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
    }

})