import React from 'react';
import { FilterField } from './FilterField';
import { Icon } from './Icon';
import { DisplayProps } from './PutField';

export class InlineEditor extends React.Component<DisplayProps,{editing:boolean,editValue:string}>{

    state = {editing:false,editValue:""};
    ref = React.createRef<HTMLInputElement>();

    setFocus:boolean;

    render(){
        let field = this.props.field;
        let label = this.props.label || field.label;
      
        let content;
        if (this.state.editing){
            content = <div style={{display:"flex",alignItems:"center",gap:10}}>
                <input ref={this.ref} type="text" style={{borderBottom:"solid 2px #000",padding:4,width:"100%",outline:0,fontSize:"15px",fontWeight:500}} 
                value={this.state.editValue} onChange={this.handleChange} onKeyPress={this.handleKeyPress} onKeyDown={this.handleKeyDown}/>
                <div onClick={this.handleCommitClick} style={{cursor:"pointer"}}>
                    <Icon icon="check" size={20} fill="currentColor"/>
                </div>
                <div onClick={this.handleCancelClick}  style={{cursor:"pointer"}}>
                    <Icon icon="close" size={20} fill="currentColor"/>
                </div>
            </div>
        }
        else {
            content = (<div className="RT-Static-Field__content" style={{display:"flex",alignItems:"center",gap:5,cursor:"pointer"}}>
                <div style={{fontWeight:500}}>{field.formattedValue}</div>
                <Icon icon="edit" size={16} fill="currentColor"/>
            </div>)
        }
        return (<div className="RT-Static-Field" onClick={this.handleClick}>
            <label className="RT-Static-Field__label RT--readonly">{label}</label>
            {content}
        </div>)
    }

    componentDidUpdate(){
        if (this.setFocus){
            this.setFocus = false;
            this.ref.current.focus();
        }
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!this.state.editing){
            this.setFocus = true;
            this.setState({editing:true,editValue:this.props.field.formattedValue});
        }
    }

    handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        this.setState({editValue:e.target.value});
    }

    handleCommitClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.commitValue();
    }

    commitValue(){
        let field = this.props.field;
        let newValue = this.state.editValue;
        this.setState({editing:false,editValue:""});
        field.setValue(newValue);
    }
    handleCancelClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({editing:false,editValue:""});
    }

    handleKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key == "Enter"){
            e.preventDefault();
            e.stopPropagation();
            this.commitValue();
        }
        else if (e.key == "Escape"){
            this.setState({editing:false,editValue:""});
        }
    }

    handleKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key == "Escape"){
            this.setState({editing:false,editValue:""});
        }
    }
}