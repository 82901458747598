import React from 'react';
import { DialogResult } from '../../core';
import { DialogContainer } from './DialogContainer';

export type MessageStyle = 'danger' | 'warning' | 'info' | 'success';
export class MessageBox extends React.Component<{
    onClose?:(result:DialogResult)=> void;
    message: string;
    style?: MessageStyle;
    layerZIndex?:number;
}> {

    render() {
        let icon: any;
        let style = this.props.style;
        let title = this.props.message;
    
        let headerClass = "Message-Box__header";
        let buttonClass = "Message-Box__buttons";
        if (style == "success") {
            icon = this.renderSuccessIcon();
            headerClass += " Message-Box__header--success";
            buttonClass += " Message-Box__buttons--success";
        } else if (style == "danger") {
            icon = this.renderErrorIcon();
            headerClass += " Message-Box__header--error";
            buttonClass += " Message-Box__buttons--error";
        } else {
            icon = this.renderInfoIcon();
            headerClass += " Message-Box__header--info";
            buttonClass += " Message-Box__buttons--info";
        }

        var header = (
            <div className={headerClass}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    {icon}
                </div>
            </div>
        );
        let content;
        if (this.props.children){
            content = <div className="Message-Box__text">
                <div className="Message-Box__text-inner">
                    {this.props.children}
                </div>
            </div>
        }

       
        var body = (
            <div className="Message-Box__body">
                <div className="Message-Box__title">{title}</div>
                {content}
            </div>
        );


        let buttons = ( <button
            className="RT-Button RT-Button--large RT-Button--cancel "
            onClick={this.handleClose}
        >
            Close
        </button>)

        
        let helpButton;
        /*
        let helpTopic = this.props.frame.helpTopic;
        if (helpTopic){
            helpButton = <div style={{position:"absolute",left:20,bottom:25}}>
                <Help.DialogHelpButton topic={helpTopic}/>
            </div>
        }
        */
        var footer = (
            <div className={buttonClass}>
               {buttons}
               {helpButton}
            </div>
        );

        return (
            <DialogContainer size="small" zIndex={this.props.layerZIndex} animate>
                {header}
                {body}
                {footer}
            </DialogContainer>
        )
      
    }
    renderCloseButton() {
        return (
            <button
                className="RT-Modal__close"
                onClick={this.handleClose}
            >
                <svg className="RT-Modal__close-icon" viewBox="0 0 40 40">
                    <path
                        d="M 10,10 L 30,30 M 30,10 L 10,30"
                        strokeWidth="2"
                    />
                </svg>
            </button>
        );
    }

    

    handleClose = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClose({continue:true});
    };

    renderSuccessIcon() {
        return (
            <svg
                className="Message-Box__icon Message-Box__icon--success"
                viewBox="0 0 24 24"
            >
               <path d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/>
            </svg>
        );
    }

    renderErrorIcon() {
        return (
            <svg
                className="Message-Box__icon Message-Box__icon--error"
                viewBox="0 0 24 24"
            >
                <path d="M12,7a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V8A1,1,0,0,0,12,7Zm.92,8.62a.76.76,0,0,0-.09-.18l-.12-.15a1,1,0,0,0-1.09-.21,1.15,1.15,0,0,0-.33.21A1,1,0,0,0,11,16a1,1,0,0,0,.08.38.9.9,0,0,0,.54.54.94.94,0,0,0,.76,0,.9.9,0,0,0,.54-.54A1,1,0,0,0,13,16a1.36,1.36,0,0,0,0-.2A.64.64,0,0,0,12.92,15.62ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" />
            </svg>
        );
    }

    renderInfoIcon() {
        return (
            <svg
                className="Message-Box__icon Message-Box__icon--info"
                viewBox="0 0 24 24"
            >
                <path d="M12,11a1,1,0,0,0-1,1v4a1,1,0,0,0,2,0V12A1,1,0,0,0,12,11Zm.38-3.92a1,1,0,0,0-.76,0,1,1,0,0,0-.33.21,1.15,1.15,0,0,0-.21.33A.84.84,0,0,0,11,8a1,1,0,0,0,.29.71,1.15,1.15,0,0,0,.33.21A1,1,0,0,0,13,8a1.05,1.05,0,0,0-.29-.71A1,1,0,0,0,12.38,7.08ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" />
            </svg>
        );
    }

    renderConfirmIcon() {
        return (
            <svg
                className="Message-Box__icon Message-Box__icon--info"
                viewBox="0 0 24 24"
            >
                <path d="M11.29,15.29a1.58,1.58,0,0,0-.12.15.76.76,0,0,0-.09.18.64.64,0,0,0-.06.18,1.36,1.36,0,0,0,0,.2.84.84,0,0,0,.08.38.9.9,0,0,0,.54.54.94.94,0,0,0,.76,0,.9.9,0,0,0,.54-.54A1,1,0,0,0,13,16a1,1,0,0,0-.29-.71A1,1,0,0,0,11.29,15.29ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM12,7A3,3,0,0,0,9.4,8.5a1,1,0,1,0,1.73,1A1,1,0,0,1,12,9a1,1,0,0,1,0,2,1,1,0,0,0-1,1v1a1,1,0,0,0,2,0v-.18A3,3,0,0,0,12,7Z"/>
            </svg>
        );
    }
}