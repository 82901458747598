
import React from 'react';
import {ActionRef,  makeComponent, PageContainer, RenderEvent} from '../core';
import { Icon } from './Icon';
import {BotState, IBotMenuCommand, IBotMessage, IBotOption,IBotInfo} from 'core/BotState';
import { IPageLaunchParams } from 'core/types';


 export const Bot  = makeComponent(class extends React.Component<{event:RenderEvent}>{


    containerRef = React.createRef<HTMLDivElement>();

    botState:BotState;

    constructor(props){
        super(props);
        let canvas = this.props.event.canvas;
        this.botState = new BotState();
        this.botState.canvas = canvas;
        this.botState.commands = this.getCommands(); //  canvas.getRecord("@commands",{});
    }

    componentDidMount(){
        this.containerRef.current.addEventListener("wheel",this.handleWheel);
        this.botState.broadcast.connect(this);
        this.props.event.canvas.blockState["$bot"] = this.botState;
        if (this.botState.promptRef.current){
            this.botState.promptRef.current.focus();
        }
        this.botState.connect();
    }

    componentWillUnmount(){
        this.containerRef.current.removeEventListener("wheel",this.handleWheel);
        this.botState.broadcast.disconnect(this);
        this.botState.disconnect();
    }

    componentDidUpdate(){
        this.botState.scrollToEnd();
    }

    getCommands():IBotMenuCommand[]{
        let commands:IBotMenuCommand[] = [];
        React.Children.forEach(this.props.children,(child:any) => {
            let props = child.props;
            if (props && props.$kind == "UI.Bot.Command"){
                let command:IBotMenuCommand = {
                    name:props.name,
                    label:props.name,
                    hint:props.hint,
                    description:props.description,
                    screen:props.screen,
                    eventParam:props.eventParam
                };
                commands.push(command);
            }
        })
        return commands;
    }

    render() {
        let event = this.props.event;
        let screenElems = [];
        let messages = this.botState.messages;
        for(let i = 0; i < messages.length;i++){
            let message = messages[i];
            let elem;
            elem = (<div key={message.id} style={{marginTop:20}}>    
                <BotMessage key="card" botState={this.botState} event={event} message={message}/>
            </div>);
            screenElems.push(elem);
        }
        let maxWidth = 800;
        return  (<div ref={this.containerRef} style={{height:"100%",display:"flex",flexDirection:"column"}}>
            <div ref={this.botState.scrollRef} className="rt-scrollbars" style={{flexGrow:1,overflowY:"auto"}}>
                <div style={{maxWidth,marginLeft:'auto',marginRight:"auto",display:"flex",flexDirection:'column',
                    minHeight:"100%",justifyContent:"flex-end",paddingLeft:10}}>
                    {screenElems}
                </div>
            </div>
            <div style={{flexGrow:0,padding:"40px 0 25px 34px",width:"100%",maxWidth,marginLeft:"auto",marginRight:"auto"}}>
                <BotPrompt botState={this.botState} />
            </div>
           
        </div>);
        

    }


    handleWheel = (e) => {
        this.botState.scrollRef.current.scrollTop = this.botState.scrollRef.current.scrollTop + e.deltaY;
    }
   
});


export class BotCommand extends React.Component<{name:string,hint:string,description:string,screen:string,eventParam:string}>{
    render(){
        return null;
    }
}

export const BotDialogCard = makeComponent(class extends React.Component<{event:RenderEvent,message:string,options:IBotOption[]}>{
    render(){

        let botInfo = this.props.event.canvas.botInfo;
        let botState:BotState = botInfo.botState;
        let style:React.CSSProperties = {backgroundColor:"#fff",padding:"10px 20px",borderRadius:6};
        let options;
        if (!botInfo.message.answered){
            options =  <BotOptions botState={botState} handleChooseOption={this.handleChooseOption} options={this.props.options} />
        }
        let timestamp; //  = <div style={{fontSize:"13px",marginBottom:15}}><span style={{fontWeight:500,marginRight:10,}}>{botInfo.message.botName}</span>{botInfo.message.timestamp}</div>
        let content;
        if (this.props.children){
            content = <div style={{marginTop:10}}>{this.props.children}</div>
        }
        return (<div>
                <div style={style}>
                    {timestamp}
                    <div>{this.props.message}</div>
                </div>
                {options}
                {content}
            </div>)
    }
        
    handleChooseOption = (option:IBotOption) => {
        let botInfo = this.props.event.canvas.botInfo;
        let botState = botInfo.botState as BotState;
        botState.executeOption(botInfo.message,option);
        botInfo.message.answered = true;
        this.forceUpdate();
    }
});

class BotMessage extends React.Component<{event:RenderEvent;botState:BotState;message:IBotMessage}>{

    shouldComponentUpdate(){
        return false;
    }

    componentDidMount(){
        this.props.message.refresh = () => this.forceUpdate();
    }

    componentWillUnmount(){
        this.props.message.refresh = null;
    }

    render(){
        let event = this.props.event;
        let message = this.props.message;
        let content;
        let footer;

        let typing;
        let style:React.CSSProperties;

        if (!message.loaded){
            typing =  <div style={{marginTop:25,display:"flex",alignItems:"center",gap:20}}><Typing/></div>
        }

        let timestamp = <div style={{fontSize:"13px",marginBottom:10,paddingLeft:5,marginTop:5}}><span style={{fontWeight:500,marginRight:10,}}>{message.botName}</span>{message.timestamp}</div>
        if (message.type == "screen"){
            let botInfo:IBotInfo = {botState:this.props.botState,message:message,onLoaded:this.handleLoaded};
            
            let questionParams = this.props.botState.getParamsForQuestion(message.question);
            if (questionParams){
                let page:IPageLaunchParams = {
                    name:"",
                    layout:"bot",
                    props:questionParams
                }
                content = (<>
                    {timestamp}
                    <PageContainer event={event} page={page} layer="bot-message" />
                </>)
            }
            else {
                typing = null;
                content = (<>
                    {timestamp}
                    <div>???</div>
                </>)
            }
        }
        else if (message.content){
            let style:React.CSSProperties = {backgroundColor:"#fff",padding:"10px 20px",borderRadius:6};
           
            content = (<>
                {timestamp}
                <div style={style}>
                    
                    <div>{message.content}</div>
                </div>
            </>);
        }
        let question;
        if (message.text){
            question = (
                <div style={{display:"flex",justifyContent:"flex-end"}}>
                    <div style={{backgroundColor:"rgb(234 234 251)",padding:"6px 18px",borderRadius:6}}>
                        <div style={{fontSize:"13px"}}>{message.timestamp}</div>
                        <div style={{fontSize:"14px"}}>{message.text}</div>
                    </div>
                </div>
            );
        }
        return (<>
            {question}
            <div style={{marginTop:20,display:"flex",paddingRight:50}}>
                <div style={{marginRight:12}}>
                    <Icon icon="robot" size={28} fill="rgb(101, 99, 255)"/>
                </div>
                <div style={{flexGrow:1}}>
                    <div style={style}>
                        <div key="typing">
                            {typing}
                        </div>
                        <div key="content">
                            {content}
                        </div>       
                    </div>
                    {footer}
                </div>
            </div>
        </>)
    }

    handleLoaded = () => {
        this.props.message.loaded = true;
        this.forceUpdate();
        this.props.botState.scrollToEnd();
    }
}

class BotPrompt extends React.Component<{botState:BotState}>{


    componentDidMount(){
        this.props.botState.promptBroadcast.connect(this);
    }

    componentWillUnmount(){
        this.props.botState.promptBroadcast.disconnect(this);
    }

    render(){
        let botState = this.props.botState;
        let placeholder = "Type your commands here";
        let commandList;
        if (botState.commandMenuOpen){
            commandList = <CommandMenu botState={botState}/>
        }
        return <div style={{position:"relative",background:"#fff"}}>
            <input ref={botState.promptRef} tabIndex={-1} type="text" value={botState.promptValue} 
        style={{ position:"relative",zIndex:1,border:"none",backgroundColor:"transparent",borderRadius:4,padding:15,width:"100%",borderBottom:"solid 2px rgb(101, 99, 255)"}}
        placeholder={placeholder} onChange={this.handleChange} onKeyPress={this.handleKeyPress} onKeyDown={this.handleKeyDown}/>
            <div tabIndex={-1} style={{zIndex:0,position:"absolute",top:0,left:0,right:0,bottom:0,opacity:0.5,padding:15}}>
                {botState.inputHelp}
            </div>
            {commandList}
        </div>
    }

    handleChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        this.props.botState.setPromptValue(e.target.value);
    }

    handleKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
        let botState = this.props.botState;
        if (e.key == "Enter"){
            e.preventDefault();
            e.stopPropagation();
            if (botState.commandMenuOpen){
                botState.chooseActiveCommand();
            }
            else {
                botState.send();
            }
        }
        else if (e.key == " "){
            if (botState.commandMenuOpen && botState.commandMenuItems.length){
                e.preventDefault();
                botState.chooseActiveCommand();
            }
        }
    }

    handleKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) => {
        let botState = this.props.botState;
        if(e.key == "Escape"){
            botState.setPromptValue("");
        }
        else if (e.key == "Tab"){
            e.preventDefault();
            botState.chooseActiveCommand();
        }
        else if (e.key == "ArrowDown"){
            e.preventDefault();
            botState.menuDown();
        }
        else if (e.key == "ArrowUp"){
            e.preventDefault();
            botState.menuUp();
        }
       
    }

}

class CommandMenu extends React.Component<{botState:BotState}>{
    render(){
        let elems = [];
        let botState = this.props.botState;
        let commands = botState.commandMenuItems;
        for(let i = 0; i < commands.length;i++){
            let command = commands[i];
            let style:React.CSSProperties = {padding:"5px 10px",fontWeight:500,display:"flex",alignItems:"center"};
            if (botState.commandMenuActiveIndex == i){
                style.backgroundColor = "rgb(100,99,195)";
                style.color = "#fff";
            }
            elems.push(<div key={command.name} style={style}>
                <div>{command.label}</div>
                <div style={{marginLeft:'auto'}}>{command.description}</div>
            </div>);
        }
        return(<div style={{position:"absolute",bottom:"100%",left:0,width:"100%",backgroundColor:"#fff",boxShadow: "0 0 0 2px hsla(0,0%,0%,0.1)",
            letterSpacing:"0.4px"}}>
            {elems}
        </div>)
    }
}

class BotOptions extends React.Component<{botState:BotState,options:IBotOption[],handleChooseOption:(option:IBotOption) => void}>{
    render(){
        let buttons = [];
        let options = this.props.options;
        if (!options) return null;
        for (let i =0 ; i < options.length; i++){
            let option = options[i];
            buttons.push(<BotDialogButton key={i} option={option} onClick={this.handleClick}/>)
        }
       return (<div style={{display:"flex",alignItems:'center',gap:20,marginTop:15,flexWrap:"wrap"}}>
            {buttons}
        </div>)
    }

    handleClick= (option:any) => {
        this.props.handleChooseOption(option);
      
    }
}

export class BotOption extends React.Component<{event:RenderEvent,onClick:ActionRef,label:string}>{

    render(): React.ReactNode {
        let label = this.props.label;
        return <button className="RT-Button RT-Button--outline" style={{display:"block",marginTop:10}} onClick={this.handleClick}>{label}</button>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (this.props.onClick){
            this.props.onClick.trigger();
        }
        let event = this.props.event;
        if (event.canvas.botInfo){
            let botState = event.canvas.botInfo.botState;
            if (botState.promptRef.current){
                botState.promptRef.current.focus();
            }
        }
    }
}

class BotDialogButton extends React.Component<{option:any,onClick:(option:any) => void}>{
    render(){
        let name = this.props.option.name;
        let label = this.props.option.label;
        if (name ==  "cancel"){
            return <button className="RT-Button RT-Button--cancel" onClick={this.handleClick}>{label}</button>
        }

        let segments = label.split('|');
        let labelElem;
        if (segments.length > 1){
            let labelElems = [];
            for(let i =0; i < segments.length;i++){
                labelElems.push(<div key={i}>{segments[i]}</div>)
            }
            labelElem = <div style={{display:"flex",alignItems:"center",gap:20}}>{labelElems}</div>
        }
        else {
            labelElem = label;
        }
       
        return <button className="RT-Button RT-Button--outline" onClick={this.handleClick}>{labelElem}</button>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick(this.props.option);
    }
}

export class Typing extends React.Component<{}>{
    render(){
        return (<div className="Bot__typing">
            <div className="Bot__typing__dot"></div>
            <div className="Bot__typing__dot"></div>
            <div className="Bot__typing__dot"></div>
        </div>)
    }
}