import { PageContainer, makeComponent, RenderEvent ,ActionRef, Canvas} from '../core';
import React  from 'react';
import { IPageLaunchParams, IRecordChangedEvent } from 'core/types';


export const InlinePage = makeComponent( class extends React.Component<{event:RenderEvent,name:string,
        layout?:string,view?:string,params?:any,autoRefresh?:number,onRecordChanged?:ActionRef,
        layer?:string,noPadding?:boolean,asWidget?:boolean},{key:number,prevParams:any}>
    {
    
    constructor(props){
        super(props);
        this.state = {key:1,prevParams:null};
    }
    static getDerivedStateFromProps(props, state) {
        let prevParams = state.prevParams;
        let nextParams = {};
        buildCompareObject(props.params,null,nextParams);
        if (prevParams){
            if (!paramsMatch(prevParams,nextParams) || !paramsMatch(nextParams,prevParams)){
                return {key:state.key + 1,prevParams:nextParams};
            }
            return null;
        }
        else {
            return {key:1,prevParams:nextParams}
        }
    }


    render(){
        let event = this.props.event;
        if (event.canvas.pendingRestart) return null;
        
        let pageName = this.props.name;
        if (!pageName) return null;

        let page:IPageLaunchParams = {
            name:pageName,
            layout:this.props.layout,
            props:this.props.params
        }
        
        
        let handleRecordChanged;
        if (this.props.onRecordChanged){
            handleRecordChanged = async (recordEvent:IRecordChangedEvent) => {
                await event.canvas.triggerAction(this.props.onRecordChanged,{value:recordEvent.rowKey,isRecordChanged:true});
            }
        }
        return <PageContainer page={page} event={event} layer={this.props.layer} autoRefresh={this.props.autoRefresh} key={this.state.key}
            handleRecordChanged={handleRecordChanged} noPadding={this.props.noPadding} asWidget={this.props.asWidget}
        />
    }

    
});


function paramsMatch(prevValues:any,nextValues:any){

    for(let key in prevValues){
        let prevValue = prevValues[key];
        let nextValue = nextValues[key];
        if (!prevValue && !nextValue) continue;
        if (!prevValue || !nextValue) return false;   
        if (prevValue != nextValue) return false;
    }
    return true;

}

function buildCompareObject(source:any,prefix:string,target:any):any {
    for(let key in source){
        let value = source[key];
        let targetKey = addPrefix(key,prefix);
        if (typeof value === "object"){
            buildCompareObject(JSON.parse(JSON.stringify(value)),targetKey,target);
        }
        else {
            target[targetKey] = value;
        }
    }
}

function addPrefix(value:string,prefix:string):string {
    if (!prefix) return value;
    return prefix + ":" + value;
}