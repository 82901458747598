

import React from 'react';
import {makeComponent, RenderEvent} from '../core'; 


export const WhenEmpty = makeComponent(class WhenEmpty extends React.Component<{event?:RenderEvent,$id?:string,label?:string,icon?:string}>{
    render(): React.ReactNode {
        return this.props.children || null;
    }
});



