import React from 'react';
import { Broadcast } from './Broadcast';
import { IVisibilityContainer } from './types';

export interface ITab extends IVisibilityContainer {
    name:string;
    hidden:boolean;
    contentKey:any;
}

export interface IMenuState {
    open:boolean
}

export class TabSetState {
    activeTab:string;
    tabs:{[name:string]:ITab};
    tabList:ITab[];
    stackContent:any;
    broadcast:Broadcast;
    menuState:IMenuState;

    constructor(){
        this.tabs = {}
        this.broadcast = new Broadcast();
        this.menuState = {open:false}; 
    }

    

    createTabs(children:any){
        this.tabs = {};
        this.tabList = [];
        let firstTabName:string;
        React.Children.forEach(children,(child:any,index:number) => {
            let tabName = child.props.$id;
            if (tabName){
                if (!firstTabName) firstTabName = tabName;
                let tab:ITab = {name:tabName,hidden:true,contentKey:0};
                this.tabs[tabName] = tab;
                this.tabList.push(tab);
            }
        });
        let activeTab = this.activeTab;
        if (activeTab && this.tabs[activeTab]){
            this.tabs[activeTab].hidden = false;
        }
        else if (firstTabName){
            this.tabs[firstTabName].hidden = false;
        }
    }

    activateTab(tabName:string, refreshContent?:boolean){
        this.activeTab = tabName;
        let found = false;
    

        for(let key in this.tabs){
            let v = this.tabs[key];
            if (key == tabName){
                v.hidden = false;
                if (refreshContent){
                    v.contentKey++;
                }
                found = true;
            }
            else {
                v.hidden = true;
            }
        }
        this.broadcast.refresh();
    }

    refresh(){
        this.broadcast.refresh();
    }

}