import React from 'react';

export class HorizontalSizer extends React.Component<{left:number,width:number,setSize:(adjustX:number) => void}>{

    startX:number;
    offsetX:number;
    sizing:boolean;

    render(){
        let left = this.props.left;
        if (this.sizing){
            left += this.offsetX;
        }
        let backgroundColor = this.sizing ? "rgb(212,214,220)" : "transparent"
        let width = this.sizing ? 3 : this.props.width;
        return (<div style={{position:"absolute",cursor:"ew-resize",top:0,left,width,bottom:0,
            backgroundColor,zIndex:9999}} 
            onMouseDown={this.handleMouseDown} >

        </div>);
    }

    handleMouseDown = (e:React.MouseEvent) => {
        this.startX = e.clientX;
        this.offsetX = 0;
        this.sizing = true;
        clearSelection();
        document.addEventListener("mousemove",this.handleMouseMove,{capture:true});
        document.addEventListener("mouseup",this.handleMouseUp,{capture:true});
        this.forceUpdate();
    }

    handleMouseUp = (ev:any) => {
        this.sizing = false;
       
        document.removeEventListener("mousemove",this.handleMouseMove,{capture:true});
        document.removeEventListener("mouseup",this.handleMouseUp,{capture:true});
        if (this.props.setSize){
            this.props.setSize(this.offsetX);
        }
        this.offsetX = 0;
        this.forceUpdate();
    }

    handleMouseMove = (ev:any) => {
        this.offsetX = ev.clientX - this.startX;
        clearSelection();
        this.forceUpdate();
    }
}

function clearSelection(targetWindow?: any) {
    var sel;
    if (!targetWindow) {
        targetWindow = window;
    }
    if (targetWindow.getSelection) {
        sel = targetWindow.getSelection();
        if (sel && sel.removeAllRanges) sel.removeAllRanges();
    }
}