import { FieldType } from "core";
import { ActionRef } from "core/ActionRef";
import { CollectionRef } from "core/CollectionRef";
import { FieldFormatter } from "core/FieldFormatter";
import { FieldRef } from "core/FieldRef";
import { Collection, CollectionArray } from "../Collection";
import { IEvalContext } from "./types";

export class ExpressionOps{

    public static add(left,right){
        if (left === null || left === undefined){
            if (right === null || right === undefined) {
                return null;
            }
            return right;
        }
        if (right == null || right === undefined){
            return left;
        }
        return left + right;
    }

    public static sub(left,right){
        return left - right;
    }

    public static mult(left,right){
        return left * right;
    }

    public static div(left,right){
        try {
            return left / right;
        }
        catch {
            return 0;
        }
    }

    public static idiv(left,right){
        try {
            return left / right;
        }
        catch {
            return 0;
        }
    }

    public static exp(left,right){
        return left ^ right;
    }

    public static mod(left,right){
        return left % right;
    }

    public static con(left,right){
        if (left === null || left === undefined){
            if (right === null || right === undefined) {
                return null;
            }
            return right;
        }
        if (right == null || right === undefined){
            return left;
        }
        return left + right;
    }

    public static isempty(value){
        if (!value) return true;
        if (Array.isArray(value) && !value.length) return true;
        return false;
    }

    public static isnull(value){
        return value === undefined || value === null;
    }

    public static contains(left,right){
        let s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return (s.left.toUpperCase().indexOf(s.right.toUpperCase()) != -1);
        }
        return false;
    }
    public static begins(left,right){
        let s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase().startsWith(s.right.toUpperCase());
        }
        return false;
    }

    public static ends(left,right){
        let s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase().endsWith(s.right.toUpperCase());
        }
        return false;
    }

    public static between(left,right1,right2){
        let s = ExpressionOps.stringsForCompare(left,right1);
        if (s){
            if (s.left < s.right) return false;
        }
        else if(left < right1) return false;

        s = ExpressionOps.stringsForCompare(left,right2);
        if (s){
            return (s.left <= s.right);
        }
        return left < right2;
    }

    public static eq(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() == s.right.toUpperCase();
        }
        return left == right;
    }

    private static stringsForCompare(left:any,right:any):{left:string,right:string}{
        if (typeof(left) === "string"){
            if (right === null || right === undefined){
                return {left,right:""};
            }
            return {left,right:right.toString()}
        }
        if (typeof(right) === "string"){
            if (left === null || left === undefined){
                return {left:"",right};
            }
            return {left,right}
        }
        return null;
    }

    public static neq(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() != s.right.toUpperCase();
        }
        return left != right;
    }

    public static gt(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() > s.right.toUpperCase();
        }
        return left > right;
    }

    public static gte(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() >= s.right.toUpperCase();
        }
        return left >= right;
    }

    public static lt(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() < s.right.toUpperCase();
        }
        return left < right;
    }

    public static lte(left,right){
        var s = ExpressionOps.stringsForCompare(left,right);
        if (s){
            return s.left.toUpperCase() <= s.right.toUpperCase();
        }
        return left <= right;
    }

    public static and(left,right){
        return left && right;
    }

    public static or(left,right){
        return left || right;
    }

    public static xor(left,right){
        return false;
    }

    public static neg(value){
        return -(value);
    }

    public static not(value){
        return !value;
    }

    public static istrue(value):boolean {
        if(value && value !== 0){
            return true;
        }
        return false;
    }

    public static isfalse(value):boolean {
       return !this.istrue(value);
    }

    public static get(context:IEvalContext,name:string){
        let canvas = context.canvas;
        if (context.scope){
            let value = context.scope[name];
            if (value !== undefined) return value;
        }
        return canvas.data[name.substring(1)];
    }

    public static current(context:IEvalContext,name:string){
        if (context.scope){
            let value = context.scope[name];
            if (value !== undefined) return value;
        }
        return Collection.getCurrentRow(context.canvas,name);
    }

    public static FMT(value:any,type:FieldType,format:string){
        return FieldFormatter.format(value,type,format);
    }
    
    public static totals(context:IEvalContext,name:string){
        if (context.scope){
            let value = context.scope[name];
            if (value !== undefined) return value;
        }
        let rows:CollectionArray = context.canvas.data[name.substring(1)];
        if (rows && rows.$$meta){
            return rows.$$meta.totals;
        }
    }

    public static row(context:IEvalContext,name:string,index:number){
        return Collection.getRow(context.canvas,name,index);
    }

   

    public static loc(context:IEvalContext,name:string){
        let canvas = context.canvas;
        if (context.scope){
            let value = context.scope[name];
            if (value !== undefined) return value;
        }
        return canvas.data[name.substring(1)];
    }
  
   
    public static prop(target,name:string){
        if (!target) return null;

        // if target is collection then use target.current as target
        if (target.$$get){
            return target.$$get(name);
        }
        if (name[0] == '@'){
            return target[name.substring(1)];
        }
        else {
            return target[name];
        }
    }

    public static field(context:IEvalContext,name:string):FieldRef{
        return context.canvas.getFieldRef(name,context.scope);
    }

    public static collection(context:IEvalContext,name:string):CollectionRef{
        return context.canvas.getCollectionRef(name,context.scope);
    }

    public static action(context:IEvalContext,name:string):ActionRef{
        let actionRef = new ActionRef(context.canvas,name,context.scope);
       // actionRef.def = context.canvas.actions[name];
        return actionRef;
    }
    
    
    public static ind(target,value){
        if (!target) return null;
       
        if (target.$$isTable){
            return target[value];
        }
        if (target.$$get){
            return target.$$get(value);
        }
        else {
            return target[value];
        }
    }

    public static sdf(name:string,args:any[]){
        let f = ExpressionOps[name];
        if (!f) throw "Invalid function: " + name;
        return f.apply(this,args);
    }

    public static th(context:IEvalContext){
        return context.canvas.data;
    }

    public static EVENT_VALUE(context:IEvalContext){
        return context.eventValue;
    }

    public static RETURN_VALUE(context:IEvalContext){
        return context.returnValue;
    }
    
    public static SCOPE_SELECTED(context:IEvalContext,name:string){
        var collection:any[] = context.canvas.data[name.substring(1)];
        if (!collection) return null;
        let selected = [];
        for(let i = 0; i < collection.length;i++){
            let row = collection[i];
            if (Collection.isRowSelected(row)){
                selected.push(row);
            }
        }
        return selected;
    }

    public static SCOPE_EVERY(context:IEvalContext,name:string){
        var collection = context.canvas.data[name.substring(1)];
        return collection;
    }

    public static ALL_ROWS(context:IEvalContext,name:string,projection:string){
        let collection:CollectionArray = context.canvas.data[name.substring(1)];
        if (!collection) return null;

        let projectionFields = projection.split(',');
        return ExpressionOps.SerializeRows(collection,projectionFields,row => true);
    }

    public static SELECTED_ROWS(context:IEvalContext,name:string,projection:string){
        let collection:CollectionArray = context.canvas.data[name.substring(1)];
        if (!collection) return null;

        let projectionFields = projection.split(',');
        return ExpressionOps.SerializeRows(collection,projectionFields,row => Collection.isRowSelected(row));
    }

    private static SerializeRows(collection:CollectionArray,projection:string[],include:(row:any) => boolean){
        var output = [];
        for(let i = 0; i < collection.length;i++){
            let row = collection[i];
            if (include(row)){
                let outRec = {};
                for(let j = 0;j < projection.length;j++){
                    let key = projection[j];
                    outRec[key] = row[key];
                }
                output.push(outRec);
            }
        }
        return output;
    }

    public static SCOPE_CURRENT(context:IEvalContext,name:string){
        let current = this.current(context,name);
        if (current){
            return current;
        }
    }

    public static RECORD_JSON(context:IEvalContext,name:string){
        let record = this.get(context,name);
        if (record){
            return record;
        }
    }

    public static MAKE_IMAGE_URL(name:string,prefix:string){
        if (!name || !name.startsWith) return null;

        if (name[0] == "/" || name.startsWith("http:") || name.startsWith("https:")){
            return name;
        }
        return prefix + name;
    }

    public static CONCAT():string{
        var s = "";
        for(let i = 0 ; i < arguments.length;i++){
            let arg = arguments[i];
            if (arg){
                s += arg;
            }
        }
        return s;
    }


    public static CONCAT_WS():string{
        var s = "";
        var sep = arguments[0];
        for(let i = 1 ; i < arguments.length;i++){
            let arg = arguments[i];
            if (arg){
                if (s) {
                    s += sep;
                }
                s += arg;
            }
        }
        return s;
    }
    
    public static STYLE_LIST():string {
        var s = "";
        var sep = ';';
        for(let i = 0 ; i < arguments.length;i++){
            let arg = arguments[i];
            if (arg){
                if (s) {
                    s += sep;
                }
                s += arg;
            }
        }
        return s;
    }

    public static INITIALS(first:string,last:string){
        let initials:string = "";
        if (first && first.substring){
            initials = first.substring(0,1);
        }
        if (last && last.substring){
            initials += last.substring(0,1);
        }
        return initials;
    }
    
    public static IIF(test:boolean,trueValue:any,falseValue:any){
        return test ? trueValue : falseValue;
    }

    public static IF(test:boolean,trueValue:any,falseValue:any){
        return test ? trueValue : falseValue;
    }
    
    public static SUM(context:IEvalContext,name:string):string{
        let i = name.indexOf('.');
        let record = name.substring(0,i);
        let totalName = "sum:" + name.substring(i+1);
        let totals = this.get(context,record + "$totals");
        if (totals){
            let total = totals[totalName];
            if (total){
                return total.value;
            }
            return null;
        }
        let rows:CollectionArray = context.canvas.getRecord(record,null);
        if (rows && rows.$$meta && rows.$$meta.totals){
            return rows.$$meta.totals[totalName];
        }
        return null;
    }

    public static getTotal(context:IEvalContext,record:string,name:string):string{
        let totals = this.get(context,record + "$totals");
        if (totals){
            let total = totals[name];
            if (total){
                return total.value;
            }
            return null;
        }
        let rows:CollectionArray = context.canvas.getRecord(record,null);
        if (rows && rows.$$meta && rows.$$meta.totals){
            return rows.$$meta.totals[name];
        }
        return null;
    }

    public static COMPUTE_SUM(context:IEvalContext,name:string):any {
        let parsed = ExpressionOps.parseVariable(name);
        if (!parsed || !parsed.target) return undefined;

        let collection = context.canvas.getRecord(parsed.target,{}) as CollectionArray;
        if (!Array.isArray(collection)) return undefined;
        let total:number =0;
        let propName = parsed.name;
        let onlySelected = (parsed.modifier == "selected");
        for(let i = 0; i < collection.length;i++){
            let row = collection[i];
            if (!onlySelected || Collection.isRowSelected(row)){
                let value = row[propName];
                if (Number.isFinite(value)){
                    total += value;
                }
            }
        }
        return total;
    }

    public static COMPUTE_COUNT(context:IEvalContext,name:string):any {
      
        let parsed = ExpressionOps.parseVariable(name);
        let collection = context.canvas.getRecord(parsed.name,{}) as CollectionArray;
        if (!Array.isArray(collection)) return undefined;
        let count:number = 0;
        let onlySelected = parsed.modifier == "selected";
        for(let i = 0; i < collection.length;i++){
            let row = collection[i];
            if (!onlySelected || Collection.isRowSelected(row)){
                count++;
            }
        }
        return count;

    }

    public static STRING_AGG(context:IEvalContext,name:string,separator:string):any {
        let parsed = ExpressionOps.parseVariable(name);
        if (!parsed || !parsed.target) return undefined;
        
        if (parsed.modifier == "every" || parsed.modifier == "selected"){
            let selected = parsed.modifier == "selected";
            let collection = context.canvas.getRecord(parsed.target,{}) as CollectionArray;
            if (!Array.isArray(collection)) return undefined;
            let values:string[] = [];
            let propName = parsed.name;
            for(let i = 0; i < collection.length;i++){
                let row = collection[i];
                if (!selected || Collection.isRowSelected(row)){
                    let value = row[propName];
                    if (value){
                        values.push(value.toString())
                    }
                }
            }
            return values.join(separator);
        }
        let record = ExpressionOps.get(context,parsed.target);
        if (record){
            return ExpressionOps.prop(record,parsed.name);
        }
        return undefined;
    }

   
    public static COUNT(context:IEvalContext,name:string):string{
        let i = name.indexOf('.');
        let record:string;
        let totalName:string;
        if (i == -1){
            record = name;
            totalName = "rowCount";
        }
        else {
            record = name.substring(0,i);
            totalName = "count:" + name.substring(i + 1);
        }
       
        let totals = this.get(context,record + "$totals");
        if (totals){
            let total = totals[totalName];
            if (total){
                return total.value;
            }
        }
        return null;
    }

    public static IS_ACTIVE_RECORD(context:IEvalContext,name:string):boolean {
        let collection = context.canvas.getCollectionRef(name,null);
        let record = context.canvas.getRecord(name,context.scope);
        return (collection && collection.currentRow && collection.currentRow == record);
    }

    public static ROW_COUNT(context:IEvalContext,name:string):number {
        let collection = context.canvas.getCollectionRef(name,null);
        if (collection.rows) return collection.rows.length;
        return 0;
    }

    public static HAS_SELECTED_ROWS(context:IEvalContext,name:string):boolean {
        let collection = context.canvas.getCollectionRef(name,null);
        if (collection && collection.rows){
            let rows = collection.rows;
            for(let i =0 ; i < rows.length;i++){
                let row = rows[i];
                if (Collection.isRowSelected(row)) return true;
            }
        }
        return false;
    }


    private static parseVariable(value:string):{name:string,target?:string,modifier?:string}{
        if (!value) return null;
        let i = value.indexOf('.');
        let target:string;
        let name:string;
        let modifier:string;
        if (i != -1){
            target = value.substring(0,i);
            name = value.substring(i + 1);
        }
        else {
            name = value;
        }
        i = name.indexOf(":");
        if (i != -1){
            modifier = name.substring(i + 1).toLowerCase();
            name = name.substring(0,i);
        }
        return {name,target,modifier};
    }

}