import React from 'react';
import { ProductTableStore } from './ProductTableStore';

export class ToggleLink extends React.Component<{store:ProductTableStore, label: string; open?: boolean; onClick: () => void; }> {
    render() {
        let style: React.CSSProperties = { textDecoration: "none", color: this.props.store.theme.linkColor, fontSize: "13px" };
        if (this.props.open) {
            return <div>
                <a href='#' style={style} onClick={this.handleClick}>Hide {this.props.label}</a>
                <div style={{ marginTop: 20 }}>
                    {this.props.children}
                </div>
            </div>;
        }
        return <div>
            <a href='#' style={style} onClick={this.handleClick}>Show {this.props.label}</a>
        </div>;
    }

    handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick();
    };
}
