import React from 'react';

export class FilterGroup extends React.Component<{ label: string; onSelectAll: () => void; onClear: () => void; }> {
    render() {
        return <div>
            <div style={{ display: "flex", alignItems: "baseline" }}>
                <div style={{ fontSize: "13px", fontWeight: 500, textTransform: "uppercase" }}>{this.props.label}</div>
                <a href='#' style={{ marginLeft: "auto", fontSize: "11px", marginRight: 8 }} onClick={this.handleSelectAll}>ALL</a>|
                <a href='#' style={{ marginLeft: 8, fontSize: "11px" }} onClick={this.handleClear}>CLEAR</a>
            </div>
            <div style={{ marginTop: 10, marginBottom: 10 }}>{this.props.children}</div>
        </div>;
    }

    handleSelectAll = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onSelectAll();
    };

    handleClear = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClear();
    };
}
