import React from 'react';
import {ActionRef, FieldRef, RecordMeta, RenderEvent} from 'core';
import {Icon} from 'components/Icon';
import { NumberFormatter } from 'core/FieldFormatter';
import {IProductRow, ProductTableStore} from './ProductTableStore';

interface ICartItem {
    product_descrip:string;
    cart_qty:number;
    price:number;
    category:string;
    avail_to_ship:number;
    backorder_qty:number;
    backorder_date:string;
    backorder_note:string;
    max_backorder_date:string;
    $$meta:RecordMeta;
}

export class CartItem extends React.Component<{event:RenderEvent,store:ProductTableStore; item:ICartItem,row:IProductRow,actions:any}>{

    render(){
        let cardStyle:React.CSSProperties = {backgroundColor:"#fff",boxShadow:"0 2px 13px 0px rgba(232,233,234,0.7)",
            padding:15,borderRadius:4,flexGrow:1};
    
        let item = this.props.item;
        let backOrderElem;
        let itemNote;
        let backorderFlag;
        if (item.backorder_note){
            itemNote = this.renderNote(item.backorder_note);
            backorderFlag = <span style={{marginLeft:2}}>*</span>
        }

        if (item.backorder_qty){
            backOrderElem = ( <div style={{fontSize:"18px",fontWeight:500,marginLeft:25,minWidth:90}}>
                <div style={{fontSize:"11px",textTransform:"uppercase",fontWeight:500,letterSpacing:"0.6px",color:"#848f99",marginBottom:5,textAlign:'center'}}>
                    Back order
                </div>
                <div style={{textAlign:"center",color:"rgb(255, 119, 19)"}}>{NumberFormatter.formatNumber(item.backorder_qty)}{backorderFlag}</div>
            </div>)
        }
        let store = this.props.store;
        let row = this.props.row;

        let selected = (store.activeRow == row);
        let cardClass = "hover-selector";
        if (selected){
            cardClass += " is-active";
        }
        

        let card = (<div style={cardStyle} className={cardClass}>
            <div style={{display:"flex",alignItems:"center"}}>
                <div style={{width:"50%",minWidth:440}}>
                    <div style={{fontSize:"16px",fontWeight:500}}>
                        {item.product_descrip}
                    </div>
                    <div style={{fontSize:"14px",fontWeight:500,color:"rgb(160,163,170)"}}>
                        {item.category}
                    </div>
                </div>
    
                <div style={{fontSize:"18px",fontWeight:500,minWidth:90}}>
                    <div style={{fontSize:"11px",textTransform:"uppercase",fontWeight:500,letterSpacing:"0.6px",color:"#848f99",marginBottom:5,textAlign:'center'}}>
                        Order Qty
                    </div>
                    <div style={{textAlign:"center"}}>{NumberFormatter.formatNumber(item.cart_qty)}</div>
                </div>

                <div style={{fontSize:"18px",fontWeight:500,marginLeft:25,minWidth:90}}>
                    <div style={{fontSize:"11px",textTransform:"uppercase",fontWeight:500,letterSpacing:"0.6px",color:"#848f99",marginBottom:5,textAlign:'center'}}>
                        Avail To Ship
                    </div>
                    <div style={{textAlign:"center",color:"rgb(100,106,114)"}}>{NumberFormatter.formatNumber(item.avail_to_ship,"#,##0;-")}</div>
                </div>
                {backOrderElem}
               
                <div style={{marginLeft:"auto",display:"flex",alignItems:'center',gap:20}}>
                    <div style={{fontSize:"18px",fontWeight:500,marginLeft:25,textAlign:"center"}}>
                        <div style={{fontSize:"11px",textTransform:"uppercase",fontWeight:500,letterSpacing:"0.6px",color:"#848f99",marginBottom:5}}>
                           Price
                        </div>
                        <div style={{textAlign:"center",color:"rgb(100,106,114)"}}>{NumberFormatter.formatMoney(item.price)}<span style={{marginLeft:2,color:"rgb(150,155,166)",fontSize:'15px'}}>/pc</span></div>
                    </div>
                    {this.props.actions}
                </div>
            </div>
            {itemNote}
        </div>);

        let rowIndex;
        if (item.$$meta){
            rowIndex = item.$$meta.index + 1;
        }
        let indexStyle:React.CSSProperties = {display:"flex",alignItems:'center',justifyContent:'center',width:28,height:28,
        backgroundColor:"rgb(232,234,240)",borderRadius:99,marginRight:12,fontWeight:500,fontSize:"13px",lineHeight:0};
        if (selected){
            indexStyle.backgroundColor = "#468ffd";
            indexStyle.color = "#fff";
        }
        let rowIndexElem = (<div style={indexStyle}>
            {rowIndex}
        </div>)
        return (<div style={{display:'flex',alignItems:"center",justifyContent:'center'}} onClick={this.handleClick}>
            {rowIndexElem}
            {card}
        </div>)
    }

    renderNote(message:string){
       
        return <div style={{fontSize:"14px",marginTop:10,letterSpacing:"0.1px"}}>
            <span style={{color:"rgb(255,119,19)",fontWeight:500,marginRight:8}}>NOTE:</span>
            {message}
        </div>
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let store = this.props.store;
        let row = this.props.row;
        store.activeRowId = row.id;
        store.loadSidebarAction.trigger({scope:{"@inventory":row}});
        store.broadcast.refresh();
    }

}

export class DataCard extends React.Component<{event:RenderEvent,icon?:string,label:string,onClick?:ActionRef}>{
    render(){
        let iconElem;
        let labelElem;

        if (this.props.icon){
            iconElem = <div style={{display:"flex",alignItems:"center",justifyContent:'center',backgroundColor:"rgb(147,166,183)",
            width:30,height:30,borderRadius:99,marginRight:12}}>
                <Icon icon={this.props.icon} size={18} fill="rgb(255,255,255)"/>
            </div>
        }
        labelElem = <div style={{fontSize:"15px",fontWeight:500}}>
            {this.props.label}
        </div>
        return (<div style={{marginTop:10}} className="Product-Table">
            <div className="hover-selector" style={{display:"flex",alignItems:"center",backgroundColor:"#fff",boxShadow:"0 2px 13px 0px rgba(232,233,234,0.7)",
            padding:10,borderRadius:4,cursor:"pointer"}} onClick={this.handleClick}>
                {iconElem}
                <div style={{flexGrow:1}}>
                    {labelElem}
                    {this.props.children}
                </div>
            </div>
        </div>)
    }

    handleClick = (e:React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        let action = this.props.onClick;
        if (action){
            action.trigger();
        }
    }
}