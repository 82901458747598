import { TimeFormatter } from "./TimeFormatter";
import { FieldType } from "./types";

export class FieldFormatter {

    static namedFormats: { [name: string]: string } = {
        "money":"$#,##0.00",
        "money-short":"$#,##0",
        "number-short":"#,##0",
        "date":"mm-dd-yyyy",
        "number":"#,##0",
        "accounting":"$#,##0.00;0;($#,##0.00)",
        "2-decimals":"#,##0.00",
        "3-decimals":"#,##0.000",
        "4-decimals":"#,##0.0000",
        "time":"h:nn a"
    };

    static format(value:any,type:FieldType,format?:string):string {
        if (format){
            let named = FieldFormatter.namedFormats[format];
            if (named){
                format = named;
            }
        }
        if(type === 'date') {
            return this.formatDate(value,format);
        } else if(type == 'datetime') {
            return this.formatDateTime(value,format);
        } else if(type == 'integer') {
            return this.formatInteger(value,format);
        } else if(type == 'money') {
            return this.formatMoney(value,format);
        } else if(type == 'decimal') {
            return this.formatNumber(value,format);
        }
        else if (type == 'logical'){
            if (value && value !== "0"){
                return "Yes";
            }
            else {
                return "No";
            }
        }
        else if (type == 'character' ||  type =='longchar'){
            return toDisplay(value);
        }
        else if (type == "time"){
            return TimeFormatter.format(value);
        }
        else if (!type && format){
            let isNumeric = new RegExp('[$#0]').test(format);
            if (isNumeric){
                return NumberFormatter.format(value,format);
            }
            return DateFormatter.format(value,format);
        }
        if (value === null || value === undefined) return "";
        if (typeof value === "string") return value;
        return value.toString();
    }
    
    static formatDate(value,format:string):string {
        return DateFormatter.format(value,format);    
    }

    static formatDateTime(value,format:string):string{
        return DateFormatter.formatDateTime(value);
    }
    
    static formatInteger(value,format:string):string {
        var parsed = this.parseInteger(value)
        return NumberFormatter.formatInt(parsed, format);            
    }

    static formatMoney(value,format:string):string {
        var parsed = this.parseMoney(value)
        return NumberFormatter.formatMoney(parsed, format);            
    }

    static formatNumber(value,format:string):string {
        var parsed = this.parseNumber(value)
        return NumberFormatter.formatNumber(parsed, format);            
    }

    static parseInput(value:any,type:FieldType):any {
        if(type === 'date') {
            return this.parseDate(value);
        } else if(type == 'datetime') {
            return this.parseDateTime(value);
        } else if(type == 'integer') {
            return this.parseInteger(value);
        } else if(type == 'money') {
            return this.parseMoney(value);
        } else if(type == 'decimal') {
            return this.parseNumber(value);
        }
        else if (type == "uid"){
            return this.parseUID(value);
        }
        else if (type == "character"){
            if (value && value.trim) return value.trim();
        }
        else if (type == "time"){
            return TimeFormatter.parseFromInput(value);
        }
        return value;
    }         

    static parseDate(value) {
        return DateFormatter.parseDateFromInput(value);    
    }

    static parseDateTime(value) {
        return DateFormatter.parseDateFromInput(value);    
       // return DateFormatter.parseDateTimeFromInput(value);
    }

    static parseInteger(value) {
        var n = StringFormatter.parseNumber(value);
        if (isNaN(n)){
            n = 0;
        }
        return n;                             
    }

   static parseMoney(value) {
        var n = StringFormatter.parseNumber(value);
        if (isNaN(n)){
            n = 0;
        }
        return n;              
    }

    static parseNumber(value) {
        var n = StringFormatter.parseNumber(value);
        if (isNaN(n)){
            n = 0;
        }
        return n;                 
    }

    static parseUID(value) {
        if (typeof value !== "string"){
            return null;
        }
        if (value.length != 36){
            return null;
        }
        if (value[8] != "-" || value[13] != "-" || value[18] !="-" || value[23] != "-") return null;
        return value;
    }

    static applyNamedFormat(value:any,format:string):string {
        if (!format) return value;
        
        if (typeof(value) === "number"){
            return this.formatNumber(value,format);
        }
        return this.formatDate(value,format);
    }
   
}

export class StringFormatter{
    static padZero(value:string, len:number):string {
        var count = len - value.length;
        if (count <= 0) {
            return value;
        }
        else {
            for (var i = 1; i <= count; i++) {
                value = '0' + value;
            }
            return value;
        }
    }
    static parseNumber(v:any):any {
        if (!v){
            return 0;
        }
        if (typeof v == "number"){
            return v;
        }
        var s = '';
        var c = '';
        var isNegative = false;
        for (var i = 0; i < v.length; i++) {
            c = v.charAt(i);
            if (((c >= '0') && (c <= '9')) || (c == '.') || (c == '-')) {
                s += c;
            }
            else if (c == '(') {
                isNegative = true;
            }
        }
        if (isNegative) {
            s = '-' + s;
        }
        return parseFloat(s);
    }

    static trim(value:string):string {
        return value.replace(/^\s+|\s+$/g, '') ;
    }

    static trimLeft(value:string):string {
        if (value === undefined || value === null) return "";
        for(var i = 0;i < value.length;i++){
            if (value[i] !== ' '){
                return value.substr(i);
            }
        }
        return "";
    }

    static endsWith(value:string,test:string):boolean{
        if (value.length >= test.length && value.substr(value.length - test.length) == test){
            return true;
        }
        return false;
    }
}


export interface IParsedDate {
    raw?: string;
    invalid?: boolean;
    dateOnly?: boolean
    year?: number;
    month?: number;
    day?: number;
    hour?: number;
    minute?: number;
    second?: number;
    dayOfWeek?: number;
}

export class DateFormatter {

    static defaultDateFormat = "mm-dd-yyyy";  // "yyyy-mm-dd"; // "mm/dd/yyyy";
    static datePlaceholder = "MM-DD-YYYY";
    static defaultDateTimeFormat = "mmm d, yyyy h:nn a";
    static monthPosition: number = 0;
    static dayPosition: number = 1;
    static yearPosition: number = 2;
    static tokenChars = "dmyhnaw";
   

    static cache: any = {};

    static setFormat(format: string) {
        DateFormatter.defaultDateFormat = format;
        var tokenStream = DateFormatter.getTokenStream(format);
        var position = 0;
        for (var i = 0; i < tokenStream.tokens.length; i++) {
            var token = tokenStream.tokens[i];
            if (token[0] == "d") {
                DateFormatter.dayPosition = position;
                position++;
            }
            else if (token[0] == "m") {
                DateFormatter.monthPosition = position;
                position++;
            }
            else if (token[0] == "y") {
                DateFormatter.yearPosition = position;
                position++;
            }
        }
    }

    static getTokenStream(format: string) {
        var t = DateFormatter.cache[format];
        if (t) return t;
        t = new FormatTokenStream(format, DateFormatter.tokenChars);
        DateFormatter.cache[format] = t;
        return t;
    }

    static formatDateTime(value: string): string {
        return this.format(value, this.defaultDateTimeFormat);
    }

    public static format(value: any, format?: string): string {
        if (value == null) {
            return "";
        }

        var parsed = this.parse(value);
        if (parsed == null || parsed.invalid) return "";
        if (format) {
            var namedFormat = FieldFormatter.namedFormats[format];
            if (namedFormat) {
                format = namedFormat;
            }
        }

        return this.applyFormat(parsed, format || this.defaultDateFormat);
    }

    private static applyFormat(date: IParsedDate, format: string): string {
        var tokenStream = DateFormatter.getTokenStream(format);
        var s = "";
        var d = date.day;
        var m = date.month;
        var y = date.year;
        var h = date.hour;
        var w = date.dayOfWeek;
        var a = "AM";
        if (h == 12) {
            a = "PM";
        }
        else if (h > 12){
            a = "PM";
            h = h - 12;
        }
        else if (h == 0) {
            h = 12;
        }

        var n = date.minute;

        for (var i = 0; i < tokenStream.tokens.length; i++) {
            switch (tokenStream.tokens[i]) {
                case "d": s += d;
                    break;
                case "dd": s += (d >= 10) ? d.toString() : "0" + d;
                    break;
                case "m": s += m;
                    break;
                case "mm": s += (m >= 10) ? m.toString() : "0" + m;
                    break;
                case "mmm": s += DateFormatter.getMonthName(m - 1).substr(0, 3);
                    break;
                case "mmmm": s += DateFormatter.getMonthName(m - 1);
                    break;
                case "yyyy": s += y;
                    break;
                case "yy": s += (y % 100);
                    break;
                case "h": s += h;
                    break;
                case "hh": s += (h >= 10) ? h.toString() : "0" + h;
                    break;
                case "n":
                case "nn": s += (n >= 10) ? n.toString() : "0" + n;
                    break;
                case "w":
                    s += DateFormatter.getDayName(w).substr(0, 3);
                    break;
                case "ww":
                    s += DateFormatter.getDayName(w);
                    break;
                case "a":
                    s += a;
                    break;
                default: s += tokenStream.tokens[i];
                    break;
            }
        }
        return s;
    }

    static parseDateTimeFromInput(value: string): string {
        if (!value) { return null };
        value = value.trim();
        var segments = value.split(/\W+/);

        if (segments.length < 3) {
            return null;
        }
        var month = parseInt(segments[DateFormatter.monthPosition], 10);
        if (!isFinite(month) || month < 1 || month > 12) {
            return null;
        }
        var day = parseInt(segments[DateFormatter.dayPosition], 10);
        if (!isFinite(day) || day < 1 || day > 31) {
            return null;
        }
        var year = parseInt(segments[DateFormatter.yearPosition], 10);
        if (!isFinite(year)) {
            return null;
        }
        if (year < 40) {
            year += 2000;
        }
        else if (year < 100) {
            year += 1900;
        }
        var hour: number = 0;
        var minutes: number = 0;
        var explicitAM: boolean = false;
        var explicitPM: boolean = false;

        if (segments.length > 3) {
            hour = parseInt(segments[3], 10);
            if (!isFinite(hour) || hour < 0 || hour > 23) {
                return null;
            }
        }
        if (segments.length > 4) {
            var minutesSegment = segments[4];
            var i = minutesSegment.indexOf('a');
            if (i != -1) {
                minutesSegment = minutesSegment.substr(0, i);
                explicitAM = true;
            }
            else {
                i = minutesSegment.indexOf('p');
                if (i != -1) {
                    minutesSegment = minutesSegment.substr(0, i);
                    explicitPM = true;
                }
            }
            minutes = parseInt(minutesSegment, 10);
            if (!isFinite(minutes) || minutes < 0 || minutes > 59) {
                return null;
            }
        }
        if (!explicitAM && !explicitPM && segments.length > 5) {
            // check am/pm
            if (segments[5][0].toLowerCase() == "p") {
                explicitPM = true;

            }
            else if (segments[5][0].toLowerCase() == "a") {
                explicitAM = true;
            }
            else {
                // unknown  (not am or pm)
                return null;
            }
        }
        if (explicitPM) {
            if (hour > 12) {
                return null;
            }
            if (hour < 12) {
                hour += 12;
            }
        }
        else if (explicitAM) {
            if (hour > 12) {
                return null;
            }
            if (hour == 12) {
                hour = 0;
            }
        }
        return this.storedDateTimeFormat(year, month, day, hour, minutes, 9);
    }

    public static today(): string {
        var date = new Date();
        return this.storedDateFormat(date.getFullYear(), date.getMonth() + 1, date.getDate())
    }

    public static parse(value: string): IParsedDate {
        if (value == null || value == '') return null;
        var parsed: IParsedDate = {
            raw: value,
            invalid: undefined
        }
        var segments = ['year', 'month', 'day', 'hour', 'minute', 'second']
        if (value != null && typeof value == "string") {
            value = value.trim();
            var parts = value.split(/[-\s:]/)
            if (parts.length == 3) {
                parsed.dateOnly = true
            }
            for (var i = 0; i < segments.length; i++) {
                var segment = segments[i];
                var part = parts[i];
                if (parsed.dateOnly && i >= 3) {
                    parsed[segment] = 0;
                    continue;
                }
                var num = parseInt(part, 10);
                if (isNaN(num)) {
                    parsed.invalid = true
                    parsed[segment] = part
                } else {
                    parsed[segment] = num
                }
            }
        }
       
        var dt = new Date(Date.UTC(parsed.year, parsed.month - 1, parsed.day));
        parsed.dayOfWeek = dt.getDay();
        return parsed;
    }

    public static storedDateTimeFormat(year: number, month: number, day: number, hour: number, minutes: number, seconds: number) {
        return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds;
    }

    public static storedDateFormat(year: number, month: number, day: number): string {
        return year + '-' + StringFormatter.padZero(month.toString(), 2) + '-' + StringFormatter.padZero(day.toString(), 2);
    }

    public static addDays(date: string, numDays: number) {
        var parsed = this.parse(date);
        var dt = Date.UTC(parsed.year, parsed.month - 1, parsed.day) + numDays * 1000 * 60 * 60 * 24;
        var d = new Date(dt);
        return this.storedDateFormat(d.getUTCFullYear(), d.getUTCMonth() + 1, d.getUTCDate());
    }

    public static addMonths(date: string, numMonths: number) {
        var parsed = this.parse(date);
        var dt = Date.UTC(parsed.year, parsed.month - 1 + numMonths, parsed.day);
        var d = new Date(dt);
        return this.storedDateFormat(d.getUTCFullYear(), d.getUTCMonth() + 1, d.getUTCDate());
    }

    static parseDateFromInput(value: string): string {
        if (!value) return null;
        value = value.trim();
        
        var segments = value.split(/\W+/);

        var today = new Date();
        var day: number;
        var month: number;
        var year: number;

        if (segments.length == 1) {
            // assume entered only day of month
            day = parseInt(segments[0], 10);
            if (isFinite(day) && day > 0 && day <= 31) {
                return this.storedDateFormat(today.getFullYear(), today.getMonth() + 1, day);
            }
            else {
                return null;
            }
        }
        else if (segments.length == 2) {
            // assume entered month and day
            if (DateFormatter.monthPosition < DateFormatter.dayPosition) {
                month = parseInt(segments[0], 10);
                day = parseInt(segments[1], 10);
            }
            else {
                month = parseInt(segments[1], 10);
                day = parseInt(segments[0], 10);
            }
            if (!isFinite(month) || month < 1 || month > 12) {
                return null;
            }

            if (!isFinite(day) || day < 1 || day > 31) {
                return null;
            }
            return this.storedDateFormat(today.getFullYear(), month, day);
        }
        else {
            month = parseInt(segments[DateFormatter.monthPosition], 10);
            day = parseInt(segments[DateFormatter.dayPosition], 10);
            year = parseInt(segments[DateFormatter.yearPosition], 10);
            if (!isFinite(day) || !isFinite(month) || !isFinite(year)) {
                return null;
            }
            if (month > 100){
                // assume year mm dd;
                let y = year;
                year = month;
                month = day;
                day = y;
            }
            if (month < 1 || month > 12) {
                return null;
            }
            if (day <1 || day > 31) {
                return null;
            }

            if (year < 50) {
                year += 2000;
            }
            else if (year < 100) {
                year += 1900;
            }
            if (year < 1753){
                return null;
            }
            return this.storedDateFormat(year, month, day);
        }
    }


    static parseISODate(date: any): Date {
        if (!date) {
            return null;
        }
        if (date instanceof Date) {
            return date as Date;
        }
        var zoneSplit = date.split('Z');
        var dateTimeSplit = zoneSplit[0].split('T');

        var dateSegments = dateTimeSplit[0].split('-');
        var timeSegments: string[];
        if (dateTimeSplit.length >= 2) {
            timeSegments = dateTimeSplit[1].split(':');
        }
        else {
            timeSegments = ['00', '00', '00'];
        }
        if (timeSegments.length < 3) return null;
        var secondSegments = timeSegments[2].split('.');

        var minutesOffset = 0;
        if (zoneSplit.length > 1) {
            var timeZone = zoneSplit[1];
            if (timeZone.length) {
                minutesOffset = parseInt(timeZone.substr(1, 2), 10) * 60 + parseInt(timeZone.substr(3, 2), 10);
                if (timeZone[0] === "+") {
                    minutesOffset = 0 - minutesOffset;

                }
            }
        }
        var milliSeconds = (secondSegments.length > 1) ? parseInt(secondSegments[1], 10) : 0;
        var timestamp = Date.UTC(parseInt(dateSegments[0], 10), parseInt(dateSegments[1], 10) - 1, parseInt(dateSegments[2], 10), parseInt(timeSegments[0], 10), parseInt(timeSegments[1], 10) + minutesOffset, parseInt(secondSegments[0], 10), milliSeconds);
        return new Date(timestamp);
    }

    static formatISOAsDuration(value: string): string {
        try {
            var dt = DateFormatter.parseISODate(value);
            return this.getDurationText(dt.getTime());
        }
        catch (e) {
            return value;
        }
    }


    static getDurationText(time: number): string {

        var now = new Date();

        var diffInSeconds = (now.getTime() - time) / 1000;
        if (diffInSeconds < 0){
            return DateFormatter.getFutureDurationText(time);
        }
        var diffInMinutes = Math.floor(diffInSeconds / 60);
        var diffInHours = Math.floor(diffInMinutes / 60);
        var diffInDays = Math.ceil((new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() - time) / (24 * 60 * 60 * 1000));
        if (diffInSeconds < 15) {
            return "a few seconds ago";
        }
        if (diffInSeconds < 60) {
            return "less than a minute ago";
        }
        else if (diffInSeconds < 3600) {
            var diffInMinutes = Math.floor(diffInSeconds / 60);
            if (diffInMinutes > 1) {
                return diffInMinutes + " minutes ago";
            }
            else {
                return "1 minute ago";
            }
        }
        else if (diffInHours < 24) {
            if (diffInHours > 1) {
                return diffInHours + " hours ago";
            }
            else {
                return "1 hour ago";
            }
        }
        else if (diffInDays == 1) {
            return "yesterday"
        }
        else if (diffInDays <= 5) {
            return diffInDays + " days ago";
        }
        else {
            var dt = new Date(time);
            return DateFormatter.format(DateFormatter.storedDateFormat(dt.getFullYear(), dt.getMonth() + 1, dt.getDate()));
        }
    }

    static getFutureDurationText(time: number): string {

        var now = new Date();

        var diffInSeconds = (time - now.getTime()) / 1000;
        var diffInMinutes = Math.floor(diffInSeconds / 60);
        var diffInHours = Math.floor(diffInMinutes / 60);
        var diffInDays = Math.ceil((time - new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime()) / (24 * 60 * 60 * 1000));
        if (diffInSeconds < 15) {
            return "a few seconds from now";
        }
        if (diffInSeconds < 60) {
            return "less than a minute from now";
        }
        else if (diffInSeconds < 3600) {
            var diffInMinutes = Math.floor(diffInSeconds / 60);
            if (diffInMinutes > 1) {
                return diffInMinutes + " minutes from now";
            }
            else {
                return "1 minute from now";
            }
        }
        else if (diffInHours < 24) {
            if (diffInHours > 1) {
                return diffInHours + " hours from now";
            }
            else {
                return "1 hour from now";
            }
        }
        else if (diffInDays == 1) {
            return "tomorrow"
        }
        else if (diffInDays <= 14) {
            return diffInDays + " days from now";
        }
        else {
            var dt = new Date(time);
            return DateFormatter.format(DateFormatter.storedDateFormat(dt.getFullYear(), dt.getMonth() + 1, dt.getDate()));
        }
    }


    static getMonthName(month: number) {
        var monthName = new Array('January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December');
        return monthName[month];
    }

    static getDayName(dw: number) {
        var dayName = new Array('Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday');
        return dayName[dw];
    }

}

export class FormatTokenStream {

    public source:string;
    public sLen:number;
    public index:number;
    public tokens:string[];
    public tokenChars:string;

    constructor(value:string,tokenChars:string){
        this.source = value;
        this.tokenChars = tokenChars;
        this.index = 0;
        this.tokens = [];
        this.sLen = value.length;
        var ch;
        while (this.index < this.sLen){
            ch = value[this.index];
            if (tokenChars.indexOf(ch) != -1){
                this.parseToken();
            }
            else {
                this.parseLiteral();
            }
        }
    }

    parseToken(){
        var ch = this.source[this.index++];
        var token = ch;
        while (this.index < this.sLen && this.source[this.index] == ch){
            token += ch;
            this.index++;
        }
        this.tokens.push(token);
    }

    parseLiteral(){
        var token = "";
        while (this.index < this.sLen){
            var ch = this.source[this.index];
            if (this.tokenChars.indexOf(ch) != -1){
                break;
            }
            token += ch;
            this.index++;
        }
        this.tokens.push(token);
    }
}

export class NumberFormatter {
    public static cache:any = {};		// cacheformatters
    public static defaultMoney = "($#,##0.00)";
    public static defaultNumber  = "#,##0";
    public static defaultInteger = "#,##0";
    static namedFormats:{[name:string]:string} = {};

    static format(value:any,formatString:string):string{
        if (!formatString){
            if (value){
                return value.toString();
            }
            return "";
        }
        let namedFormat = this.namedFormats[formatString];
        if (namedFormat){
            formatString = namedFormat;
        }

        var nf:NumberFormat = NumberFormatter.cache[formatString];
        if (!nf){
            nf = new NumberFormat(formatString);
            NumberFormatter.cache[formatString] = nf;
        }
        return nf.toString(value);
    }

    static formatMoney(value:any,formatString?:string){
        return NumberFormatter.format(value,formatString || NumberFormatter.defaultMoney);
    }

    static formatNumber(value:any,formatString?:string){
        return NumberFormatter.format(value,formatString || NumberFormatter.defaultNumber);
    }

    static formatInt(value:any,formatString?:string){
        return NumberFormatter.format(value, formatString || NumberFormatter.defaultInteger);
    }
}



export class NumberFormat {
    public tokens:string[];
    public decimals:number;
    public decimalsOptional:boolean;
    public comma:boolean;
    public leadingZero:boolean;
    public hideZero:boolean;
    public zeroLiteral:string;
    public format:string;
    public isPercent:boolean;

    constructor(format:string){
        format = format || "";
        var segments = format.split(";");
        if (segments.length > 1){
            if (segments[1]){
                this.zeroLiteral = segments[1];
            }
            else {
                this.hideZero = true;
            }
        }
        this.format = format;
        var tokenStream = new FormatTokenStream(segments[0],"+(#,.0)%");
        var tokens = tokenStream.tokens;
        var token;
        var prev;
        var newTokens = [];
        this.decimals = 0;
        var numberToken:boolean;
        var decimalPoint:boolean;

        for(var i = 0; i < tokens.length;i++){
            token = tokens[i];
            switch(token[0]){
                case ",":
                    this.comma = true;
                    break;
                case ".":
                    decimalPoint = true;
                    if (prev == "0"){
                        this.leadingZero = true;
                    }
                    if (!numberToken){
                        numberToken = true;
                        newTokens.push("#");
                    }
                    break;
                case "#":
                    if (!numberToken){
                        numberToken = true;
                        newTokens.push("#");
                    }
                    if (decimalPoint){
                        this.decimals = token.length;
                        this.decimalsOptional = true;
                    }
                    break;
                case "0":
                    if (!numberToken){
                        numberToken = true;
                        newTokens.push("#");
                    }
                    if (decimalPoint){
                        this.decimals = token.length;
                    }
                    break;
                case "%":
                    this.isPercent = true;
                    newTokens.push(token);
                    break;
                default:
                    newTokens.push(token);
                    break;
            }
            prev = token;
        }
        this.tokens = newTokens;
    }

    toString(value:any):string{
        value = StringFormatter.parseNumber(value);
        if (isNaN(value)){
            value = 0;
        }
        if (value == 0){
            if (this.hideZero){
                return "";
            }
            if (this.zeroLiteral) {
                return this.zeroLiteral;
            }
        }
        /*
        if (this.isPercent){
            value = value * 100;
        }
        */
        var tokens = this.tokens;
        var s = "";
        var negativeHandled = false;
        for(var i = 0; i < tokens.length;i++){
            var token = tokens[i];
            switch(token){
                case "#":
                    s += this.getFormattedNumber(value);
                    break;
                case "(":
                case ")":
                    if (value < 0){
                        s += token;
                    }
                    negativeHandled = true;
                    break;
                case "+":
                    if (value > 0){
                        s += "+";
                    }
                    else if (value < 0){
                        s += "-";
                    }
                    negativeHandled = true;
                    break;
                default:
                    s += token;
                    break;
            }
        }
        if (value < 0 && !negativeHandled){
            return "-" + s;
        }
        return s;
    }

    getFormattedNumber(value:any):string {
                
      
        
        if (value < 0){
            value = Math.abs(value);
        }
        
        var s = value.toFixed(this.decimals).split(".");
        var result = this.getWholePortion(s[0]);
        if (s.length > 1){
            result += this.getDecimalPortion(s[1]);
        }
        return result;
    }

    getWholePortion(fixedString:string):string {

        if (fixedString == "0"){
            if (this.leadingZero){
                return "0";
            }
            return "";
        }	    
        if (this.comma){	
            var p = 0;
            var r = "";
                        
            for (var i = fixedString.length - 1 ; i >= 0 ; i--){
                if (p == 3){
                    r = ','+ r;
                    p = 0;
                }
                r = fixedString.charAt(i) + r;
                p++;
            }
            return r;
        }
        
        return fixedString;
    }

    getDecimalPortion(fixedString:string):string {
        if (this.decimalsOptional){
            // trim trailing zeros
            var i = fixedString.length - 1;
            while (i >=0 && fixedString[i] == "0"){
                i--;
            }
            if (i >=0){
                return "." + fixedString.substr(0,i+1);
            }
            return "";
        }
        return "." + fixedString;
    }
}


function toDisplay(value: any) {
    if (value && typeof (value) !== "string") {
        return (value as any).toString();
    }
    return value;
}