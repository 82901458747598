import { StyledText } from 'components/helpers/StyledText';
import React from 'react';
import { ScreenRef } from './ActionEvent';
import { ActionRef } from './ActionRef';
import { Canvas } from './Canvas';
import { CollectionRef } from './CollectionRef';
import { DynamicRender } from './DynamicRender';
import { FieldRef } from './FieldRef';
import { ActionHandler, ComponentStyles, getActionName,  IEventScope, IVisibilityContainer } from './types';

let maxDepth = 8;

export type RenderFunc = (event:RenderEvent) => any;

export type RenderContext = {
    designMode?:boolean;
    activeEditId?:string;
}
export class RenderEvent {

    canvas:Canvas;
    scope:IEventScope;
    depth:number;
    eventValue:any;
    visibilityContainer:IVisibilityContainer;
    keyPrefix:string;
    context:RenderContext;

    constructor(canvas:Canvas,context?:RenderContext){
        this.canvas = canvas;
        this.depth = 1;
        this.context = context || {};
    }
    
    create(scope:IEventScope):RenderEvent{
        let child = new RenderEvent(this.canvas);
        child.scope = scope;
        child.eventValue = this.eventValue;
        child.visibilityContainer = this.visibilityContainer;
        child.depth = this.depth + 1;
        child.context = this.context;
        if (child.depth > maxDepth){
            throw "Maximum nested draw depth reached (" + maxDepth + ")";
        }
        return child;
    }

    createScope(name:string,value:any):RenderEvent{
        return this.create({...this.scope,[name]:value});
    }

    forCollectionRow(collection:CollectionRef,row:any,key:any):RenderEvent{
        let childEvent = this.create({...this.scope,[collection.name]:row});
        childEvent.keyPrefix = key;
        return childEvent;
    }

    field = (field:string):FieldRef => {
       return this.canvas.getFieldRef(field,this.scope);
    }

    collection = (collection:string):CollectionRef => {
        return this.canvas.getCollectionRef(collection,this.scope)
    }
    
    get designMode():boolean {
        return this.context && this.context.designMode;
    }

    action = (action:ActionHandler | string):ActionRef => {
        let canvas = this.canvas;
        let actionName = getActionName(action);
        if (!actionName) return null;
        
        let actionRef = new ActionRef(canvas,actionName,this.scope);
        let def = this.canvas.actions[actionName];
        actionRef.def = def;
        return actionRef;
    }

   
    getValue = (expr:string):any => {
        return this.canvas.getValue(expr,{scope:this.scope,value:this.eventValue});
    }

    getBlockState():any {
        return this.canvas.blockState["$blockState"];
    }

    setBlockState(values:any){
        let newState = {...this.getBlockState(),...values};
        this.canvas.blockState["$blockState"] = newState;
    }
    screen:ScreenRef;

  
    getImageUrl(filename:string): string {
        return this.canvas.app.getImageUrl(filename);
    }

    render(content:any[] | any):any{
        if (Array.isArray(content)){
            return DynamicRender.render(this,content);
        }
        return DynamicRender.render(this,[content]);
    }
 
    
    computeStyles(defined:string,override?:string):ComponentStyles{
        return StyledText.computeStyles(defined,override);
    }
}