import { ICanvasError, ICodeError, IValidationError } from "core/types";
import { SuperProperty } from "typescript";

export class PageForbiddenError extends Error implements ICanvasError{
    showDialog?:boolean;
    messages:string[];
    sourceCode?:any;
    codeErrors?:ICodeError[];
    validationErrors?:IValidationError[];
    $$error:true;
    name:string;
    render:any;

    constructor(messages:string[],render:any){
        super(messages[0]);
        this.messages = messages;
        this.name = "FORBIDDEN";
        this.$$error = true;
        this.render = render;
    }
}