

import {NotificationToast, Spinner, Card,PutField, Tab, MessageBox,  ErrorDialog,Dialog,
     Confirm,  Button,ButtonGroup,ColorSwatch,Column,EmptyState,FileUpload,
    Line,NavLink,Panel,RadioButtons,RecordTabs,TabSet,Timeline, InlinePage,Spacer,Text,FilterField,Page, TimelineItem, TextBox} from './components';

import {Workspace,WorkspaceLink} from 'components/Workspace';
import { ProductTable } from 'components/product-table/ProductTable';
import {CartItem,DataCard} from 'components/product-table/CartItem';
import {CartDetail} from 'components/product-table/CartDetail';

import {SearchPanel} from 'components/SearchPanel';
import {CodeBlock} from 'components/CodeBlock';

import {Application,ICanvasError,RenderEvent,Url} from './core';

import { LISAApi } from "api/LISAApi";
import {Navigator} from './components/Navigator';
import Signin from './components/Signin';
import { ISigninProps } from './core/Application';
import React from 'react';
import {DataView,DataViewColumns,DataViewGrouping,DataViewSort,DataViewSorting} from 'components/data-table/DataView';
import { IParsedUrl } from 'core/Url';
import {Bot, BotCommand, BotDialogCard, BotOption} from 'components/Bot';
import {ValueChart, ValueLimitChart} from 'components/charts/ValueLimitChart';
import {SeriesChart} from 'components/charts/SeriesChart';
import {Annotation, CardGroup, List, LogicalValue, ToggleCard} from 'components/ToggleCard';
import {NavBarValueSelector} from 'components/NavBarValueSelector';
import {DateRangePicker} from 'components/popups/DateRangePicker';
import { DataTable} from 'components/data-table/DataTable';
import {PortalBanner} from 'components/PortalBanner';
import {Layout, LayoutHeader} from 'components/Layout';
import {LayoutNavIcon, LayoutNavStrip} from 'components/LayoutNavStrip';
import {Footer} from 'components/Footer';
import {Header} from 'components/Header';
import {ActionBar} from 'components/ActionBar';
import {WhenEmpty} from 'components/WhenEmpty';
import {ForEach} from 'components/ForEach';
import {ProductBreadcrumbs} from 'components/product-table/ProductBreadcrumbs';
import { FutureStock } from 'components/product-table/FutureStock';
import {Menu} from 'components/NavLink';
import {CartStatus} from 'components/product-table/ProductTitleBar';
import {NumberedList} from 'components/ForEach';
import {Sidebar} from 'components/Sidebar';
import {PageSource} from 'components/PageSource';
import {AvailableQtyCell} from 'components/product-table/AvailableQtyCell';
import {Container} from 'components/Container';
import {InNavbar} from 'components/InNavbar';
import {MultiOptionFilter, SingleOptionFilter} from 'components/filters/MultiOptionFilter';
import {FilterPanel} from 'components/filters/FilterPanel';
import {PageTitle} from 'components/PageTitle';
import {DialogButtons} from 'components/DialogButtons';
import {InSearch} from 'components/InSearch';
import {MainMenuLink} from 'components/MainNavMenu';
import {IconComponent} from 'components/Icon';
import {Stacked} from 'components/Stacked';
import {InTotalBar} from 'components/InTotalBar';
import {Value} from 'components/Value';
import {StudioEditorChildren} from 'components/studio/StudioEditorChildren';
import {SearchableList} from 'components/SearchableList';
import {RowSelector} from 'components/RowSelector';
import {StudioMenuItem} from 'components/studio/StudioMenuItem';
import { StudioPropEditor } from 'components/studio/StudioPropEditor';
import {InlineEditor} from 'components/InlineEditor';
import {MenuLaunch} from 'components/Container';
import {ActivityTimeline} from 'components/ActivityTimeline';
import {Image} from 'components/Image';
import {StudioNode} from 'components/studio/StudioNode';
import {InToolbox} from 'components/InToolbox';
import {TaskList} from 'components/TaskList';
import {SearchBox} from 'components/SearchBox';
import {DatePicker} from 'components/DatePicker';
import {InlineFieldEditor, InlineIconEditor} from 'components/InlineFieldEditor';
import {StudioToolbox,StudioToolboxFolder,StudioToolboxItem} from 'components/studio/StudioToolbox';
import {SectionEditor} from 'components/SectionEditor';
import {StudioEditorDialog} from 'components/studio/StudioEditorDialog';
import {SpotHolder} from 'components/SpotHolder';
import { StudioInlinePage } from 'components/studio/StudioInlinePage';
import { DesignerContentSlot } from 'components/studio/DesignerContentSlot';
import { DropList } from 'components/DropList';
import {Link} from 'components/Link';
import { SourceCode } from 'components/studio/SourceCode';
import {Body, PutBlock, TableRow} from 'components/PutBlock';
import { ElementContextMenu } from 'components/studio/ElementContextMenu';
import {DesignerProp} from 'components/studio/DesignerProp';
import { HtmlRender } from 'core/HtmlRender';
import {Table} from 'components/table/Table';
import { Pagination } from 'components/table/Table';
import {Select} from 'components/display/Select';
import { TextArea } from 'components/display/TextArea';

Application.addComponents("UI",{
    Bot,
    "Bot.Dialog": BotDialogCard,
    "Bot.Command":BotCommand,
    "Bot.Option":BotOption,
    Card,
    PutField,
    Tab,
    Button,
    Dialog,
    "Dialog.Header":Dialog.Header,
    ButtonGroup,
    ColorSwatch,
    Column,
    DataTable,
    EmptyState,
    FileUpload,
    FilterField,
    Icon:IconComponent,
    Line,
    NavLink,
    Panel,
    RadioButtons,
    RecordTabs,
    TabSet,
    Timeline,
    TimelineItem,
    InlinePage,
    SearchPanel,
    Spacer,
    Text,
    Workspace,
    WorkspaceLink,
    ProductTable,
    CodeBlock,
    CartItem,
    DataCard,
    CartDetail,
    DataView,"DataView.Columns":DataViewColumns,"DataView.Grouping":DataViewGrouping,"DataView.Sort":DataViewSort,"DataView.Sorting":DataViewSorting,
    "Chart.ValueLimit":ValueLimitChart,
    SeriesChart,
    "Chart.Value":ValueChart,
    ToggleCard,
    CardGroup,
    Page,
    NavBarValueSelector,
    DateRangePicker,
    PortalBanner,
    Layout,
    LayoutNavStrip,
    LayoutNavIcon,
    LayoutHeader,
    LogicalValue,
    Annotation,
    List,
    Footer,
    Header,
    ActionBar,
    WhenEmpty,
    ForEach,
    ProductBreadcrumbs,
    FutureStock,
    Menu,
    CartStatus,
    NumberedList,
    Sidebar,
    InNavbar,
    PageSource,
    AvailableQtyCell,
    Container,
    MultiOptionFilter,
    SingleOptionFilter,
    FilterPanel,
    PageTitle,
    DialogButtons,
    InSearch,
    MainMenuLink,
    Stacked,
    InTotalBar,
    Value,
    StudioEditorChildren,
    SearchableList,
    RowSelector,
    StudioMenuItem,
    InlineEditor,
    MenuLaunch,
    ActivityTimeline,
    Image,
    InToolbox,
    TaskList,
    InlineFieldEditor,
    StudioPropEditor,
    StudioToolbox,
    StudioToolboxItem,
    StudioToolboxFolder,
    SectionEditor,
    StudioEditorDialog,
    SearchBox,
    DatePicker,
    SpotHolder,
    InlineIconEditor,
    StudioInlinePage,
    DesignerContentSlot,
    DropList,
    Link,
    SourceCode,
    PutBlock,
    Body,
    TableRow,
    DesignerProp
});

HtmlRender.componentMap = {
    field:(event,props) => <PutField key={props.key} event={event} field={props.field} label={props.label} styles={null} labelStyles={null} asCalendar={false} />,
    card:(event,props) => <Card key={props.key} event={event} label={props.label} children={props.children} />,
    table:(event,props) => <Table key={props.key} event={event} tableProps={props} />,
    pagination:(event,props) => <Pagination key={props.key} event={event} collection={props.collection} />,
    select:(event,props) => <Select key={props.key} event={event} field={props.field} styles={{}}/>,
    input:(event,props) => <FilterField key={props.key}  $id="" field={props.field} />
}

Application.addComponents("Studio",{
    Node:StudioNode
});

Application.instance.getMessageBox = () => MessageBox as any;
Application.instance.getConfirmDialog = () => Confirm as any;
Application.instance.doPostRequest = LISAApi.doPostRequest;
Application.instance.renderError = (canvas,error:ICanvasError) => <ErrorDialog canvas={canvas} error={error}/>
var apiPath = window["LISA_API_PATH"] || "";
if (apiPath == "$apipath$"){
    apiPath = "";
}
Application.instance.host = apiPath;

Application.instance.renderSignin = (props:ISigninProps) => {
     return <Signin app={props.app} done={props.done} token={props.urlValues.token} tokenType={props.urlValues.tokenType} 
        username={props.urlValues.username} settings={props.app.signinSettings} />
} 



function App() {
    let app = Application.instance;
      

    if (app.studioSettings && app.studioSettings.enabled){
       
    }

    let location = document.location;
    let parsed = Url.parse(location.pathname,location.search);
    if (parsed.folder){
        app.currentWorkspace = "user/workspaces/" + parsed.folder;
    }

    return (
        <div className="App" >
            <NotificationToast />
            <Spinner/>
            <PageShell url={parsed}/>
            
        </div>
       
    );
}

function renderNavigator(parsed:IParsedUrl){
    let screenName;
    if (parsed.isScreen){
        screenName = parsed.folder + "/" + parsed.filename;
    }
    return <Navigator appName={parsed.app} domain={parsed.domain}  branch={parsed.branch} screenProps={parsed.search} screenName={screenName} />
}

export default App;


class PageShell extends React.Component<{url:IParsedUrl}>{


    componentDidMount(){
        let app = Application.instance;
     //   app.studioBroadcast.connect(this);
        document.addEventListener("visibilitychange", function() {
            if (document.visibilityState === 'visible') {
                app.startPolling();
            } else {
                app.stopPolling();
            }
        });
        if (document.visibilityState === 'visible'){
            app.startPolling();
        }
        document.addEventListener("contextmenu",this.handleContextMenu as any);
        document.addEventListener("mousemove",this.handleMouseMove as any);
        app.codeDesignBroadcast.connect(this);
    }

    componentWillUnmount(){
        let app = Application.instance;
     //   app.studioBroadcast.disconnect(this);
        app.stopPolling();
        document.removeEventListener("contextmenu",this.handleContextMenu as any)
        document.removeEventListener("mousemove",this.handleMouseMove as any);
        app.codeDesignBroadcast.disconnect(this);
    }

    render(){
     
        let app = Application.instance;
        let designerOpen = app.codeDesignerEnabled;

        let appContent =  (<div key="navigator" style={{backgroundColor:"var(--rt-page-bg-color)",height:"100%"}}>
                {renderNavigator(this.props.url)}
        </div>)

        let content;
        if (designerOpen){
            content = ( <DesignerOverlay>
               {appContent}
            </DesignerOverlay>)
        }
        else {
            content = appContent;
        }

        
        return (<div key="app" style={{position:"fixed",top:0,bottom:0,left:0,right:0,transition:"right 0.15s ease-out"}}>
            {content}
        </div>)

    }

    handleContextMenu = (e:React.MouseEvent) => {
        if (!e.shiftKey){
            e.preventDefault();
            let target = e.target as HTMLElement;
            if (target){
                let app = Application.instance;
                let rect = {top:e.clientY,bottom:e.clientY,left:e.clientX,right:e.clientX};
                app.contextMenuManager.handleOpen({rect,target});
            }
        }
    }

    handleMouseMove = (ev:any) => {
        let target = ev.target as HTMLElement;
        let app = Application.instance;
        if (target){
            let hoverElem = target.closest('[data-hover-popup]');
            if (hoverElem){
                let id = hoverElem.getAttribute("data-hover-popup");
                if (id != app.hoverPopupManager.activeHandlerId){
                    app.hoverPopupManager.handleOpen({x:ev.clientX,y:ev.clientY,target})
                }
                return;
            }
        }
        if (app.hoverPopupManager.activeHandlerId){
            app.hoverPopupManager.close();
        }

    }
}


class DesignerOverlay extends React.Component<{}>{

    dynamicStyleSheet:CSSStyleSheet;
    activeElemId:string;
    contentKey:number = 1;
    isDesignerOpen:boolean;

    componentDidMount(): void {
        document.addEventListener("mousedown",this.handleMouseDown,{capture:true});
        document.addEventListener("click",this.handleClick,{capture:true});
        this.dynamicStyleSheet = new CSSStyleSheet();
        (document as any).adoptedStyleSheets = [this.dynamicStyleSheet];

    }
    componentWillUnmount(): void {
        document.removeEventListener("mousedown",this.handleMouseDown,{capture:true})
        document.removeEventListener("click",this.handleClick,{capture:true});
        (document as any).adoptedStyleSheets = [];
    }

    render(): React.ReactNode {
        return <div style={{position:"absolute",top:0,left:0,right:0,bottom:0,backgroundColor:"#fff"}}>
            <div style={{position:"absolute",top:0,left:0,right:0,height:50}}>
                {this.renderToolbar()}
            </div>
            <div style={{position:"absolute",top:70,left:20,right:20,bottom:20,userSelect:"none"}} data-app-container="true">
                {this.props.children}
            </div>
        </div>
    }

    renderToolbar(){
        let app = Application.instance;
        let content;
        if (this.activeElemId){
            let i = this.activeElemId.indexOf(":");
            let section = this.activeElemId.substring(0,i);
            let element = this.activeElemId.substring(i + 1);
            let nodeId = {
                page:app.designerTargetPage,
                section,
                element
            }
            content = <ElementContextMenu key={this.contentKey} event={null} targetCanvas={null} nodeId={nodeId}  />
        }


        return <div style={{backgroundColor:"#fff",borderBottom:"solid 1px rgb(220,220,220)",height:50,fontFamily:"'Red Hat Mono'"}} >
            <div style={{height:"100%",display:"flex",alignItems:"center",paddingLeft:30,gap:25,paddingTop:4}} >
                {content}
            </div>
        </div>
    }

    handleMouseDown = (e:any) => {
        if (!e.shiftKey){
            let appContainer  = e.target.closest("[data-app-container]");
            if (!appContainer) return
            e.preventDefault();
            e.stopPropagation();
            let elem = e.target.closest('[data-elem-id]');
            if (elem){
                let elemId = elem.getAttribute("data-elem-id");
                this.setActiveElement(elemId);
            }
        }
    }

    handleClick = (e:any) => {
        if (!e.shiftKey){
            let appContainer  = e.target.closest("[data-app-container]");
            if (!appContainer) return
            e.preventDefault();
            e.stopPropagation();
        }
        else {
            
        }
    }

    setActiveElement(elemId:string){
        let sheet = this.dynamicStyleSheet;
        if (!sheet) return;
        if (sheet.cssRules.length){
            sheet.deleteRule(0);
            sheet.deleteRule(0);
        }
        
        if (elemId){
            let fullId = elemId;
            sheet.insertRule('[data-elem-id="' + fullId + '"] {position:relative;overflow:visible}',0);
            sheet.insertRule('[data-elem-id="' + fullId + '"]:after {pointer-events:none;content:\'\';z-index:1000;position:absolute;top:0;left:0;right:0;bottom:0;border:solid 2px #FFC800;}',1);
        }
        this.activeElemId = elemId;
        this.contentKey++;
        this.forceUpdate();
    }
}
