import { ActionRef, RenderEvent } from 'core';
import React from 'react';
import { ClickThrottle } from './helpers/ClickThrottle';

export class DialogButtons extends React.Component<{event:RenderEvent,cancelLabel?:string,styles?:string,hideCancel?:boolean},{confirming:boolean}>{

    clickThrottle:ClickThrottle;

    state = {confirming:false};

    buttonRef = React.createRef<HTMLButtonElement>();
    
    render(){
        if (this.state.confirming) return this.renderConfirming();

        let cancelButton;
        if (!this.props.hideCancel){
            let cancelLabel = this.props.cancelLabel || "Cancel";
            cancelButton = (<>
                <button
                    ref={this.buttonRef}
                    className="RT-Button RT-Button--cancel"
                    style={{fontSize:"15px",fontWeight:500,marginRight:4}}
                    
                    onClick={this.handleCancelClick}
                >
                    {cancelLabel}
                </button>
                {"\n"}
            </>)
        }
        let styles = this.props.event.computeStyles(null,this.props.styles);

    
        let className = ["RT-Dialog-Buttons",styles.className].join(' ');

        return (
            <div className={className} style={styles.style}>
                {cancelButton}
                {this.props.children}
            </div>
        );
    }

    renderConfirming(){
        let styles = this.props.event.computeStyles(null,this.props.styles);

    
        let className = ["RT-Dialog-Buttons",styles.className].join(' ');

        return (
            <div className={className} style={styles.style}>
                 <div style={{fontWeight:500,paddingTop:8}}>This page contains unsaved changes</div>
                 <button className="RT-Button btn-destructive btn-lg" onClick={this.handleProceedClick}>
                    Discard changes
                 </button>
                 <button className="RT-Button" style={{border:"solid 1px #212529"}} onClick={this.handleReturnClick}>
                    Don't close
                 </button>
            </div>
        );
    }

    handleCancelClick = (e:React.MouseEvent) => {
        let canvas = this.props.event.canvas;
        this.clickThrottle = this.clickThrottle || new ClickThrottle();
        this.clickThrottle.handle(e,null,() => {
            e.preventDefault();
            e.stopPropagation();
            if(canvas.trackUnsavedChanges && canvas.hasUnsavedChanges){
                this.setState({confirming:true});
            }
            else {
                canvas.closeDialogBox({continue:false});
            }
        
        });
    }

    handleReturnClick = (e:React.MouseEvent) => {
        this.clickThrottle = this.clickThrottle || new ClickThrottle();
        this.clickThrottle.handle(e,null,() => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({confirming:false});
        });
    }

    handleProceedClick = (e:React.MouseEvent) => {
        this.clickThrottle = this.clickThrottle || new ClickThrottle();
        this.clickThrottle.handle(e,null,() => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({confirming:false});
            let canvas = this.props.event.canvas;
            canvas.closeDialogBox({continue:false});
        });
    }

}
