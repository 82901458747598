import React from 'react';
import { toDisplay } from '../helpers';
import { AnchorPoint } from '../helpers/AnchorPoint';
import { DatePickerPopup } from '../popups/DatePickerPopup';
import { ComponentStyles, InputControl } from '../../core';
import { Popup } from '../popups/Popup';
import { DisplayProps } from '../PutField';
import { FieldError } from './FieldError';
import { FieldLabel } from './FieldLabel';
import { makeInput } from 'core/InputControl';


export const TextBox = makeInput(class extends React.Component<DisplayProps>{
    
    componentDidMount(){
        let input = this.props.input
        if (input.focusElemRef.current && input.field.shouldFocus) {
            input.focusElemRef.current.focus();
            input.focusElemRef.current.select();
        }
        if (this.props.autoComplete && input.focusElemRef.current){
            this.props.event.canvas.registerAutoFillField(this.props.field,input.focusElemRef.current);
        }
    }

    componentDidUpdate(){
        let input = this.props.input;
        if (input.focusElemRef.current && input.field.shouldFocus){
            input.focusElemRef.current.focus();
            input.focusElemRef.current.select();
        }
    }

    componentWillUnmount(): void {
        let input = this.props.input
        if (this.props.autoComplete && input.focusElemRef.current){
            this.props.event.canvas.unregisterAutoFillField(input.focusElemRef.current);
        }
    }

    render(){
        let input = this.props.input;
       
        let label;
        if (!this.props.hideLabel){
            label = this.props.label;
        }
        let isComboBox:boolean;
        if (input.field.lookup){
            isComboBox = true;
        }
        let maxLen = input.field.size;
        return <TextBoxPresenter input={input} placeholder={this.props.placeholder} format={this.props.format}
            help={this.props.help} label={label} required={this.props.required} isComboBox={isComboBox} 
            maxLen = {maxLen} styles={this.props.styles} labelStyles={this.props.labelStyles}
            noTabStop={this.props.noTabStop} autoComplete={this.props.autoComplete}/>
    }
});



class TextBoxPresenter extends React.Component<{
    input: InputControl;
    isComboBox?: boolean;
    width?:any;
    placeholder?:string;
    format?:string;
    help?:any;
    label:string;
    required?:boolean;
    noTabStop?:boolean;
    maxLen?:number;
    styles?:ComponentStyles;
    labelStyles?:ComponentStyles;
    autoComplete?:string;
    
}> {

    datePickerRef:React.RefObject<any>; // React.RefObject<Forms.DatePicker>;

    constructor(props){
        super(props);
        let input = this.props.input;
        if (input && input.field && input.type == "date"){
            input.handleLaunchFinder = this.launchDatePicker;
        }
    }

    render() {
        let input = this.props.input;
        let field = input.field;

     
        let hideValue:boolean;
        if (input.type == "uid"){
            hideValue = true;
        }

        let containerClass = ["RT-TextBox"];
        containerClass.push("RT-TextBox--" + input.type);
        let inputClassName = ["RT-TextBox__input"];
        let containerStyle:React.CSSProperties = {};
        if (this.props.width){
            containerStyle.width = this.props.width;
        }

        let errorElem;

        if (field.error) {
            containerClass.push("RT-TextBox--has-error");
            errorElem = <FieldError input={input}/>
        }
        if (hideValue){
            containerClass.push("RT-TextBox--hide-value");
        }

        let helpElem;
        if (this.props.help) {
            helpElem = (
                <small className="form-text text-muted">{this.props.help}</small>
            );
        }

        let readOnly = field.disabled || field.readonly;
        let label;

        if (this.props.label) {
            label = <FieldLabel  label={this.props.label} styles={this.props.labelStyles} required={this.props.required}/>
        }
       
        if (this.props.styles){
            inputClassName.push(this.props.styles.className);
        }
      
        let value = input.editValue as string;
       

        let isComboBox = this.props.isComboBox;
        let isDatePicker = input.type == "date" || input.type == "datetime";
        let displayElem;
        let clearButton;
      
        if (isComboBox && !input.dirty) {
          
            let lookupLabel = field.lookupLabel;
           
            if (hideValue){
                value = "";
            }
       
            displayElem = (
                <div className="RT-TextBox__description">
                    <div style={{ opacity: 0 }}>{value}</div>
                    <div className="RT-TextBox__description-text">
                        {toDisplay(lookupLabel)}
                    </div>
                </div>
            );
            if (field.value){
                clearButton = <div className="RT-TextBox__clear-button">
                    <ClearIcon onClick={this.handleClear}/>
                </div>
            }
        }

        let hasFinder: boolean;
        let finderButton;
        if (input.isLookup || isDatePicker) {
            hasFinder = true;
        }

        if (hasFinder) {
            let icon = (isDatePicker) ? Icons.datePicker() : Icons.search();
            finderButton = (
                <button
                    className="RT-TextBox__finder-button"
                    tabIndex={-1}
                    onClick={this.handleFinderClick}
                    ref={input.findButtonRef}
                >
                    {icon}
                </button>
            );
            containerClass.push("RT-TextBox--has-finder");
            if (input.finderOpen && !isDatePicker){
                value = "";
                displayElem = null;
            }
        }
        
        let datePicker;
        if (input.finderOpen && isDatePicker){
            datePicker = this.renderDatePicker();
        }
        let inputType = "text";
        if (this.props.format && this.props.format.toLowerCase() == "password") {
            inputType = "password";
        }
    
        let maxLength:number;
        if (this.props.maxLen){
            maxLength = this.props.maxLen;
        }
        return (
            <>
                <div
                    className={containerClass.join(" ")}
                    style={containerStyle}
                >
                    {label}
                    <div className="RT-TextBox__container">
                        {displayElem}
                        <input
                            name={this.props.autoComplete}
                            ref={input.focusElemRef}
                            className={inputClassName.join(" ")}
                            style={this.props.styles.style}
                            type={inputType}
                            placeholder={this.props.placeholder}
                            value={value || ""}
                            tabIndex={this.props.noTabStop ? -1 : null}
                            readOnly={readOnly}
                            onBlur={input.onBlur}
                            onChange={input.onChange}
                            onKeyDown={input.onKeyDown}
                            onKeyPress={input.onKeyPress}
                            maxLength={maxLength}
                            autoComplete={this.props.autoComplete || "custom-data"}
                        />
                        {clearButton}
                        {finderButton}
                        {helpElem}
                        {errorElem}
                    </div>
                </div>
                {datePicker}
            </>
        );
    }

    handleFinderClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.input.launchFinder(false);
    };

    handleClear = () => {
        let input = this.props.input;
        input.setValue(null);
        input.focusElemRef.current.focus();
    }

    launchDatePicker = async () => {
        let input = this.props.input;
        input.finderOpen = true;
        input.allowInputWithFinderOpen = true;
        this.datePickerRef = React.createRef();
        input.focusElemRef.current.focus();
        input.refresh();
    }

    renderDatePicker(){
        
        let input = this.props.input;
        let allowedValues = this.getAllowedDateValues();
        let content =  <DatePickerPopup ref={this.datePickerRef} input={input} value={input.editValue} 
            valueRange={input.field.valueRange as any} 
            allowedValues={allowedValues}
            onChange={this.handleDatePickerChange} close={this.handleCloseDatePicker} />
        return <Popup
            attachedRef={input.focusElemRef.current}
            onForceClose={this.handleClose}
            zIndex={20000}
            render={() => content}
            anchorPoint={AnchorPoint.BottomAlignLeft}

        />
    
    }

    getAllowedDateValues():string[] {

        let field = this.props.input.field;
        if (!field.options) return undefined;
        let values:string[] = [];
        for(let i = 0; i < field.options.length;i++){
            let option = field.options[i];
            values.push(option.value);
        }
        return values;
    }

    handleDatePickerChange = (date:string) => {
        let input = this.props.input;
        input.finderOpen = false;
        if (input.focusElemRef.current){
            input.focusElemRef.current.focus();
        }
        input.setValue(date);
    }

    handleCloseDatePicker = () => {
        let input = this.props.input;
        input.finderOpen = false;
       
        input.refresh();
    }

    handleClose = () => {
        let input = this.props.input;
        input.finderOpen = false;
        input.refresh();
    }

    
}

export class ClearIcon extends React.Component<{ onClick: () => void }>{
    render() {
        let style = { alignItems: "center", display: "inline-flex",cursor:"pointer" };
        return <a href='#' onClick={this.handleClick} tabIndex={-1} style={style} data-studio-click>
            <svg style={{ width: 16, height: 16 }} focusable="false" viewBox="0 0 32 32"><path d="M18.1,16l8.9-8.9c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0L16,13.9L7.1,4.9c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l8.9,8.9l-8.9,8.9c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"></path></svg>
        </a>
    }
    handleClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onClick();
    }
}






export class Icons {
    static search(){
         return <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" focusable="false" style={{display:"block"}} role="presentation" viewBox="0 0 24 24">
             <g fillRule="evenodd" fill="currentColor"><path fillRule="nonzero" d="M17.32 15.906l2.859 2.858a.5.5 0 0 1 .003.717l-.7.7a.5.5 0 0 1-.718-.002l-2.858-2.86a8 8 0 1 1 1.414-1.414zM11 17a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" ></path></g>
         </svg>
    }

    static datePicker(){
        let style:React.CSSProperties = {display:"block",width:20,height:20};
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style}><path d="M19,4H17V3a1,1,0,0,0-2,0V4H9V3A1,1,0,0,0,7,3V4H5A3,3,0,0,0,2,7V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V7A3,3,0,0,0,19,4Zm1,15a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12H20Zm0-9H4V7A1,1,0,0,1,5,6H7V7A1,1,0,0,0,9,7V6h6V7a1,1,0,0,0,2,0V6h2a1,1,0,0,1,1,1Z"/></svg>

    }

    static close(){
         return  <svg style={{ width: 16, height: 16,display:"block" }} focusable="false" viewBox="0 0 32 32"><path d="M18.1,16l8.9-8.9c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0L16,13.9L7.1,4.9c-0.6-0.6-1.5-0.6-2.1,0c-0.6,0.6-0.6,1.5,0,2.1l8.9,8.9l-8.9,8.9c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l8.9-8.9l8.9,8.9c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L18.1,16z"></path></svg>

    }
}