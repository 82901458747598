import { Application, Canvas, PageContainer } from 'core';
import React from 'react';
import { ScreenRef } from './ActionEvent';
import {Broadcast} from './Broadcast';
import { Dialog } from './Dialog';
import { Response } from './Response';
import { IPageLaunchParams } from './types';


export interface IBotMessage {
    id:string;
    type:'screen' | 'card';
    question?:string;
    text?:string;
    botName:string;
    screen?:string;
    params?:any;
    loaded?:boolean;
    timestamp?:string;
    answered?:boolean;
    content?:any;
    refresh?:() => void;
}

export interface IBotOption {
    id:string;
    label:string;
    screen?:string;
    params?:any;
    command?:string;
}

export interface IBotMenuCommand {
    name:string;
    label:string;
    description:string;
    hint?:string;
    screen:string;
    eventParam?:string;
}

export interface IBotInfo {
    botState:BotState;
    message:IBotMessage;
    command?:string;
    onLoaded?:() => void;
    dataRequestId?:string;
}

class BotPubSub {
    webSocket:WebSocket;
    listeners:BotState[] = [];
  

    async connect(listener:BotState){
        if (!this.webSocket){
            await this.connectToPubSub();
        }
        let index = this.findListener(listener);
        if (index == -1){
            this.listeners.push(listener);
        }
    }

    disconnect(listener:BotState){
        let index = this.findListener(listener);
        if (index != -1) {
            this.listeners.splice(index, 1);
        }
        if (this.listeners.length == 0){
            this.webSocket.close();
            this.webSocket = null;
        }
    }

    private findListener(listener:BotState):number{
        for(let i = 0; i < this.listeners.length;i++){
            let item = this.listeners[i];
            if (item == listener) return i;
        }
        return -1;
    }
    
    private async connectToPubSub(){
        let botConnectUrl = Application.instance.session.botConnect;
        let res = await fetch(botConnectUrl);
        let url = await res.text();
        let ws = new WebSocket(url);
        ws.onopen = () => console.log('connected');
        ws.onclose = () => console.log('ws closed');

        ws.onmessage  = event => {
            
            let data = event.data;
            for(let i = 0; i < this.listeners.length;i++){
                let listener = this.listeners[i];
                listener.onWebSocketMessage(data);
            }
          
        }
        this.webSocket = ws;
    }

   

}
export class BotState {
    canvas:Canvas;

    private static botPubSub:BotPubSub;

    broadcast = new Broadcast();
    promptBroadcast = new Broadcast();
    messages:IBotMessage[] = [];

    promptRef = React.createRef<HTMLInputElement>();
    scrollRef = React.createRef<HTMLDivElement>();
    promptValue:string = "";
    inputHelp:string;
    nextId = 1;

    commandMenuOpen:boolean;
    commandMenuActiveIndex:number;
    commandMenuItems:IBotMenuCommand[];
    commands:IBotMenuCommand[] = [
        {name:"sales",label:"sales",description:"display today's sales",hint:null,screen:"sales"},
        {name:"tag",label:"tag",description:"get tag information",hint:"number",screen:"tag"},
        {name:"truck",label:"truck",description:"get truck information",hint:"number",screen:"truck"},
        {name:"user",label:"user",description:"get user information",hint:"first or last name",screen:"user"}
    ]

    constructor(){
        var app = Application.instance;
        let greeting;
        if (app.session.firstName){
            greeting = "Hello " + app.session.firstName + "!";
        }
        else {
            greeting = "Hello!";
        }
        this.messages.push({type:"card",botName:"Lisabot",content:greeting,loaded:true,id:(this.nextId++).toString()});
    }

    async connect(){
       if (BotState.botPubSub == null){
           BotState.botPubSub = new BotPubSub();
       }
       BotState.botPubSub.connect(this);
    }

    disconnect(){
        if (BotState.botPubSub){
            BotState.botPubSub.disconnect(this);
        }
    }

    getParamsForQuestion(question:string){
        if (!question) return {};
        let i = question.indexOf(' ');
        let commandText:string;
        let value:string;
        if (i == -1){
            commandText = question;
        }
        else {
            commandText = question.substring(0,i);
            value = question.substring(i + 1);
        }
        let command = this.findCommand(commandText);
        if (!command) return null;
        
        let params:any = {screen:command.screen};
        if (value){
            let values = this.parseParameterValues(command,value);
            Object.assign(params,values);
        }
        return params;
    }
    
    parseParameterValues(command:IBotMenuCommand, s:string):any {
        let values:any = {};
        let i = s.indexOf(":");
        let eventParamName = command.eventParam || "@event-value";
        if (i == -1){
            values[eventParamName] = s;
            return values;
        }
        let segments = s.split(":");
        let paramName:string;
        for(let i =0 ; i < segments.length;i++){
            let seg = segments[i].trim();
            let sp = seg.lastIndexOf(' ');
            let value:string;
            let nextParam:string;
            if (sp == -1){
                if (i == segments.length - 1){
                    value = seg;
                }
                else {
                    nextParam = seg;
                }
            }
            else {
                value = seg.substring(0,sp);
                nextParam = seg.substring(sp + 1);
            }
        
            if (paramName){
                values["@" + paramName] = value;
            }
            else if (value){
                values[eventParamName] = value;
            }
            paramName = nextParam;
        }

        return values; 
    }

    

    findCommand(command:string):IBotMenuCommand {
        if (!command) return null;
        command = command.toLowerCase();
        for(let i =0 ; i < this.commands.length;i++){
            let c = this.commands[i];
            if (c.name == command){
                return c;
            }
        }
        return null;
    }
    onWebSocketMessage(data:string){
        if (data[0] == "{"){
            let message = JSON.parse(data);
            let messageId = message.header.message_id;
            this.dataArrived(messageId,message.body);
            return;
        }
        this.addQuestion(data,data);
        //this.botState.addMessage({loaded:true,type:"card",content:event.data,botName:"lisabot"});
    }

    send(){
        let text = this.promptValue;
        if (text){
            this.addQuestion(text,text);
            this.setPromptValue("");
        }
    }

    addMessage(item:IBotMessage){
        this.messages.push(item);
        this.broadcast.refresh();
    }

    addQuestion(value:string,text:string){
     
        this.messages.push({type:"screen",question:value,text,botName:"LisaBot",id:(this.nextId++).toString()});

        this.promptBroadcast.refresh();
        if (this.promptRef.current){
            this.promptRef.current.focus();
        }
        this.broadcast.refresh();
    }

    setPromptValue(value:string){
        this.promptValue = value;
        let help;
        if (value){
            let space = value.indexOf(' ');
            if (space == -1){
                this.buildCommmandMenu();
                this.commandMenuOpen = true;
            }
            else {
                this.commandMenuOpen = false;
                let commandText = value.substr(0,space);
                if (space == value.length  - 1){
                    let command = this.getCommand(commandText);
                    if (command){
                        help = value + command.hint;
                    }
                }
            }
        }
        else {
            this.commandMenuOpen = false;
        }
        this.inputHelp = help;
        this.promptBroadcast.refresh();
    }

    findMessage(message:IBotMessage):number {
        for(let i = 0; i < this.messages.length;i++){
            if (message == this.messages[i]) return i;
        }
        return -1;
    }

    getCommand(commandText:string):IBotMenuCommand{
        for(let i = 0; i < this.commands.length;i++){
            let command = this.commands[i];
            if (command.name == commandText){
                return command;
            }
        }
    }
    cancel(){

        if (this.promptRef.current){
            this.promptRef.current.focus();
        }
        this.messages.pop();
        this.broadcast.refresh();
       
    }

    scrollToEnd(){
        if (this.scrollRef.current){
            this.scrollRef.current.scrollTop = 99999;
        }
    }

    buildCommmandMenu(){
        this.commandMenuItems = [];
        
        let value = this.promptValue;
        let n:number;
        if (value){
            n  = value.length;
        }
        for(let i = 0; i < this.commands.length;i++){
            let command = this.commands[i];
            if (value){
                if (command.label.substr(0,n) == value){
                    this.commandMenuItems.push(command);
                }
            }   
            else {
                this.commandMenuItems.push(command);
            }
        }
        this.commandMenuActiveIndex = 0;
    }

    chooseActiveCommand(){
        if (this.commandMenuItems && this.commandMenuActiveIndex < this.commandMenuItems.length){
            let command = this.commandMenuItems[this.commandMenuActiveIndex];
            this.setPromptValue(command.name + " ");
            if (!command.hint){
                this.send();
            }
        }
    }

    menuUp(){
        if (this.commandMenuOpen){
            if (this.commandMenuActiveIndex > 0){
                this.commandMenuActiveIndex--;
                this.promptBroadcast.refresh();
            }
        }
        else {
            this.buildCommmandMenu();
            this.commandMenuOpen = true;
            this.promptBroadcast.refresh();
        }
    }
    menuDown(){
        if (this.commandMenuOpen){
            if (this.commandMenuItems && (this.commandMenuActiveIndex + 1) < this.commandMenuItems.length){
                this.commandMenuActiveIndex++;
                this.promptBroadcast.refresh();
            }
        }
        else {
            this.buildCommmandMenu();
            this.commandMenuOpen = true;
            this.promptBroadcast.refresh();
        }
    }
    executeOption(message:IBotMessage,option:IBotOption){
    
        if (option.id == "cancel"){
            this.cancel();
        }
        else {
            if (option.screen){
    
                let page:IPageLaunchParams = {
                    name:option.screen,
                    layout:"default",
                    props:option.params
                }
                let dialog = <PageContainer page={page} />
                Dialog.open(this.canvas,dialog,null,"dialog");
            }
            else {
                let value = option.id;
                let text = option.id;
                let command = option.command;
                if (command){
                    value = command + " " + option.id;
                    text = command + " " + option.label;
                }
                text = message.text;
                let index = this.findMessage(message);
                if (index != -1){
                    this.messages.splice(index,1);  // remove the dialog from the message list
                }
                this.addQuestion(value,text);
            }
        }
    }

    public waitingForData(canvas:Canvas){
        
    }   

    public async dataArrived(id:string,data:any){
       
        let canvas = this.findCanvas(id);
        if (!canvas) return;
        let screenRef = new ScreenRef(canvas,{});
        await Response.process(screenRef,"batch",data);
        canvas.loaded();
        canvas.botInfo.onLoaded();
    }

    private findCanvas(id:string):Canvas {
        let screens = Application.instance.activeScreens;
        for(let i = 0; i < screens.length;i++){
            let screen = screens[i];
            let canvas = screen.canvas;
            if (canvas.botInfo && canvas.botInfo.dataRequestId == id){
                return canvas;
            }
        }
        return null;
    }
}