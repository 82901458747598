import { Application } from "./Application";
import { RenderEvent } from "./RenderEvent";
import React from 'react';
import { IComponentDefinition, IComponentPropType } from "./ComponentDefinition";
import { KIND } from "./types";
import {ClickThrottle} from 'components/helpers/ClickThrottle';
import { StyledText } from "components/helpers/StyledText";
import { ActionRef } from "./ActionRef";
import { HtmlRender } from "./HtmlRender";

interface IContainerProps {
    event?:RenderEvent;
    children?:any[];
    key?:any;
    $dynamic?:boolean;
    $id?:string;
    $def?:IComponentDefinition;
    $kind?:string;
    $elem?:any;
    containerize?:boolean;
    $src_kind?:string;
}

export class DynamicRender {
    static nextKey:number = 30000;

    static render(event:RenderEvent, elems:any[]):any {
        let containerProps = {children:[]};
        DynamicRender.renderArray(event,elems,containerProps);
        let children = containerProps.children;
        if (children.length == 1){
            return children[0];
        }
        return children;
    }

    static containerClass(className:string):string{
        return className;
    }

    static renderArray(event:RenderEvent,elems:any[],containerProps:IContainerProps){
        if (!elems) return;
    
        for(let i = 0; i < elems.length;i++){
            let elem = elems[i];
            let id = elem.$id;
            if (elem.$sec){
                id = elem.$sec + ":" + id;
            }
            let tag = elem.$tag;

            if (tag){
                containerProps.children.push(HtmlRender.renderTag(event,elem,i,id));
            }
            let kind = elem.$kind;
            if (!kind) continue;
            
        
            if (kind[0] >= 'a' && kind[0] <= 'z'){
                containerProps.children.push(DynamicRender.renderHTMLElement(event,elem,i,id));
                continue;
            }
           
           
            let forEachElem:any;
        
            if (kind == "When"){
                if (DynamicRender.evalWhen(event,elem)){
                    DynamicRender.renderArray(event,elem.children,containerProps);
                }
                continue;
            }
            else if (kind == "UI.ForEach"){
                renderForEach(event,elem,containerProps);
                continue;
            }
            else if (kind == "IfBlock"){
                DynamicRender.runIfBlock(event,elem,containerProps);
                continue;
            }
            else if (kind == "UI.Content"){
                let contentValue = this.computeProp(event,"expr",elem.content);
                if (typeof(contentValue) === "string"){
                    let content = JSON.parse(contentValue);
                    if (Array.isArray(content)){
                        DynamicRender.renderArray(event,content,containerProps);
                    }
                }
                continue;
            }
            else if (kind == "PageSettings"){
                DynamicRender.loadPageSettings(event,elem);
                continue;
            }
          
            let Comp = Application.getKind(kind);
            if (Comp){
                let def:IComponentDefinition = Comp.$def;
                let key;
                if (elem.$id){
                    if (event.keyPrefix){
                        key = event.keyPrefix + ":" + elem.$id;
                    }
                    else { 
                        key = elem.$id;
                    }
                }
                else {
                    key = i;
                }
                let props:IContainerProps = {key,event,$dynamic:true,$id:id,$def:def,$kind:kind,$elem:elem,$src_kind:elem.$src_kind};
              
                
                if (def && def.props){
                    let ifProp = def.props["if"];
                    if (ifProp && elem["if"]){
                        let test = event.getValue(elem["if"]);
                        if (!test) continue;
                    }
                    for(let key in def.props){
                        let prop = def.props[key];
                        if (key == "children"){
                            if (prop.type == "content"){
                                if (elem.children){
                                    props.children = [];
                                    if (prop.asRenderFunc){
                                        props["render_children"] = (event:RenderEvent) => DynamicRender.render(event,elem.children);
                                    }
                                    else if (prop.asDesignMode){
                                        let childEvent = event.create(event.scope);
                                        childEvent.context = {...event.context,designMode:true};
                                        DynamicRender.renderArray(childEvent,elem.children,props);
                                    }
                                    else {
                                        DynamicRender.renderArray(event,elem.children,props);
                                    }
                                }
                            }
                        }
                        else if (prop.type == "content") {
                            if (prop.asRenderFunc){
                                props["render_" + key] = (event:RenderEvent) => DynamicRender.render(event,elem[key]);
                            }
                            else {
                                let contentValue = elem[key];
                                if (contentValue){
                                    props[key] =  DynamicRender.render(event,contentValue);
                                }
                            }
                        }
                        else {
                            
                            let value = elem[key];
                            if (prop.type == "object"){
                                props[key] = value;
                            }
                            else if (value || value === 0){
                                props[key] = DynamicRender.computeProp(event,prop.type,value);
                            }
                        }
                    }
                    if (forEachElem){
                        renderForEach(event,forEachElem,props);
                    }
                    let reactElem = React.createElement(Comp,props);
                    if (def.propName){
                        containerProps[def.propName] = reactElem;
                    }
                    else {
                        containerProps.children.push(reactElem);
                    }
                }
            }
        }       
    }

    static loadPageSettings(event:RenderEvent,elem){
        let children = elem.children;
        if (!children) return;
        for(let i = 0; i < children.length;i++){
            let child = children[i];
            if (child.$kind == "Title"){
                let title = this.computeProp(event,"text-expr",child.value);
                let subtitle = this.computeProp(event,"text-expr",child.subtitle);
                event.canvas.title = title;
                event.canvas.subtitle = subtitle;
                event.canvas.icon = this.computeProp(event,"expr",child.icon);
            }
            else if (child.$kind == "Layout"){
                event.canvas.width = child.width;
                event.canvas.height = child.height;
                event.canvas.styles = this.computeProp(event,"expr",child.styles);
                event.canvas.scrollable = child.scrollable;
                event.canvas.stackPosition = child.stackPosition;
                event.canvas.showClose = child.showClose;
            }
        }
       
       
    }

    static computeProp(event:RenderEvent,type:IComponentPropType,value:any):any {
        switch(type){
            case "field":return event.field(value);
            case "text":return value;
            case "enum":return value;
            case "text-expr":return StyledText.format(event.getValue(value));
            case "boolean":return !!value;
            case "expr": return event.getValue(value);
            case "action":return event.action(value);
            case "collection":return event.collection(value);
            case "block":return value;
            case "fragment":return DynamicRender.render(event,value);
            case "number":return value;
            case "record":return event.getValue(value);
            case "record-ref":return event.getValue(value);
            case "handler":return DynamicRender.getParams(event,value);
            case "page":return event.getValue(value); // value; 
            case "symbol":return value;     
            case "constant-expr":return value;    
            case "event":return DynamicRender.getActionRef(event,value);
            case "file":return event.field(value);
            case "object":return value;
        }
    }

    static getActionRef(event:RenderEvent,statements:any){
        let actionRef = new ActionRef(event.canvas,"prop",event.scope);
        actionRef.statements = statements;
        return actionRef;
    }

    static getParams(event:RenderEvent,elems:any[]):any {
        let params = event.canvas.createLaunchParameters(null,{scope:event.scope,value:event.eventValue})
        if (!elems) return params;
        for(let i = 0; i < elems.length;i++){
            let elem = elems[i];
            if (elem.$kind == KIND.PASS){
                let propName = elem.name;
                if (propName){
                    params[propName] = event.getValue(elem.value);
                }
            }
            else if (elem.$kind == KIND.PARAM_CONTENT){
                let propName = elem.name;
                if (propName){
                    params[propName] = elem.children;
                }
            }
        }
        return params;
    }

    static evalWhen(event:RenderEvent,elem:any) : boolean {
        let conditions = elem.conditions;
        if (!conditions || !conditions.length) return false;
        for(let i = 0; i < conditions.length;i++){
            let cond = conditions[i];
            let test = event.getValue(cond.expr);
            if (!test) return false;
        }
        return true;
    }

    static runIfBlock(event:RenderEvent, elem:any,containerProps:IContainerProps):Promise<any> {
        let statements = elem.statements;
        if (!statements) return;
        for(let i = 0; i < statements.length;i++){
            let statement = statements[i];
            if (statement.$kind == "Else"){
                DynamicRender.renderArray(event,statement.children,containerProps);
                return;
            }
            if (statement.$kind == "If" || statement.$kind == "ElseIf"){
                let test = event.getValue(statement.condition);
                if (test){
                    DynamicRender.renderArray(event,statement.children,containerProps);
                    return;
                }
            }
        }
    }

    static renderHTMLElement(event:RenderEvent,elem:any,index:number,id:string){
        let kind = elem.$kind;
        if (kind == "text"){
            if (elem.text){
                return event.getValue(elem.text);
            }
            return null;
        }
        if (kind == "test") return React.createElement(renderTest);

        let props:any = {};
        let requiresHandlers:boolean;
        for(let key in elem){
            if (key[0] == "$") continue;
            if (key == "children"){
                props["children"] = [];
                DynamicRender.renderArray(event,elem.children,props);
            }
            else if (key == "onClick"){
                props[key] = DynamicRender.getActionRef(event,elem[key]);
                if (props[key]){
                    requiresHandlers = true;
                }
            }
            else {
                props[key] =  event.getValue(elem[key]);
            }
        }
        props.key = index;
        if (requiresHandlers || id){
            props["event"] = event;
            props["$kind"] = elem.$kind;
            props["$src_kind"] = elem.$src_kind;
            props["$id"] = id;
            return <HtmlTag {...props} />
        }
        if (props["childClass"]){
            props["children"] = <WrappedChildren className={props["childClass"]} children={props["children"]}/>
        }
        return React.createElement(elem.$kind,props);
    }
}

class WrappedChildren extends React.Component<{className:string}>{
    render(){
        let className = this.props.className;
        return React.Children.map(this.props.children,(child:any,index) =>{
            if (!child) return child;
            return <div key={child.key} className={className}>{child}</div>
        }) || null;
    }
}
function renderTest(props:any){
    var statement:React.CSSProperties = {color:"rgb(205, 29, 208)"}
    let argValue:React.CSSProperties = {color:"rgb(1 132 191)"}
    let stringValue:React.CSSProperties = {color:"rgb(232 96 0)"}
    let args:React.CSSProperties = {marginLeft:25};
    let argName:React.CSSProperties = {};
    let spacer = <span style={{marginLeft:8}}/>
    return <div style={{fontFamily:"monospace",fontSize:"16px",marginBottom:50}}>
        <br/>
        <span style={statement}>MESSAGE</span>
        {spacer}
        <span style={argValue}><span style={{marginRight:1}}>@</span>message</span>
        <div style={args}>
            <span style={argName}>AS DIALOG BOX</span>
            <br/>
            <span style={argName}>STYLE</span>
            {spacer}
            <span style={stringValue}>'SUCCESS'</span>
            <br/>
            <span style={argName}>ON RECORD CHANGED</span>
            {spacer}
            <span style={{opacity:0.5}}>BEGIN</span>
            <div style={args}>
                <span style={statement}>REFRESH PAGE</span>
                <br/>
                
            </div>
            <span style={{opacity:0.5}}>END</span>
        </div>
        <br/>
        <span style={statement}>MESSAGE</span>
        {spacer}
        <span style={statement}>CONCAT</span><span>(</span>
        <div style={args}>
            <div style={args}>
                <span style={stringValue}>'Hello'</span>
                <span>,</span>
                <br/>
                <span style={stringValue}>'World!'</span>
            </div>
            <span>)</span>
        </div>
        <div style={args}>
            <span style={argName}>AS DIALOG BOX</span>
            <br/>
            <span style={argName}>STYLE</span>
            {spacer}
            <span style={statement}>IIF</span>
            <span>(</span>
            <div style={args}>
                <span style={argValue}>@message = @check</span>
                <span>,</span>
                <br/>
                <span style={statement}>CONCAT</span><span>(</span>
        
                <div style={args}>
                    <span style={stringValue}>'Hello'</span>
                    <span>,</span>
                    <br/>
                    <span style={stringValue}>'World!'</span>
                </div>
                <span>)</span>
                <br/>
                <span>,</span>
                <span style={stringValue}>'Error'</span>
            </div>
            <span>)</span>
        </div>
    </div>
}

function renderForEach(event:RenderEvent,forEachElem:any,containerProps:any){
    let collectionRef = event.canvas.getCollectionRef(forEachElem.collection,event.scope);
    if (!collectionRef) return null;
    let rows:any[] = collectionRef.rows;
    if (rows && rows.length){
        for(let index = 0; index < rows.length;index++){
            let row = rows[index];
            let rowEvent = event.create({...event.scope,[forEachElem.collection]:row});
            rowEvent.keyPrefix = index.toString();
            DynamicRender.renderArray(rowEvent,forEachElem.row,containerProps);
        }
    }
    else if (forEachElem.children){
        let whenEmptyProps = {children:[],whenEmpty:[]};
        DynamicRender.renderArray(event,forEachElem.children,whenEmptyProps as any);
        if (whenEmptyProps.whenEmpty){
            containerProps.children.push(whenEmptyProps.whenEmpty);
        }
    }
}

class HtmlTag extends React.Component<{event:RenderEvent,$kind,$id:string,onClick:ActionRef}>{

    clickThrottle:ClickThrottle;

    render(): React.ReactNode {
         let onClick;
         if (this.props.onClick){
            onClick = this.handleClick;
         }
        return React.createElement(this.props.$kind,{...this.props,onClick,event:null,"data-elem-id":this.props.$id});
    }
    
    handleClick = (e:React.MouseEvent) => {
        this.clickThrottle = this.clickThrottle || new ClickThrottle();
        this.clickThrottle.handle(e,null,() => {
            e.preventDefault();
            e.stopPropagation();
            let onClick = this.props.onClick;
            if (onClick){
                onClick.trigger();
            }
        });
    }

    
}