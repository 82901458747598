export class ClickThrottle {
    lastClick:number = 0;
    lastCurrentTarget:any;
    delay:number = 1000;

    handle(e:React.MouseEvent,id:string,handler:()=> any){
        let current = Date.now();
        let lastClick = this.lastClick;
        let lastCurrentTarget = this.lastCurrentTarget;

        this.lastClick = current;
        this.lastCurrentTarget = e.currentTarget;

        if (lastCurrentTarget == e.currentTarget && id == id && lastClick && ((current - lastClick) < this.delay)){
            e.preventDefault();
            e.stopPropagation();
            console.log("Click ignored");
            return;
        }
        handler();
    }
}